import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { IPIIMetaData } from './phx-pii-field.interface';

/** The sole purpose of this abstraction is to prevent component bloat in PhxPiiFieldComponent, so it does not have to
 * know about all possible entity types and their api endpoints to reveal the pii data, as it means any new item causes
 * a change in this common component.
 * */
@Injectable()
export abstract class PiiRevealService {
  abstract revealPiiData(metadata: IPIIMetaData): Observable<string>;
}
