import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-phx-slider',
  templateUrl: './phx-slider.component.html',
  styleUrls: ['./phx-slider.component.less']
})
export class PhxSliderComponent {
  @Input() showSlider = false;
  @Input() fullScreen = false;
  @Input() isResizable = false;

  @Output() onClose: EventEmitter<any> = new EventEmitter<any>();
}
