<div class="login-container">
  <form [formGroup]="form" class="login-form mat-elevation-z4">
    <div class="login-fields">
      <div class="logo-container text-center">
        <img src="../../../assets/login/logo_FBO.svg" alt="logo"/>
      </div>
      <div class="field" [class.disabled]="askPassword">
        <mat-icon alt="emailIcon">email</mat-icon>
        <input autocomplete="email" placeholder="Enter your email" #emailInputField type="email" class="form-control input-lg"
               formControlName="email" (keyup.enter)="checkUserEmail()" aria-label="email"/>
        <input autocomplete="current-password" type="password" class="d-none">
      </div>
      <div class="field" *ngIf="askPassword">
        <mat-icon alt="lockIcon">lock</mat-icon>
        <input type="email" autocomplete="username" class="d-none">
        <input placeholder="Enter your password" autocomplete="current-password" #passwordInputField type="password" class="form-control input-lg"
               formControlName="password" (keyup.enter)="login()" aria-label="password"/>
      </div>
    </div>
    <div class="login-actions">
      <ng-container *ngIf="askPassword; else firstScreenButtons">
        <button type="button"
                class="btn btn-secondary pull-left" (click)="backToEmailField()">
          Back
        </button>
        <button type="button" [disabled]="form.invalid"
                class="btn btn-primary" (click)="login()">
          Sign in
        </button>
      </ng-container>
      <ng-template #firstScreenButtons>
        <span></span>
        <button type="button" [disabled]="form.get('email').invalid"
                class="btn btn-primary" (click)="checkUserEmail()">
          Next
        </button>
      </ng-template>
    </div>

    <div *ngIf="askPassword" class="login-recover text-center">
      <a (click)="forgotAccount()">Recover Password</a>
    </div>
    <div *ngIf="userIpRestrictionError"
         class="login-recover text-center alert alert-danger margin-top-20 margin-left-10">
      {{userIpRestrictionError}}
    </div>
    <div *ngIf="isLoginFailed" class="login-recover text-center alert alert-danger margin-top-20 margin-left-10">
      {{errorMessage}}
    </div>
  </form>
</div>
