import { AfterViewInit, Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';

@Directive({
  selector: '[appApplyClassesWhenScrolledIntoView]'
})
export class ApplyClassesWhenScrolledIntoViewDirective implements OnInit, OnDestroy, AfterViewInit {
  @Input('appApplyClassesWhenScrolledIntoView') applyClasses: string | string[];

  private observer?: IntersectionObserver;

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    this.el.nativeElement.style.visibility = 'hidden';
    this.createObserver();
  }

  ngAfterViewInit() {
    this.observer?.observe(this.el.nativeElement);
  }

  ngOnDestroy() {
    this.removeObserver();
  }

  private removeObserver(): void {
    this.observer?.unobserve(this.el.nativeElement);
  }

  private createObserver(): void {
    try {
      this.observer = new IntersectionObserver(
        entries => {
          this.checkIfInView(entries);
        },
        { threshold: [0] }
      );
    } catch {
      /** If Intersection Observer is not available, add all classes right away and call it a day  */
      this.addClasses();
    }
  }

  private addClasses(): void {
    (Array.isArray(this.applyClasses) ? this.applyClasses : [this.applyClasses]).forEach(c => this.el.nativeElement.classList.add(c));
    this.el.nativeElement.style.visibility = 'visible';
  }

  private checkIfInView(entries: IntersectionObserverEntry[]): void {
    if (entries[0].isIntersecting) {
      this.addClasses();
      this.removeObserver();
    }
  }
}
