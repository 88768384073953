<!-- NOTE: header -->
<div class="version-panel-container">
    <div class="version-header-container">
        <div class="title-container">
            <h3>
                <div class="material-icons-outlined col-xs-1">
                    filter_none
                </div>
                <div i18n="@@phx.panelVersion.versions" class="col-xs-4">Versions</div>
            </h3>
        </div>
    </div>
    <!-- NOTE: compare versions toggle -->
    <div class="version-toggle-container">
        <app-phx-version-comparison-toggle></app-phx-version-comparison-toggle>
    </div>
    <!-- NOTE: single version display -->
    <ng-container *ngIf="!multiple && sortedVersions?.length">
        <ng-container
          *ngTemplateOutlet="versionTemplate; context: { versions:sortedVersions }"
        >
        </ng-container>
    </ng-container>
    <!-- NOTE: multiple version display -->
    <ng-container *ngIf="multiple && groupedVersions.length">
        <app-phx-accordion
          [closeOthers]="false"
        >
            <ng-container *ngFor="let group of groupedVersions; let i = index">
                <div
                  class="version-item-container"
                  [ngClass]="{'active': activeGroupIndex === i}"
                >
                    <app-phx-accordion-group
                      #accordian
                      [showToggle]="false"
                      [(isOpen)]="group.isActive"
                      [class.active]="group.isActive"
                      class="version-group"
                    >
                        <div accordion-heading>
                            <div class="version-item">
                                <div class="version-row group-parent">
                                    <div class="version-number">{{ entityType }} {{ group.groupNumber }}
                                    </div>
                                    <div class="version-date group-date">Effective {{ group.versions[0].EffectiveDate |
                                        date:
                                        phxConstants.DateFormat.mediumDate }}</div>
                                    <div class="group-parent-icons">
                                        <i class="material-icons header-glyphicon"
                                            *ngIf="!accordian.isOpen">keyboard_arrow_down</i>
                                        <i class="material-icons header-glyphicon"
                                            *ngIf="accordian.isOpen">keyboard_arrow_up</i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ng-container
                          *ngTemplateOutlet="versionTemplate; context: { versions:group.versions, activeGroupIndex: i }"
                        >
                        </ng-container>
                    </app-phx-accordion-group>
                </div>
            </ng-container>
        </app-phx-accordion>
    </ng-container>
</div>

<ng-template
  #versionTemplate
  let-versions="versions"
  let-activeGroupIndex="activeGroupIndex"
>
    <ng-container *ngFor="let version of versions">
        <!-- NOTE: if there is a currentGroup use that for comparison -->
        <div class="version-item-container"
            (click)="selectVersion(version, version.VersionNumber === currentVersion && (version.Id === (currentGroup || version.Id)), activeGroupIndex)"
            [ngClass]="{
                'active': version.VersionNumber === currentVersion && currentGroup && version.GroupNumber === currentGroup
                    || version.VersionNumber === currentVersion && !currentGroup,
                'version-comparison-source': (
                    (comparisonSourceVersionNumber$ | async) === version.VersionId ||
                    (comparisonSourceVersionNumber$ | async) === version.VersionNumber
                ) && (version.Id === (currentGroup || version.Id))
            }">
            <div class=" version-item" title="{{
                    version.VersionNumber === currentVersion ? 'Viewing: ' : ''}}{{
                    (comparisonSourceVersionNumber$ | async) === version.VersionNumber ? 'Comparing to: ' : ''}}{{
                    version.Id + '.' + version.VersionNumber
                }}">

                <div class="version-row">
                    <div i18n="@@common.versionInterpolation" class="version-number">Version {{
                        version.VersionNumber
                        }}</div>
                    <div i18n="@@common.effectiveDateInterpolation" class="version-date">Effective {{
                        version.EffectiveDate
                        | date: phxConstants.DateFormat.mediumDate }}</div>
                </div>
                <div class="version-row">
                    <div class="version-status" *ngIf="statusGroup" [ngClass]="{
                        'active-highlight' : version.StatusGroup === activeStatusGroup, 
                        'inprogress-highlight' : version.StatusGroup !== inactiveStatusGroup && version.StatusGroup !== activeStatusGroup 
                    }">
                        <ng-container *ngIf="version.StatusId">
                            {{ version.StatusId | CodeValue: statusGroup: 'text'}}
                        </ng-container>
                        <ng-container *ngIf="version.StatusCode && !version.StatusId">
                            {{ version.StatusCode | CodeValueByCode: statusGroup: 'text'}}
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</ng-template>
