import { Pipe, PipeTransform } from '@angular/core';
import { CodeValue } from 'src/app/common/model';
import { IOverrideTimesheetException } from 'src/app/common/model/override-timesheet-exception';

@Pipe({
  name: 'availableOverrideExceptionCode'
})
export class AvailableOverrideExceptionCodePipe implements PipeTransform {
  transform(selectedOverrideExceptionCode: string | null, allOptions: CodeValue[], currentOverrideExceptionCode: IOverrideTimesheetException[]): CodeValue[] {
    const selectedCodes = new Set(currentOverrideExceptionCode.map(({ ExceptionCode }) => ExceptionCode));

    return allOptions.filter(({ code }) => !selectedCodes.has(code) || code === selectedOverrideExceptionCode);
  }

}
