import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { DocumentComplianceData } from '../../data-services/fbo-monolith-compliance-data/models';
import { PhxModalComponent } from '../phx-modal/phx-modal.component';
import { FileItem } from 'ng2-file-upload';
import { PhxButton } from '../../model';
import { ComplianceStatus } from '../../model/rule-engine';
import { DialogService } from '../../services/dialog.service';

@Component({
  selector: 'app-phx-document-file-upload-preview-modal',
  templateUrl: './phx-document-file-upload-preview-modal.component.html',
  styleUrls: ['./phx-document-file-upload-preview-modal.component.less']
})
export class PhxDocumentFileUploadPreviewModalComponent {
  @Output() uploadCurrentFile = new EventEmitter<FileItem>();
  @Output() getNewFile = new EventEmitter();

  complianceData: DocumentComplianceData;
  fileUrl: string;
  buttonList: PhxButton[] = [];
  currentComplianceStatus: ComplianceStatus;

  readonly ComplianceStatus = ComplianceStatus;

  private readonly errorStatuses = new Set(['Error', 'Critical', 'Fatal']);
  private currentFileItem: FileItem;

  @ViewChild(PhxModalComponent) modal: PhxModalComponent;

  constructor(
    private dialogService: DialogService
  ) {

  }

  open(complianceData: DocumentComplianceData, fileUrl: string, fileItem: FileItem) {
    this.complianceData = complianceData;
    this.fileUrl = fileUrl;
    this.currentFileItem = fileItem;

    this.setStatus();
    this.setModalButtons();

    this.modal.show();
  }

  private setStatus(): void {
    const isCompliant = this.complianceData.documentValidationRuleResults.every(rule => rule.isValid);

    if (isCompliant) {
      this.currentComplianceStatus = ComplianceStatus.Compliant;
    } else {
      const isNonCompliant = this.complianceData.documentValidationRuleResults.some(rule => this.errorStatuses.has(rule.executedRuleLevel || rule.ruleLevel));
      this.currentComplianceStatus = isNonCompliant ? ComplianceStatus.NonCompliant : ComplianceStatus.Warning;
    }
  }

  private doUpload() {
    if (this.currentComplianceStatus !== ComplianceStatus.Compliant) {
      this.dialogService.confirm('Document Compliance', 'Uploading a document with errors can delay your onboarding process. Do you want to continue?', {
        size: 'md'
      }).then(() => { this.completeUpload(); }).catch(() => { });
    } else {
      this.completeUpload();
    }
  }

  private completeUpload() {
    this.currentFileItem.isUploaded = false;
    this.uploadCurrentFile.emit(this.currentFileItem);
    this.modal.hide();
  }

  private setModalButtons() {
    this.buttonList = [];
    this.buttonList.push({
      icon: null,
      tooltip: this.currentComplianceStatus !== ComplianceStatus.Compliant ? 'Proceed with Errors' : 'Proceed',
      btnType: 'primary',
      action: () => {
        this.doUpload();
      }
    });

    this.buttonList.push({
      icon: null,
      tooltip: 'Replace',
      btnType: 'default',
      action: () => {
        this.getNewFile.emit();
        this.modal.hide();
      }
    });
  }
}
