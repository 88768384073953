import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../../services/navigation.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-no-access',
  templateUrl: './no-access.component.html',
  styleUrls: ['./no-access.component.less']
})
export class NoAccessComponent implements OnInit {

  constructor(
    private navigationService: NavigationService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.navigationService.disableBreadcrumbs();
  }

  gotItClick() {
    this.router.navigate(['/next', 'activity-centre', 'to-do'], { replaceUrl: true });
  }
}
