import { Observable } from 'rxjs';
import { IActionPermissionAction } from './domain-service-response';

export interface StateAction {

  /**
   * The state action id defined in ApplicationConstants that's in sync with the codetable in the database.
   * It is used to retrieve default displayText and commandName.
   * Security will also be checked against it.
   * Pure UI action does not need to provide this field.
   */
  actionId?: number;
  actionValidationUrl?: string;

  /**
   * Set this flag to true when actionId is provided but does not wish to perform security check against it.
   * ex. batch screen actions
   */
  skipSecurityCheck?: boolean;

  /**
   * The display text of the button.
   * Override any default if provided.
   */
  displayText?: string;

  /**
   * The command name of the button.
   * Override any default if provided.
   */
  commandName?: string;

  /**
   * The unique action name
   * Used for tagging the button/list element for analytics
   * Defaults to the commandName
   * Override any default if provided.
   */
  actionName?: string;

  /**
   * The sort order of the button within the same style.
   */
  sortOrder?: number;

  /**
   * The style of the button.
   * Please refer to the enum for currently supported style.
   * Currently ordered as PRIMARY -> DANGER -> WARNING -> SECONDARY -> ...
   */
  style?: StateActionButtonStyle;

  /**
   * Set this flag to true when a declined comment dialog is desired.
   * The comment entered is returned in the actionOption obj.
   */
  showDeclinedCommentDialog?: boolean;

  // Set this flag when the dropdown menu of prefilled defined reasons above the freeform comment dialog is desired.
  showDeclineReasons?: boolean;

  // If the above flag is true, this field is used to retrieve the list of reasons from the database for the specific page (workorder, people, compliance document, etc.)
  entityTypeId?: number;

  /**
   * Use this observable to determine the disabled state of the button
   */
  disabled$?: Observable<boolean>;

  // Action Permission Specific Variables
  entityType?: number;
  isAuditable?: boolean;
  actionConfig?: IActionPermissionAction;
  displayType?: string;
  isActionCompleted?: boolean;

  /* ----- private variables, do not use outside of component. ----- */
  primaryAction?: boolean;
  secondaryAction?: boolean;
  dangerAction?: boolean;
  warningAction?: boolean;

  /**
   * This function is called when the button is clicked.
   * @param action - the action itself
   * @param componentOption - component data that can be used in the logic
   * @param actionOption - additional data available from the action
   */
  onClick(action: StateAction, componentOption: StateActionButtonsOption, actionOption?: OnClickStateActionOption): void | Promise<void>;

  /**
   * This function is used to determine the hidden state of the button.
   * @param action - the action itself
   * @param componentOption - component data that can be used in the logic
   * @returns - hidden or not
   */
  hiddenFn?(action: StateAction, componentOption: StateActionButtonsOption): boolean;

  /**
   * This function is used to determine the disabled state of the button.
   * @param action - the action itself
   * @param componentOption - component data that can be used in the logic
   * @returns - disabled or not
   */
  disabledFn?(action: StateAction, componentOption: StateActionButtonsOption): boolean;
}

export interface StateActionButtonsOption {
  displayType: StateActionDisplayType;
  refData: any;
}

export interface OnClickStateActionOption {
  comment?: string;
  atsPlacementId?: number;
  declineReasons?: string[] | string;
}

export enum StateActionButtonStyle {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  DANGER = 'DANGER',
  WARNING = 'WARNING'
}

export enum StateActionDisplayType {
  BUTTON = 'BUTTON',
  DROPDOWN = 'DROPDOWN',
  SMALLBUTTON = 'SMALL_BUTTON',
  LARGEBUTTON = 'LARGE_BUTTON',
  BUTTONWITHELIPSIS = 'BUTTON_WITH_ELIPSIS'     // buttons without style config will be placed in elipsis
}
