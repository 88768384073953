import { Theme } from '../models/theme.model';
import { PhxConstants } from '../../../model';

export const SOFTCHOICE_THEME: Theme = {
  tenantCode: PhxConstants.ThemeTenant.SOFTCHOICE,
  tenantLogo: '/assets/logos/logo-softchoice.png',
  attributes: [
    {
      value: '#fff',
      variableName: 'side-nav-text-hover'
    },
    {
      value: '#fff',
      variableName: 'logo-background'
    },
    {
      value: '#FF5000',
      variableName: 'accent-color'
    },
    {
      value: 'url("/assets/images/todo-list-check-softchoice.svg")',
      variableName: 'todo-list-check'
    },
    {
      value: 'url("/assets/timeline/status_current_softchoice.svg")',
      variableName: 'timeline-current-icon'
    },
    {
      value: 'url("/assets/timeline/status_done_softchoice.svg")',
      variableName: 'timeline-done-icon'
    }
  ]
};
