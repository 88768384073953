export const fboAdvantages: Array<{ icon?: string; header: string; text: string; bgColor: string; iconColor: string }> = [
  {
    header: 'Contract Management',
    text: 'Create and manage contract engagements over their full lifecycle. Publish changes and extensions in seconds!',
    bgColor: '#FFF3CD',
    iconColor: '#EC9F06',
    icon: 'description'
  },
  {
    header: 'Timesheet and Expenses',
    text: 'Set up timesheets and expenses for worker entry and approval. Timesheets in a VMS? No problem! Easily import hours into FBO.',
    bgColor: '#EAF1D6',
    iconColor: '#68811E',
    icon: 'alarm_on'
  },
  {
    header: 'AI Driven Compliance',
    text: 'Spend less time reviewing documents and client compliance requirements with our AI driven compliance framework. We do the work for you!',
    bgColor: '#D7EDDF',
    iconColor: '#218947',
    icon: 'list_alt'
  },
  {
    header: 'Bill and Pay',
    text: 'Directly bill clients and pay your workers seamlessly after timesheets have been approved.',
    bgColor: '#CCE8E4',
    iconColor: '#00897A',
    icon: 'paid'
  },
  {
    header: 'Document Publishing',
    text: 'Use our document templates to generate a signing package that can be sent to your workers for easy onboarding and change management.',
    bgColor: '#CCE1E4',
    iconColor: '#127B8A',
    icon: 'task'
  },
  {
    header: 'Integrations',
    text: 'Integrate with ATS, onboarding and Accounting systems and reduce your administration load.',
    bgColor: '#D5DADE',
    iconColor: '#2F4858',
    icon: 'integration_instructions'
  }
];
