import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalSearchBarComponent } from './components/global-search-bar/global-search-bar.component';
import { DxSelectBoxModule } from 'devextreme-angular';
import { GlobalSearchBarService } from './services/global-search-bar.service';
import { ReactiveFormsModule } from '@angular/forms';
import { GlobalSearchService } from '../services/global-search-service';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { GlobalSearchSuggestionAssignmentComponent } from './components/global-search-suggestion-assignment/global-search-suggestion-assignment.component';
import { GlobalSearchSuggestionTransactionComponent } from './components/global-search-suggestion-transaction/global-search-suggestion-transaction.component';
import { GlobalSearchSuggestionPaymentComponent } from './components/global-search-suggestion-payment/global-search-suggestion-payment.component';
import { PhoenixCommonModule } from 'src/app/common/PhoenixCommon.module';
import { GlobalSearchUtil } from '../util/GlobalSearchUtil';

@NgModule({
  declarations: [GlobalSearchBarComponent,
    GlobalSearchSuggestionAssignmentComponent,
    GlobalSearchSuggestionTransactionComponent,
    GlobalSearchSuggestionPaymentComponent],
  exports: [
    GlobalSearchBarComponent
  ],
  imports: [
    CommonModule,
    PhoenixCommonModule,
    DxSelectBoxModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule
  ],
  providers: [
    GlobalSearchBarService,
    GlobalSearchService,
    GlobalSearchUtil
  ]
})
export class GlobalSearchBarModule {
}
