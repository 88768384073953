<app-phx-accordion
  *ngIf="hasHistoryToDisplay"
>
  <app-phx-accordion-group
    *ngFor="let stateHistoryHeader of stateHistoryHeaders; first as isfirstVersion"
    [(isOpen)]="stateHistoryHeader.isOpen"
    [isDisabled]="nonVersion"
    panelClass="state-history-panel"
    [class.non-version]="nonVersion"
    [showToggle]="false"
  >
    <div accordion-heading class="version-header" [class.first-version-header]="isfirstVersion">
      <div class="menu-icon">
        <i class="glyphicon"
          [ngClass]="{'glyphicon-menu-up': stateHistoryHeader.isOpen, 'glyphicon-menu-down': !stateHistoryHeader.isOpen}"></i>
      </div>
      <div class="version-header-content">
        <div class="col-sm-8">
          <div class="version-number">
            {{stateHistoryHeader.VersionDisplayText}}
          </div>
          <div class="version-status" *ngIf="stateHistoryHeader.CurrentStatus">
            {{'workflow.stateHistory.Status' | phxTranslate}}
            {{stateHistoryHeader.CurrentStatus | CodeValue: statusCodeValueGroups}}
          </div>
        </div>
        <div class="col-sm-4">
          <div class="version-effective-date" *ngIf="stateHistoryHeader.EffectiveDate">
            {{'workflow.stateHistory.Effective' | phxTranslate}}
            {{stateHistoryHeader.EffectiveDate | date:phxConstant.DateFormat.mediumDate}}
          </div>
        </div>
      </div>
    </div>
    <div class="content-group state-history-wrapper">
      <div *ngIf="(!stateHistoryHeader.StateHistoryGrouped || stateHistoryHeader.StateHistoryGrouped.length === 0) && showNoHistoryMessage">
        History Not Available
      </div>
      <div *ngFor="let group of stateHistoryHeader.StateHistoryGrouped; last as isLastGroup" class="time-block"
        [class.future]="group.Future" [class.last]="isLastGroup" [class.current]="group.LatestCurrent">
        <div class="time-block-container">
          <div *ngFor="let history of group.StateHistory; last as isLastHistory" class="content"
            [class.last]="isLastHistory">
            <div class="row">
              <div class="col-lg-9 col-xs-8">
                <div class="col-lg-4 status" [class.next-step]="history.NextStep">
                  <ng-container *ngIf="history.NextStep">
                    {{'workflow.stateHistory.NextStep' | phxTranslate}}
                  </ng-container>
                  {{history.EntityStatusId | CodeValue: statusCodeValueGroups}}
                </div>
                <div class="col-lg-8 description">
                  {{history.Description}}
                </div>
              </div>
              <div class="col-lg-3 col-xs-4 date">
                <ng-container *ngIf="history.CreatedDatetime">
                  {{history.CreatedDatetime | date:phxConstant.DateFormat.medium}}</ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </app-phx-accordion-group>
</app-phx-accordion>
