import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { AbstractControl as LegacyAbstractControl } from './../ngx-strongly-typed-forms';
import { FormGroup as LegacyFormGroup } from 'src/app/common/ngx-strongly-typed-forms';
import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { VersionComparisonToggleService } from './version-comparison-toggle.service';
import { IDocument, IOverrideTimesheetException } from '../model/override-timesheet-exception';
import { ToTypedFormGroup } from '../model/to-typed-form-group.model';

type PhxFormControl = AbstractControl<any, any> | LegacyAbstractControl<any>;
type PhxFormGroup<T> = FormGroup<ToTypedFormGroup<T>> | LegacyFormGroup<T>;
type PhxFormArray<T> = FormArray<FormGroup<ToTypedFormGroup<T>>> | LegacyFormGroup<T[]>;

@Injectable()
export abstract class VersionComparisonService<EntityGetParams, EntityType> {
  customFormNames: Record<string, string>;

  /** NOTE: list of controls with different values than last version */
  protected changedControls$ = new BehaviorSubject<Map<PhxFormControl, string>>(new Map());
  /** NOTE: some content forms are not part of the root form and we need to compare them separately */
  protected customFormsChangedControls$ = new BehaviorSubject<Map<PhxFormControl, string>>(new Map());
  /** NOTE: list of tab names not part of app-phx-navigation-bar that we want to highlight (ie. org role type vertical tabs) */
  protected changedCustomTabNames$ = new BehaviorSubject<string[]>([]);
  /** NOTE: list of items that were removed from last version - used to message user */
  protected versionCompareMessage$ = new BehaviorSubject<string>('');

  /** NOTE: maintain cache of comparison details */
  protected versionComparisonsCache: Map<number, Map<PhxFormControl, string>>;
  protected versionCompareMessagesCache: Map<number, string>;
  protected versionComparisonCustomTabNamesCache: Map<number, string[]>;

  /** NOTE: list of custom forms - components manually added their custom forms to this list by calling 'addForComparison' */
  protected customFormsForComparison: Map<string, FormGroup<ToTypedFormGroup<any>> | LegacyFormGroup<any> | FormGroup<ToTypedFormGroup<any>>[] | LegacyFormGroup<any>[]>;
  /** NOTE: reusable list - reset for each tab comparison */
  protected activeChangedControlsList: Map<PhxFormControl, string>;
  /** NOTE: list to collect changes from each tab */
  protected changedControlsList: Map<PhxFormControl, string>;
  /** NOTE: reusable string - to hold the name of the tab currently being compared - paired with each changed controls of that tab in Map */
  protected activeComparisonTab: string;

  /** NOTE: list to collect the removes items (ie. payee, rates, contacts, etc) */
  protected removedItemsList: string[] = [];
  /** NOTE: list to collect the added items that we dont highlight (ie. org roles) */
  protected addedItemsList: string[] = [];
  /** NOTE: list to collect the changed items that we dont highlight (ie. notification emails) */
  protected changedItemsList: string[] = [];

  constructor(
    protected versionComparisonToggleService: VersionComparisonToggleService,
  ) {
  }

  hasChanged$(control: PhxFormControl): Observable<boolean> {
    return combineLatest([this.changedControls$, this.customFormsChangedControls$]).pipe(
      map(([changedControls, customFormsChangedControls]) => !!changedControls?.get(control) || !!customFormsChangedControls?.get(control))
    );
  }

  tabHasChanges$(tabName: string): Observable<boolean> {
    return combineLatest([this.changedControls$, this.customFormsChangedControls$, this.changedCustomTabNames$]).pipe(
      map(([changedControls, customFormsChangedControls, customTabNames]) =>
        [...Array.from(changedControls.values()), ...Array.from(customFormsChangedControls.values()), ...customTabNames]
          .some(tab => tab === tabName)
      )
    );
  }

  getMessaging$(): Observable<string> {
    return this.versionCompareMessage$;
  }

  resetComparisonData() {
    this.changedControls$.next(new Map());
    this.customFormsChangedControls$.next(new Map());
    this.changedCustomTabNames$.next([]);
    this.versionCompareMessage$.next('');

    this.activeChangedControlsList = new Map();
    this.changedControlsList = new Map();
    this.activeComparisonTab = null;

    this.removedItemsList = [];
    this.addedItemsList = [];
    this.changedItemsList = [];

    this.versionComparisonToggleService.setVersionComparisonSourceNumber(null);
  }

  /** NOTE: some components create forms separate from the entity root form. 
   * this maintains a list of forms to compare on top of the entity root form
   */
  addForComparison(
    form: FormGroup<ToTypedFormGroup<any>> | LegacyFormGroup<any> | FormGroup<ToTypedFormGroup<any>>[] | LegacyFormGroup<any>[],
    formType: string
  ): void {
    if (!this.customFormsForComparison) {
      this.customFormsForComparison = new Map();
    }
    this.customFormsForComparison.set(formType, form);
  }


  protected setMessage(message: string) {
    this.versionCompareMessage$.next(message);
  }

  protected cacheChangedControl(control: PhxFormControl): void {
    this.activeChangedControlsList.set(control, this.activeComparisonTab);
  }

  protected areNumberArraysDifferent(arr1: number[], arr2: number[]): boolean {
    let isDifferent = false;
    for (const num of arr1) {
      if (!arr2.includes(num)) {
        isDifferent = true;
        break;
      }
    }
    return isDifferent;
  }

  protected compareAsNumberArray = (control: PhxFormControl, previousValue: number[]): void => {
    if (Array.isArray(control.value)) {
      let isDifferent = false;

      isDifferent = control.value.length !== previousValue.length;
      if (!isDifferent) {
        isDifferent = this.areNumberArraysDifferent(control.value, previousValue);
      }

      if (isDifferent) {
        this.cacheChangedControl(control);
      }
    }
  };

  protected areValuesEqual(currentValue: any, previousValue: any): boolean {
    if (!currentValue && !previousValue) {
      return true;
    }

    if (isNaN(currentValue)) {
      if (currentValue?.toString() !== previousValue?.toString()) {
        return false;
      }
    } else if (+currentValue !== +previousValue) {
      return false;
    }

    return true;
  };

  protected compareValue(control: PhxFormControl, previousValue: any): void {
    if (!this.areValuesEqual(control.value, previousValue)) {
      this.cacheChangedControl(control);
    }
  };

  protected setAllChildControlsAsChanged(formGroup: PhxFormGroup<any>): void {
    Object.keys(formGroup.controls).forEach((key: string) => {
      const control = formGroup.controls[key];
      this.cacheChangedControl(control);
    });
  }

  protected compareOverrideTimesheetExceptions(overrideExceptions: PhxFormArray<IOverrideTimesheetException>, previousVersions: IOverrideTimesheetException[]) {
    if (overrideExceptions.value.length < previousVersions.length && !this.removedItemsList.some(s => s === 'Override timesheet exception')) {
      this.removedItemsList.push('Override timesheet exception');
    }

    for (const override of overrideExceptions.controls as PhxFormGroup<IOverrideTimesheetException>[]) {
      if (override?.value?.ExceptionCode) {
        const previousException = previousVersions?.find(f => f.ExceptionCode === override.value.ExceptionCode);

        if (!previousException) {
          this.setAllChildControlsAsChanged(override);
          this.cacheChangedControl(override.controls.ExceptionCode);
        } else {
          this.compareValue(override.controls.ExceptionCode, previousException.ExceptionCode);
          const document = override.controls.Document as PhxFormGroup<IDocument>;
          if (
            !this.areValuesEqual(override.controls.Name.value, previousException.Name) ||
            !this.areValuesEqual(override.controls.Reason.value, previousException.Reason) ||
            !this.areValuesEqual(document.controls.FileName.value, previousException.Document.FileName) ||
            !this.areValuesEqual(document.controls.FileStorageId.value, previousException.Document.FileStorageId)
          ) {
            this.cacheChangedControl(override.controls.ExceptionCode);
          }
        }
      }
    }
  }



  abstract compareEntities(form: FormGroup<ToTypedFormGroup<any>> | LegacyFormGroup<any>,
    currentEntity: EntityType, previousEntityVersionGetParams: EntityGetParams, isDestroyed$: Subject<boolean>
  ): void;
  abstract clearCachedComparisonControlListByVersion(versionIdentifier: number): void;
  protected abstract compareCustomForms();
}
