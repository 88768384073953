import { AccountService } from './account/shared';
import { RestrictionModule } from './restriction/restriction.module';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, DEFAULT_CURRENCY_CODE, ErrorHandler, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';

import { PhoenixCommonModule } from './common/PhoenixCommon.module';

import { AppComponent } from './app.component';
import { AppRouting } from './app.routes';

import { DxDateBoxModule, DxNumberBoxModule, DxRadioGroupModule, DxSelectBoxModule, DxTextBoxModule, DxTreeViewModule } from 'devextreme-angular';
import { ClientSpecificFieldsModule } from './client-specific-fields/client-specific-fields.module';

import { CommonListsObservableService } from './common/lists/lists.observable.service';
import { CommonDataService } from './common/services/commonData.service';
import { LoginComponent } from './account/login/login.component';
import { ForgotPasswordComponent } from './account/account-forgot-password/account-forgot-password.component';
import { RegisterComponent } from './account/register/register.component';
import { HomeComponent } from './home/home.component';
import { ApiService, CommonService, IframeService, LoadingSpinnerService, NavigationService, SignalrService } from './common';
// DevExtreme - load localized messages
import { loadMessages } from 'devextreme/localization';
import devextremeAjax from 'devextreme/core/utils/ajax';
import dxFrCA from './../../vendor/devextreme/localization/messages/fr-CA.json';
import { UrlData } from './common/services/urlData.service';
import { SidenavService } from './sidenav/sidenav.service';
import { AuthService } from './common/services/auth.service';
import { EventService } from './common/services/event.service';
import { RootService } from './common/root.service';
import { ViewEmailInBrowserComponent } from './view-email-in-browser/view-email-in-browser.component';
import { AccountResetPasswordComponent } from './account/app-account-reset-password/app-account-reset-password.component';
import { AccessCodeComponent } from './account/access-code/access-code.component';
import { MsalBroadcastService, MsalGuard, MsalModule, MsalRedirectComponent, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';
import { MSALGuardConfigFactory, MSALInstanceFactory, MSALInterceptorConfigFactory } from './sso/msal-config';
import { GlobalErrorHandler, rollbarFactory, RollbarService } from './global-error-handler';
import { LayoutModule } from './layouts/layout.module';
import { MatIconModule } from '@angular/material/icon';
import { TokenInterceptor } from './common/interceptors/token.interceptor';
import { sendRequestFactory } from './sendRequestFactory';
import { RedirectMappeduserComponent } from './account/redirect-mappeduser/redirect-mappeduser.component';
import { PortalComponent } from './account/portal/portal.component';
import { LocaleInterceptor } from './common/interceptors/locale.interceptor';
import { AppSessionStorageService, AppStorageService, appStorageServiceFactory } from './common/services/app-storage.service';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { environment } from '../environments/environment';
import { Userpilot } from 'userpilot';
import { ConfigurationService } from './configuration/service/configuration.service';
import { UserBehaviorService } from './common/services/user-behavior.service';
import { localStorageFactory, sessionStorageFactory } from 'ngx-webstorage-service';
import { appInitializer } from './initialization/app-initializer';
import { ApplyClassesWhenScrolledIntoViewDirective } from './home/directives/apply-classes-when-scrolled-into-view.directive';
import { MatButtonModule } from '@angular/material/button';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';

Userpilot.initialize(environment.userPilotToken);

loadMessages(dxFrCA);

const matFormFieldDefaultOptions: MatFormFieldDefaultOptions = {
  appearance: 'outline',
  subscriptSizing: 'dynamic'
};

@NgModule({
  declarations: [
    ApplyClassesWhenScrolledIntoViewDirective,
    AppComponent,
    LoginComponent,
    ForgotPasswordComponent,
    RegisterComponent,
    AccountResetPasswordComponent,
    HomeComponent,
    ViewEmailInBrowserComponent,
    AccessCodeComponent,
    RedirectMappeduserComponent,
    PortalComponent
  ],
  imports: [
    PhoenixCommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRouting,
    DxTextBoxModule,
    DxNumberBoxModule,
    DxDateBoxModule,
    DxSelectBoxModule,
    DxRadioGroupModule,
    DxTreeViewModule,
    RestrictionModule,
    ClientSpecificFieldsModule,
    MsalModule,
    LayoutModule,
    MatIconModule,
    NgxJsonViewerModule,
    MatButtonModule
  ],
  providers: [
    LoadingSpinnerService,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: AppStorageService, useFactory: (iframeService: IframeService) => appStorageServiceFactory(iframeService, localStorageFactory), deps: [IframeService] },
    { provide: RollbarService, useFactory: rollbarFactory, deps: [AppStorageService] },
    { provide: AppSessionStorageService, useFactory: (iframeService: IframeService) => appStorageServiceFactory(iframeService, sessionStorageFactory), deps: [IframeService] },
    CommonListsObservableService,
    SidenavService,
    UrlData,
    CommonDataService,
    CommonService,
    NavigationService,
    SignalrService,
    ApiService,
    AuthService,
    AccountService,
    EventService,
    RootService,
    IframeService,
    { provide: HTTP_INTERCEPTORS, useClass: LocaleInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    // msal
    { provide: MSAL_INSTANCE, useFactory: MSALInstanceFactory },
    { provide: MSAL_GUARD_CONFIG, useFactory: MSALGuardConfigFactory },
    { provide: MSAL_INTERCEPTOR_CONFIG, useFactory: MSALInterceptorConfigFactory },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    ConfigurationService,
    UserBehaviorService,
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'CAD'
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true
    },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: matFormFieldDefaultOptions }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {
  constructor(rootService: RootService, httpClient: HttpClient) {
    devextremeAjax.inject({ sendRequest: sendRequestFactory(httpClient) });
    rootService.init();
  }
}
