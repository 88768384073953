<div class="todo-container" *ngIf="todoList$ | async as todoList">
    <div class="todo-header-container">To-Do List</div>
    <div class="group-top-container" *ngFor="let group of todoList?.items">
        <ng-container *ngIf="!!group?.todos?.length">
            <a (click)="toggleGroup(group.reportTypeId)">
                {{todoListNames[group.reportTypeId]}}
                <i class="arrow material-icons md-18"
                    [ngClass]="{'arrowExpanded' : todoListsShowing[group.reportTypeId]}"></i>
            </a>
            <div [ngClass]="{'hide' : !todoListsShowing[group.reportTypeId]}" class="list-container">
                <dx-list [dataSource]="group?.todos" (onItemReordered)="onItemReordered($event, group.reportTypeId)">
                    <dxo-item-dragging
                      [group]="'todoType' + group.reportConfig"
                      [data]="group?.todos"
                      [allowReordering]="true"
                      [onDragStart]="dragStart"
                      [onDragEnd]="dragEnd"
                    >
                    </dxo-item-dragging>
                    <div *dxTemplate="let item of 'item'" class="todo-row">
                        <div class="done-icon-container" (click)="toggleToDo(item, group.reportTypeId)">
                            <div class="not-done-todo" [ngClass]="{'done' : item.isDone}" *ngIf="!item.isDone"><i
                                    class="material-icons">radio_button_unchecked</i></div>
                            <div class="done-todo" *ngIf="item.isDone"><i
                                    class="material-icons">check_circle_outline</i></div>
                        </div>
                        <div class="todo-link-container" (click)="navigateToItem(item, group.reportTypeId)">
                            {{item.description}}</div>
                        <div class="delete-todo-container" (click)="removeTodoItem(item, group.reportTypeId)"
                            title="Remove to-do"><i class="material-icons">delete</i></div>
                    </div>
                </dx-list>
                <div class="clear-container pointer" (click)="clearList(group.reportTypeId)">clear list</div>
            </div>
        </ng-container>
    </div>

    <div *ngIf="!todoList?.items?.length" class="no-todos-container">
        You don't have any To-Dos.
    </div>
</div>
