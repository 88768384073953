import { isEqual } from 'lodash';

/***
 * Tracks the number of facets currently in use.
 *
 * Use this class for O(1) lookups.
 * */
export class FacetTracker {
    private activeCheckboxFacets = 0;
    private activeRangeFacets = 0;
    private checkboxActivationMap: Map<string, Set<string | number>> = new Map();
    private rangeFacetInfoMap: Map<string, IRangeFacetInfo> = new Map();

    private static toggleEntryWithinSet(set: Set<number | string>, value: number | string) {
        if (set.has(value)) {
            set.delete(value);
        } else {
            set.add(value);
        }
    }

    private static isRangeFacetActive(facetInfo: IRangeFacetInfo) {
        return !isEqual(facetInfo.min, facetInfo.lowerBound)
            || !isEqual(facetInfo.max, facetInfo.upperBound);
    }

    getNumActiveFacets() {
        return this.activeRangeFacets + this.activeCheckboxFacets;
    }

    addCheckboxFacet(fieldName: string): void {
        this.checkboxActivationMap.set(fieldName, new Set<string | number>());
    }

    toggleCheckboxFacet(fieldName: string, value: string | number): void {
        const selectedFacetValues = this.checkboxActivationMap.get(fieldName);
        const sizeBefore = selectedFacetValues.size;
        FacetTracker.toggleEntryWithinSet(this.checkboxActivationMap.get(fieldName), value);
        const sizeAfter = selectedFacetValues.size;
        if (sizeBefore === 0) {
            // We added an entry to the set.
            this.activeCheckboxFacets++;
        }
        if (sizeAfter === 0) {
            // We have just removed an entry from the set.
            this.activeCheckboxFacets--;
        }
    }

    addRangeFacet(fieldName: string, min: number | Date, max: number | Date): void {
        const rangeFacetInfo = {
            min,
            max,
            lowerBound: min,
            upperBound: max
        } as IRangeFacetInfo;
        this.rangeFacetInfoMap.set(fieldName, rangeFacetInfo);
    }

    setFacetRange(fieldName: string, lowerBound: number | Date, upperBound: number | Date): void {
        const rangeFacetInfo = this.rangeFacetInfoMap.get(fieldName);
        const isActiveBefore = FacetTracker.isRangeFacetActive(rangeFacetInfo);
        rangeFacetInfo.lowerBound = lowerBound;
        rangeFacetInfo.upperBound = upperBound;
        const isActiveAfter = FacetTracker.isRangeFacetActive(rangeFacetInfo);

        if (!isActiveBefore && isActiveAfter) {
            this.activeRangeFacets++;
        } else if (isActiveBefore && !isActiveAfter) {
            this.activeRangeFacets--;
        }
    }

    clearFacetsSelections(): void {
        this.resetMap(this.checkboxActivationMap, () => new Set<string | number>());
        this.activeCheckboxFacets = 0;
        this.resetMap(this.rangeFacetInfoMap, (facetRangeInfo) => {
            facetRangeInfo.lowerBound = facetRangeInfo.min;
            facetRangeInfo.upperBound = facetRangeInfo.max;
            return facetRangeInfo;
        });
        this.activeRangeFacets = 0;
    }

    private resetMap<K, T>(map: Map<K, T>, valueCreator: (oldVal: T) => T) {
        const keys = Array.from(map.keys());
        keys.map(key => {
            const oldVal = map.get(key);
            map.set(key, valueCreator(oldVal));
        });
    }
}

interface IRangeFacetInfo {
    min: number | Date;
    max: number | Date;
    lowerBound: number | Date;
    upperBound: number | Date;
}
