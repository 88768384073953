/**
 * Possible values of vertical alignment of the toast
 */
export enum PhxToastPosition {
    /**
     * Aligns at the top-right corner of the screen
     */
    Top = 1,

    /**
     * Aligns at the bottom-right corner of the screen
     */
    Bottom = 2
}

/**
 * Represents the type of the toast
 */
export enum PhxToastType {
    success = 'alert-success',
    danger = 'alert-danger',
    warning = 'alert-warning',
    info = 'alert-primary'
}

/**
 * Represents an toast option button
 */
export interface PhxToastButton {
    /**
     * Id of the button
     */
    id: number;

    /**
     * Display text
     */
    displayText: string;

    /**
     * Callback to be executed
     */
    onClick: () => void;

    /**
     * Button tooltip
     */
    toolTip?: string;
}
