import { from as observableFrom, Observable } from 'rxjs';
import { ApiService, PhxConstants, WorkflowService } from '../common';
import { Injectable } from '@angular/core';
import { RemittanceBatch } from './model';
import { IProvincialTaxHeaderDto, IProvincialTaxVersionPickerDto, PayrollFormMode } from './model/provincial-tax.interface';
import * as moment from 'moment';
import { TaxVersion } from './model/tax-version.model';
import { map } from 'rxjs/operators';
import { IRecordOfEmploymentValidationResult } from '../contact/models/roe-validation-result.interface';
import { IRoeRequest } from './model/roe-request.interface';
import { environment } from '../../environments/environment';

declare const oreq: any;

@Injectable({
  providedIn: 'root'
})
export class PayrollService {
  private apiEndPoint = environment.payrollServiceApiEndpoint;
  payrollFormMode: PayrollFormMode;

  constructor(private apiService: ApiService, private workflowService: WorkflowService) {
  }

  public parseDate(date: string | Date): Date {
    if (typeof date === 'string') {
      return moment(date, PhxConstants.DateFormat.API_Date).toDate();
    } else if (date instanceof Date) {
      return date;
    } else {
      return null;
    }
  }

  public getListPendingRemittanceTransaction(oDataParams): Observable<any> {
    oDataParams =
      oDataParams ||
      oreq
        .request()
        .withSelect(['Id', 'WorkflowPendingTaskId', 'OrganizationIdInternal', 'OrganizationInternalLegalName', 'SourceDeductionTypeId', 'CurrencyId'])
        .url();
    return this.apiService.query('Payroll/getListPendingRemittanceTransaction?' + (oDataParams ? oDataParams + '&' : ''));
  }

  public getOrganizationName(organizationIdInternal: number): Observable<any> {
    const oDataParams = oreq
      .request()
      .withSelect(['LegalName'])
      .withFilter(oreq.filter('Id').eq(organizationIdInternal))
      .url();
    return this.apiService.query('org/getOrganizations?' + (oDataParams ? oDataParams + '&' : ''));
  }

  public getRemittanceBatches(organizationIdInternal: number, oDataParams) {
    return this.apiService.query<Array<RemittanceBatch>>('Payroll/getRemittanceBatch/' + organizationIdInternal + '?' + (oDataParams ? oDataParams + '&' : ''));
  }

  public getRemittanceBatchById(organizationIdInternal: number, batchId: number) {
    return this.apiService.query<RemittanceBatch>('payroll/getRemittanceBatchById/' + organizationIdInternal + '/' + batchId);
  }

  public getAllWorkerCompensations(): Observable<any> {
    return this.apiService.query('Payroll/getAllWorkerCompensations');
  }

  public getPendingRemittancePayroll(url: string, oDataParams?: string): Observable<any> {
    return this.apiService.query(url + '?' + (oDataParams ? oDataParams + '&' : ''));
  }

  public getListPendingPaymentTransactionRemittanceHealthTax(oDataParams): Observable<any> {
    return this.apiService.query('Payroll/getListPendingPaymentTransactionRemittanceHealthTax?' + (oDataParams ? oDataParams + '&' : ''));
  }

  public SubmitPaymentTransactionsForRemittance(paymentTransactionIds: number[], remittanceType: string, remittanceDate: any): Observable<any> {
    let remittanceTypeId: number;

    switch (remittanceType) {
      case 'payroll-deduction':
        remittanceTypeId = PhxConstants.RemittanceType.Payroll;
        break;
      case 'wcb':
        remittanceTypeId = PhxConstants.RemittanceType.WorkerSafety;
        break;
      case 'health-tax':
        remittanceTypeId = PhxConstants.RemittanceType.HealthTax;
        break;
      default:
        remittanceTypeId = 0;
    }
    const command = {
      EntityIds: paymentTransactionIds,
      RemittanceType: remittanceTypeId,
      RemittanceDate: remittanceDate
    };
    return observableFrom(this.apiService.command('RemittanceTransactionRemit', command));
  }

  public getProvincialTaxHeaderByProvincialTaxVersionId(provincialTaxVersionId: number): Observable<IProvincialTaxHeaderDto> {
    return this.apiService.query<IProvincialTaxHeaderDto>(`Payroll/getProvincialTaxHeaderByProvincialTaxVersionId/${provincialTaxVersionId}`)
      .pipe(map(response => {
        response.ProvincialTaxVersionPickers.forEach(x => {
          x.EffectiveDate = moment(x.EffectiveDate, PhxConstants.DateFormat.API_Date).toDate();
        });
        response.ProvincialTaxVersionPickers = (this.sortPayrollTaxVersionPickers(response.ProvincialTaxVersionPickers) as IProvincialTaxVersionPickerDto[]);
        response.ProvincialTaxVersions.forEach(x => {
          x.EffectiveDate = moment(x.EffectiveDate, PhxConstants.DateFormat.API_Date).toDate();
        });
        response.CurrentVersion = response.ProvincialTaxVersions.find(x => x.Id === provincialTaxVersionId);
        return response;
      }));
  }

  public sortPayrollTaxVersionPickers(taxVersions: Array<TaxVersion>): Array<TaxVersion> {
    taxVersions
      .sort((a, b) => {
        return b.Id - a.Id;
      })
      .forEach((version, index, versions) => {
        version.VersionNumber = versions.length - index;
      });
    const sortedVersions: Array<TaxVersion> = (taxVersions || [])
      .sort((a, b) => {
        const aDate = this.parseDate(a.EffectiveDate);
        const bDate = this.parseDate(b.EffectiveDate);

        const aTime = aDate ? aDate.getTime() : null;
        const bTime = bDate ? bDate.getTime() : null;
        if (aTime !== bTime) {
          // Effective Date desc
          return bTime - aTime;
        } else if (a.TaxVersionStatusId !== b.TaxVersionStatusId) {
          // StatusId asc // TODO by status sort order or by text?
          return a.TaxVersionStatusId - b.TaxVersionStatusId;
        } else {
          // Id desc
          return b.Id - a.Id;
        }
      });
    return sortedVersions;
  }

  public executeAction(command: string, data: any) {
    return observableFrom(this.apiService.command(command, data, true));
  }

  public getFederalTaxHeaderByFederalTaxVersionId(federalTaxVersionId: number): Observable<any> {
    return this.apiService.query('Payroll/getFederalTaxHeaderByFederalTaxVersionId/' + federalTaxVersionId);
  }

  public getWorkflowAvailableActions(EntityTypeId: number, VersionId: number) {
    return this.apiService.query('task/getTasksAvailableActionsByTargetEntity/targetEntityTypeId/' + EntityTypeId + '/targetEntityId/' + VersionId);
  }

  public getAvailableStateActions(versionId: number) {
    return observableFrom(this.workflowService.getAvailableStateActions(PhxConstants.EntityType.FederalTaxVersion, versionId, false));
  }

  public actionExecute(commandName: string, payload: any) {
    return observableFrom(this.apiService.command(commandName, payload));
  }

  public validateRoeGeneration(workOrders: IRoeRequest[]): Observable<IRecordOfEmploymentValidationResult> {
    return this.apiService.httpPostRequest<IRecordOfEmploymentValidationResult>('recordOfEmployment/roe-report-validation', workOrders, this.apiEndPoint);
  }

  getRoeReport(assignmentIds: number[]) {
    return this.apiService.httpPostRequest('recordOfEmployment/roe-report', assignmentIds, this.apiEndPoint);
  }
}
