import { Directive, HostListener, Input } from '@angular/core';

import { ClickAnalyticsData } from '../../services/google-analytics/models/click-analytics-data.model';
import { GoogleAnalyticsService } from '../../services/google-analytics/google-analytics.service';

@Directive({
  selector: '[appCollectClickDataAnalytics]'
})
export class CollectClickDataAnalyticsDirective {
  @Input('appCollectClickDataAnalytics') data?: ClickAnalyticsData & Record<string, any>;

  constructor(private googleAnalyticsService: GoogleAnalyticsService) {}

  @HostListener('click')
  public trackClick(): void {
    if (this.data) {
      void this.googleAnalyticsService.sendClickData({
        ...this.data
      });
    }
  }
}
