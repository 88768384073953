export enum OrganizationContactColumnsKey {
  Name = 'Contact/FullName',
  Profile = 'ProfileTypeId',
  ProfilePrimaryEmail = 'PrimaryEmail',
  Status = 'ProfileStatusId'
}

export enum OrganizationAdvanceColumnsKey {
  Id = 'Id',
  Description = 'Description',
  AmountInitial = 'AmountInitial',
  PaidAmount = 'PaidAmount',
  PaybackRemainder = 'PaybackRemainder',
  AdvanceStatusId = 'AdvanceStatusId',
}

export enum OrganizationGarnisheeColumnsKey {
  Id = 'Id',
  Description = 'Description',
  PayAmountMaximum = 'PayAmountMaximum',
  PaidAmount = 'PaidAmount',
  PaybackRemainder = 'PaybackRemainder',
  GarnisheeStatusId = 'GarnisheeStatusId',
  PayAmountIsMaximum = 'PayAmountIsMaximum',
  CurrencyCode = 'CurrencyCode'
}

export enum OrganizationNumberValidationType {
  BusinessNumber = 1,
  EmployerIdentificationNumber = 2,
  TaxNumber = 3
}

// form actions - prepares form to enact a state action
// header/version actions - send state command to backend and updates form from response
export enum RebateFeeActions {
  // header actions
  Create = 'Create',
  Activate = 'Activate',
  Deactivate = 'Deactivate',
  // version actions
  Submit = 'Submit', // Submits an Edit/Schedule Change
  // Form actions
  BeginEdit = 'BeginEdit',
  BeginScheduleChange = 'BeginScheduleChange',
  CancelCreate = 'CancelCreate',
  CancelChange = 'CancelChange', // Cancel the Edit/Schedule Change process
}
