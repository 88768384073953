import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'workflowHistoryPropertyPath'
})
export class WorkflowHistoryPropertyPathPipe implements PipeTransform {
  transform(path: string): string {
    return path
      .split('/')
      .reduce((acc, currentVal) => {
        let valToPush = currentVal;
        if (!isNaN(+currentVal)) {
          valToPush = `#${+currentVal + 1}`;
          const lastArrItem = acc.slice(-1)[0];
          if (!!lastArrItem) {
            valToPush = lastArrItem + ` ${valToPush}`;
            acc.pop();
          }
        } else {
          valToPush = valToPush.replace(/([A-Z]+\d?)/g, ' $1');
        }
        acc.push(valToPush);
        return acc;
      }, [])
      .join(' / ')
      .replace(/\s+/g, ' ')
      .trim();
  }
}
