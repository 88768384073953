import { ApiService, CodeValueService, PhxConstants } from '../../../common';
import { IReportData } from '../../../report/model/report-nav';
import { CodeValue, PhxDataTableState } from '../../../common/model';
import { BehaviorSubject, forkJoin, Observable, Subject } from 'rxjs';
import { filter, map, share, take } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import {
  IApiPostReportShareDetail,
  IApiPostReportShareLean,
  IApiReportShareDetailResponse,
  IApiReportShareListResponse,
  IApiTeamSummaryResponse,
  IReportShare,
  IReportShareDetail,
  IReportShareDetailSave,
  IReportSharee,
  IReportShareeData,
  IReportShareSimpleSave,
  IReportTeamSharee,
  IReportUserSharee,
  ReportShareeType
} from './i-report-sharing-api';
import { CommonListsObservableService } from '../../../common/lists/lists.observable.service';
import { AuthService } from '../../../common/services/auth.service';
import { TeamStatus } from '../../../team/model/team.enums';
import { ICommonListsItem } from '../../../common/lists';
import * as moment from 'moment';
import FunctionalRole = PhxConstants.FunctionalRole;

export class ReportShareApiWrapper {

  // Variable to hold ReportCodeValues and cache it.
  private static ReportCodeValues: Array<CodeValue>;
  // Subjects to store required data. Data is cached on the class level.
  private static FullShareeData$ = new BehaviorSubject<IReportShareeData>(null);
  private static NewShareeData$ = new BehaviorSubject<IReportShareeData>(null);
  private reportApiEndpoint = environment.reportServiceApiEndpoint;
  private securityApiEndPoint = environment.securityServiceApiEndPoint;
  // Observables to get required data to fill the subjects above.
  private teamListSummary$: Observable<IApiTeamSummaryResponse[]> = this.apiService.httpGetRequest<IApiTeamSummaryResponse[]>(`teams/teamsSummary`, this.securityApiEndPoint).pipe(share());
  private listUserProfileInternalAll$ = this.commonListsObservableService.listUserProfileInternalAll$().pipe(filter(e => !!e), take(1));
  private getUserList$ = this.apiService.httpGetRequest('ReportSecurityView/GetListUserProfileInternal', this.reportApiEndpoint).pipe(share());
  private readonly NEW_REPORTS_START_INDEX = 16;
  private readonly LEGACY_REPORT_IDS = [3];

  constructor(codeValueService: CodeValueService,
              private authService: AuthService,
              private apiService: ApiService,
              private commonListsObservableService: CommonListsObservableService) {
    if (!ReportShareApiWrapper.ReportCodeValues) {
      this.fillReportCodeValues(codeValueService);
    }
    if (!ReportShareApiWrapper.FullShareeData$.value) {
      this.fillFullShareeDataObservable();
    }
    if (!ReportShareApiWrapper.NewShareeData$.value) {
      this.fillNewShareeDataObservable();
    }
  }

  /**
   * Get report data for every reportType provided.
   */
  public getAllReportsForCurrentUser(showSpinner = false): Observable<IReportData[]> {
    return forkJoin([
      this.authService.getCurrentProfile(),
      this.apiService.httpGetRequest<IApiReportShareListResponse[]>(`Report/reportSharing/searchStateList`, this.reportApiEndpoint, showSpinner)
    ]).pipe(map(([currentProfile, list]) => {
      const currentProfileId = currentProfile.Id;
      const reportData = [];
      const isAccountsReceivableFunctionalRole = currentProfile.FunctionalRoles.some(fr => fr.FunctionalRoleId === FunctionalRole.AccountsReceivable);
      const transformedList = (list || []).map(this.transformToReportShareObject);
      ReportShareApiWrapper.ReportCodeValues.forEach(codeReportTypeEntry => {
        const codeValueObj = codeReportTypeEntry;
        if (isAccountsReceivableFunctionalRole || (!isAccountsReceivableFunctionalRole && !this.LEGACY_REPORT_IDS.includes(codeValueObj.id))) {
          reportData.push({
            CodeValue: codeValueObj,
            OwnedStates: transformedList.filter(reportShare => {
              return reportShare.ReportTypeId === codeReportTypeEntry.id
                && reportShare.OwnerProfileId === currentProfileId;
            }).sort(this.stateNameSorter),
            SharedStates: transformedList.filter(reportShare => {
              return reportShare.ReportTypeId === codeReportTypeEntry.id
                && reportShare.OwnerProfileId !== currentProfileId && !reportShare.IsPublic;
            }).sort(this.stateNameSorter),
            CompanyStates: transformedList.filter(reportShare => {
              return reportShare.ReportTypeId === codeReportTypeEntry.id
                && reportShare.OwnerProfileId !== currentProfileId && reportShare.IsPublic;
            }).sort(this.stateNameSorter)
          });
        }
      });
      return reportData;
    }));
  }

  public getAllReportsForCurrentUserByReportType(reportTypeId: PhxConstants.ReportType, showSpinner = false): Observable<IReportData> {
    const codeValue = ReportShareApiWrapper.ReportCodeValues.find(codeVal => reportTypeId === codeVal.id);
    if (!codeValue) {
      return;
    }

    const componentName = codeValue.text;
    return forkJoin([
      this.authService.getCurrentProfile(),
      this.apiService.httpGetRequest<IApiReportShareListResponse[]>(`Report/reportSharing/searchStateList/componentName/${componentName}`, this.reportApiEndpoint, showSpinner)
    ]).pipe(map(([currentProfile, list]) => {
      const currentProfileId = currentProfile.Id;
      const transformedList = (list || []).map(this.transformToReportShareObject);
      return {
        CodeValue: codeValue,
        OwnedStates: transformedList
          .filter(reportShare => reportShare.OwnerProfileId === currentProfileId)
          .sort(this.stateNameSorter),
        SharedStates: transformedList
          .filter(reportShare => reportShare.OwnerProfileId !== currentProfileId && !reportShare.IsPublic)
          .sort(this.stateNameSorter),
        CompanyStates: transformedList
          .filter(reportShare => reportShare.OwnerProfileId !== currentProfileId && reportShare.IsPublic)
          .sort(this.stateNameSorter)
      };
    }));
  }

  public getReportShareDetail(reportShareId: number, showSpinner = false) {
    return forkJoin([
      this.getFullShareeData(),
      this.apiService.httpGetRequest<IApiReportShareDetailResponse>(`Report/reportSharing/userProfileSearchState/${reportShareId}`, this.reportApiEndpoint, showSpinner)
    ]).pipe(map(([shareeData, detailedShareReportResponse]) => {
      if (!detailedShareReportResponse) {
        return null;
      }
      return this.transformApiResponseToReportShareDetailResponse(detailedShareReportResponse, shareeData);
    }));
  }

  // public postReportShareShareChanges(apiPostReportShare: IApiPostReportShareLean, showSpinner = false) {
  public postReportShareShareChanges(reportShareSave: IReportShareSimpleSave, showSpinner = false) {
    const reportShare: IApiPostReportShareLean = this.transformReportShareShareSaveToApiReportShareLean(reportShareSave);
    return this.apiService.httpPostRequest('Report/reportSharing/userProfileSearchStateShareSave', reportShare, this.reportApiEndpoint, showSpinner);
  }

  public postReportShareFullChanges(reportShareDetails: IReportShareDetailSave, showLoader = false) {
    if (!reportShareDetails.State) {
      return;
    }
    const reportShareDetail = this.transformReportShareDetailToApiPost(reportShareDetails);
    return forkJoin([
      this.getFullShareeData(),
      this.apiService.httpPostRequest<IApiReportShareDetailResponse>('Report/reportSharing/userProfileSearchStateCompleteSave', reportShareDetail, this.reportApiEndpoint, showLoader)
    ]).pipe(map(([shareeData, response]) => {
      if (response) {
        return this.transformApiResponseToReportShareDetailResponse(response, shareeData);
      }
      return null;
    }));
  }

  public deleteReportShare(stateId: number, showSpinner = false) {
    return this.apiService.httpDeleteRequest(`Report/reportSharing/userProfileSearchStateDelete/${stateId}`, this.reportApiEndpoint, showSpinner);
  }

  public getNewShareeData(): Observable<IReportShareeData> {
    return this.getOneTimeObservableFromSubject(ReportShareApiWrapper.NewShareeData$);
  }

  public getFullShareeData(): Observable<IReportShareeData> {
    return this.getOneTimeObservableFromSubject(ReportShareApiWrapper.FullShareeData$);
  }

  private getOneTimeObservableFromSubject<T>(subject: Subject<T>): Observable<T> {
    return new Observable<T>((subscriber => {
      subject.pipe(filter(d => !!d)).subscribe(shareeData => {
        subscriber.next(shareeData);
        subscriber.complete();
      });
    }));
  }

  private fillReportCodeValues(codeValueService: CodeValueService) {
    ReportShareApiWrapper.ReportCodeValues = ReportShareApiWrapper.ReportCodeValues ||
      codeValueService.getCodeValues('report.CodeReportType', true)
        // Only use 'new' reports
        .filter(reportType => reportType.id >= this.NEW_REPORTS_START_INDEX || this.LEGACY_REPORT_IDS.includes(reportType.id))
        // Sort alphabetically
        .sort((a, b) => {
          if (a.description < b.description) {
            return -1;
          }
          return 1;
        });
  }

  private transformApiResponseToReportShareDetailResponse(apiReportShareDetailResponse: IApiReportShareDetailResponse, shareeData: IReportShareeData): IReportShareDetail {
    if (!shareeData) {
      return null;
    }

    // falseApiReportShareListResponse doesn't have full name. We will therefore use shareeData to set that.
    const falseApiReportShareListResponse = (apiReportShareDetailResponse as any) as IApiReportShareListResponse;
    const partialReportShare = this.transformToReportShareObject(falseApiReportShareListResponse);

    const detailReportShare = partialReportShare as IReportShareDetail;
    detailReportShare.OwnerFullName = '';
    if (shareeData.Map.get(detailReportShare.OwnerProfileId)) {
      detailReportShare.OwnerFullName = shareeData.Map.get(detailReportShare.OwnerProfileId).Name;
    }

    detailReportShare.State = this.getStateObject(apiReportShareDetailResponse.state);
    detailReportShare.StateDescription = apiReportShareDetailResponse.stateDescription;

    return detailReportShare;
  }

  private transformReportShareDetailToApiPost(reportShareDetails: IReportShareDetailSave): IApiPostReportShareDetail {
    return {
      userProfileSearchStateId: reportShareDetails.StateId,
      stateName: reportShareDetails.StateName || '',
      stateDescription: reportShareDetails.StateDescription || '',
      state: this.getStringFromStateObject(reportShareDetails.State) || '',
      componentName: reportShareDetails.ComponentName,
      teamGuids: reportShareDetails.ShareeTeamIds,
      userProfileIds: reportShareDetails.ShareeUserIds,
      isPublic: reportShareDetails.IsPublic
    };
  }

  private fillFullShareeDataObservable() {
    forkJoin([this.listUserProfileInternalAll$, this.teamListSummary$])
      .pipe(take(1), map(([userList, teamList]) => {
        const reportShareeData: IReportShareeData = {
          List: [],
          Map: new Map<string | number, IReportSharee>()
        };
        this.populateShareeData(teamList, this.transformTeamSummaryToTeamSharee, reportShareeData);
        this.populateShareeData(userList, this.transformUserProfileInternalAllToUserSharee, reportShareeData);

        return reportShareeData;
      })).subscribe(fullShareeData => {
      ReportShareApiWrapper.FullShareeData$.next(fullShareeData);
    });
  }

  private fillNewShareeDataObservable() {
    forkJoin([this.getUserList$, this.teamListSummary$])
      .pipe(take(1), map(([userList, teamList]) => {
        const reportShareeData: IReportShareeData = {
          List: [],
          Map: new Map<string | number, IReportSharee>()
        };
        this.populateShareeData(teamList, this.transformTeamSummaryToTeamSharee, reportShareeData, this.isTeamActive);
        this.populateShareeData(userList as any[], this.transformNewShareeUserToUserSharee, reportShareeData);

        return reportShareeData;
      })).subscribe((newShareeData) => {
      ReportShareApiWrapper.NewShareeData$.next(newShareeData);
    });
  }

  private populateShareeData<T>(source: T[],
                                mappingFn: (t: T) => IReportSharee,
                                destination: IReportShareeData,
                                filterFn: (sourceObj: T) => boolean = (o => true)) {
    (source || []).map(sourceObj => {
      if (!filterFn(sourceObj)) {
        return;
      }

      const sharee = mappingFn(sourceObj);
      destination.List.push(sharee);
      destination.Map.set(sharee.Id, sharee);
    });
  }

  // Don't allow sharing with inactive teams or expired teams.
  private isTeamActive(teamSummary: IApiTeamSummaryResponse) {
    if (teamSummary.statusId !== TeamStatus.Active) {
      return false;
    }
    if (!teamSummary.expiryDate) {
      return true;
    }
    const now = new Date();

    return now < new Date(teamSummary.expiryDate);
  }

  private stateNameSorter(a: IReportShare, b: IReportShare) {
    const aStateName = (a.StateName || '').trim().toLowerCase();
    const bStateName = (b.StateName || '').trim().toLowerCase();
    if (aStateName < bStateName) {
      return -1;
    }
    if (aStateName > bStateName) {
      return 1;
    }
    return 0;
  }

  private getStringFromStateObject(state: PhxDataTableState): string {
    if (!state) {
      return null;
    }

    const stateCopy = JSON.parse(JSON.stringify(state));

    delete stateCopy.selectedRowKeys;
    for (const column of stateCopy.columns) {
      if (column.dataType === 'date' && column.filterValue) {
        if (column.filterValue instanceof Date) {
          column.filterValue.toJSON = function() {
            return moment(this).format();
          };
        } else if (Array.isArray(column.filterValue)) {
          for (let i = 0; i < column.filterValue.length; i++) {
            column.filterValue[i] = moment(column.filterValue[i]).format();
          }
        }
      }
    }

    return JSON.stringify(stateCopy);
  }

  private getStateObject(stateString: string): PhxDataTableState {
    const state = JSON.parse(stateString) as PhxDataTableState;
    if (state != null) {
      for (const column of state.columns) {
        if (column.dataType === 'date' && column.filterValue) {
          if (typeof column.filterValue === 'string' || column.filterValue instanceof String) {
            column.filterValue = new Date(moment(column.filterValue.toString()).format());
          } else if (Array.isArray(column.filterValue)) {
            for (let i = 0; i < column.filterValue.length; i++) {
              column.filterValue[i] = new Date(moment(column.filterValue[i].toString()).format());
            }
          }
        }
      }
    }
    return state;
  }

  ///////////////////////////////////////////
  // Data structure transformation helpers //
  ///////////////////////////////////////////

  private transformToReportShareObject(apiReportShare: IApiReportShareListResponse): IReportShare {
    const reportShareObj: IReportShare = {
      OwnerProfileId: apiReportShare.userProfileIdOwner,
      OwnerFullName: apiReportShare.fullName,
      StateId: apiReportShare.userProfileSearchStateId,
      IsPublic: apiReportShare.isPublic,
      StateName: apiReportShare.stateName,
      ReportTypeId: -1,
      ComponentName: apiReportShare.componentName,
      ShareeTeamIds: apiReportShare.teamGuids || [],
      ShareeUserIds: apiReportShare.userProfileIds || [],
      IsShared: apiReportShare.isPublic
    };
    const componentReportCodeValue = ReportShareApiWrapper.ReportCodeValues.find(codeValue => codeValue.text === apiReportShare.componentName);
    if (componentReportCodeValue) {
      reportShareObj.ReportTypeId = componentReportCodeValue.id as PhxConstants.ReportType;
    }
    reportShareObj.IsShared = reportShareObj.IsShared || reportShareObj.ShareeTeamIds.length > 0 || reportShareObj.ShareeUserIds.length > 0;

    return reportShareObj;
  }

  private transformTeamSummaryToTeamSharee(teamSummary: IApiTeamSummaryResponse): IReportTeamSharee {
    return {
      Id: teamSummary.id,
      Name: teamSummary.name,
      NumTeamMembers: teamSummary.adminCount + teamSummary.memberCount,
      ShareeType: ReportShareeType.Team
    };
  }

  private transformUserProfileInternalAllToUserSharee(user: ICommonListsItem): IReportUserSharee {
    return {
      Id: user.Id,
      Name: user.Data.Contact.FullName,
      FunctionalRolesId: user.Data.UserProfileFunctionalRoles.map(f => f.FunctionalRoleId),
      ShareeType: ReportShareeType.User
    };
  }

  private transformNewShareeUserToUserSharee(newSharee: any): IReportUserSharee {
    return {
      Id: newSharee.userProfileId,
      Name: newSharee.fullName,
      FunctionalRolesId: newSharee.functionalRolesId,
      ShareeType: ReportShareeType.User
    };
  }

  private transformReportShareShareSaveToApiReportShareLean(reportShareSimpleSave: IReportShareSimpleSave) {
    return {
      userProfileSearchStateId: reportShareSimpleSave.StateId,
      componentName: reportShareSimpleSave.ComponentName,
      isPublic: reportShareSimpleSave.IsPublic,
      teamGuids: reportShareSimpleSave.ShareeTeamIds,
      userProfileIds: reportShareSimpleSave.ShareeUserIds
    };
  }
}
