import { Component, Inject } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';

import { DialogComponent } from '../../services/dialog/models/dialog-component.model';

@Component({
  selector: 'app-phx-dialog-error-template',
  templateUrl: './phx-dialog-error-template.component.html',
  styleUrls: ['./phx-dialog-error-template.component.less']
})
export class PhxDialogErrorTemplateComponent implements DialogComponent<{ title: string; message: string }, void> {
  constructor(public dialogRef: DialogRef<void>, @Inject(DIALOG_DATA) public dialogData: { title: string; message: string }) {}

  public close(): void {
    this.dialogRef?.close();
  }
}
