import { Injectable } from '@angular/core';
import { ApiService, PhxConstants } from '../common';

import { from as observableFrom } from 'rxjs';

interface ISaveTemplate {
  templateName: string;
  templateDescription: string;
}

@Injectable({
  providedIn: 'root'
})
export class TemplateService {

  constructor(private apiService: ApiService) { }

  public templateNew(entityTypeId: PhxConstants.EntityType, details: ISaveTemplate, templateBody: any, isPrivate: boolean = false) {
    const payload = {
      Description: details.templateDescription,
      EntityTypeId: entityTypeId,
      IsPrivate: isPrivate,
      Name: details.templateName,
      // TemplateBody: this.workorderDetails.RootObject
      TemplateBody: templateBody
      // TemplateBody: this.assignmentToTemplate(this.workorderDetails.RootObject, 0, 0)
    };

    return observableFrom(this.apiService.command('TemplateNew', payload));
  }

  changeEntityChain(obj, changeValues: Array<{key: string, value: any}>) {
    for (const i in obj) {
      if (!obj.hasOwnProperty(i)) {
        continue;
      }
      const changeValue = changeValues.find(x => x.key === i);
      if (changeValue) {
        obj[i] = changeValue.value;
        continue;
      }
      if (typeof obj[i] === 'object') {
        obj[i] = this.changeEntityChain(obj[i], changeValues);
      } 
    }
    return obj;
  }
}


