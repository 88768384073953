<dx-select-box #selectBox [dataSource]="dataSource" [placeholder]="placeholder" [showClearButton]="showClearButton"
  [readOnly]="readOnly" [disabled]="disabled" [displayExpr]="textField" [valueExpr]="valueField" [value]="value"
  [searchEnabled]="searchable" [acceptCustomValue]="acceptCustomValue" [width]="width" [maxLength]="maxLength"
  (onValueChanged)="onValueChanged($event)" (onCustomItemCreating)="onCustomItemCreating($event)"
  (onOpened)="onOpened($event)" itemTemplate="tooltip-item-template" fieldTemplate="tooltip-field-template"
  [buttons]="buttons">
  <ng-container *dxTemplate="let itemData of 'tooltip-item-template'; let itemIndex = index">
    {{itemData[textField]}}
    <ng-container *ngTemplateOutlet="tooltipIcon; context: { $implicit: itemIndex, hoverOnly: true }"></ng-container>
    <ng-container *ngTemplateOutlet="tooltip; context: { $implicit: itemIndex, itemData: itemData }"></ng-container>
  </ng-container>

  <div *dxTemplate="let itemData of 'tooltip-field-template'">
    <!-- fieldTemplate must contain the dx-text-box widget -->
    <dx-text-box #fieldTextBox style="display:inline-block" [value]="itemData ? itemData[textField] : null" [placeholder]="placeholder"></dx-text-box>
    <ng-container *ngIf="itemData">
      <!-- <ng-container *ngTemplateOutlet="tooltipIcon; context: { $implicit: 'selected', hoverOnly: false }"></ng-container> -->
      <ng-container #fieldTooltip *ngTemplateOutlet="tooltip; context: { $implicit: 'selected', itemData: itemData }"></ng-container>
    </ng-container>
  </div>

</dx-select-box>

<ng-template #tooltipIcon let-id="$implicit" let-hoverOnly="hoverOnly">
  <i *ngIf="showTooltip" id="tooltip-{{id}}-{{guid}}" class="material-icons md-18 icon-tooltip" [class.phx-dx-hover-only]="hoverOnly">info</i>
</ng-template>

<ng-template #tooltip let-id="$implicit" let-itemData="itemData">
  <app-phx-tool-tip id="tooltip-component-{{id}}-guid" *ngIf="showTooltip" targetId="#tooltip-{{id}}-{{guid}}" position="right">
    <!-- The tooltip template, passing itemData -->
    <!-- TODO: should this be merged into phx-select-box, or kept as it's own component for now? -->
    <ng-template *ngTemplateOutlet="templateRef; context: { $implicit: itemData }"></ng-template>
  </app-phx-tool-tip>
</ng-template>