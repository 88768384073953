import { Component } from '@angular/core';
import { VersionComparisonToggleService } from '../../services/version-comparison-toggle.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-phx-version-comparison-toggle',
  templateUrl: './phx-version-comparison-toggle.component.html',
  styleUrls: ['./phx-version-comparison-toggle.component.less']
})
export class PhxVersionComparisonToggleComponent {
  showToggle$ = this.versionComparisonToggleService.showCompareAllVersionsToggle$;
  isShowingVersionChanges$ = this.versionComparisonToggleService.isShowingVersionChanges$;

  constructor(
    private versionComparisonToggleService: VersionComparisonToggleService
  ) {
  }

  toggleVersionComparison() {
    this.isShowingVersionChanges$.pipe(
      take(1)
    ).subscribe(isShowing => this.versionComparisonToggleService.setIsShowingVersionChanges(!isShowing));
  }
}
