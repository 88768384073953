import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { takeUntil, map, tap } from 'rxjs/operators';
import { BaseComponentOnDestroy } from '../../epics/base-component-on-destroy';
import { IWFHEntityVersion, IWFHLog, IWFHLogDifference, IWFHLogUser, IWFHPropertyGroup, IAuditHistoryDocument, IWFHStatus, PhxConstants, PhxFormControlLayoutType } from '../../model';
import { WorkflowService } from '../../services/workflow.service';
import { PhxModalComponent } from '../phx-modal/phx-modal.component';
import { PhxWorkflowStatusNamePipe } from '../../pipes/phx-workflow-status-name.pipe';
import { Observable } from 'rxjs/internal/Observable';
import { AuditHistoryService } from '../../services/audit-history.service';

@Component({
  selector: 'app-phx-workflow-change-history',
  templateUrl: './phx-workflow-change-history.component.html',
  styleUrls: ['./phx-workflow-change-history.component.less']
})
export class PhxWorkflowChangeHistoryComponent extends BaseComponentOnDestroy implements OnChanges {
  @Input() entityTypeId: number;
  @Input() entityType: string;
  @Input() entityId: number;
  @Input() groupingId: string;
  @Input() currentStatusCode: string;
  @Input() showNoHistoryMessage = true;
  @Input() statusCodeGroupName?: string;
  /**
   * Custom path to the version field of IWFHResponse
   */
  @Input() versionFieldPath = '';

  @ViewChild('actionReasonModal') actionReasonModal: PhxModalComponent;

  history$: Observable<Array<IWFHEntityVersion>>;
  phxConstant = PhxConstants;
  searchForm: UntypedFormGroup;
  searchStatusList: any[] = [];
  searchUsersList: any[] = [];
  entityActionList: string[] = [];
  layoutType = PhxFormControlLayoutType;
  comment: string;
  actionName: string;

  searchFilterMetaData = {
    count: 0,
    statusCode: null,
    userId: null,
    fieldName: null,
    action: null
  };

  isSearchEnabled = false;

  constructor(
    private workflowService: WorkflowService,
    private fb: UntypedFormBuilder,
    private phxWorkflowStatusNamePipe: PhxWorkflowStatusNamePipe,
    private auditHistoryService: AuditHistoryService
  ) {
    super();
    this.searchForm = this.fb.group({
      statusCode: [null],
      userId: [null],
      fieldName: [null],
      action: [null]
    });
  }

  get canPerformSearch() {
    const formGroupValue = this.searchForm.value;
    return formGroupValue.statusCode || formGroupValue.userId || formGroupValue.fieldName || formGroupValue.action;
  }

  get searchFormChanges$() {
    return this.searchForm.valueChanges;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.entityTypeId || changes.entityId || changes.currentStatusCode) {
      this.history$ = this.auditHistoryService.getAuditHistory(this.groupingId).pipe(
        takeUntil(this.isDestroyed$),
        map(dataList => ({
          dataList,
          history: this.mapHistoryByVersion(dataList)
        })),
        tap(({ dataList }) => {
          this.initSearch(dataList);
        }),
        map(({ history }) => history)
      );
    }
  }

  /**
   * Map API data to desired structure
   * @returns returns sorted history versions in descending order.
   */
  private mapHistoryByVersion(responseData: IAuditHistoryDocument[]): Array<IWFHEntityVersion> {
    if (!responseData.length) {
      return [];
    }
    const history = responseData.reduce((entityVersionsArr: Array<IWFHEntityVersion>, responseDataItem: IAuditHistoryDocument) => {
      const statusCode = responseDataItem.entityStatusCode;

      const {
        comment: versionComment,
        createdByUserName: currentChangeUserName,
        differences: currentChangeDifferences,
        createdByProfileId: currentChangeUserId,
        createdDateTime: currentChangeCreatedOnTime,
        isInitialVersion,
        entityAction
      } = responseDataItem;

      const currentWorkflowInstanceId = (responseDataItem?.additionalInfo?.workflowInstanceId as string) ?? responseDataItem.workflowInstanceId;

      const currentChangeVersionId = this.versionFieldPath ? this.versionFieldPath.split('.').reduce((node, key) => (node ? node[key] : null), responseDataItem) : responseDataItem.entityVersion;

      const propertyGroups: Array<IWFHPropertyGroup> = currentChangeDifferences.reduce((groupArr: Array<IWFHPropertyGroup>, currentDiff: IWFHLogDifference) => {
        const foundGroup = groupArr.find(group => group.propertyPath === currentDiff.propertyPath);

        if (foundGroup) {
          foundGroup.differences.push({ ...currentDiff });
        } else {
          groupArr.push({
            propertyPath: currentDiff.propertyPath,
            recentDiffTime: currentChangeCreatedOnTime,
            differences: [{ ...currentDiff }]
          });
        }

        return groupArr;
      }, []);

      const log: IWFHLog = {
        userId: currentChangeUserId,
        userName: currentChangeUserName,
        createdOn: currentChangeCreatedOnTime,
        recentPropertyGroupTime: currentChangeCreatedOnTime,
        propertyGroups
      };

      const status: IWFHStatus = {
        statusCode,
        comment: versionComment,
        recentLogTime: currentChangeCreatedOnTime,
        isOpen: false,
        logsUsers: [],
        logsList: [log],
        hasPropertyGroups: log.propertyGroups.length > 0,
        isInitialVersion,
        entityAction
      };

      const foundVersion: IWFHEntityVersion = entityVersionsArr.find(entityVersion => entityVersion.versionId === currentChangeVersionId);

      if (foundVersion) {
        foundVersion.statusList.push(status);

        const isLogTimeLatest = +new Date(currentChangeCreatedOnTime) > +new Date(foundVersion.recentStatusTime);
        if (isLogTimeLatest) {
          foundVersion.recentStatusTime = currentChangeCreatedOnTime;
          foundVersion.recentStatusCode = statusCode;
        }
      } else {
        const newVersion: IWFHEntityVersion = {
          versionId: currentChangeVersionId,
          isOpen: false,
          statusList: [status],
          currentStatusCode: null,
          recentStatusCode: statusCode,
          recentStatusTime: currentChangeCreatedOnTime,
          workflowInstanceId: currentWorkflowInstanceId
        };

        entityVersionsArr.push(newVersion);
      }

      return entityVersionsArr;
    }, []);

    history
      .sort((a, b) => b.versionId - a.versionId)
      .forEach(historyVersion => {
        historyVersion.statusList
          .sort((a, b) => this.validateSortByDateInDescendingOrder(a.recentLogTime, b.recentLogTime))
          .forEach(status => {
            status.logsList
              .sort((a, b) => this.validateSortByDateInDescendingOrder(a.recentPropertyGroupTime, b.recentPropertyGroupTime))
              .forEach(log => {
                log.propertyGroups
                  .sort((a, b) => this.validateSortByDateInDescendingOrder(a.recentDiffTime, b.recentDiffTime))
                  .forEach(group => group.differences.sort((a, b) => this.validateSortByDateInDescendingOrder(a.createdOn, b.createdOn)));
              });
            status.logsUsers = status.logsList.map(logItem => ({ id: logItem.userId, name: logItem.userName } as IWFHLogUser));
          });
      });

    history[0].isOpen = true;
    this.setCurrentHistoryStatus(history[0]);
    this.setFuturePath(history[0]);

    /**
     * Filters out statuses that are not meant to be displayed
     * If a given status DOES NOT have any property groups and IS NOT next status or future step, then remove
     */
    history.forEach(version => {
      const validStatuses = version.statusList.filter(status => status.isInitialVersion || status.hasPropertyGroups || (!status.hasPropertyGroups && (status.isFutureStep || status.isNextStep)));

      version.statusList = [...validStatuses];
    });

    return history;
  }

  /**
   * sets current status
   * except if the current status is of type 'active'
   * because we have to show checked arrow with it
   * @param entityVersion of type IWFHEntityVersion
   */
  private setCurrentHistoryStatus(entityVersion: IWFHEntityVersion) {
    if (this.currentStatusCode !== PhxConstants.CommonStatus.Active && entityVersion.recentStatusCode === this.currentStatusCode) {
      entityVersion.currentStatusCode = this.currentStatusCode;
    }
  }

  /**
   * sets future status
   * gets all the ids of next statuses and make new object
   * concat all the found next statuses in the status list
   * @param entityVersion of type IWFHEntityVersion
   */
  private setFuturePath(entityVersion: IWFHEntityVersion) {
    if (entityVersion?.currentStatusCode) {
      const futurePathStatusCodes: Array<string> = this.workflowService.getPreferredEntityStatusCodePath(this.entityTypeId, entityVersion.currentStatusCode) || [];
      const futureStatuses: Array<IWFHStatus> = futurePathStatusCodes
        .map((code: string, index: number) => {
          return {
            isFutureStep: true,
            isNextStep: index === 0,
            statusCode: code,
            recentLogTime: null,
            isOpen: false,
            logsUsers: [],
            logsList: []
          } as IWFHStatus;
        })
        .reverse();
      entityVersion.statusList = futureStatuses.concat(entityVersion.statusList);
    }
  }

  /**
   * get status and users list to pass to search dropdowns
   * @param responseData data array
   */
  initSearch(responseData?: IAuditHistoryDocument[]) {
    if (!responseData?.length) {
      return;
    }

    this.entityActionList = Array.from(new Set<string>(responseData.map(({ entityAction }) => entityAction)));

    responseData.forEach((item: IAuditHistoryDocument) => {
      item.differences.forEach((diff: IWFHLogDifference) => {
        diff.propertyPath = diff.propertyPath || 'default';
        diff.createdOn = item.createdDateTime;
      });
    });

    this.searchStatusList = responseData.reduce((arr, current) => {
      const statusCode = current.entityStatusCode;
      if (!arr.some(x => x.key === statusCode)) {
        arr.push({
          key: statusCode,
          value: this.phxWorkflowStatusNamePipe.transform(statusCode, this.entityType)
        });
      }
      return arr;
    }, []);

    this.searchUsersList = responseData.reduce((arr, current) => {
      if (!arr.some(x => x.key === current.createdByProfileId)) {
        arr.push({
          key: current.createdByProfileId,
          value: current.createdByUserName
        });
      }
      return arr;
    }, []);

    this.searchForm.patchValue({
      userId: null,
      statusCode: null,
      fieldName: null,
      action: null
    });

    this.searchFormChanges$.pipe(takeUntil(this.isDestroyed$)).subscribe(() => {
      if (this.canPerformSearch) {
        this.performSearch();
      } else {
        this.clearFilters();
      }
    });
  }

  performSearch(isSearchEnabled = true) {
    this.searchFilterMetaData = {
      ...this.searchFilterMetaData,
      ...this.searchForm.value
    };

    this.isSearchEnabled = isSearchEnabled;
  }

  clearFilters() {
    this.searchForm.patchValue(
      {
        statusCode: null,
        userId: null,
        fieldName: null,
        action: null
      },
      { emitEvent: false }
    );
    this.performSearch(false);
  }

  trackByFn(index: number, entiryVersion: IWFHEntityVersion) {
    return entiryVersion.versionId;
  }

  checkIsCurrentStatus(entityVersion: IWFHEntityVersion, status: IWFHStatus) {
    return entityVersion.currentStatusCode && status.statusCode === entityVersion.currentStatusCode && +new Date(status.recentLogTime) === +new Date(entityVersion.recentStatusTime);
  }

  validateSortByDateInDescendingOrder(date1: any, date2: any) {
    return +new Date(date2) - +new Date(date1);
  }

  showComment(event, comment: string, actionName: string) {
    event.stopPropagation();

    this.comment = comment;
    this.actionName = actionName;

    this.actionReasonModal.show();
  }

  copyWorkflowInstanceId(instanceId, event) {
    event.stopPropagation();

    navigator.clipboard.writeText(instanceId);
  }
}
