

import { Pipe, PipeTransform } from '@angular/core';
import { PhxLocalizationService } from '../services/phx-localization.service';
import { TypedResourceKeys } from '../utility/typed-resource-keys';

/**
 * The pipe to translate application specific signals to human readable and localized text.
 * @Usage value | phxTypedTranslate: '<EnumTypeName>'
 */
@Pipe({
    name: 'phxTypedTranslate',
    pure: true
})
export class PhxTypedLocalizationPipe implements PipeTransform {

    constructor(private localService: PhxLocalizationService) { }

    /**
     * Transforms the application specific signals to human readable and localized text.
     * @param value The type value
     * @param type The type
     */
    transform(value: number, type: string): any {
        let result = '', res = '';
        if (value && type) {

            if (TypedResourceKeys.hasOwnProperty(type)) {
                if (TypedResourceKeys[type].hasOwnProperty(value)) {
                    res = TypedResourceKeys[type][value];
                }
            }

            result = this.localService.translate(res);
        }

        return result;
    }

}
