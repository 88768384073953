import { Injectable } from '@angular/core';
import * as _ from 'lodash';
@Injectable()
export class AggregateSummarizerService {
    private defaultMapping: any = {
        apply: (key, data) => {
            return {
                label: key,
                value: data
            };
        },
        action: (data) => data,
        template: { }
    };

    aggregateGroups(mapping, startingAt, data, previous?: any) {
        if (Array.isArray(mapping) || typeof mapping === 'string') {
            return data;
        }

        if (!mapping[startingAt]) {
            return data;
        }

        const effectiveMapping = _.extend({}, this.defaultMapping, mapping[startingAt]);
        let groupedResult = effectiveMapping.action(data);
        groupedResult = _.extend({}, effectiveMapping.template, groupedResult);
        const normalizedData = [];
        let lastItem;
        _.forEach(groupedResult, (value, key) => {
            lastItem = effectiveMapping.apply(key, value, previous);

            if (effectiveMapping.next) {
                lastItem[effectiveMapping.next.property] = this.aggregateGroups(mapping, effectiveMapping.next.target, value, key);
            }

            normalizedData.push(lastItem);
        });

        return normalizedData;
    }
}
