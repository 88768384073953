import { Component, Input } from '@angular/core';
import { CodeValueGroups, PhxConstants } from 'src/app/common';
import { IPaymentDetails } from '../../../interfaces/global-search';
import { GlobalSearchService } from '../../../services/global-search-service';
import { GlobalSearchUtil } from '../../../util/GlobalSearchUtil';

@Component({
  selector: 'app-global-search-suggestion-payment',
  templateUrl: './global-search-suggestion-payment.component.html',
  styleUrls: ['./global-search-suggestion-payment.component.less']
})
export class GlobalSearchSuggestionPaymentComponent {
  @Input() paymentData: Array<IPaymentDetails>;
  readonly phxConstants = PhxConstants;
  readonly codeValueGroups = CodeValueGroups;

  constructor(
      private globalSearchService: GlobalSearchService,
      public gsUtil: GlobalSearchUtil) {
  }

  onPaymentSuggestionClick(paymentDetails: IPaymentDetails) {
    this.globalSearchService.onPaymentSuggestionClick(paymentDetails);
  }
}
