import * as Store from 'azsearchstore/dist/store';

export interface IFacetConfig {
    SearchFieldName: string;
    UiFieldName: string;
    UiType: FacetConfigUiType;
    // DataType: Store.RangeDataType | Store.CheckboxDataType;
    Facet?: Store.Facet;
    Min?: Date;
    Max?: Date;
}

export interface IListViewConfigEntry {
    ColumnHeader: string;
    FieldName: string;
    DisplayOverride?: (resultObj: any) => string;
    Sortable: boolean;
}

export type ListViewConfig = IListViewConfigEntry[];

export type IFacetsConfig = Array<IFacetConfig>;

export enum FacetConfigUiType {
    Checkbox,
    Dropdown,
    Range
}

export enum RangeFacetBoundType {
    LowerBound,
    UpperBound
}
