import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ApiService } from '../../services/api.service';
import { MailCampaignSelectionDto } from './models/mail-campaign-selection-dto';
import { EntityList } from '../../model';

@Injectable({
  providedIn: 'root'
})
export class FboMonolithMailCampaignSelectionService {
  constructor(private apiService: ApiService) {}

  public getSelectionsList(): Observable<EntityList<MailCampaignSelectionDto>> {
    return this.apiService.httpGetRequest<EntityList<MailCampaignSelectionDto>>('MailCampaignSelection/getListMailCampaignSelection');
  }
}
