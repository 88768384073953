import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { EntityVersion, PhxConstants } from '../../model';

@Component({
  selector: 'app-phx-switch-version',
  templateUrl: './phx-switch-version.component.html',
  styleUrls: ['./phx-switch-version.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PhxSwitchVersionComponent implements OnChanges {

  @Input() customEntityName: string;
  @Input() entityType: string;
  @Input() statusGroup: string;
  @Input() currentVersion: number;
  @Input() entityVersions: EntityVersion[];
  @Input() isShowAllVersionsButtonVisible = false;

  @Output() selected = new EventEmitter<EntityVersion>();
  @Output() expand = new EventEmitter<boolean>();

  isOpen = false;
  phxConstants = PhxConstants;

  selectedIndex: number;
  sortedVersions: EntityVersion[];

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.entityVersions || changes.currentVersion) {
      let versions = changes.entityVersions ? changes.entityVersions.currentValue : this.entityVersions;
      versions = Array.isArray(versions) ? versions.sort((a, b) => a.VersionNumber - b.VersionNumber) : [];

      const currentVersion = changes.currentVersion ? changes.currentVersion.currentValue : this.currentVersion;
      this.selectedIndex = versions.findIndex(item => item.VersionNumber === currentVersion);

      this.sortedVersions = [...versions];
    }
  }

  selectVersion(index: number) {
    if (index !== this.selectedIndex) {
      const version = this.sortedVersions[index];
      if (version) {
        this.selected.emit(version);
      }
    }
  }

  showAll() {
    this.isOpen = false;
    this.expand.emit(true);
  }

}
