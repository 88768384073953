<div class="modal-header">
  <h4 class="modal-title pull-left">{{ dialogData.title }}</h4>
  <button
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="close()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p [innerHTML]="dialogData.message"></p>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-primary"
    (click)="close()"
  >
    OK
  </button>
</div>
