import { environment } from '../environments/environment';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

export const LoadAnalyticsScripts = (): any => {
  InitAppInsightsForAzureSearch();
  return loadGoogleTagManager();
};

export const InitAppInsightsForAzureSearch = () => {
  const instrumentationKey = environment.azSearchAppInsightsInstrumentationKey;
  if (!instrumentationKey?.trim()) {
    return;
  }
  const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey
      /* ...Other Configuration Options... */
    }
  });
  appInsights.loadAppInsights();
  appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview
};

export const loadGoogleTagManager = (): any => {
  const googleTagManagerId = environment.googleTagManagerId;

  // Add Google Analytics initialization
  const googleAnalyticsFourScript = document.createElement('script');

  googleAnalyticsFourScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
  `;

  document.head.appendChild(googleAnalyticsFourScript);

  let dataLayerRef: any;

  if (googleTagManagerId) {
    // Add Google Tag Manager <script> to index dynamically
    const googleTagManagerScript = document.createElement('script');

    googleTagManagerScript.async = true;
    googleTagManagerScript.src = `https://www.googletagmanager.com/gtag/js?id=${googleTagManagerId}`;
    document.head.appendChild(googleTagManagerScript);
    // Add Google Tag Manager Initialization
    (
      (w, d, s, l, i) => {
        w[l] = w[l] || [];
        dataLayerRef = w[l];

        w[l].push({
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'gtm.start': new Date().getTime(),
          event: 'gtm.js'
        });

        const googleTagManagerUrl = `https://www.googletagmanager.com/gtm.js?id=`;
        const f = d.getElementsByTagName(s)[0];
        const j = d.createElement('script');
        const dl = l !== 'dataLayer' ? '&l=' + l : '';
        j.async = true; j.src = googleTagManagerUrl + i + dl;
        f.parentNode.insertBefore(j, f);
      }
    )(window, document, 'script', 'dataLayer', googleTagManagerId);

    // Add Google Tag Manager - NOSCRIPT initialization

    const bodyTagReference = document.getElementsByTagName('body');
    const googleTagManagerNoScriptInit = document.createElement('noscript');

    googleTagManagerNoScriptInit.innerHTML = `
      <iframe
        src="https://www.googletagmanager.com/ns.html?id=${googleTagManagerId}"
        height="0"
        width="0"
        style="display:none;visibility:hidden"
      >
      </iframe>
    `;

    bodyTagReference[0].appendChild(googleTagManagerNoScriptInit);
  }
  return dataLayerRef;
};

// TODO: Move this to the wiki
// DO NOT DELETE - Left here for future use on how to capture user data to send to 'UserPilot'
/*
export function createUserAnalyticsData(userContext: UserContext) {
  let account: {
    id: string;  // Highly recommended
    name?: string; // Optional
    // is_paying:    //
    // monthly_value://
    // planLevel:    // Optional
    // planPrice:    // Optional
    // creationDate: // Optional

    // You can add any additional account level key-values here,
    // as long as it's not one of the above reserved names.
    roles: string;
  } = null;
  const visitor = {
    id: '', // Required if user is logged in
    // email:        //
    full_name: '', //
    // role:         // Optional

    // You can add any additional visitor level key-values here,
    // as long as it's not one of the above reserved names.
    profile_type: undefined,
    roles: []
  };

  account = {
    id: '',
    name: '',
    roles: ''
  };

  const options: { visitor: any; account?: any; } = {
    visitor
  };

  if (account) {
    const user = userContext.User;
    options.account = account;
  }
}*/
