import { Injectable } from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { IWindowMessage, WindowEntityEventMessage } from './../model/window-message';

@Injectable({
  providedIn: 'root'
})
export class IframeService {

  constructor() { }

  public get isIframe(): boolean {
    return window !== window.parent && !window.opener;
  }

  public get navigationExtras(): NavigationExtras {
    if (this.isIframe) {
      return {
        replaceUrl: this.isIframe
      };
    } else {
      return {};
    }
  }

  public postEventMessage(event: string, entityId?: number) {
    let data = new WindowEntityEventMessage();
    data.payload = {
      event: event
    };

    if (entityId) {
      data.payload.entityId = entityId;
    }

    this.postMessage(data);    
  }

  private postMessage(data: IWindowMessage) {
    window.top.postMessage(data, '*');
  }
}
