import { ApiService } from './api.service';
import { Injectable } from '@angular/core';

import * as _ from 'lodash';


@Injectable()
export class CommonDataService {

    constructor(
        private apiService: ApiService
    ) { }

    data = {
        watchConfigOnWorkflowEvent: {
            stateNameGo: '',
            stateIncludesFilter: '',
            groupingEntityTypeId: 0,
            targetEntityTypeId: 0,
            targetEntityId: 0,
            stateParamMapping: {},
            functionCallBack: null
        } as WatchConfigOnWorkflowEvent,
        organizationsListInternal: [] as InternalOrganization[],
    };

    getWatchConfigOnWorkflowEvent() {
        return _.cloneDeep(this.data.watchConfigOnWorkflowEvent);
    }


    setWatchConfigOnWorkflowEvent(stateNameGo: string, stateIncludesFilter: string, groupingEntityTypeId: number, targetEntityTypeId: number,
        targetEntityId: number, stateParamMapping: any, functionCallBack: any = null) {
        this.data.watchConfigOnWorkflowEvent.stateNameGo = _.cloneDeep(stateNameGo);
        this.data.watchConfigOnWorkflowEvent.stateIncludesFilter = _.cloneDeep(stateIncludesFilter);
        this.data.watchConfigOnWorkflowEvent.groupingEntityTypeId = _.cloneDeep(groupingEntityTypeId);
        this.data.watchConfigOnWorkflowEvent.targetEntityTypeId = _.cloneDeep(targetEntityTypeId);
        this.data.watchConfigOnWorkflowEvent.targetEntityId = _.cloneDeep(targetEntityId);
        this.data.watchConfigOnWorkflowEvent.stateParamMapping = _.cloneDeep(stateParamMapping);
        this.data.watchConfigOnWorkflowEvent.functionCallBack = functionCallBack;
    }

    getListOrganizationInternal(oDataParams?: any): Promise<{Id: number, DisplayName: string, Code: string, IsTest: boolean}[]> {
        oDataParams =
            oDataParams ||
            oreq
                .request()
                .withSelect(['Id', 'DisplayName', 'Code', 'IsTest'])
                .url();

        return new Promise((resolve, reject) => {
            if (this.apiService.isEmptyObject(this.data.organizationsListInternal)) {
                this.apiService.queryWithPromise('org/getListOrganizationsOriginalAndStatusIsAtiveOrPendingChangeInActiveInternalRole?' + oDataParams).then(
                    (response: any) => {
                        this.data.organizationsListInternal = _.cloneDeep(response.Items);
                        resolve(this.data.organizationsListInternal);
                    },
                    (responseError) => {
                        this.data.organizationsListInternal = [];
                        reject(responseError);
                    }
                );
            } else {
                resolve(this.data.organizationsListInternal);
            }
        });
    }
}
interface WatchConfigOnWorkflowEvent {
    stateNameGo: string;
    stateIncludesFilter: string;
    groupingEntityTypeId: number;
    targetEntityTypeId: number;
    targetEntityId: number;
    stateParamMapping: any;
    functionCallBack: (data: any) => void;
}

interface InternalOrganization {
    Id: number,
    DisplayName: string,
    Code: string,
    IsTest: boolean
}
