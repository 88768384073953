<div class="row padding-10">
    <div *ngIf="dummyPublicState" class="col-xs-11">
        <div>
            <span class="sharee-name">All Company</span>
        </div>
        <div class='overflow-ellipsis'>
            Everyone
        </div>
    </div>
    <div *ngIf="!dummyPublicState" class="col-xs-11">
        <div>
            <span class="sharee-name"  data-toggle="tooltip" [title]="getShareeName(shareeId)">{{ getShareeName(shareeId) }}</span>
            <span *ngIf="isOwner" class="owner-text"> (Owner)</span>
        </div>
        <div class='overflow-ellipsis' data-toggle="tooltip" [title]="getShareeDescription(shareeId)">
            {{ getShareeDescription(shareeId) }}
        </div>
    </div>
    <div class="col-xs-1">
        <button [disabled]="disabled" class="pull-right btn btn-sm dropdown-toggle"
                data-toggle="dropdown" type="button" (click)="removeSharee.emit({ shareeId: shareeId, shareeType: shareeType })">
            <i class="material-icons">close</i>
        </button>
    </div>
</div>
