import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { filter, take } from 'rxjs/operators';
import { IReportSharee, ReportShareeType } from '../../../../helpers/classes/report-sharing-api/i-report-sharing-api';
import { ReportShareService } from '../../../services/report-share.service';

@Component({
  selector: 'app-report-sharee',
  templateUrl: './report-sharee.component.html',
  styleUrls: ['./report-sharee.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportShareeComponent implements OnInit {
  @Input() shareeId: number | string;
  @Input() shareeType: ReportShareeType;
  @Input() disabled = false;
  @Input() isOwner = false;
  @Input() dummyPublicState = false;
  @Output() removeSharee = new EventEmitter<{ shareeId: number | string, shareeType: ReportShareeType }>();

  fullShareeMap: Map<string | number, IReportSharee> = new Map<string | number, IReportSharee>();

  constructor(private reportShareDialogService: ReportShareService,
              private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.reportShareDialogService.fullShareeMap$
        .pipe(filter(e => !!e), take(1))
        .subscribe(fullShareeMap => {
          this.fullShareeMap = fullShareeMap;
          this.cdr.detectChanges();
        });
  }

  getFunctionalRolesString(functionalRoleIds: number[]) {
    return this.reportShareDialogService.getFunctionRoleString(functionalRoleIds);
  }

  getShareeName(shareeId: number | string) {
    const sharee = this.fullShareeMap.get(shareeId);
    if (!sharee) {
      return '';
    }

    return sharee.Name || '';
  }

  getShareeDescription(shareeId: number | string) {
    const sharee = this.fullShareeMap.get(shareeId);
    if (!sharee) { return ''; }

    if (sharee.ShareeType === ReportShareeType.User) {
      return this.getFunctionalRolesString(sharee.FunctionalRolesId);
    }

    return `${sharee.NumTeamMembers} ${sharee.NumTeamMembers > 1 ? 'members' : 'member'}`;
  }

}
