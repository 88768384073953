import { DialogRef } from '@angular/cdk/dialog';
import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy, ViewChild, TemplateRef } from '@angular/core';

import { DialogService } from '../../services/dialog.service';
import { ModalDatePickerData } from '../../model';

@Component({
  selector: 'app-phx-modal-date-picker',
  templateUrl: './phx-modal-date-picker.component.html',
  styleUrls: ['./phx-modal-date-picker.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PhxModalDatePickerComponent implements OnInit {
  @ViewChild('datepickerTemplate', { static: true }) datepickerTemplate: TemplateRef<void>;

  @Input() dataModel: ModalDatePickerData;
  @Input() showBackButton: boolean;

  @Output() dateSelected = new EventEmitter<Date>();
  @Output() back = new EventEmitter<any>();

  private dialogRef?: DialogRef<void>;

  constructor(private dialogService: DialogService) {}

  ngOnInit() {
    if (!this.dataModel) {
      throw new Error('Invalid initialization. "dataModel" is required.');
    }
  }

  showModal() {
    this.dialogRef = this.dialogService.showInPopup(this.datepickerTemplate);
  }

  hideModal() {
    this.dialogRef?.close();
  }

  onSelection($event) {
    this.dateSelected.emit($event);
    this.hideModal();
  }

  onBack() {
    this.back.emit(null);
    this.hideModal();
  }

  isHighlightedDate(date: Date): boolean {
    if (this.dataModel.HighlightedDates) {
      return this.dataModel.HighlightedDates.some((d: Date) => d.getTime() === date.getTime());
    } else {
      return false;
    }
  }
}
