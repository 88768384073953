<form [formGroup]="inputFormGroup" [class]="cssClass">
  <app-phx-panel headerTitle="Override Timesheet Exception">
    <ng-container formArrayName="OverrideTimesheetExceptions"
      *ngIf="(!editable && (OverrideTimesheetExceptions?.value?.length === 1 && OverrideTimesheetExceptions?.value[0]?.ExceptionCode)) ||
      (OverrideTimesheetExceptions?.value?.length > 0 && OverrideTimesheetExceptions?.value[0]?.ExceptionCode) || editable">
      <ng-container *ngFor="let c of OverrideTimesheetExceptions.controls; let i = index">
        <div class="override-main">
          <ng-container [formGroupName]="i">
            <!-- Select Timesheet Exception Dropdown Row -->
            <phx-form-control [labelText]="'Override Timesheet Exception ' + (i + 1)"
              [control]="c?.get('ExceptionCode')" class="exceptions-dropdown">

              <div class="row">
                <div class="col-lg-12 override-main-row" [ngClass]="{'pr-0':!(c?.get('Name').value && editable)}">
                  <div class="override-main-row-left" [ngClass]="{'margin-bottom-5':editable}">
                    <div class="override-row-check-outer">
                      <input type="checkbox" formControlName="IsSelected"
                        [id]="'checkbox_OverrideTimesheetExceptions' + i" (click)="checkboxSelectedEvent($event,i)"
                        [attr.disabled]="!c?.get('ExceptionCode')?.value || !editable ? true : null" />

                      <app-phx-select-box [formControl]="c?.get('ExceptionCode')" *ngIf="editable"
                        [items]="c?.get('ExceptionCode').value | availableOverrideExceptionCode : html.codeValueLists.OverrideTimesheetExceptionCode : overrideTimesheetExceptionFormArrayValue"
                        textField="text" valueField="code"
                        [readOnly]="c?.get('Reason').value && c?.get('Document')?.value.FileStorageId"></app-phx-select-box>

                      <div *ngIf="!editable" class="fieldViewMode form-control-static">
                        {{getExceptionText(c?.get('ExceptionCode').value)}}
                      </div>
                    </div>
                  </div>

                  <div class="override-main-row-right">
                    <div class="override-row-rightbox">
                          <span>
                            <i class="doc-author" *ngIf="c?.get('Name').value"> Override by {{c?.get('Name').value}} {{' '}}{{c?.get('DateTimeUtc').value |
                              date:'EEE MMM dd, YYYY' }}</i>
                            
                              <a class="doc-anchor override-doc" *ngIf="c?.get('Document')?.value.FileStorageId"
                              (click)="openDocument(c?.get('Document')?.value.FileStorageId)">
                              <i>View Document</i></a>
                          </span>
                    </div>
                  </div>
                  <div class="override-del-btn" *ngIf="c?.get('Name').value && editable">
                    <button type="button" class="btn btn-default btn-material right pull-right"
                      (click)="handleDeleteAction(i)">
                      <i class="material-icons">delete</i>
                    </button>
                  </div>
                </div>
              </div>
            </phx-form-control>

          </ng-container>
        </div>
      </ng-container>
    </ng-container>
    <div class="plus-button-bottom">
      <button *ngIf="editable && showAddButton" class="btn btn-default btn-material right pull-right"
        type="button" (click)="addTimesheetException()">
        <i class="material-icons">add</i>
      </button>
    </div>
  </app-phx-panel>
</form>


<app-phx-dialog [config]="phxDialogComponentConfigModel"></app-phx-dialog>

<app-phx-document-file-upload #itemFileUpload [showAddButton]="false" [configuration]="documentUploadConfiguration"
  [fileUploaderOptions]="phxDocumentFileUploaderOptions"
  (successItem)="getUploadCompleteNotification($event)">
</app-phx-document-file-upload>
