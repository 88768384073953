<div class="login-container">
    <div class="login-form mat-elevation-z4 text-center">
        <div class="login-img">
            <img src="../../../assets/portal/img-portal-migration.png" alt=""/>
        </div>
        <div class="login-title">
            <h3>{{accountResourceKeys.portal.portalMessageTitle | phxTranslate}}</h3>
        </div>
        <div class="login-text">
            {{ accountResourceKeys.portal.portalMessageText | phxTranslate }}
        </div>
        <div class="login-actions">
            <button type="button" class="btn btn-primary" (click)="goToPortal()">
                {{ accountResourceKeys.portal.goToPortalBtn | phxTranslate }}
            </button>
        </div>
        <div class="portal-learn-more">
            <a href="https://www.procomservices.com/en/fbo-migration-page" target="_blank">{{ accountResourceKeys.portal.learnMoreLink | phxTranslate }}</a>
        </div>
    </div>
  </div>
  