import { inject } from '@angular/core';
import { CanMatchFn, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MAIL_CAMPAIGN_PATHS } from '../../mass-mailer-routing/mail-campaign-paths.const';
import { FboMonolithMailCampaignService } from '../../../common/data-services/fbo-monolith-mail-campaign/fbo-monolith-mail-campaign.service';
import { PhxConstants } from '../../../common';

export const canEditCampaignGuard: CanMatchFn = (route: Route, segments: UrlSegment[]): boolean | UrlTree | Observable<boolean | UrlTree> => {
  const router = inject(Router);

  const campaignId = +segments[segments.length - 1];

  if (!campaignId) {
    return router.createUrlTree([MAIL_CAMPAIGN_PATHS.history.getRouterLink()]);
  }

  const fboMonolithMailCampaignService = inject(FboMonolithMailCampaignService);
  return fboMonolithMailCampaignService.getCampaignById(campaignId).pipe(
    map(campaign => {
      return campaign.MailCampaignStatusId === PhxConstants.MailCampaignStatus.Sent ? router.createUrlTree([MAIL_CAMPAIGN_PATHS.viewCampaign.getRouterLink(campaignId)]) : true;
    })
  );
};
