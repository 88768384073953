import { IRuleResult } from '../../../../common/model/rule-engine';
import { DocumentValidationRuleResult } from 'src/app/common/data-services/fbo-monolith-compliance-data/models';
import { PhxConstants } from '../../../../common';

export const cleanComplianceRulesResult = <T extends IRuleResult | DocumentValidationRuleResult>(ruleResults: T[] | null, ruleTextKey: keyof T, currentLang: string): T[] => {
  return (ruleResults ?? []).reduce((cleanRules, ruleResult) => {
    const isInapplicableRule = (ruleResult as DocumentValidationRuleResult).ruleExecuted === false;

    if (isInapplicableRule) {
      return cleanRules;
    }

    const rulePatch: Partial<T> = {};
    /** NOTE: 'providedValue' is a string[] */
    rulePatch.providedValue = ruleResult.providedValue?.filter(value => !!value);
    /** NOTE: 'info' property is a dictionary of localized strings - get the correct one for current user  */
    rulePatch[ruleTextKey] = (ruleResult.info?.[currentLang] as T[keyof T]) ?? ruleResult[ruleTextKey];

    /** NOTE: remove time from date comparison strings */
    if (rulePatch.providedValue?.length) {
      if (ruleResult.type === PhxConstants.ComplianceComparisonType.DateComparison) {
        /** NOTE: provided value comes in as a date (not sure why - looking at {{Today}} in bubble) this skips those and prevents any issues */
        /** NOTE: this try/catch will be removed - added oct 6/24 */
        try {
          rulePatch.providedValue = rulePatch.providedValue.map(value => value?.split('T')?.[0]);
        } catch (err) { }
      } else {
        /** Show only distinct values, as of BUG 47662 */
        rulePatch.providedValue = Array.from(new Set(rulePatch.providedValue.filter(value => value !== '')));
      }
    } else if (!ruleResult.isOptional) {
      rulePatch.providedValue = [' - '];
    }

    return cleanRules.concat({ ...ruleResult, ...rulePatch });
  }, []);
};
