<div class="header-nav-container" *ngIf="!showOnlyMainContent">
  <app-header></app-header>
</div>
<app-sidenav *ngIf="!showOnlyMainContent" (navigationBarCollapse)="handleNavigationBarCollapse($event)"></app-sidenav>
<app-loading-spinner-overlay text="common.generic.loadingText"></app-loading-spinner-overlay>
<div id="mainContentPage" class="main-content" [class.full-screen]="isNavigationBarCollapsed"
  [ngClass]="{'sidebar-open' : isSidebarOpen, 'side-bar' : isSidebarEnabled, 'adjust-for-top-nav': !showOnlyMainContent}"
  [class.hide-vertical-scrolling]="topMenuOpen" [class.hide-side-nav]="showOnlyMainContent">
  <app-breadcrumbs [class.hide]="!showBreadcrumb"></app-breadcrumbs>
  <div class="page-content" [class.remove-padding-margin]="removePaddingMargin">
    <app-phx-layout-banner></app-phx-layout-banner>
    <router-outlet></router-outlet>
  </div>
</div>
<div class="phx-toast-container"></div>
<app-phx-panel-bar *ngIf="isSidebarEnabled" [isSidebarOpen]="isSidebarOpen" (toggle)="onToggle()"></app-phx-panel-bar>
<div class="impersonating-message-container" *ngIf="userIsImpersonating">Currently Impersonating another User.</div>