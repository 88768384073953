<div class="account-container portal-container stacked" *ngIf="!isLoading">
  <ng-container *ngTemplateOutlet="getTemplate()"></ng-container>
</div>
<ng-template #invalidTokenTemplate>
  <div class="content">
    <div class="logo text-center">
      <img src="/assets/logo-flex-backoffice.png" alt="logo" />
    </div>
    <div class="login-fields">
      <p>It looks like the link you have clicked to set up your Flexbackoffice account has expired. Please contact your
        client services representative and request that they issue a new FlexBackOffice invitation.</p>
      <p>
         Il semble que le lien sur lequel vous avez cliqué pour configurer votre compte Flexbackoffice a expiré.
        Veuillez contacter votre représentant du service clientèle et lui demander d'envoyer une nouvelle invitation
        FlexBackOffice.
      </p>
      <div class="login-buttons">
        <button class="login-action btn btn-primary" (click)="redirectHomePage()">Ok</button>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #existingUserTemplate>
  <div class="content">
    <div class="logo text-center">
      <img src="/assets/logo-flex-backoffice.png" alt="logo" />
    </div>
    <div class="login-fields">
      <p>{{ accountResourceKeys.register.existingUserMessage | phxTranslate }}</p>
      <p>Il semblerait que vous êtes déjà enregistre sur FlexBackOffice! Un instant pendant que nous vous redirigeons vers la page de connexion ....</p>
      <div class="login-buttons">
        <button class="login-action btn btn-primary" (click)="redirectLoginPage()">Ok</button>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #registerTemplate>
  <div class="content">
    <div class="logo text-center">
      <img src="/assets/logo-flex-backoffice.png" alt="logo" />
    </div>
    <form [formGroup]="form" (ngSubmit)="register()" *ngIf="form">
      <div class="login-fields">
        <phx-form-control [control]="form.controls['email']"
          [labelText]="accountResourceKeys.register.usernameLabel | phxTranslate" [editable]="true"
          [showValidationMessages]="true" [layoutType]="formControlLayoutType">
          <input type="text" readonly="readonly" class="form-control input-lg" formControlName="email" id="email" aria-required="true" />
        </phx-form-control>

        <div formGroupName="newPasswordGroup">
          <phx-form-control [control]="passwordGroup.controls['password']"
            [labelText]="accountResourceKeys.register.pwdLabel | phxTranslate" [editable]="true" [showValidationMessages]="true"
            [layoutType]="formControlLayoutType">
            <input type="password" class="form-control input-lg" formControlName="password" id="password" aria-required="true" />
          </phx-form-control>

          <phx-form-control [control]="passwordGroup.controls['confirmPassword']"
            [labelText]="accountResourceKeys.register.confirmPwdLabel | phxTranslate" [editable]="true"
            [showValidationMessages]="true" [layoutType]="formControlLayoutType">
            <input type="password" class="form-control input-lg" formControlName="confirmPassword" id="confirmPassword" aria-required="true" />
          </phx-form-control>
          <div class="col-xs-12">
            <phx-form-control-validation [control]="passwordGroup"
              *ngIf="!passwordGroup.controls['confirmPassword'].pristine"></phx-form-control-validation>
          </div>
        </div>

        <phx-form-control [control]="form.controls['cultureId']"
          [labelText]="accountResourceKeys.register.languageLabel | phxTranslate" [editable]="true"
          [showValidationMessages]="true" [layoutType]="formControlLayoutType">
          <dx-radio-group layout="horizontal"
            [items]="cultureList" displayExpr="text" valueExpr="id" [formControl]="form.controls['cultureId']" id="cultureId">
          </dx-radio-group>
        </phx-form-control>


      </div>
      <div class="login-buttons">
        <span id="loading" style="display: none;">{{ accountResourceKeys.login.loadingMessage | phxTranslate }}</span>
        <button [disabled]="!form.valid" class="login-action btn btn-primary">{{ accountResourceKeys.register.registerButton | phxTranslate }}</button>
      </div>
      <div class="login-error alert alert-danger" *ngIf="validationMessages.length > 0">
        <div *ngFor="let error of validationMessages">
          {{error}}
        </div>
      </div>
    </form>
  </div>
</ng-template>