import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { UserProfileBookmarkService } from '../common/services/user-profile-bookmark.service';
import { EntityList } from '../common/model';
import { Bookmark } from './models/bookmark.model';
import { UserProfileBookmarkDto } from '../common/model/user-profile-bookmark.dto';

@Injectable()
export class BookmarkStateService {
  private bookmarks = new BehaviorSubject<Array<Bookmark> | null>(null);

  constructor(private bookmarkService: UserProfileBookmarkService) {
    this.getBookmarks();
  }

  public get bookmarks$(): Observable<Array<Bookmark>> {
    return this.bookmarks.asObservable().pipe(filter(b => Boolean(b)));
  }

  public upsertBookmark(item: Bookmark): void {
    this.bookmarkService
      .addUpdateBookmark(item)
      .then(() => this.getBookmarks());
  }

  public async deleteBookmark(bookmark: Bookmark): Promise<void> {
    await this.bookmarkService.bookmarkDelete({
      Id: bookmark.id,
      LastModifiedDatetime: bookmark.lastModifiedDatetime,
      pathName: bookmark.pathName
    });
    this.getBookmarks();
  }

  private getBookmarks(): void {
    this.bookmarkService
      .getBookmarkByUser()
      .pipe(
        map<EntityList<UserProfileBookmarkDto>, Array<Bookmark>>(response =>
          !response.Items?.length
            ? []
            : response.Items.map(item => ({
                id: item.Id,
                lastModifiedDatetime: item.LastModifiedDatetime,
                pathName: item.PathName,
                statePath: item.StatePath,
                description: item.Description,
                creationDate: item.CreationDate,
                title: item.Title,
                icon: item.Icon,
                associatedType: item.AssociatedType,
                tempValue: item.Title
              }))
        )
      )
      .subscribe(r => {
        this.bookmarks.next(r);
      });
  }
}
