<div [formGroup]="control.parent" *ngIf="customFieldValue?.CustomFieldVersionConfiguration && nameAttribute && control">
  <phx-form-control [control]="control" [forceValidation]="forceValidation" [listDataSource]="dataSourceDetails"
    displayField="Value" valueField="Id" [iconTemplate]="iconTemplate" [viewModeText]="getViewModeText"
    (selectedListItem)="onSelectedListItemChange($event)"
    [labelText]="customFieldValue.CustomFieldVersionConfiguration?.DisplayName" [layoutType]="layoutType">

    <!-- List -->
    <div *ngIf="customFieldValue.CustomFieldVersionConfiguration?.UIControlTypeId == 1 && 
           customFieldValue.CustomFieldVersionConfiguration?.CustomFieldDataSourceDetails">
     
      <app-phx-select-box [ngClass]="{'custom-form-field': layoutType === PhxFormControlLayoutType.Mobile}"
        [items]="dataSourceDetails" textField="Value" valueField="Id"
        (valueChanged)="updateValueDataSource($event?.value)" [formControl]="control">

      </app-phx-select-box>

    </div>

    <!-- OpenText -->
    <div *ngIf="customFieldValue.CustomFieldVersionConfiguration?.UIControlTypeId == 2">

      <input [ngClass]="{ 'show-error' : forceValidation && control.errors }" [formControl]="control"
        [placeholder]="'common.phxCustomFieldValue.openTextFieldPlaceholder' | phxTranslate : customFieldValue.CustomFieldVersionConfiguration?.DisplayName"
        type="text" class="form-control" (change)="updateValueText($event.target.value)" />
    </div>

    <!-- Radio -->
    <div *ngIf="customFieldValue.CustomFieldVersionConfiguration?.UIControlTypeId == 4 &&
                customFieldValue.CustomFieldVersionConfiguration.CustomFieldDataSourceDetails">
      <label class="radio-inline"
        *ngFor="let radio of customFieldValue.CustomFieldVersionConfiguration.CustomFieldDataSourceDetails">
        <input type="radio" [checked]="radio.Id === customFieldValue.CustomFieldDataSourceDetailId" value="radio.Id"
          name="{{nameAttribute}}" [formControl]="control" (click)="updateValueDataSource(radio.Id)" />
        {{ radio.Value }}
      </label>
    </div>

  </phx-form-control>

</div>