import { Component, Input } from '@angular/core';
import { PhxConstants, CodeValueGroups } from 'src/app/common';
import { GlobalSearchService } from '../../../services/global-search-service';
import { GlobalSearchUtil } from '../../../util/GlobalSearchUtil';

@Component({
  selector: 'app-global-search-suggestion-transaction',
  templateUrl: './global-search-suggestion-transaction.component.html',
  styleUrls: ['./global-search-suggestion-transaction.component.less']
})
export class GlobalSearchSuggestionTransactionComponent {
  @Input() transactionData;
  readonly phxConstants = PhxConstants;
  readonly codeValueGroups = CodeValueGroups;

  constructor(private globalSearchService: GlobalSearchService, public gsUtil: GlobalSearchUtil) { }

  onTransactionSuggestionClick(item) {
    this.globalSearchService.onTransactionSuggestionClick(item);
  }
}
