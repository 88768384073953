import { Injectable } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ApiService } from './api.service';
import { CommonService } from './common.service';
import { PhxConstants } from '../model';
import { WindowRefService } from './WindowRef.service';

@Injectable()
export class ReportService {
  endpoint = 'api/report';

  constructor(private apiService: ApiService, private commonService: CommonService, private winRefService: WindowRefService, private domSanitizer: DomSanitizer) {}

  public openDownloadUrl(downloadTypeId: PhxConstants.DownloadType, entityId: number, downloadFile = false, cache = false, data = '') {

    let redirectWindow: Window = null;

    // https://stackoverflow.com/questions/20696041/window-openurl-blank-not-working-on-imac-safari
    const isSafariNonDesktop = navigator.vendor?.indexOf('Apple') > -1 && navigator.userAgent && !navigator.userAgent.match('CriOS') && !!navigator.userAgent.match(/(iPad|iPhone)/);
    if (isSafariNonDesktop) {
      downloadFile = false;
      redirectWindow = this.winRefService.openUrl('about:blank', '_blank');
    }

    this.getDownloadUrl(downloadTypeId, entityId, downloadFile, cache, data).then(url => {
      if (redirectWindow) {
        redirectWindow.location.href = url;
      } else if (downloadFile) {
        this.winRefService.openUrl(url, '_parent');
      } else {
        this.winRefService.openUrl(url, '_blank');
      }
    }).catch(() => {
      // error UX
    });
  }

  public async getDownloadUrlForIframe(downloadTypeId: PhxConstants.DownloadType, entityId: number, downloadFile = false, cache = false, data = ''): Promise<SafeResourceUrl> {
    const url = await this.getDownloadUrl(downloadTypeId, entityId, downloadFile, cache, data);
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }

  private getDownloadUrl(downloadTypeId: PhxConstants.DownloadType, entityId: number, downloadFile = false, cache = false, data = ''): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.apiService
        .queryWithPromise(`report/getDownloadAccess/${downloadTypeId}/${entityId}?downloadFile=${downloadFile}&cache=${cache}&timezoneOffset=${new Date().getTimezoneOffset()}&data=${data}`, false)
      .then((encryptedTicket) => {
        if (encryptedTicket) {
          resolve(`${this.commonService.api2Url}${this.endpoint}/download?tx=${encryptedTicket}`);
        } else {
          reject();
        }
      }).catch(() => {
        reject();
      });
    });
  }
}
