import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { PhxDataTableColumn } from '../../model';
import { DxListComponent } from 'devextreme-angular';

interface NameValuePair {
  id: string;
  value: string;
}

interface ColumnVisibility {
  id: string;
  isVisible: boolean;
}

@Component({
  selector: 'app-phx-azsearch-column-chooser',
  templateUrl: './phx-azsearch-column-chooser.component.html',
  styleUrls: ['./phx-azsearch-column-chooser.component.less']
})
export class PhxAzsearchColumnChooserComponent {
  @Input() visible: boolean;
  @Input() container: string;

  @Output() closeChooser: EventEmitter<void> = new EventEmitter<void>();
  @Output() orderColumns: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Output() updateColumnVisibility: EventEmitter<ColumnVisibility[]> = new EventEmitter<ColumnVisibility[]>();

  @ViewChild('visibleColumnList') visibleColumnList: DxListComponent;
  @ViewChild('hiddenColumnList') hiddenColumnList: DxListComponent;

  selectColumnsTitle = $localize`:@@common.phxAzsearchColumnChooser.selectColumnsTitle:Select and Order Columns`;
  noHiddenColumns = $localize`:@@common.phxAzsearchColumnChooser.noHiddenColumns:No hidden columns.`;
  noVisibleColumns = $localize`:@@common.phxAzsearchColumnChooser.noVisibleColumns:No visible columns.`;
  searchVisibleColumns = $localize`:@@common.phxAzsearchColumnChooser.searchVisibleColumns:Search visible columns`;
  searchHiddenColumns = $localize`:@@common.phxAzsearchColumnChooser.searchHiddenColumns:Search hidden columns`;
  allColumns: NameValuePair[];
  visibleColumns: NameValuePair[] = [];
  hiddenColumns: NameValuePair[] = [];

  selectedHiddenColumns = [];

  visibilityChanges: ColumnVisibility[] = [];

  constructor(private cdr: ChangeDetectorRef) { }

  onChooserClose() {
    this.visibleColumnList.searchValue = "";
    this.hiddenColumnList.searchValue = "";
    this.closeChooser.emit();
  }

  onClickUpdateColumns() {
    this.onChooserClose();
    this.updateColumnVisibility.emit(this.visibilityChanges);
    this.orderColumns.emit(this.visibleColumns.map(col => col.id));
  }

  /** NOTE: onDragStart and onDragEnd happen in the context of the dx-list - we are two way binding 
   * visibleColumns and hiddenColumns - the e.toDate below ends up as the new value of the
   * bound list       e!--[oo/]
   */
  onDragStart(e) {
    e.itemData = e.fromData[e.fromIndex];
  }
  onDragEnd(e) {
    let start = e.fromIndex;
    let end = e.toIndex;

    while (start < 0) {
      start += e.toData.length;
    }
    while (end < 0) {
      end += e.toData.length;
    }
    if (end >= e.toData.length) {
      let k = end - e.toData.length;
      while ((k--) + 1) {
        e.toData.push(undefined);
      }
    }

    e.toData.splice(end, 0, e.toData.splice(start, 1)[0]);
  }

  onClickShowColumn(e) {
    if (e.addedItems.length) {
      this.updateVisibilityList(e.addedItems[0].id, true);
      this.hiddenColumns = this.hiddenColumns.filter(f => f.id !== e.addedItems[0].id);
      this.visibleColumns = [...this.visibleColumns, { ...e.addedItems[0] }];
    }
  }

  onClickHideColumn(e) {
    if (e.removedItems.length) {
      this.updateVisibilityList(e.removedItems[0].id, false);
      /** NOTE: update with new instance so UI updates correctly */
      this.visibleColumns = [...this.visibleColumns.filter(f => f.id !== e.removedItems[0].id)];
      this.hiddenColumns = [{ ...e.removedItems[0] }, ...this.hiddenColumns];
      /** NOTE: we dont select hidden columns but we need to give the dx-list a new empty array each list change */
      this.selectedHiddenColumns = [];
    }
  }

  /** NOTE: maintain a list of visibility changes since chooser was opened for the forEach to update grid */
  private updateVisibilityList(id: string, isVisible: boolean) {
    const existsIndex = this.visibilityChanges.findIndex(f => f.id === id);
    if (existsIndex > -1) {
      this.visibilityChanges[existsIndex].isVisible = isVisible;
    } else {
      this.visibilityChanges.push({ id, isVisible });
    }
  }

  /** NOTE: this is called from the azsearch data table when the column chooser is opened
   *  we want to have the current hidden and visible columns lists and the order of the visible ones
   */
  updateColumns(orderedVisibleColumnDataFields: string[]) {
    this.visibilityChanges = [];
    this.hiddenColumns = this.allColumns?.filter(f => !orderedVisibleColumnDataFields.includes(f.id));

    const tmpCols = this.allColumns?.filter(f => orderedVisibleColumnDataFields.includes(f.id));
    /** NOTE: map the visible columns to the correct order defined by orderedVisibleColumnDataFields */
    this.visibleColumns = orderedVisibleColumnDataFields.map(col => tmpCols.find(f => f.id === col));

    /** NOTE: user can update column lists when loading a saved report - need to know about that change */
    this.cdr.detectChanges();
  }

  /** NOTE: this is called from the azsearch data table every time the data table loads new state */
  initColumns(allColumns: PhxDataTableColumn[]) {
    if (allColumns.length) {
      /** NOTE: map column data to something more manageable for the dx list control */
      this.allColumns = allColumns
        .filter(f => f.showInColumnChooser)
        .map(column => ({ id: column.dataField, value: column.caption })).filter(col => !!col)
        .sort((a, b) => {
          if (a.value > b.value) {
            return 1;
          } else if (a.value < b.value) {
            return -1;
          } else {
            return 0;
          }
        });
    }
  }
}
