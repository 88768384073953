import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopNavMenuComponent } from './top-nav-menu.component';
import { PhoenixCommonModule } from '../common/PhoenixCommon.module';
import { FeedbackComponent } from '../feedback/feedback.component';
import { ReactiveFormsModule } from '@angular/forms';
import { GlobalSearchBarModule } from '../global-search/global-search-bar/global-search-bar.module';
import { DownloadCenterButtonModule } from '../download-center/download-center-button/download-center-button.module';
import { MatDialogModule } from '@angular/material/dialog';
import { PortalDialogComponent } from './components/portal-dialog/portal-dialog.component';
import { DxDropDownButtonModule } from 'devextreme-angular';


@NgModule({
  declarations: [FeedbackComponent, TopNavMenuComponent, PortalDialogComponent],
  exports: [
    TopNavMenuComponent
  ],
  imports: [
    CommonModule,
    PhoenixCommonModule,
    ReactiveFormsModule,
    GlobalSearchBarModule,
    DownloadCenterButtonModule,
    MatDialogModule,
    DxDropDownButtonModule
  ]
})
export class TopNavMenuModule {
}
