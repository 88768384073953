import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

export interface MatSnackBarData {
  message: string;
  action?: string;
  icon?: string;
}

@Component({
  selector: 'app-phx-mat-toast',
  templateUrl: './phx-mat-toast.component.html',
  styleUrls: ['./phx-mat-toast.component.less']
})
export class PhxMatToastComponent {

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: MatSnackBarData,
              public matSnackBarRef: MatSnackBarRef<PhxMatToastComponent>) {
  }

  actionBtnClick() {
    this.matSnackBarRef.dismissWithAction();
  }

  closeToast() {
    this.matSnackBarRef.dismiss();
  }
}
