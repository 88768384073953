import { Store } from 'azsearchstore';
export enum SearchDirection {
    None,
    Asc,
    Desc
}

export enum SearchType {
    SearchAndGetNewFacets,
    SearchAndPreserveFacets,
    SortOrPageChange,
}

export interface IExecutedSearchInfo {
    searchType: SearchType;
}

export interface ISearchResultsExtension extends Store.SearchResults {
    searchTime: number;
}

export interface IFacetsExtension extends Store.Facets {
    numActiveFacets: number;
}

export interface ISearchSuggestionsExtension extends Store.Suggestions {
    searchTime: number;
}

export interface IParametersExtension extends Store.Parameters {
    suggestionInput: string;
}

export interface ISearchStateExtension extends Store.SearchState {
    results: ISearchResultsExtension;
    facets: IFacetsExtension;
    suggestions: ISearchSuggestionsExtension;
    parameters: IParametersExtension;
}
