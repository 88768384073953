<div class="behaviors-list-container" [ngClass]="sideBarPanelIsOpen ? 'add-open-panel-space' : 'add-space'"
    *ngIf="filteredBehaviors$ | async as filteredBehaviors">
    <div *ngFor="let behavior of filteredBehaviors; index as idx;" (mouseover)="showBehaviorDetails(idx, behavior)"
        (mouseleave)="hideBehaviorDetails()"
        [class]="'behavior-container behavior-position-' + (displayCount - idx) + ' border-' + behavior.BehaviorCode ">
        <div class="avatar-container">
            <img [src]="behavior.ProfilePicture + '?access_token=' + token"
                (error)="handleMissingPicture($event, behavior)" alt="" *ngIf="behavior.ProfilePicture" />
            <div *ngIf="!behavior.ProfilePicture" class="initials-container">
                <div>{{behavior.Name.charAt(0)}}</div>
            </div>
        </div>
        <div class="details-container" *ngIf="behaviorIndex === idx">
            <span><b>{{behavior.Name}}</b> {{behaviorDetails}}</span>
        </div>
    </div>
    <div *ngIf="userCount > userLimit" class="more-link-container" (click)="showAllUsersModal()"
        i18n-title="@@userBehavior.showModalTooltip" title="Show all users currently on this page.">...</div>
</div>

<app-phx-modal cssClass="modal-md" [showHeader]="true" #currentUsersModal>
    <div class="col-md-12 no-padding-h" custom-title>
        <span i18n="@@userBehavior.allUsersModalTitle">Users Currently on this Page</span>
    </div>
    <div *ngIf="!!allUsersDetails.length">
        <div class="all-users-container">
            <div class="details-row" *ngFor="let detail of allUsersDetails">
                <div [class]="'avatar-container' + ' border-' + detail.behaviorCode ">
                    <img [src]="detail.ProfilePicture + '?access_token=' + token"
                        (error)="handleMissingPicture($event, detail)" alt="" *ngIf="detail.ProfilePicture" />
                    <div *ngIf="!detail.ProfilePicture" class="initials-container">
                        <div>{{detail.name.charAt(0)}}</div>
                    </div>
                </div>
                <div class="all-details-container">
                    <span><b>{{detail.name}}</b> {{detail.details}}</span>
                </div>
            </div>
        </div>
        <div class="admin-footer-container">
            <button class="btn btn-default" (click)="closeModal()" i18n="@@common.button.close">Close</button>
        </div>
    </div>
</app-phx-modal>