<dx-date-box *ngIf="pickerType;else noPickerType" [value]="value" [type]="type" [disabled]="disabled" [min]="min" [max]="max" [acceptCustomValue]="allowUserInput"
  [dateSerializationFormat]="type === 'date' ? 'yyyy-MM-dd' : null"
  [readOnly]="readOnly" [showClearButton]="showClearButton" (onValueChanged)="onValueChangedHandler($event)" (onClosed)="closedHandler($event)"
  (onOpened)="openedHandler($event)" [width]="width" [displayFormat]="getExtremeFormat" [calendarOptions]="{ minZoomLevel,  maxZoomLevel}" [placeholder]="placeholder"
  [interval]="interval" [pickerType]="pickerType" (isValidChange)="isValidChangeHandler($event)" (onKeyUp)="onKeyUp($event)" [openOnFieldClick]="openOnFieldClick">
</dx-date-box>

<ng-template #noPickerType>
  <dx-date-box [type]="type" [disabled]="disabled" [value]="value" [min]="min" [max]="max" [readOnly]="readOnly" [showClearButton]="showClearButton" [acceptCustomValue]="allowUserInput"
    [dateSerializationFormat]="type === 'date' ? 'yyyy-MM-dd' : null"
    (onValueChanged)="onValueChangedHandler($event)" (onClosed)="closedHandler($event)" (onOpened)="openedHandler($event)" [placeholder]="placeholder"
    [width]="width" [displayFormat]="getExtremeFormat" [calendarOptions]="{ minZoomLevel,  maxZoomLevel}" (onKeyUp)="onKeyUp($event)" [interval]="interval" [openOnFieldClick]="openOnFieldClick"
    (isValidChange)="isValidChangeHandler($event)">
  </dx-date-box>
</ng-template>
