import { Injectable } from '@angular/core';

import { ApiService } from './api.service';
import { EntityList } from '../model';
import { UserProfileBookmarkDto } from '../model/user-profile-bookmark.dto';

@Injectable({
  providedIn: 'root'
})
export class UserProfileBookmarkService {
  constructor(private apiService: ApiService) {}

  // Commands
  getBookmarkByUser() {
    return this.apiService.query<EntityList<UserProfileBookmarkDto>>('userProfileBookmarks/getBookmarkByUser');
  }

  // Need pathName, color, description
  addUpdateBookmark(command) {
    return this.apiService.command('UserProfileAddBookmark', command);
  }

  // ProfileId can be found inside the handler. Only need the PathName.
  bookmarkDelete(command) {
    return this.apiService.command('UserProfileDeleteBookmark', command);
  }
}
