import { ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges, ViewRef } from '@angular/core';
import { orderBy } from 'lodash';
import { CodeValueService, DialogService, PhxLocalizationService } from '../..';
import { CodeValueGroups, OnClickStateActionOption, StateAction, StateActionButtonsOption, StateActionButtonStyle, StateActionDisplayType } from '../../model';

@Component({
  selector: 'app-phx-state-action-buttons',
  templateUrl: './phx-state-action-buttons.component.html',
  styleUrls: ['./phx-state-action-buttons.component.less']
})
export class PhxStateActionButtonsComponent implements OnChanges {
  /**
   * The configuration of actions.
   * Refer to the StateAction interface for more details.
   */
  @Input() stateActions: StateAction[] = [];

  /**
   * The display type of the component.
   * Refer to the StateActionDisplayType for available options.
   */
  @Input() displayType: StateActionDisplayType = StateActionDisplayType.BUTTON;

  /**
   * An array of actionIds provided by backend.
   * This is used to check if the action is currently available based on access restrictions.
   */
  @Input() availableStateActions: number[] = [];

  /**
   * Reference data that will be passed back to call back functions in componentOption
   */
  @Input() refData: any;

  /**
   * Float the button list to the right, for cases when floating it to the left causes it to be clipped by screen
   * */
  @Input() floatButtonsListToRight = false;

  actionClicked: StateAction;
  executing = false;

  public filteredStateActions: StateAction[];

  constructor(
    private localizationService: PhxLocalizationService,
    private codeValueService: CodeValueService,
    private dialogService: DialogService,
    private cd: ChangeDetectorRef
  ) {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (changes.stateActions) { // remove currentValue check because it should support null
        this.configStateActions();
        this.sortStateActions();
        this.filterStateActions();
      } else if (changes.availableStateActions) { // remove currentValue check because it should support null
        this.filterStateActions();
      }
    }
  }

  showDropdown() {
    return this.displayType === StateActionDisplayType.DROPDOWN;
  }

  showButton() {
    return this.displayType === StateActionDisplayType.BUTTON;
  }

  showSmallButton() {
    return this.displayType === StateActionDisplayType.SMALLBUTTON;
  }

  showLargeButton() {
    return this.displayType === StateActionDisplayType.LARGEBUTTON;
  }

  showButtonWithElipsis() {
    return this.displayType === StateActionDisplayType.BUTTONWITHELIPSIS;
  }

  isActionHidden(action: StateAction) {
    let hidden = false;
    if (action.hiddenFn) {
      hidden = action.hiddenFn(action, this.getComponentOption());
    }
    return hidden;
  }

  isActionDisabled(action: StateAction) {
    let disabled = false;
    if (action.disabledFn) {
      disabled = action.disabledFn(action, this.getComponentOption());
    }
    return disabled;
  }

  onClickAction(action: StateAction) {

    if (!this.isActionDisabled(action) && !this.isActionHidden(action)) {
      this.actionClicked = action;
      if (action.showDeclinedCommentDialog) {
        if (action.showDeclineReasons) {
          this.showDeclinedCommentWithReasonDialog(action.entityTypeId);
        } else {
          this.showDeclinedCommentDialog();
        }
      } else {
        this.executeAction(action);
      }
    }
  }

  executeAction(action: StateAction, option?: OnClickStateActionOption) {
    if (!this.executing) {
      this.executing = true;
      action.onClick(action, this.getComponentOption(), option);
      setTimeout(() => {
        this.executing = false;
        if (!(this.cd as ViewRef).destroyed) {
          this.cd.detectChanges();
        }
      }, 1000);
    } else {
      console.warn('The following action was not executed because another action is currently executing:');
      console.warn(action);
    }
  }

  showDeclinedCommentDialog() {
    this.dialogService
      .comment({
        title: this.actionClicked.displayText,
        helpBlock: this.localizationService.translate('common.phxWorkflowButtons.declineHelpblock', this.actionClicked.displayText),
        label: this.localizationService.translate('common.phxWorkflowButtons.declineReasonLabel', this.actionClicked.displayText.toLowerCase()),
        maxLength: 1024,
        saveButtonText: this.actionClicked.displayText
      },
        { size: 'md' }
      )
      .then(comment => {
        this.executeAction(this.actionClicked, {
          comment
        });
      }, () => {
      });
  }

  showDeclinedCommentWithReasonDialog(entityTypeId: number) {
    this.dialogService
      .commentWithReasons({
        title: this.actionClicked.displayText,
        helpBlock: this.localizationService.translate('common.phxWorkflowButtons.declineHelpblock', this.actionClicked.displayText),
        label: this.localizationService.translate('common.phxWorkflowButtons.declineReasonLabel', this.actionClicked.displayText.toLowerCase()),
        maxLength: 1024,
        saveButtonText: this.actionClicked.displayText,
        showDeclineReasonList: true,
        showSelectionControls: false,
        // eslint-disable-next-line object-shorthand
        entityTypeId: entityTypeId,
      },
        { size: 'md' }
      )
      .then(comment => {
        this.executeAction(this.actionClicked, {
          comment: comment.Comment,
          declineReasons: comment.DeclineReasons
        });
      }, () => { });
  }

  getComponentOption(): StateActionButtonsOption {
    return {
      displayType: this.displayType,
      refData: this.refData
    };
  }

  configStateActions() {
    this.stateActions = this.stateActions || [];
    this.stateActions.forEach((action: StateAction) => {
      if (action.actionId && !action.displayText) {
        action.displayText = this.codeValueService.getCodeValueText(action.actionId, CodeValueGroups.StateAction);
      }
      if (action.actionId && !action.commandName) {
        action.commandName = this.codeValueService.getCodeValueCode(action.actionId, CodeValueGroups.StateAction);
      }
      if (!action.actionName) {
        action.actionName = action.commandName;
      }
      action.primaryAction = action.style === StateActionButtonStyle.PRIMARY;
      action.secondaryAction = action.style === StateActionButtonStyle.SECONDARY;
      action.dangerAction = action.style === StateActionButtonStyle.DANGER;
      action.warningAction = action.style === StateActionButtonStyle.WARNING;
    });
  }

  sortStateActions() {
    this.stateActions = orderBy(this.stateActions, ['primaryAction', 'dangerAction', 'warningAction', 'secondaryAction', 'sortOrder'], ['desc', 'desc', 'desc', 'desc', 'asc']);
    if (this.showButton() || this.showButtonWithElipsis()) {
      this.stateActions.reverse();
    }
  }

  filterStateActions() {
    const availableStateActions = this.availableStateActions || [];
    this.filteredStateActions = this.stateActions.filter((action: StateAction) => {
      return !action.actionId || action.skipSecurityCheck || availableStateActions.some((id: number) => id === action.actionId);
    });
  }

  hasStateActions() {
    return this.filteredStateActions?.some((action: StateAction) => !this.isActionHidden(action));
  }
}
