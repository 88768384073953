<ng-template  #datepickerTemplate>
    <div class="responsiveModal modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-header">
              <button *ngIf="showBackButton" class="btn btn-link pull-left" (click)="onBack()">
                <i class="material-icons">arrow_back</i>
              </button>
              <h4 class="modal-title">{{dataModel.Title}}</h4>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-xs-12 caption">
                  {{dataModel.Caption}}
                </div>
              </div>
              <div class="row datepicker-container">
                <div class="datepicker">
                  <dx-calendar
                          (onValueChanged)="onSelection($event.value)"
                          [min]="dataModel.StartDate"
                          [max]="dataModel.EndDate"
                          [(value)]="dataModel.SelectedDate"
                          [firstDayOfWeek]="1">
                      <span *dxTemplate="let cellData of 'cell'; let i = index"
                            [ngClass]="{'highlight-day' : isHighlightedDate(cellData.date)}">
                        {{cellData.text}}
                      </span>
                  </dx-calendar>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button (click)="hideModal()" type="button" class="btn btn-default btn-block"
                      data-dismiss="modal">{{ 'common.generic.cancel' | phxTranslate }}</button>
            </div>
          </div>
    </div>
</ng-template>
