import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { IAuditHistoryDocument } from '../model';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class AuditHistoryService {
  private readonly auditServiceApiEndPoint = environment.auditServiceApiEndPoint;

  constructor(private apiService: ApiService) {}
  getAuditHistory(groupingId: string): Observable<Array<IAuditHistoryDocument>> {
    return this.apiService.httpGetRequest<Array<IAuditHistoryDocument>>(`Audit/${groupingId}`, this.auditServiceApiEndPoint);
  }
}
