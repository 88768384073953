import { AfterViewInit, Component, Input, OnChanges, SimpleChanges, Type, ViewChild, ViewContainerRef } from '@angular/core';

@Component({
  selector: 'app-phx-panel-custom',
  templateUrl: './phx-panel-custom.component.html',
  styleUrls: ['./phx-panel-custom.component.less']
})
export class PhxPanelCustomComponent implements AfterViewInit, OnChanges {
  @Input() name: string;
  @Input() icon: string;
  @Input() componentType: Type<any>;
  @Input() componentInputs: Record<string, any>;

  @ViewChild('customPanel', { read: ViewContainerRef }) customPanel: ViewContainerRef;

  private customComponent: any | null = null;

  ngAfterViewInit(): void {
    this.createComponent();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.componentInputs) {
      this.setInputs(changes.componentInputs.currentValue);
    }
  }

  private createComponent() {
    if (this.customPanel && !this.customComponent) {
      this.customComponent = this.customPanel.createComponent(this.componentType);
      this.setInputs(this.componentInputs);
    }
  }

  private setInputs(inputs: Record<string, any>) {
    if (this.customComponent) {
      // eslint-disable-next-line guard-for-in
      for (const key in inputs) {
        this.customComponent.setInput(key, this.componentInputs[key]);
      }
    }
  }
}
