import { Injectable } from '@angular/core';

@Injectable()
export class GlobalSearchUtil {
    firstPlusCount(array: string[]): string {
        if (!array || array.length < 1) {
            return '';
        }
        const totalWorkerCount = array.length;
        return array[0] + (totalWorkerCount > 1 ? ` + ${totalWorkerCount - 1}` : '');
    }

    isNum(n: number) {
        return !isNaN(n);
    }
}
