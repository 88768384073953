import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../common/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PhxLocalizationService } from '../../common';
import { BaseComponentOnDestroy } from '../../common/epics/base-component-on-destroy';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-account-forgot-password',
  templateUrl: './account-forgot-password.component.html',
  styleUrls: ['./account-forgot-password.component.less']
})
export class ForgotPasswordComponent extends BaseComponentOnDestroy implements OnInit {
  form: UntypedFormGroup;
  passwordForgotSent = false;
  errorToShow: string = null;
  private userEmail: string;
  pendingForgotPassword = false;

  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private router: Router,
    private phxLocalizationService: PhxLocalizationService,
    private route: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    this.authService.getCurrentUser()
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(() => this.router.navigate(['next', 'account', 'manage']));

    this.userEmail = this.route.snapshot.paramMap.get('email');
    this.form = this.fb.group({
      email: [this.userEmail, [
        Validators.required,
        Validators.minLength(6),
        Validators.email
      ]]
    });
    if (this.userEmail) {
      this.pendingForgotPassword = true;
      this.sendForgotPassword();
    }
  }

  sendForgotPassword() {
    this.errorToShow = null;
    const email = this.form.get('email').value;
    this.authService.forgotPassword(email)
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe({
        next: () => {
          this.passwordForgotSent = true;
          this.pendingForgotPassword = false;
          setTimeout(() => {
            this.router.navigate(['login']);
          }, 5000);
        },
        error: error => {
          if (!error || (error && !error.data)) {
            this.errorToShow = this.phxLocalizationService.translate('account.forgot.pwdResetErrorMessage');
          } else if (error.status === 304) {
            this.errorToShow = this.phxLocalizationService.translate('account.forgot.emailIncorrectErrorMessage');
          } else if (error.status === 400) {
            this.errorToShow = error.error.ModelState['model.Email'][0];
          }
        }
      });
  }

}
