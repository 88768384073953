import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, Data } from '@angular/router';
import { Observable, forkJoin, of } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { switchMap } from 'rxjs/operators';
import { PhxConstants } from '../PhoenixCommon.module';
import { ConfigurationService } from 'src/app/configuration/service/configuration.service';
import { environment } from 'src/environments/environment';
import { MODULE_ROUTES } from '../constants/module-routes.const';

const canAccess = (routeData: Data): Observable<boolean> => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const configurationService = inject(ConfigurationService);

  return authService.getCurrentProfile().pipe(
    switchMap(profile => forkJoin([configurationService.getFeatureFlagConfigurationData$(), of(profile)])),
    switchMap(([, profile]) => {
      if (!configurationService.isFeatureActive(PhxConstants.FeatureFlags.UseAccessByRoleGaurd)) {
        return of(true);
      }

      let hasAccess = false;
      /** NOTE: get any role/operation access specifically assigned to this route */

      const validRoleIds = routeData?.validRoles || [];
      const validFunctionalIds = routeData?.validOperations || [];
      /** NOTE: get current user's role and operation access */

      const userRoleIds = profile.FunctionalRoles.map(m => m.FunctionalRoleId);
      const userFunctionalIds = profile.FunctionalOperations;

      if (routeData) {
        /** NOTE: if there are no accepted role/operation passed with the route allow access  */

        if (!validRoleIds.length && !validFunctionalIds.length) {
          hasAccess = true;
          /** NOTE: if user matches role or operation then we grant access - maybe there is an AND scenario - revisit */
        } else if (validRoleIds.filter(f => userRoleIds.includes(f)).length || validFunctionalIds.filter(f => userFunctionalIds.includes(f)).length) {
          hasAccess = true;
        }
      } else {
        /** NOTE: to prevent an unintentioal blocking of users on production we will grant them access as a fall back
         *  until guarding by roles is an understood common step in development
         */
        if (environment.production) {
          hasAccess = true;
        }
        console.error(`ROUTER ERROR: Access guard missing 'validRoles' list - ${router.url}`);
      }

      if (!hasAccess) {
        router.navigate([`/${MODULE_ROUTES.MAIN}`, MODULE_ROUTES.NO_ACCESS], { replaceUrl: true });
      }

      return of(hasAccess);
    })
  );
};

export const roleAccessGuard: CanActivateFn = (route: ActivatedRouteSnapshot): Observable<boolean> => {
  return canAccess(route?.data);
};

export const roleAccessChildGuard: CanActivateFn = (route: ActivatedRouteSnapshot): Observable<boolean> => {
  if (route.data?.validRoles) {
    return canAccess(route.data);
  } else if (route?.parent?.data) {
    return canAccess(route.parent.data);
  }
  return of(false);
};
