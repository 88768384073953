import { from as observableFrom, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiService, PhxConstants } from '../../common';
import { IOrganization, IPaysideDeductionHeader, IVmsFeeHeader } from '../models';
import { EntityList } from '../../common/model';
import { environment } from '../../../environments/environment';

declare let oreq: any;

@Injectable({
  providedIn: 'root'
})
export class OrganizationApiService {
  data: any = {
    organizationsListInternal: [],
    clientList: []
  };
  private readonly apiEndPoint: string;


  constructor(private apiService: ApiService) {
    this.apiEndPoint = environment.organizationServiceApiEndpoint;
  }

  getListOrganizationClient1(oDataParams?: any): Observable<any> {
    oDataParams =
      oDataParams ||
      oreq
        .request()
        .withExpand(['OrganizationAddresses, OrganizationClientRoles'])
        .withSelect(['Id', 'DisplayName', 'OrganizationAddresses/IsPrimary', 'OrganizationAddresses/SubdivisionId',
                     'OrganizationClientRoles/IsChargeSalesTax', 'OrganizationClientRoles/ClientSalesTaxDefaultId'])
        .url();
    return this.apiService.query('org/getListOrganizationsOriginalAndStatusIsAtiveOrPendingChangeInActiveClientRole?' + oDataParams);
  }

  getListOrganizationsStatusActiveOrPendingChange(oDataParams?: any): Observable<any> {
    oDataParams =
      oDataParams ||
      oreq
        .request()
        .withSelect(['Id', 'DisplayName', 'Code'])
        .url();
    return this.apiService.query('org/getListOrganizationsStatusActiveOrPendingChange' + oDataParams);
  }

  getListOrganizationsOriginalAndStatusIsAtiveOrPendingChangeInActiveInternalRole(oDataParams = null): Observable<any> {
    oDataParams =
      oDataParams ||
      oreq
        .request()
        .withSelect(['Id', 'DisplayName', 'Code', 'IsTest'])
        .url();
    return this.apiService.query('org/getListOrganizationsOriginalAndStatusIsAtiveOrPendingChangeInActiveInternalRole' + (oDataParams ? '?' + oDataParams : ''));
  }

  getInternalOrganizationList(oDataParams = null): Observable<any> {
    oDataParams =
      oDataParams ||
      oreq
        .request()
        .withSelect(['Id', 'DisplayName', 'Code', 'IsTest'])
        .url();
    return this.apiService.query('org/getInternalOrganizationList' + (oDataParams ? '?' + oDataParams : ''));
  }

  getOrganizationName(organizationIdInternal: number): Observable<any> {
    const oDataParams = oreq
      .request()
      .withSelect(['LegalName'])
      .withFilter(oreq.filter('Id').eq(organizationIdInternal))
      .url();
    return this.apiService.query('org/getOrganizations?' + (oDataParams ? oDataParams + '&' : ''));
  }

  getListUserProfileWorker(oDataParams = null): Observable<any> {
    oDataParams =
      oDataParams ||
      oreq
        .request()
        .withExpand(['Contact', 'UserProfileAddresses', 'UserProfileWorkerSourceDeductions', 'UserProfileWorkerOtherEarnings', 'UserProfilePaymentMethods'])
        .withSelect([
          'Id',
          'ContactId',
          'ProfileTypeId',
          'PayeeName',
          'PaymentMethodId',
          'OrganizationId',
          'Contact/Id',
          'Contact/FullName',
          'UserProfileWorkerSourceDeductions/IsApplied',
          'UserProfileWorkerSourceDeductions/SourceDeductionTypeId',
          'UserProfileWorkerSourceDeductions/RatePercentage',
          'UserProfileWorkerOtherEarnings/IsApplied',
          'UserProfileWorkerOtherEarnings/PaymentOtherEarningTypeId',
          'UserProfileWorkerOtherEarnings/RatePercentage',
          'UserProfileAddresses/ProfileAddressTypeId',
          'UserProfileAddresses/SubdivisionId',
          'UserProfilePaymentMethods/PaymentMethodTypeId',
          'UserProfilePaymentMethods/IsSelected',
          'UserProfilePaymentMethods/IsPreferred'
        ])
        .url();
    return this.apiService.query('UserProfile/getListUserProfileWorker' + (oDataParams ? '?' + oDataParams : ''));
  }

  getListOrganizationInternal(isForceRefresh: boolean = false, oDataParams = null): Observable<any> {
    return new Observable(observer => {
      oDataParams = oDataParams || oreq.request().withSelect(['Id', 'DisplayName', 'LegalName', 'Code', 'IsTest']).url();

      if (Object.keys(this.data.organizationsListInternal).length === 0 || isForceRefresh) {
        this.apiService.query<EntityList<any>>('org/getListOrganizationsOriginalAndStatusIsAtiveOrPendingChangeInActiveInternalRole?' + oDataParams)
          .subscribe(response => {
            this.data.organizationsListInternal = response.Items;
            observer.next(this.data.organizationsListInternal);
            observer.complete();
          },
            () => {
              this.data.organizationsListInternal = [];
              observer.next(this.data.organizationsListInternal);
              observer.complete();
            }
          );
      } else {
        observer.next(this.data.organizationsListInternal);
        observer.complete();
      }
    });
  }

  getListClient(isForceRefresh: boolean = false, oDataParams = null): Observable<any> {
    return new Observable(observer => {
      oDataParams = oDataParams || oreq.request().withSelect(['Id', 'DisplayName', 'LegalName', 'Code']).url();
      if (Object.keys(this.data.clientList).length === 0 || isForceRefresh) {
        this.apiService.query('org/getListOrganizationsOriginalAndStatusIsAtiveOrPendingChangeInActiveClientRole?' + oDataParams)
          .subscribe((response: any) => {
            this.data.clientList = response.Items;
            observer.next(this.data.clientList);
            observer.complete();
          },
            () => {
              this.data.clientList = [];
              observer.next(this.data.clientList);
              observer.complete();
            }
          );
      } else {
        observer.next(this.data.clientList);
        observer.complete();
      }
    });
  }

  getSingleOrganizationInternalRoleByOriginalAndStatusIsAtiveOrPendingChangeOrganization(organizationId: number): Observable<any> {
    return this.apiService.query('org/getSingleOrganizationInternalRoleByOriginalAndStatusIsAtiveOrPendingChangeOrganization/' + organizationId);
  }

  getSingleOrganizationInternalWithoutDecryption(organizationId: number): Observable<any> {
    return this.apiService.query('org/getSingleOrganizationInternalWithoutDecryption/' + organizationId);
  }

  getByOrganizationId(organizationId: number) {
    return this.apiService.query<IOrganization>('org?id=' + organizationId);
  }

  getUserProfileInternalList() {
    const filter = oreq
      .filter('ProfileStatusId')
      .eq(PhxConstants.ProfileStatus.Active)
      .or()
      .filter('ProfileStatusId')
      .eq(PhxConstants.ProfileStatus.PendingChange)
      .or()
      .filter('ProfileStatusId')
      .eq(PhxConstants.ProfileStatus.Inactive)
      .or()
      .filter('ProfileStatusId')
      .eq(PhxConstants.ProfileStatus.PendingDeactivation)
      .or()
      .filter('ProfileStatusId')
      .eq(PhxConstants.ProfileStatus.PendingActivation);
    const oDataParams = oreq
      .request()
      .withExpand(['Contact'])
      .withSelect(['Id', 'ProfileStatusId', 'Contact/Id', 'Contact/FullName'])
      .withFilter(filter)
      .url();

    return this.apiService.query('UserProfile/getListUserProfileInternal' + (oDataParams ? '?' + oDataParams : ''));
  }

  getProfilesForOrganization(organizationIdInternal: number, tableState, oDataParams): Observable<any> {
    const tableStateParams = tableState ? this.generateRequestObject(tableState).url() : '';
    return this.apiService.query('UserProfile/getProfilesForOrganization/' + organizationIdInternal + '?' + (oDataParams ? oDataParams + '&' : '') + tableStateParams);
  }

  getSingleGarnisheeDetail(organizationId: number, garnisheeId: number): Observable<any> {
    return this.apiService.query('org/getSingleGarnisheeDetailByOriginalAndStatusIsAtiveOrPendingChangeOrganization/organization/' + organizationId + '/garnishee/' + garnisheeId);
  }

  getListGarnisheePayToGroup() {
    return this.apiService.query('org/getListGarnisheePayToGroup');
  }

  getListGarnisheesByOriginalAndStatusIsAtiveOrPendingChangeOrganization(organizationId, tableState, oDataParams) {
    const tableStateParams = tableState ? this.generateRequestObject(tableState).url() : '';
    return this.apiService.query('org/getListGarnisheesByOriginalAndStatusIsAtiveOrPendingChangeOrganization/organization/'
      + organizationId + '?' + (oDataParams ? oDataParams + '&' : '') + '&' + tableStateParams);
  }

  garnisheeNewSave(command) {
    return observableFrom(this.apiService.command('GarnisheeNew', command));
  }

  garnisheeSubmit(command) {
    return observableFrom(this.apiService.command('GarnisheeSubmit', command));
  }

  refreshActiveAdvancesAndActiveGarnisheesCount(organizationId: number) {
    return this.apiService.query(`org/${organizationId}/activeAdvancesAndGarnisheesCounts`);
  }

  advanceNew(command) {
    return observableFrom(this.apiService.command('AdvanceNewState', command));
  }

  advanceSubmit(command) {
    return observableFrom(this.apiService.command('AdvanceSubmit', command));
  }

  getSingleAdvanceDetailByOriginalAndStatusIsActiveOrPendingChangeOrganization(organizationId: number, advanceId: number): Observable<any> {
    return this.apiService.query('org/getSingleAdvanceDetailByOriginalAndStatusIsAtiveOrPendingChangeOrganization/organization/' + organizationId + '/advance/' + advanceId);
  }

  createOrganization(): any {
    return this.apiService.httpPostRequest<IOrganization>(`Organization`,
      {
        Organization: null
      }, environment.organizationServiceApiEndpoint, true);
  }

  generateRequestObject(tableState) {
    const searchObj = tableState?.search?.predicateObject ? tableState.search.predicateObject : null;
    const sortObj = tableState?.sort?.predicate ? tableState.sort.predicate + (tableState.sort.reverse ? ' desc ' : '') : null;
    let currentPage = tableState?.pagination?.currentPage ? tableState.pagination.currentPage : 1;
    const pageSize = tableState?.pagination?.pageSize ? tableState.pagination.pageSize : 30;
    currentPage--;
    let oDataParams = oreq.request();
    if (Object.keys(searchObj).length > 0) {
      oDataParams = oDataParams.withFilter(oreq.filter().smartTableObjectConverter(searchObj));
    }
    if (sortObj) {
      oDataParams = oDataParams.withOrderby(sortObj);
    }
    if (!tableState?.pagination?.isDisabled) {
      oDataParams = oDataParams
        .withTop(pageSize)
        .withSkip(currentPage * pageSize)
        .withInlineCount();
    } else {
      oDataParams = oDataParams.withInlineCount();
    }
    return oDataParams;
  }

  getRebatesAndFeesDetailsByOriginalAndStatusIsAtiveOrPendingChangeOrganization(organizationId): Observable<any> {
    return this.apiService.query('org/getRebatesAndFeesDetailsByOriginalAndStatusIsAtiveOrPendingChangeOrganization/' + organizationId);
  }

  getSingleVmsFeeHeaderByVersion(vmsFeeVersionId): Observable<IVmsFeeHeader> {
    return this.apiService.query('org/getSingleVmsFeeHeaderByVersion/vmsFeeVersion/' + vmsFeeVersionId);
  }

  getSingleRebateHeaderByVersion(rebateVersionId) {
    return this.apiService.query('org/getSingleRebateHeaderByVersion/rebateVersion/' + rebateVersionId);
  }

  getSinglePaySideDeductionHeaderByVersion(paysideDeductionVersionId): Observable<IPaysideDeductionHeader> {
    return this.apiService.query('org/getSinglePaySideDeductionHeaderByVersion/paySideDeductionVersion/' + paysideDeductionVersionId) as Observable<IPaysideDeductionHeader>;
  }
  
  getWorkOrdersAssociatedWithRebateFee(entityTypeId, entityId) {
    return this.apiService.query(`assignment/rebatesdeductiblesandfees/entityTypeId/${entityTypeId}/entityId/${entityId}/`);
  }

  getOrganizationsAssociatedWithUserProfile(userProfileId: number): Observable<IOrganization[]> {
    return this.apiService.httpGetRequest<IOrganization[]>(`Organization/associated-organizations/${userProfileId}`, this.apiEndPoint);
  }
}
