<div *ngIf="buttons?.length > 0" [ngClass]="{'inline-btn flex-row' : placedOnModal || !isMobile}">
  <app-phx-fab [fabButtons]="buttons" *ngIf="!placedOnModal && isMobile"></app-phx-fab>
  <ng-container *ngIf="buttons.length > 0 && (placedOnModal || !isMobile)">
    <div *ngIf="showButtonsLeft" class="btn-group btn-row-left">
      <ng-container *ngFor="let button of buttons">
        <button *ngIf="button.isTertiaryAction" class="btn btn-link {{button.btnClasses?.join(' ')}}"
                [disabled]="button.disabled && button.disabled()" (click)="onButtonClick(button)">
          {{button.tooltip}}
        </button>
      </ng-container>
    </div>
    <div [ngClass]="{'minimum-padding' : showButtonsLeft && isMobile}" class="btn-group btn-row-right">
      <ng-container *ngFor="let button of buttons">
        <button [ngClass]="{'small-buttons' : showButtonsLeft && isMobile}" *ngIf="!button.isTertiaryAction"
                class="btn btn-{{button.btnType}} {{button.btnClasses?.join(' ')}}"
                [disabled]="button.disabled && button.disabled()" (click)="onButtonClick(button)">
          {{button.tooltip}}
        </button>
      </ng-container>
    </div>
  </ng-container>
</div>
