import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WindowRefService } from 'src/app/common';
import { environment } from 'src/environments/environment';
import { AccountModuleResourceKeys } from '../account-module-resource-keys';

@Component({
  selector: 'app-portal',
  templateUrl: './portal.component.html',
  styleUrls: ['./portal.component.less']
})
export class PortalComponent implements OnInit {

  token: string;

  accountResourceKeys = AccountModuleResourceKeys;

  constructor(
    private route: ActivatedRoute,
    private winRef: WindowRefService
  ) { }

  ngOnInit() {
    this.token = this.route.snapshot.paramMap.get('token');
  }

  goToPortal() {
    const params = new URLSearchParams();
    params.append('Source', 'fbo');
    params.append('MigrationToken', this.token);
    const url = environment.contractorPortalLoginUrl + '?' + params.toString();
    this.winRef.openUrl(url, '_self');
  }

}
