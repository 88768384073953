import { Injectable } from '@angular/core';
import { CookieService as NgxCookie } from 'ngx-cookie';

@Injectable()
export class CookieService {

  constructor(
    private ngxCookie: NgxCookie
  ) {
  }

  get CookieStore() {
    return 'phoenixCookie';
  }

  put(key, val) {
    this.ngxCookie.put(key, val);
  }

  getItem(key) {
    if (!key) {
      return null;
    }
    return this.ngxCookie.get(key);
  }

  setItem(key, value, expires: string | Date, path: string = '/', domain?: string, secure?: boolean) {
    if (!key || /^(?:expires|max\-age|path|domain|secure)$/i.test(key)) {
      return false;
    }

    this.ngxCookie.put(key, value, {
      domain,
      secure,
      expires,
      path
    });
  }

  removeItem(key, path?: string, domain?: string) {
    this.ngxCookie.remove(key, {
      path,
      domain
    });
  }

  getProfileIdFromCookie() {
    const defaultProfile: any = { profileId: -1, dbId: -1 };
    // Returns the deserialized value of given cookie key
    return this.ngxCookie.getObject(this.CookieStore) || defaultProfile;
  }

  setProfileIdInCookie(profileId: number, dbId: number) {
    const profile: any = { profileId, dbId };
    // Returns the deserialized value of given cookie key
    return this.ngxCookie.putObject(this.CookieStore, profile);
  }

  putObject(k, v) {
    return this.ngxCookie.putObject(k, v);
  }
}
