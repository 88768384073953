import { ExportingEvent } from 'devextreme/ui/data_grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';

export const saveToExcel = async (e: Pick<ExportingEvent, 'fileName' | 'component'>, getCustomizeCellFunction?: any): Promise<void> => {
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet();

  await exportDataGrid({
    component: e.component,
    worksheet,
    customizeCell: getCustomizeCellFunction?.(e.component.getVisibleRows())
  });

  const buffer: BlobPart = await workbook.xlsx.writeBuffer();

  saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${e.fileName || 'xlsx'}.xlsx`);
};
