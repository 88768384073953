import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild, HostBinding, Optional } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { FormControlExtensions } from './formcontrol.extensions';
import { PhxFormControlLayoutType } from '../../model';
import { PhxModalComponent } from '../phx-modal/phx-modal.component';
import { PhoenixCommonModuleResourceKeys } from '../../PhoenixCommonModule.resource-keys';
import { IPIIMetaData } from '../phx-pii-field/phx-pii-field.interface';
import { AbstractControl as LegacyAbstractControl } from '../../ngx-strongly-typed-forms';
import { VersionComparisonService } from '../../services/version-comparison.service';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ClickAnalyticsData } from '../../services/google-analytics/models/click-analytics-data.model';

@Component({
  selector: 'phx-form-control',
  templateUrl: './phx-form-control.component.html',
  styleUrls: ['./phx-form-control.component.less']
})
export class PhxFormControlComponent implements OnInit, OnChanges {
  @Input() control: AbstractControl<any, any> | LegacyAbstractControl<any>;

  @Input()
  @HostBinding('attr.data-form-control-name') // Used for tagging a specific control (by controlName) for analytics
  name: string;

  @Input() labelText: string;
  @Input() hideLabelText = false;
  @Input() helperText: string;
  @Input() showValidationMessages = false;
  @Input() forceValidation = false;
  @Input() ignoreValidationMessages = false;
  @Input() removeStyleClasses = false;
  @Input() editable = true;
  @Input() viewModeText: string;
  @Input() layoutType: PhxFormControlLayoutType = PhxFormControlLayoutType.Responsive;
  @Input() enableLabelAsterisk = true;
  @Input() enableLabelForceAsterisk = false;
  @Input() warningMessage: string;
  @Input() piiMetaData: IPIIMetaData = null;
  @Input() labelAlign: 'right' | 'left' = 'right';

  @Input() showLabelAsHyperlink = false;
  @Input() link?: string;
  @Input() relativeToRoute?: ActivatedRoute;

  @Input() iconTemplate: TemplateRef<any>;

  @Input() listDataSource: Array<any>;
  @Input() displayField: string;
  @Input() valueField: string;
  @Input() disabled: boolean;
  @Input() canDisplayValue = true;
  @Input() clickAnalyticsData?: ClickAnalyticsData & Record<string, any>;

  @Output() labelClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectedListItem: EventEmitter<any> = new EventEmitter();
  @Output() revealedPiiMetaData: EventEmitter<IPIIMetaData> = new EventEmitter<IPIIMetaData>();

  @ViewChild(PhxModalComponent) selectListModal: PhxModalComponent;

  PhxFormControlLayoutType: typeof PhxFormControlLayoutType = PhxFormControlLayoutType;
  PhoenixCommonModuleResourceKeys: typeof PhoenixCommonModuleResourceKeys = PhoenixCommonModuleResourceKeys;
  filteredDataSource: Array<any>;

  hasChanged$: Observable<boolean>;
  constructor(
    @Optional() private versionComparisonService: VersionComparisonService<any, any>
  ) { }

  ngOnInit(): void {
    this.name = this.name || FormControlExtensions.findControlName(this.control);
    
    if (this.labelText && !this.name) {
      throw new Error('Invalid initialization. Need `name` and `labelText`.');
    }

    if (this.piiMetaData?.IsMasked && !this.ignoreValidationMessages) {
      this.forceValidation = true;
    }

    this.initVersionValueChange();
  }

  private initVersionValueChange(){
    if(this.versionComparisonService){
      this.hasChanged$ = this.versionComparisonService.hasChanged$(this.control);
    }
  }

  trackByFn(index: number, item: any) {
    return this.valueField ? item[this.valueField] : null;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.listDataSource) {
      if (!Array.isArray(this.listDataSource)) {
        throw new Error('Invalid `listDataSource`. It should be an array');
      }

      const collection = this.listDataSource || [];

      if (collection.length) {
        collection.forEach(item => {
          if (item === null) {
            throw new Error('The provided `listDataSource` is contaminated by null entires');
          }

          if (!item.hasOwnProperty(this.displayField) || !item.hasOwnProperty(this.valueField)) {
            throw new Error('The provided `listDataSource` has invalid elements');
          }

          if (typeof item[this.displayField] !== 'string') {
            throw new Error(`Invalid 'displayField' value: ${item[this.displayField]}. The 'displayField' must have a string value`);
          }
        });

        this.onFilterList();
      }
    }
  }

  onLabelClick() {
    this.labelClick.emit();
  }

  /**
   * Triggers on selecting an item from the list
   * @param selectedItem Selected item
   */
  onSelectItem(selectedItem: any) {
    this.control.setValue(typeof selectedItem[this.valueField] === 'number' ? +selectedItem[this.valueField] : selectedItem[this.valueField]);
    this.selectedListItem.emit(selectedItem);
    this.displayModal(false);
  }

  /**
   * Sets the select list modal visibility
   * @param show Specifies whether the modal should be visible or not
   */
  public displayModal(show: boolean) {
    if (this.selectListModal) {
      if (show) {
        this.onFilterList();
        this.selectListModal.show();
      } else {
        this.selectListModal.hide();
      }
    }
  }

  /**
   * Triggers on filter by search text
   * @param filterText Search text
   */
  onFilterList(filterText: string = '') {
    this.filteredDataSource = this.listDataSource.filter(item => {
      return (item[this.displayField] as string).toLowerCase().includes(filterText.toLowerCase());
    });
  }

  getPiiMetaData(event: any) {
    if (event) {
      this.revealedPiiMetaData.emit(event);
    }
  }
}
