import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { formatDate } from '@angular/common';
import { Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { ToolTipField } from '../../model/phx-select-box-w-tooltip';
import { FormControl as LegacyFormControl } from '../../ngx-strongly-typed-forms';
import { BaseComponentOnDestroy } from '../../epics/base-component-on-destroy';
import { CommonListsObservableService } from '../../lists/lists.observable.service';
import { CodeValueGroups, PhxConstants } from '../../model';
import { CodeValueService } from '../../services/code-value.service';
import { CommonService } from '../../services/common.service';
import { PhxLocalizationService } from '../../services/phx-localization.service';
import { UserProfileWorkerListItem } from '../../lists/models/user-profile-worker-list-item.model';
import { WorkerSelectOption } from './models/worker-selection.model';
import { CheckRelevantWorker } from './utils/models/check-relevant-worker.model';

@Component({
  selector: 'app-select-worker-profile',
  templateUrl: './select-worker-profile.component.html',
  styleUrls: ['./select-worker-profile.component.less']
})
export class SelectWorkerProfileComponent extends BaseComponentOnDestroy implements OnInit {
  @Input() labelText = 'Worker Profiles';

  @Input() set control(c: FormControl<number> | LegacyFormControl<number>) {
    this.initControl(c);
  }

  @Input() checkRelevantWorker?: CheckRelevantWorker;

  @Output() selectWorkerProfile = new EventEmitter<SelectWorkerProfileComponent['listUserProfileWorker'][number]>();

  public currentlySelectedWorker: SelectWorkerProfileComponent['listUserProfileWorker'][number];
  public profileIdControl: FormControl<number> | LegacyFormControl<number>;

  public readonly tooltipFields: Array<ToolTipField> = [
    { label: 'Email', value: 'PrimaryEmail' },
    { label: 'Organization', value: 'OrganizationName' },
    { label: 'Update Date', value: 'LastModifiedDatetime' }
  ];

  public listUserProfileWorker: Array<WorkerSelectOption> = [];

  public selectedProfileWorkerUrlLink: string;
  public hasLoadedOptions = false;
  public listProfileType = this.codeValueService.getCodeValues(CodeValueGroups.ProfileType, true);
  private subscription: Subscription;

  constructor(private listObservableService: CommonListsObservableService, private codeValueService: CodeValueService, private commonService: CommonService) {
    super();
  }

  ngOnInit(): void {
    this.getListUserProfileWorker();
  }

  private initControl(c: FormControl<number> | LegacyFormControl<number>): void {
    this.profileIdControl = c;
    this.subscription?.unsubscribe();
    this.subscription = this.profileIdControl.valueChanges.pipe(this.takeUntilDestroyed).subscribe(x => {
      const selectedProfileWorker = this.listUserProfileWorker.find(i => i.Id === x);
      this.selectWorkerProfile.emit(selectedProfileWorker);
      this.currentlySelectedWorker = selectedProfileWorker;
      if (selectedProfileWorker) {
        this.selectedProfileWorkerUrlLink = `/next/contact/${selectedProfileWorker.Contact.Id}/profile/`;
        this.selectedProfileWorkerUrlLink += `${this.commonService.getUserProfileTypeSufix(selectedProfileWorker.ProfileTypeId)}/${selectedProfileWorker.Id}`;
      }
    });
  }

  private getListUserProfileWorker(): void {
    this.listObservableService
      .listUserProfileWorkers$()
      .pipe(
        takeUntil(this.isDestroyed$),
        filter(r => Boolean(r))
      )
      .subscribe(response => {
        this.listUserProfileWorker = this.getFilteredListUserProfileWorker(response).reduce<Array<WorkerSelectOption>>((allWorkerSelectOptions, workerListItem) => {
          // If no filter provided, any item is considered relevant
          if (this.checkRelevantWorker?.(workerListItem) ?? true) {
            const profileName = this.listProfileType.find(i => i.id === workerListItem.ProfileTypeId);
            const displayItem = workerListItem.Id + '-' + workerListItem.Contact.FullName + '-' + profileName.description;

            return allWorkerSelectOptions.concat({
              ...workerListItem,
              OrganizationName: workerListItem.Organization ? workerListItem.Organization.DisplayName : null,
              LastModifiedDatetime: formatDate(new Date(), PhxConstants.DateFormat.mediumDate, PhxLocalizationService.locale),
              DisplayValue: displayItem
            });
          }

          return allWorkerSelectOptions;
        }, []);
        this.hasLoadedOptions = true;
      });
  }

  private getFilteredListUserProfileWorker(items: Array<UserProfileWorkerListItem>): Array<UserProfileWorkerListItem> {
    // 15634 - workers belonging to certain profileTypes should not appear if they are missing an org
    return items.filter(item =>
      item.ProfileTypeId === PhxConstants.UserProfileType.WorkerCanadianInc ||
      item.ProfileTypeId === PhxConstants.UserProfileType.WorkerSubVendor ||
      item.ProfileTypeId === PhxConstants.UserProfileType.WorkerUnitedStatesLLC
        ? item.OrganizationId != null
        : true
    );
  }
}
