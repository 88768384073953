import { Component, Input } from '@angular/core';

import { CodeValueGroups, PhxConstants } from '../../model';
import { rateTypeAbbreviations } from '../../constants/timesheet.const';
import { CodeValueService } from '../../services/code-value.service';
import { OvertimeTimeSheetDetailsPayload } from './models/overtime-time-sheet-details-payload.model';
import { TimesheetRateAndAmount } from './models/timesheet-rate-and-amount.model';

@Component({
  selector: 'app-time-sheet-overtime-breakdown',
  templateUrl: './time-sheet-overtime-breakdown.component.html',
  styleUrls: ['./time-sheet-overtime-breakdown.component.less']
})
export class TimeSheetOvertimeBreakdownComponent {
  @Input() set overtimeTimeSheetDetails(payload: OvertimeTimeSheetDetailsPayload) {
    payload.doUseShortForm ??= true;
    payload.doUseLowerCase ??= true;

    const otDetailsMap = new Map<number, number>();

    payload.rateAndAmounts?.forEach(otRateAndAmount => otDetailsMap.set(otRateAndAmount.RateTypeId, (otDetailsMap.get(otRateAndAmount.RateTypeId) ?? 0) + otRateAndAmount.UnitAmount));

    this.localOvertimeSheetDetails = Array.from(otDetailsMap.entries()).map(([rateTypeId, unitSum]) => {
      const displayText =
        (payload.doUseShortForm || rateTypeId === PhxConstants.RateType.Primary
          ? rateTypeAbbreviations[rateTypeId]?.()
          : this.codeValueService.getCodeValueText(rateTypeId, CodeValueGroups.RateType)) + ':';

      return {
        RateTypeId: rateTypeId,
        UnitAmount: unitSum,
        displayText: payload.doUseLowerCase ? displayText?.toLowerCase() : displayText
      };
    });
  }

  public readonly codeValueGroups = CodeValueGroups;
  public readonly hourUnit = PhxConstants.RateUnit.Hour;

  public localOvertimeSheetDetails: Array<TimesheetRateAndAmount & { displayText: string }> = [];

  constructor(private codeValueService: CodeValueService) {}
}
