<app-phx-tool-tip *ngIf="stateHistory" [targetId]="targetId" [position]="position">
  <div class="row state-history-tool-tip">
    <div class="col-xs-2 icon-column">
      <div class="milestone-icon current"></div>
    </div>
    <div class="col-xs-10 detail">
      <div class="current-status">
        {{'workflow.stateHistory.CurrentStep' | phxTranslate}}
        {{stateHistory.EntityStatusId | CodeValue: statusCodeValueGroups}}
      </div>
      <div *ngIf="stateHistory.Description" class="description">
        {{stateHistory.Description}}
      </div>
      <div>
        <div class="col-xs-8 next-status">
          <ng-container *ngIf="nextStatusId">
            {{'workflow.stateHistory.NextStep' | phxTranslate}}
            {{nextStatusId | CodeValue: statusCodeValueGroups}}
          </ng-container>
        </div>
        <div class="col-xs-4 view-history">
          <a (click)="onClickViewHistory($event)">{{'workflow.stateHistory.ViewHistory' | phxTranslate}}</a>
        </div>
      </div>
    </div>
  </div>
</app-phx-tool-tip>