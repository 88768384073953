<dx-data-grid
  #grid
  class="gridContainer"
  [columns]="columns"
  [dataSource]="dataSource"
  [columnChooser]="columnChooserConfig"
  [allowColumnResizing]="true"
  [columnResizingMode]="configuration.columnHidingEnabled ? 'column' : 'widget'"
  [allowColumnReordering]="true"
  [columnHidingEnabled]="configuration.columnHidingEnabled"
  [columnAutoWidth]="true"
  [showBorders]="configuration.showBorders"
  [showColumnLines]="true"
  [rowAlternationEnabled]="configuration.rowAlternationEnabled"
  [hoverStateEnabled]="true"
  [selectedRowKeys]="[]"
  [noDataText]="configuration.noDataText"
  [loadPanel]="loadPanelConfig"
  (onRowClick)="onRowClick($event)"
  (onToolbarPreparing)="onToolbarPreparing($event)"
  (onContextMenuPreparing)="onContextMenuPreparing($event)"
  (onEditorPreparing)="onEditorPreparing($event)"
  (onContentReady)="onContentReady()"
  [syncLookupFilterValues]="false">

  <dxo-selection [mode]="configuration.selectionMode" [selectAllMode]="configuration.selectAllMode"
                 [allowSelectAll]="configuration.allowSelectAll"
                 [showCheckBoxesMode]="configuration.showCheckBoxesMode" [deferred]="configuration.deferred">
  </dxo-selection>

  <dxo-filter-row [visible]="configuration.showFilter"></dxo-filter-row>
  <dxo-header-filter [visible]="false"></dxo-header-filter>
  <dxo-search-panel [visible]="configuration.showSearch" [width]="200"
                    placeholder="{{ 'common.generic.search' | phxTranslate }}..."></dxo-search-panel>
  <dxo-group-panel [visible]="configuration.showGrouping"
                   [emptyPanelText]="'common.phxDataTable.groupByDescription' | phxTranslate"></dxo-group-panel>
  <dxo-grouping [autoExpandAll]="true" [expandMode]="'buttonClick'" [contextMenuEnabled]="true"
                [allowCollapsing]="false"></dxo-grouping>
  <dxo-sorting mode="multiple"></dxo-sorting>
  <dxo-scrolling [mode]="configuration.scrollMode" showScrollbar="always" [useNative]="true"></dxo-scrolling>
  <dxo-paging [pageSize]="configuration.pageSize"></dxo-paging>
  <dxo-remote-operations [sorting]="true" [paging]="true" [filtering]="true" [summary]="true"></dxo-remote-operations>

  <div *dxTemplate="let d of 'defaultDateCellTemplate'">
    <span>{{ d.value | date: dateColumnFormat }}</span>
  </div>

  <div *dxTemplate="let data of 'centerToolbarContent'">
    <div class="center-toolbar-content">
      <div class="text-content">
        <ng-container *ngIf="gridTotalCount"> {{ pageIndex * pageSize + 1 }}
          - {{ gridTotalCount < (pageIndex + 1) * pageSize ? gridTotalCount : (pageIndex + 1) * pageSize }}
          <span i18n="@@common.of">of</span> {{ gridTotalCount }}
        </ng-container>
      </div>
      <div class="navigation-buttons">
        <span class="material-icons navigation-button-previous"
              [ngClass]="{'navigation-button-disabled': pageIndex === 0}" *ngIf="gridTotalCount > pageSize"
              (click)="goPreviousPage()">
          navigate_before
        </span>
        <span class="material-icons navigation-button-next"
              [ngClass]="{'navigation-button-disabled': pageIndex === lastPageIndex}"
              *ngIf="gridTotalCount > pageSize" (click)="goNextPage()">
          navigate_next
        </span>
      </div>
    </div>
  </div>

  <div class="custom-toolbar-content" *dxTemplate="let data of 'toolbarContent'">
    <ng-container>
      <button id="resetButton" class="btn btn-default template-reset-button" (click)="resetGrid()"
              [title]="commonModuleResourceKeys.generic.reset | phxTranslate">
        <i class="material-icons custom-tool-button" aria-hidden="true">replay</i>
        <span>{{commonModuleResourceKeys.generic.reset | phxTranslate}}</span>
      </button>
    </ng-container>
  </div>

  <div class="bottom-spacer"></div>
</dx-data-grid>
