import { Pipe, PipeTransform } from '@angular/core';

import { IWFHEntityVersion, IWFHLog, IWFHLogDifference, IWFHStatus } from '../model';

@Pipe({
  name: 'phxWorkflowHistorySearch'
})
export class PhxWorkflowHistorySearchPipe implements PipeTransform {
  transform(history: IWFHEntityVersion[], searchFilterMetaData: any): IWFHEntityVersion[] {
    searchFilterMetaData.count = 0;
    const { statusCode, userId, fieldName, action } = searchFilterMetaData;

    if (statusCode || userId || fieldName || action) {
      const cloneHistory = JSON.parse(JSON.stringify(history));
      let searchedHistory: IWFHEntityVersion[];

      searchedHistory = cloneHistory.reduce((acc: Array<IWFHEntityVersion>, version: IWFHEntityVersion) => {
        if (action) {
          version.statusList = version.statusList.filter(({ entityAction }) => action === entityAction);
        }

        if (statusCode) {
          version.statusList = version.statusList.filter(s => s.statusCode === statusCode);
        }

        if (userId) {
          version.statusList = version.statusList
            .map((status: IWFHStatus) => {
              status.logsList = status.logsList.filter(log => log.userId === +userId);
              return status;
            })
            .filter(x => !!x.logsList.length || x.logsUsers.some(user => user.id === +userId));
        }

        if (fieldName) {
          version.statusList = version.statusList
            .map((status: IWFHStatus) => {
              status.logsList = status.logsList
                .map((log: IWFHLog) => {
                  log.propertyGroups = log.propertyGroups
                    .map(group => {
                      group.differences = group.differences.filter(diff => this.checkDifference(diff, fieldName));
                      return group;
                    })
                    .filter(x => !!x.differences.length);
                  return log;
                })
                .filter(x => !!x.propertyGroups.length);
              status.isOpen = true;
              return status;
            })
            .filter(x => !!x.logsList.length);
        }

        if (version.statusList.length) {
          acc.push(version);
          version.isOpen = true;
        }

        return acc;
      }, []);

      if (searchedHistory.length) {
        searchFilterMetaData.count = searchedHistory.reduce((acc, current) => {
          current.statusList.forEach(status => status.logsList.forEach(log => log.propertyGroups.forEach(group => (acc += group.differences.length))));
          return acc;
        }, searchFilterMetaData.count);
      }
      return searchedHistory;
    }

    return history;
  }

  private checkDifference({ displayName, fieldName }: IWFHLogDifference, query: string) {
    if (displayName) {
      const regex = new RegExp(query.toLowerCase(), 'g');
      return regex.test(displayName.toLowerCase());
    }

    query = query.replace(/\s+/g, '').toLowerCase();

    return fieldName.toLowerCase().startsWith(query);
  }
}
