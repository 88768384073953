export interface IDownloadCenterFile {
    Id: string;
    Filename: string;
    Status: DownloadCenterFileStatus;
    ExportRequestedDate: Date;
    FileSize?: number;
    RequestedCount?: number;
    ExportedCount?: number;
    FileServiceFileId?: number;
}

export type IDownloadCenterFiles = IDownloadCenterFile[];

export interface IDownloadCenterDateOrganizedFiles {
    Date: Date;
    DownloadCenterFiles: IDownloadCenterFiles;
}

export type IDownloadCenterDateOrganizedFilesList = IDownloadCenterDateOrganizedFiles[];

export enum DownloadCenterFileStatus {
    Preparing = 1,
    Ready = 2,
    Error = 3,
    Archived = 4
}

export interface IReportPermissions {
    IsMyDataViewable: boolean;
    IsMyDataExportable: boolean;
    IsTeamDataViewable: boolean;
    IsTeamDataExportable: boolean;
    IsAllDataViewable: boolean;
    IsAllDataExportable: boolean;
    IsHierarchicalDataExportable: boolean;
    IsHierarchicalDataViewable: boolean;
}
