import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NavigationService } from './navigation.service';
import { PhxConstants } from '../PhoenixCommon.module';

export interface LayoutBannerConfig {
  message: string;
  title: string;
  canClose: boolean;
  type: PhxConstants.BannerType;
  /** NOTE: isCustom = true shows the rainy cloud message box */
  isCustom?: boolean;
  /** NOTE: property for dev use - not shown in production */
  error?: string;
}

@Injectable({
  providedIn: 'root'
})
export class PhxLayoutBannerService {

  private bannerConfigSubject = new BehaviorSubject<LayoutBannerConfig | null>(null);
  bannerConfig$ = this.bannerConfigSubject.asObservable();

  constructor(
    private navigationService: NavigationService
  ) { }

  openBanner(config: LayoutBannerConfig) {
    this.navigationService.updateBreadCrumbState(false);
    this.bannerConfigSubject.next(config);
  }

  closeBanner() {
    this.bannerConfigSubject.next(null);
  }
}
