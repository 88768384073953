<nav
  aria-label="Actions"
  class="top-nav"
>
  <ul class="top-menu">
    <li
      (mouseenter)="isViewingBookmarks = true"
      (mouseleave)="isViewingBookmarks = false"
      class="navigation-item"
    >
      <i class="material-icons md-24 shortcut-add-bookmark">star_border</i>

      <figure
        *ngIf="isViewingBookmarks"
        class="nested-menu"
      >
        <figcaption i18n="@@common.bookmarks">Bookmarks</figcaption>
        <app-bookmark-side-menu eventName="AddToFavourites"></app-bookmark-side-menu>
      </figure>
    </li>

    <!-- 
        This item is unique since it relies on auxiliary class to maintain the hover effect
        due to the way drag and dropping works in devextreme.
     -->
    <li
      class="navigation-item"
      [class.busy]="isBusyWithTodos"
      (mouseenter)="isBusyWithTodos = true"
      (mouseleave)="handleMouseLeave()"
    >
      <i class="material-icons md-24">done_outline</i>
      <figure class="nested-menu">
        <figcaption i18n="@@common.todoList">To-Do List</figcaption>
        <app-todo-list (draggingInProgress)="isDraggingTodos = $event"></app-todo-list>
      </figure>
    </li>
  </ul>
</nav>

<nav aria-label="Navigation">
  <ul class="top-menu">
    <li
      *ngFor="let item of navigationItems"
      class="navigation-item"
      routerLinkActive="is-active-link"
    >
      <a
        (click)="clickNavItem(item)"
        [routerLink]="item.State"
      >
        <i class="material-icons accordion-heading">{{ item.Icon }}</i>
      </a>

      <figure
        *ngIf="item.HasChildren && isViewingNavigation"
        class="nested-menu"
      >
        <figcaption>{{ item.Description }}</figcaption>
        <ul>
          <li
            *ngFor="let child of item.Children"
            class="navigation-item"
            routerLinkActive="is-active-link"
          >
            <a
              (click)="clickNavItem(child)"
              [routerLink]="child.State"
            >
              {{ child.Description }}

              <mat-icon *ngIf="child.HasChildren"> keyboard_arrow_right </mat-icon>
            </a>

            <ng-container *ngIf="child.HasChildren">
              <ul class="nested-menu">
                <li
                  *ngFor="let grandChild of child.Children"
                  class="navigation-item"
                  routerLinkActive="is-active-link"
                >
                  <a
                    (click)="clickNavItem(grandChild)"
                    [routerLink]="grandChild.State"
                  >
                    {{ grandChild.Description }}
                  </a>
                </li>
              </ul>
            </ng-container>
          </li>
        </ul>
      </figure>
    </li>
  </ul>
</nav>

<footer>
  <div class="build-number">{{ buildNumber }}</div>
  <button
    #toggleNavigation
    class="sidebar-toggle"
    (click)="sidebarToggle.emit()"
    [appCollectClickDataAnalytics]="{
      action: 'Expand navigation',
      feature: 'Side Navigation',
      type: 'Toggle Navigation'
    }"
  >
    <mat-icon> keyboard_double_arrow_right </mat-icon>
  </button>
</footer>
