import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { ApiService } from '../../services/api.service';
import { GlobalConfigurationDto } from './models/global-configuration-dto';

@Injectable({
  providedIn: 'root'
})
export class AdminDataService {
  private readonly apiEndPoint = environment.adminServiceApiEndpoint;

  constructor(private apiService: ApiService) {}

  public getGlobalConfiguration<TData>(documentType: string, groupName: string) {
    const url = `GlobalConfiguration/${documentType}/${groupName}`;
    
    return this.apiService.httpGetRequest<GlobalConfigurationDto<TData>[]>(url, this.apiEndPoint);
  }
}
