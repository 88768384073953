<ng-container *ngIf="hasStateActions()" [ngSwitch]="displayType">

    <ng-container *ngSwitchCase="stateActionDisplayType.DROPDOWN">
        <div class="btn-toolbar link-bar">
            <div class="btn-group">
                <div class="btn-wrapper">
                    <button class="btn btn-clouds dropdown-toggle" type="button" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="true">
                        {{'common.generic.actions' | phxTranslate}}...
                        <span class="caret"></span>
                    </button>
                    <ul class="dropdown-menu bordered">
                        <ng-container *ngFor="let action of stateActions">
                            <li *ngIf="!isActionHidden(action)" (click)="onClickAction(action)" class="action-link"
                                [class.disabled]="executing || isActionDisabled(action) || (action.disabled$ && (action.disabled$ | async) === true)"
                                [attr.data-action-name]="action.actionName">
                                <a> {{action.displayText}} </a>
                            </li>
                        </ng-container>
                    </ul>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="stateActionDisplayType.BUTTON">
        <ng-container *ngTemplateOutlet="lg_btn_tpl"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="stateActionDisplayType.LARGEBUTTON">
        <ng-container *ngTemplateOutlet="lg_btn_tpl"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="stateActionDisplayType.SMALLBUTTON">
        <div class="btn-group-xs">
            <ng-container *ngFor="let action of stateActions">
                <button *ngIf="!isActionHidden(action)" type="button" class="btn btn-xs" (click)="onClickAction(action)"
                    [disabled]="executing || isActionDisabled(action) || (action.disabled$ && (action.disabled$ | async) === true)"
                    [class.btn-primary]="action.primaryAction" [class.btn-danger]="action.dangerAction"
                    [class.btn-secondary]="action.secondaryAction" [class.btn-warning]="action.warningAction"
                    [attr.data-action-name]="action.actionName">
                    {{action.displayText}}
                </button>
            </ng-container>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="stateActionDisplayType.BUTTONWITHELIPSIS">
        <div class="btn-toolbar button-bar">
            <div class="btn-group">
                <ng-container *ngFor="let action of stateActions">
                    <button *ngIf="action.style && !isActionHidden(action)" type="button"
                        class="btn btn-default action-button" (click)="onClickAction(action)"
                        [disabled]="executing || isActionDisabled(action) || (action.disabled$ && (action.disabled$ | async) === true)"
                        [class.btn-primary]="action.primaryAction" [class.btn-danger]="action.dangerAction"
                        [class.btn-secondary]="action.secondaryAction" [class.btn-warning]="action.warningAction"
                        [attr.data-action-name]="action.actionName">
                        {{action.displayText}}
                    </button>
                </ng-container>
                <div class="btn-group elipsis">
                    <button class="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="true">
                        <i class="material-icons md-24">more_horiz</i>
                    </button>
                    <ul class="dropdown-menu bordered">
                        <ng-container *ngFor="let action of stateActions">
                            <li *ngIf="!action.style && !isActionHidden(action)" (click)="onClickAction(action)"
                                class="action-link"
                                [class.disabled]="executing || isActionDisabled(action) || (action.disabled$ && (action.disabled$ | async) === true)"
                                [attr.data-action-name]="action.actionName">
                                <a> {{action.displayText}} </a>
                            </li>
                        </ng-container>
                    </ul>
                </div>
            </div>
        </div>
    </ng-container>

</ng-container>

<ng-template #lg_btn_tpl>
    <div class="btn-toolbar button-bar">
        <div class="btn-group">
            <ng-container *ngFor="let action of stateActions">
                <button *ngIf="!isActionHidden(action)" type="button" class="btn btn-default action-button"
                    (click)="onClickAction(action)"
                    [disabled]="executing || isActionDisabled(action) || (action.disabled$ && (action.disabled$ | async) === true)"
                    [class.btn-primary]="action.primaryAction" [class.btn-danger]="action.dangerAction"
                    [class.btn-secondary]="action.secondaryAction" [class.btn-warning]="action.warningAction"
                    [class.btn-lg]="displayType === stateActionDisplayType.LARGEBUTTON"
                    [attr.data-action-name]="action.actionName">
                    {{action.displayText}}
                </button>
            </ng-container>
        </div>
    </div>
</ng-template>