import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { BaseComponentOnDestroy } from '../../epics/base-component-on-destroy';
import { EntityVersion, PhxConstants } from '../../model';
import { PhxSideBarService } from '../../services/phx-side-bar.service';
import { first, takeUntil } from 'rxjs/operators';
import { VersionComparisonToggleService } from '../../services/version-comparison-toggle.service';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-phx-panel-version',
  templateUrl: './phx-panel-version.component.html',
  styleUrls: ['./phx-panel-version.component.less'],
  changeDetection: ChangeDetectionStrategy.Default
})

export class PhxPanelVersionComponent extends BaseComponentOnDestroy implements OnInit {

  entityType: string;
  statusGroup: string;

  multiple: boolean;
  currentGroup: string;
  currentVersion: number;
  entityVersions: EntityVersion[];

  comparisonSourceVersionNumber$ = this.versionComparisonToggleService.versionComparisonSourceNumber$;

  phxConstants = PhxConstants;

  groupedVersions: Array<{
    entityId: number,
    isActive?: boolean,
    versions: EntityVersion[];
  }> = [];

  sortedVersions: EntityVersion[];

  activeGroupIndex?: number;
  entityTypes = PhxConstants.SideBarEntityType;

  activeStatusGroup = 'active';
  inactiveStatusGroup = 'inactive';

  private activeStatusHighlightConfig: { [entityType: string]: any; } = {
    [this.entityTypes.WorkOrder]: PhxConstants.WorkOrderVersionStatus.Approved,
    [this.entityTypes.PermanentPlacement]: PhxConstants.PermanentPlacementVersionStatus.Approved,
    [this.entityTypes.Organization]: PhxConstants.CodeOrganizationStatus.Active,
    [this.entityTypes.UserProfile]: PhxConstants.CodeProfileStatus.Active,
    [this.entityTypes.ProvincialPayrollTax]: PhxConstants.TaxVersionStatus.Active,
    [this.entityTypes.FederalPayrollTax]: PhxConstants.TaxVersionStatus.Active,
    [this.entityTypes.SalesTax]: PhxConstants.TaxVersionStatus.Active,
    [this.entityTypes.WcbRates]: PhxConstants.WcbSubdivisionVersionStatus.Active,
    [this.entityTypes.CommissionRate]: PhxConstants.CommissionRateVersionStatus.Approved,
    [this.entityTypes.VmsFee]: PhxConstants.VmsFeeVersionStatus.Active,
    [this.entityTypes.Rebate]: PhxConstants.RebateVersionStatus.Active
  };

  private inactiveStatusHighlightConfig: { [entityType: string]: any[]; } = {
    [this.entityTypes.WorkOrder]: [PhxConstants.WorkOrderVersionStatus.Deleted, PhxConstants.WorkOrderVersionStatus.Cancelled, PhxConstants.WorkOrderVersionStatus.Replaced],
    [this.entityTypes.PermanentPlacement]: [PhxConstants.PermanentPlacementVersionStatus.Replaced,
    PhxConstants.PermanentPlacementVersionStatus.Deleted, PhxConstants.PermanentPlacementVersionStatus.Terminated],
    [this.entityTypes.Organization]: [PhxConstants.CodeOrganizationStatus.Replaced, PhxConstants.CodeOrganizationStatus.Deleted],
    [this.entityTypes.UserProfile]: [PhxConstants.CodeProfileStatus.Replaced, PhxConstants.CodeProfileStatus.Inactive, PhxConstants.CodeProfileStatus.Deleted],
    [this.entityTypes.ProvincialPayrollTax]: [PhxConstants.TaxVersionStatus.Replaced],
    [this.entityTypes.FederalPayrollTax]: [PhxConstants.TaxVersionStatus.Replaced],
    [this.entityTypes.SalesTax]: [PhxConstants.TaxVersionStatus.Replaced],
    [this.entityTypes.WcbRates]: [PhxConstants.WcbSubdivisionVersionStatus.Replaced, PhxConstants.WcbSubdivisionVersionStatus.Cancelled, PhxConstants.WcbSubdivisionVersionStatus.Terminated,
    PhxConstants.WcbSubdivisionVersionStatus.Discarded],
    [this.entityTypes.CommissionRate]: [PhxConstants.CommissionRateVersionStatus.Replaced, PhxConstants.CommissionRateVersionStatus.Deleted],
    [this.entityTypes.VmsFee]: [PhxConstants.VmsFeeVersionStatus.Replaced],
    [this.entityTypes.Rebate]: [PhxConstants.RebateVersionStatus.Replaced]
  };

  constructor(
    private cdr: ChangeDetectorRef,
    private phxSidebarService: PhxSideBarService,
    private versionComparisonToggleService: VersionComparisonToggleService,
  ) {
    super();
  }

  private versionChanges(entityVersions: any, currentVersion: any) {
    if (entityVersions || currentVersion) {
      this.phxSidebarService.getVersionsAreMultiple$().pipe(first()).subscribe((isMultiple) => {
        this.multiple = isMultiple;
        if (isMultiple) {
          const versions = Array.isArray(this.entityVersions) ? this.entityVersions.reduce(
            (result, item: EntityVersion) => ({
              ...result,
              [item.Id]: [
                ...(result[item.Id] || []),
                item
              ]
            }),
            {}
          ) : {};

          this.groupedVersions = Object.keys(versions).map(key => ({
            entityId: parseFloat(key),
            groupNumber: versions[key]?.[0]?.GroupNumber,
            isActive: (versions[key] as EntityVersion[]).some(item => item.GroupNumber === this.currentGroup),
            versions: [...versions[key] as EntityVersion[]].sort((a, b) => b.VersionNumber - a.VersionNumber)
          })).sort((a, b) => b.entityId - a.entityId);

          this.activeGroupIndex = this.groupedVersions.findIndex(v => v.isActive);

        } else {
          this.sortedVersions = Array.isArray(this.entityVersions) ? [...this.entityVersions].sort((a, b) => b.VersionNumber - a.VersionNumber) : [];
        }
      });
    }
  }

  ngOnInit() {
    this.subscribeVersionVariables();
  }

  private getStatusGroup(version: EntityVersion<any>, entityType: PhxConstants.SideBarEntityType): string {
    if (this.activeStatusHighlightConfig[entityType] &&
      (this.activeStatusHighlightConfig[entityType] === (version.StatusCode ?? version.StatusId))
    ) {
      return this.activeStatusGroup;
    } else if (this.inactiveStatusHighlightConfig[entityType]?.includes((version.StatusCode ?? version.StatusId)) ||
      this.inactiveStatusHighlightConfig[`${this.activeStatusHighlightConfig[entityType]}${version.StatusId}`]
    ) {
      return this.inactiveStatusGroup;
    }

    return '';
  }

  private subscribeVersionVariables() {
    this.phxSidebarService.entityTypeChange$().pipe(takeUntil(this.isDestroyed$)).subscribe(
      entity => this.entityType = entity?.valueOf()?.replace(PhxConstants.SideBarEntityType.WorkOrder, 'Work Order')
    );

    combineLatest([
      this.phxSidebarService.getEntityVersions$(),
      this.phxSidebarService.entityTypeChange$()
    ])
      .subscribe(([entityVersions, entityType]) => {
        this.entityVersions = entityVersions?.map(version => ({ ...version, StatusGroup: this.getStatusGroup(version, entityType) }));
        this.versionChanges(this.entityVersions, this.currentVersion);
        this.cdr.detectChanges();
      });

    this.phxSidebarService.getCurrentVersion$().pipe(takeUntil(this.isDestroyed$)).subscribe(currentVersion => {
      this.currentVersion = currentVersion;
      this.versionChanges(this.entityVersions, this.currentVersion);
      this.cdr.detectChanges();
    });
    this.phxSidebarService.getCurrentGroup$().pipe(takeUntil(this.isDestroyed$)).subscribe(currentGroup => {
      this.currentGroup = currentGroup;
      this.versionChanges(this.entityVersions, this.currentVersion);
      this.cdr.detectChanges();
    });
    this.phxSidebarService.getStatusGroup$().pipe(takeUntil(this.isDestroyed$)).subscribe(statusGroup => this.statusGroup = statusGroup);
  }

  selectVersion(version: EntityVersion, isActive: boolean, activeGroupIndex?: number) {
    window.scroll(0, 0);
    if (!isActive) {
      this.activeGroupIndex = activeGroupIndex;
      this.currentGroup = null;
      this.currentVersion = null;
      this.phxSidebarService.selectedVersion.emit(version);
    }
  }
}
