/* eslint-disable @typescript-eslint/naming-convention */
import { from as observableFrom, Observable, of, Subject, Subscription, BehaviorSubject, lastValueFrom } from 'rxjs';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BasicUserProfile, EntityList, PhxConstants, User, UserContext, UserInfo, UserProfile } from '../model';

import { LoadingSpinnerService } from '..';
import { CookieService } from './cookie.service';
import { ApiService } from './api.service';
import { environment } from '../../../environments/environment';
import { SignalrService } from './signalr.service';
import { EventService } from './event.service';
import { PhxLocalizationService } from './phx-localization.service';
import { filter } from 'lodash';
import * as uuidModule from 'uuid';
import { map, switchMap } from 'rxjs/operators';
import { TokenAuth } from '../model/auth';
import { Router } from '@angular/router';
import * as Rollbar from 'rollbar';
import { RollbarService } from 'src/app/global-error-handler';
import { AppStorageService } from './app-storage.service';
import { StorageService } from 'ngx-webstorage-service';
import { Userpilot } from 'userpilot';
import { loadTranslations } from '@angular/localize';
import { ThemeService } from './theme/theme.service';

declare let $;
declare let window;

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {
  currentProfile: UserProfile;
  rawApiEndPoint: string;
  generalServiceApiEndpoint: string;
  currentUser: UserInfo;

  isImpersonatingSubject = new BehaviorSubject<boolean>(false);
  isImpersonating$ = this.isImpersonatingSubject.asObservable();


  private readonly apiEndPoint;
  private userContext: UserContext;
  private broadcastEventLogoutSubscription: Subscription;
  private currentProfileSubject: Subject<any> = new Subject();
  private uniqueAppId = '';
  private initialPage?: string;

  constructor(
    @Inject(RollbarService) private rollbar: Rollbar,
    private loadingSpinnerService: LoadingSpinnerService,
    private http: HttpClient,
    private cookieService: CookieService,
    private apiService: ApiService,
    private signalrService: SignalrService,
    private eventService: EventService,
    private localizationService: PhxLocalizationService,
    private router: Router,
    @Inject(AppStorageService) private storageService: StorageService,
    private themeService: ThemeService
  ) {
    this.apiEndPoint = environment.apiUrl + 'api';
    this.rawApiEndPoint = environment.apiUrl.replace(/\/$/, '');
    this.generalServiceApiEndpoint = environment.generalServiceApiEndpoint;
    $.ajaxSetup({
      beforeSend: (xhr) => {
        this.jqBeforeSendEvent(xhr);
      }
    });
    this.checkAppId();
  }

  get appId() {
    return this.uniqueAppId;
  }

  get currentProfileChange$(): Observable<any> {
    return this.currentProfileSubject.asObservable();
  }

  ngOnDestroy(): void {
    this.broadcastEventLogoutSubscription.unsubscribe();
  }

  jqBeforeSendEvent(xhr) {
    const token = this.storageService.get('BearerToken');
    if (token) {
      xhr.setRequestHeader('Authorization', 'Bearer ' + token);
    }
  }

  subscribeProfileChanges(next?: () => void): Subscription {
    return this.currentProfileSubject.subscribe(next);
  }

  getCurrentUser(): Observable<UserInfo> {
    return observableFrom(this.fetchCurrentUser());
  }

  getCurrentProfile(): Observable<UserProfile> {
    return observableFrom(this.fetchCurrentProfile());
  }

  getUserContextSync(): UserContext | null {
    return this.userContext;
  }

  getUserContext(): Promise<UserContext> {
    return new Promise((resolve, reject) => {
      if (this.userContext?.User) {
        return resolve(this.userContext);
      }
      this.loadContext()
        .then((response: UserContext) => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  changePassword(oldPassword: string, newPassword: string, confirmPassword: string): Promise<any> {
    const data = { oldPassword, newPassword, confirmPassword };
    return lastValueFrom(this.http.post(this.apiEndPoint + '/account/ChangePassword', data));
  }

  forgotPassword(email): Observable<any> {
    return this.http.post(this.apiEndPoint + '/account/ForgotPassword', { Email: email });
  }

  resetPassword(model) {
    return lastValueFrom(this.http.post(this.apiEndPoint + '/account/ResetPassword', model));
  }

  setCurrentProfile(databaseId: number, profileId: number): Promise<UserContext> {
    this.loadingSpinnerService.show();
    return new Promise((resolve, reject) =>
      this.setBearerTokenByProfileId(databaseId, profileId)
        .subscribe({
          next: () => {
            this.loadContext(false).then((userContext: UserContext) => {
              this.currentProfileSubject.next({ DatabaseId: databaseId, ProfileId: profileId });
              this.loadingSpinnerService.hide();
              resolve(userContext);
            });
          },
          error: error => {
            this.loadingSpinnerService.hideAll();
            reject(error);
          }
        })
    );
  }

  hasFunctionalOperation(functionalOperation: number): boolean {
    return this.currentProfile?.FunctionalOperations?.includes(functionalOperation);
  }

  loadContext(loadUserTheme: boolean = true): Promise<UserContext> {
    return new Promise((resolve, reject) => {
      if (!this.isLoggedIn()) {
        reject();
        return;
      }
      this.apiService.query<UserContext>('security/context').subscribe({
        next: (rsp) => {
          if (rsp) {
            this.userContext = rsp;
            if (loadUserTheme) {
              this.themeService.setTenantTheme(this.userContext.User.CurrentTenantName);
            }

            this.isImpersonatingSubject.next(rsp.IsImpersonating);

            // FIXME: pickProfile can return {}, which should not assignable to UserProfile
            this.currentProfile = this.pickProfile(this.userContext);
            this.signalrService.setAccessToken(this.storageService.get('BearerToken'));
            this.signalrService.connect().catch(() => {
            });

            this.storageService.set('BearerIdentity', JSON.stringify({
              userName: this.currentProfile.PrimaryEmail,
              profileId: this.currentProfile.Id,
              databaseId: this.currentProfile.DatabaseId,
              tenantName: this.currentProfile.TenantName,
              email: this.currentProfile.PrimaryEmail
            }));
            resolve(this.userContext);
          } else {
            reject();
          }
        },
        error: err => {
          reject(err);
        }
      });
    });
  }

  redirectAfterLogin() {
    if (this.currentProfile?.Id) {
      this.IdentifyUserPilotUser();
      if (this.initialPage) {
        void this.router.navigate([this.initialPage]);
        this.initialPage = undefined;
      } else if (this.currentProfile.ProfileTypeId === PhxConstants.UserProfileType.Internal) {
        this.router.navigate(['/next/account/home']);
      } else {
        this.router.navigate(['/next/activity-centre']);
      }
    } else {
      throw new Error('No profiles found for current user');
    }
  }


  fetchCurrentUser(): Promise<UserInfo> {
    return new Promise((resolve, reject) => {
      if (!this.isLoggedIn()) {
        return reject();
      }

      if (this.currentUser) {
        return resolve(this.currentUser);
      }

      this.http.get(this.apiEndPoint + '/account/UserInfo').subscribe({
        next: (response: UserInfo) => {
          this.currentUser = response;
          if (!this.currentUser) {
            reject('User not valid');
          } else {
            resolve(this.currentUser);
          }
        },
        error: err => {
          this.currentUser = null;
          reject(err);
        }
      });
    });
  }

  login(username: string, password: string, adToken?: string) {
    const body = new URLSearchParams();
    body.set('grant_type', 'password');
    body.set('username', username);
    body.set('password', password);
    const url = this.rawApiEndPoint + '/token';
    const httpHeaderOptions: { [name: string]: string | string[]; } = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'App-Id': this.appId,
      'Browser-Name': this.apiService.browserName,
      'OS-Name': this.apiService.operatingSystem
    };
    if (adToken) {
      httpHeaderOptions['Ad-Auth-Token'] = adToken;
    }

    const options = {
      headers: new HttpHeaders(httpHeaderOptions)
    };

    return this.http.post<TokenAuth>(url, body.toString(), options)
      .pipe(
        switchMap((response) =>
          this.apiService.handleTokenAuthResponse(response.access_token, response.refresh_token, response['.expires']).pipe(map(() => response)))
      );
  }

  validateUserEmail(email: string, allowMigratedUser?: boolean) {
    return this.http.get(`${this.apiEndPoint}/Account/ValidateUsername?username=${encodeURIComponent(email)}&allowMigratedUser=${allowMigratedUser}`);
  }

  accessCode(accessCode: string, token: string) {
    this.loadingSpinnerService.show();
    return new Promise<{ entityTypeId: number; entityId: number; }>((resolve, reject) =>
      this.setBearerTokenByAccessCode(accessCode, token)
        .subscribe({
          next: (response: BasicUserProfile) => {
            this.loadingSpinnerService.hide();
            resolve({ entityTypeId: response.entityTypeId, entityId: response.entityId });
          },
          error: error => {
            this.loadingSpinnerService.hideAll();
            reject(error);
          }
        }));
  }

  isLoggedIn(): boolean {
    return !!this.storageService.get('BearerToken');
  }

  clearCookiesAndCachedItems() {
    this.storageService.remove('BearerIdentity');
    this.storageService.remove('BearerToken');
    this.storageService.remove('RefreshToken');
    this.storageService.remove('BearerTokenExpiresOn');
    this.cookieService.removeItem(this.cookieService.CookieStore);
    this.currentUser = null;
    this.currentProfile = null;
  }

  logout(sendLogoutRequest = true) {
    this.signalrService.disconnect();
    if (sendLogoutRequest) {
      const refreshToken = this.storageService.get('RefreshToken');

      this.http.post(`${this.apiEndPoint}/Account/Logout`, {}, {
        headers: new HttpHeaders({
          'Refresh-Token': refreshToken
        })
      }).subscribe();
    }
    this.clearCookiesAndCachedItems();
    this.eventService.trigger('broadcastEvent:logout');
  }

  validateRegistrationToken(et: any) {
    const data = '=' + et;
    const url = this.apiEndPoint + '/account/ValidateExternalToken';
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };

    return this.http.post(url, data, options);
  }

  validateAccessCodeToken(et: any) {
    const data = '=' + et;
    const url = this.apiEndPoint + '/account/ValidateExternalAccessCodeToken';

    const headers: { [name: string]: string; } = {
      'Content-Type': 'application/x-www-form-urlencoded'
    };

    const options = {
      headers: new HttpHeaders(headers)
    };

    return this.http.post<{ IsExistingUser: boolean; token: string; }>(url, data, options);
  }

  register(username: string, password: string, confirmPassword: string, cultureId: number, et: string) {
    const data = {
      UserName: username,
      Password: password,
      ConfirmPassword: confirmPassword,
      CultureId: cultureId,
      RegistrationToken: et
    };
    const url = this.apiEndPoint + '/Account/Register';
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        PhoenixCultureId: cultureId.toString()
      })
    };

    return this.http.post(url, data, options);
  }

  initApp(locale: string) {
    this.localizationService.setLocale(locale);

    return new Promise((resolve, reject) => {
      Promise.all([
        lastValueFrom(this.apiService.query('pagetitle')).then((rsp: EntityList<any>) => {
          window.PhoenixPageTitles = rsp.Items;
          return rsp;
        }),
        this.loadTenantSupportData(),
        this.loadTranslationData(locale)
      ])
        .then(responseArr => {
          resolve(responseArr);
        })
        .catch(err => {
          this.rollbar.error(err);
          if (err?.status === PhxConstants.HTTPResponseStatus.Unauthorized) {
            this.logout(false);
          }
          reject(err);
        });
    });
  }

  loadTenantSupportData() {
    return Promise.all([
      this.loadAppCodeData(),
      this.loadEntityStatusData()
    ]);
  }

  loadTranslationDataByBrowserCulture() {
    if ((window as any).PhxTranslations) {
      return of((window as any).PhxTranslations);
    }
    const culture = window.navigator.languages ? window.navigator.languages[0] : window.navigator.language || window.navigator.userLanguage;
    return this.apiService.query('localization/full/' + culture);
  }

  fetchCurrentProfile(): Promise<UserProfile> {
    return new Promise((resolve, reject) => {
      if (!this.currentProfile?.Id || this.currentProfile.Id <= 0) {
        this.loadContext().then(
          () => {
            resolve(this.currentProfile);
          },
          (err) => {
            reject(err);
          }
        );
      } else {
        resolve(this.currentProfile);
      }
    });
  }

  isCurrentProfileUnderAccountingRole() {
    return (
      filter(this.currentProfile.FunctionalRoles, item => (
        item.FunctionalRoleId === PhxConstants.FunctionalRole.Finance ||
        item.FunctionalRoleId === PhxConstants.FunctionalRole.Controller ||
        item.FunctionalRoleId === PhxConstants.FunctionalRole.BackOffice ||
        item.FunctionalRoleId === PhxConstants.FunctionalRole.AccountsReceivable ||
        item.FunctionalRoleId === PhxConstants.FunctionalRole.BackOfficeARAP
      )).length > 0
    );
  }

  isCurrentProfileUnderClientServicesRole() {
    return (
      filter(this.currentProfile.FunctionalRoles, item => (
        item.FunctionalRoleId === PhxConstants.FunctionalRole.ClientServices ||
        item.FunctionalRoleId === PhxConstants.FunctionalRole.ClientServicesLead
      )).length > 0
    );
  }

  isSystemAdministrator() {
    const functionalRoles = this.currentProfile?.FunctionalRoles ?? [];
    return functionalRoles.some(r => r.FunctionalRoleId === PhxConstants.FunctionalRole.SystemAdministrator);
  }

  setRedirectAfterLogin(url: string): void {
    this.initialPage = url;
  }

  private loadAppCodeData() {
    return lastValueFrom(this.apiService.query('code')).then((rsp: EntityList<any>) => {
      window.PhoenixCodeValues = rsp.Items.reduce(
        (result, item) => ({
          ...result,
          [item.groupName]: [
            ...(result[item.groupName] || []),
            item,
          ],
        }),
        {},
      );

      lastValueFrom(this.apiService.httpGetRequest(`WorkflowCode/CodeValue`, this.generalServiceApiEndpoint)).then((results: any[]) => {
        const reducedValues = results.reduce(
          (result, item) => ({
            ...result,
            [item.groupName]: [
              ...(result[item.groupName] || []),
              item,
            ],
          }),
          {},
        );
        window.PhoenixCodeValues = {
          ...window.PhoenixCodeValues,
          ...reducedValues
        };
        return results;
      });
      return rsp;
    });
  }

  private loadTranslationData(locale: string) {
    return Promise.all([
      lastValueFrom(this.apiService.query(`localization/full/${locale}`)).then((rsp: any) => {
        window.PhxTranslations = rsp;
        return rsp;
      }),
      // NOTE: to create translation file - ng extract-i18n --out-file en-CA.json --output-path src/assets/i18n --format=json
      lastValueFrom(this.http.get(`assets/i18n/${locale}.json`)).then((rsp: any) => {
        loadTranslations(rsp);
        return true;
      }).catch(() => {
        return true;
      }),
    ]);
  }

  private loadEntityStatusData() {
    return Promise.all([
      lastValueFrom(this.apiService.query('state/getPreferredEntityStatus')).then((rsp: EntityList<any>) => {
        window.PhxPreferredEntityStatus = rsp.Items;
        return rsp;
      }),
      lastValueFrom(this.apiService.query('state/getEntityStatusTableNames')).then((rsp: EntityList<any>) => {
        window.PhxStateEntityStatusTableNames = rsp.Items;
        return rsp;
      })
    ]);
  }

  private checkAppId() {
    this.uniqueAppId = this.storageService.get('AppId');
    if (!this.uniqueAppId) {
      this.setAppId();
    }
  }

  private setAppId() {
    this.uniqueAppId = 'FBO-Angular-' + uuidModule.v4();
    this.storageService.set('AppId', this.uniqueAppId);
  }

  private pickProfile(context: UserContext) {
    const user: Partial<User> = context ? context.User : {};
    const userProfiles: UserProfile[] = user ? user.UserProfiles : [];
    const len = userProfiles ? userProfiles.length : 0;

    if (len) {
      let result;

      for (let i = len - 1; i >= 0; i--) {
        if ((userProfiles[i].Id === user.CurrentProfileId || userProfiles[i].Id === user.CurrentProfileId) && (!userProfiles[i].DatabaseId || userProfiles[i].DatabaseId === user.CurrentDatabaseId)) {
          result = userProfiles[i];
          break;
        }

        if (userProfiles[i].IsPrimary) {
          result = userProfiles[i];
        } else {
          if (!result || result.Id <= 0) {
            result = userProfiles[i];
          }
        }
      }

      result.DatabaseId = user.CurrentDatabaseId;
      result.TenantName = user.CurrentTenantName;
      this.cookieService.setProfileIdInCookie(result.Id, result.DatabaseId);

      return result || {};
    }

    return {};
  }

  private setBearerTokenByProfileId(dbId: number, profileId: number) {
    this.cookieService.putObject(this.cookieService.CookieStore, { profileId, dbId });
    return this.generateNewTokenFromAccount(`${this.apiEndPoint}/account/SwitchProfile/${dbId}/${profileId}`, {});
  }

  private setBearerTokenByAccessCode(accessCode: string, token: string) {
    return this.generateNewTokenFromAccount(`${this.apiEndPoint}/account/AccessCode`, {
      AccessCode: accessCode,
      Token: token
    });
  }

  private generateNewTokenFromAccount(url: string, body: any) {
    const options = {
      headers: new HttpHeaders({
        'App-Id': this.appId,
        'Browser-Name': this.apiService.browserName,
        'OS-Name': this.apiService.operatingSystem
      })
    };
    return this.http.post<BasicUserProfile>(url, body, options)
      .pipe(
        switchMap((response) => {
          const accessToken = response?.access_token;
          const newRefreshToken = response?.refresh_token;
          this.storageService.set('BearerIdentity', JSON.stringify({
            userName: response.userName,
            profileId: response.profileId,
            databaseId: response.databaseId,
            tenantName: response.tenantName,
            email: response.email
          }));
          return this.apiService.handleTokenAuthResponse(accessToken, newRefreshToken, response.accessTokenExpiresUtc).pipe(map(() => response));
        })
      );
  }

  // userPilot configuration
  IdentifyUserPilotUser() {
    this.getUserContext().then(response => {
      const res = response as UserContext;
      const userData = res.User;
      const currentProfile = userData.UserProfiles.find(p => p.Id === userData.CurrentProfileId);
      /** NOTE: https://docs.userpilot.com/article/23-identify-users-track-custom-events */
      Userpilot.identify(
        userData.Id.toString(),
        {
          name: userData.FirstName + ' ' + userData.LastName,
          email: currentProfile.PrimaryEmail,
          created_at: new Date(), // ISO8601 Date,
          company:  // optional
          {
            id: currentProfile.OrganizationId, // Company Unique ID
            created_at: Date() // ISO8601 Date
          }
        }
      );
    });
  }
}
