/***************************************************************************************************
 * Load `$localize` onto the global scope - used if i18n tags appear in Angular templates.
 */
import '@angular/localize/init';
/**
 * This file includes polyfills needed by Angular and is loaded before the app.
 * You can add your own extra polyfills to this file.
 *
 * This file is divided into 2 sections:
 *   1. Browser polyfills. These are applied before loading ZoneJS and are sorted by browsers.
 *   2. Application imports. Files imported after ZoneJS that should be loaded before your main
 *      file.
 *
 * The current setup is for so-called "evergreen" browsers; the last versions of browsers that
 * automatically update themselves. This includes Safari >= 10, Chrome >= 55 (including Opera),
 * Edge >= 13 on the desktop, and iOS 10 and Chrome on mobile.
 *
 * Learn more in https://angular.io/guide/browser-support
 */

/***************************************************************************************************
* BROWSER POLYFILLS
*/

(window as any).global = window;

import 'core-js/es/array';

/***************************************************************************************************
 * Zone JS is required by Angular itself.
 */
import 'zone.js';  // Included with Angular CLI.



/***************************************************************************************************
 * APPLICATION IMPORTS
 */

/**
 * Date, currency, decimal and percent pipes.
 * Needed for: All but Chrome, Firefox, Edge, IE11 and Safari 10
 */


import 'json3';

const reISO = /^(\+\d.)?(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/;
const reMsAjax = /^\/Date\((d|-|.*)\)[\/|\\]$/;

const oldParse = JSON.parse;
JSON.parse = function(text, reviver) {
    if (typeof (text) === 'undefined') {
        return text;
    } else {
        const reviverFunction = function(key, value) {
            let newValue = value;
            if (typeof newValue === 'string') {
                let a = reISO.exec(newValue);
                if (a) {
                    newValue = new Date(newValue);
                }
                a = reMsAjax.exec(newValue);
                if (a) {
                    const b = a[1].split(/[-+,.]/);
                    newValue = new Date(b[0] ? +b[0] : 0 - +b[1]);
                }
            }
            if (typeof reviver === 'function') {
                newValue = reviver(key, newValue);
            }
            return newValue;
        };
        return oldParse(text, reviverFunction);
    }
};
