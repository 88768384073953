import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app';
import { environment } from './environments/environment';

import 'hammerjs';

declare function require(name: string);

(window as any).$ = require('../node_modules/jquery/dist/jquery.min.js');
(window as any).jQuery = (window as any).$;
(window as any).oreq = require('../node_modules/oreq/src/oreq.js');
(window as any).hubConnection = require('../node_modules/signalr/jquery.signalR.js');

require('../vendor/oreq.smartTableAdapter.js');

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule);
