import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { Navigation, NavigationMenuItem } from 'src/app/sidenav/model';
import { SidenavService } from 'src/app/sidenav/sidenav.service';
import { MODULE_ROUTES } from '../constants/module-routes.const';
import { PhxConstants } from '../PhoenixCommon.module';

export const azsearchGuard: CanActivateFn = (route, state) => {
  const sideNavService = inject(SidenavService);
  const router = inject(Router);

  const extractAccessiblePaths = (navigationData: Navigation): string[] => {
    const getPaths = (navItems: NavigationMenuItem[]): string[] => {
      return navItems.flatMap(navItem => {
        const paths: string[] = [];
        if (navItem.State?.toLowerCase() === `/next/${MODULE_ROUTES.REPORT_SEARCH}/${PhxConstants.ReportType.BillingTransactionReport}`) {
          paths.push(`/${MODULE_ROUTES.MAIN}/${MODULE_ROUTES.REPORT_SEARCH}/${PhxConstants.ReportType.TransactionStatisticsReport}`);
        }
        if (navItem.State) {
          paths.push(navItem.State.toLowerCase());
        }
        if (navItem.HasChildren) {
          paths.push(...getPaths(navItem.Children));
        }
        return paths;
      });
    };

    return getPaths(navigationData.NavigationItems).filter(path => path.includes(MODULE_ROUTES.REPORT_SEARCH));
  };

  const isAccessible = (accessiblePaths: string[], userPath: string): boolean => accessiblePaths.some(path => userPath.includes(path));

  return sideNavService.getNavigationData().pipe(
    filter(Boolean),
    map(extractAccessiblePaths),
    filter(paths => paths.length > 0),
    map(paths => (isAccessible(paths, state.url) ? true : router.createUrlTree([`/${MODULE_ROUTES.MAIN}`, MODULE_ROUTES.NO_ACCESS])))
  );
};
