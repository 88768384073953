import { Component, Input, OnInit, Optional } from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { ValidationMessagesConfiguration, defaultConfig } from './config';
import { MessageProvider } from './message-provider';
import { PhxLocalizationService } from '../../index';

@Component({
    selector: 'phx-form-control-validation',
    templateUrl: './phx-form-control-validation.component.html',
    styleUrls: ['./phx-form-control-validation.component.less']
})
export class PhxFormControlValidationComponent implements OnInit {
    @Input() control: AbstractControl;
    @Input() class: string;

    config: ValidationMessagesConfiguration;
    messageProvider: MessageProvider;

    constructor(
        @Optional() private customConfig: ValidationMessagesConfiguration,
        private localizationService: PhxLocalizationService
    ) {

        this.config = Object.assign({}, defaultConfig);

        Object.keys(defaultConfig.defaultErrorMessages).map( (key) => {
            defaultConfig.defaultErrorMessages[key] = this.localizationService.translate(defaultConfig.defaultErrorMessages[key]);
        });

        if (customConfig?.defaultErrorMessages) {
            this.config = {
                ...defaultConfig,
                ...customConfig,
                defaultErrorMessages: {
                    ...defaultConfig.defaultErrorMessages,
                    ...customConfig.defaultErrorMessages
                }
            };
        }

        const errorMessages = Object.assign({}, defaultConfig.defaultErrorMessages, this.config.defaultErrorMessages);
        this.messageProvider = new MessageProvider(errorMessages);
    }

    ngOnInit(): void {
        this.mergeWithLocalConfiguration();
    }

    get errorMessage(): string {
        if (this.control) {
            for (const errorPropertyName in this.control.errors) {
                if (this.control.errors.hasOwnProperty(errorPropertyName)) {
                    return this.messageProvider.getErrorMessage(errorPropertyName, this.control.errors[errorPropertyName]);
                }
            }
        }

        return null;
    }

    /**
     * Merge instance specific configuration with the default and/or custom one.
     */
    private mergeWithLocalConfiguration(): void {
        if (this.class) {
            this.config.class = this.class;
        }
    }
}
