<div class="login-container">
  <div class="login-form mat-elevation-z4">

    <ng-container *ngIf="!pendingForgotPassword; else loadingPendingForgotPassword">
      <div *ngIf="passwordForgotSent; else passwordForm" class="text-white password-reset-success">
        <img src="../../../assets/login/icon_forgotpass.svg" alt=""/>
        <p>{{ 'account.forgot.pwdForgotSentMessage' | phxTranslate }}</p>
      </div>
      <ng-template #passwordForm>
        <form [formGroup]="form">
          <div class="login-fields">
            <div class="logo-container text-center">
              <img src="../../../assets/login/logo_FBO.svg" alt="logo"/>
            </div>
            <div class="field">
              <input type="email" autocomplete="email" class="form-control input-lg" formControlName="email"
                     placeholder="{{ 'account.forgot.emailId' | phxTranslate }}" aria-label="email" />
            </div>
          </div>
          <div class="login-actions">
            <span></span>
            <button type="button" class="btn btn-primary" (click)="sendForgotPassword()">
              {{ 'account.forgot.recoverButton' | phxTranslate }}
            </button>
          </div>
        </form>
      </ng-template>
    </ng-container>
    <ng-template #loadingPendingForgotPassword>
      <span class="text-white">{{ 'common.phxDataTableConfiguration.loadPanelText' | phxTranslate }}</span>
    </ng-template>
    <div class="alert alert-danger alert-dismissable error-box" *ngIf="errorToShow">
      <ul class="list-unstyled text-white">
        <li>{{ errorToShow }}</li>
      </ul>
    </div>
  </div>
</div>
