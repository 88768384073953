import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VersionComparisonToggleService {
  private showCompareAllVersionsToggleSubject = new BehaviorSubject<boolean>(false);
  showCompareAllVersionsToggle$ = this.showCompareAllVersionsToggleSubject.asObservable();

  private isShowingVersionChangesSubject = new BehaviorSubject<boolean>(false);
  isShowingVersionChanges$ = this.isShowingVersionChangesSubject.asObservable();

  private versionComparisonSourceNumberSubject = new BehaviorSubject<number>(null);
  versionComparisonSourceNumber$ = this.versionComparisonSourceNumberSubject.asObservable();

  setShowCompareAllVersionsToggle(canShow: boolean) {
    this.showCompareAllVersionsToggleSubject.next(canShow);
  }

  setIsShowingVersionChanges(canShow: boolean) {
    this.isShowingVersionChangesSubject.next(canShow);
  }

  setVersionComparisonSourceNumber(versionNumber: number) {
    this.versionComparisonSourceNumberSubject.next(versionNumber);
  }

}
