import { formatDate } from '@angular/common';

import { Store as AzSearchStore } from 'azsearchstore';

import { FacetConfigUiType, IFacetsConfig, ListViewConfig } from './global-search-facet';
import { AzSearchStoreExtensionType, GlobalSearchFieldType, IGsConfiguration } from './global-search';
import { ISearchBarType } from '../interfaces/global-search';
import { GlobalSearchUtil } from '../util/GlobalSearchUtil';
import { PhxLocalizationService } from '../../common';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace GlobalSearchConstants {
  const gsUtil = new GlobalSearchUtil();

  const API_VERSION: AzSearchStore.SearchApiVersion = '2016-09-01';
  const HIGHLIGHT_PRE_TAG = '<b>';
  const HIGHLIGHT_POST_TAG = '</b>';
  const MIN_FACET_DATE = new Date(1975, 1, 1);
  const MAX_FACET_DATE = new Date(2038, 1, 1);
  export const ITEMS_PER_PAGE = 25;
  export const MAX_SUGGESTIONS = 5;

  const GsSearchParameters: AzSearchStore.SearchParametersUpdate = {
    apiVersion: API_VERSION,
    count: true,
    top: ITEMS_PER_PAGE,
    searchMode: 'all'
  };

  ////////////////////////////
  // WORK ORDER SEARCH VARS //
  ////////////////////////////
  const GsWorkOrderConfig: AzSearchStore.Config = {
    service: '',
    queryKey: '',
    index: ''
  };

  const GsWorkOrderSuggestionsParameters: AzSearchStore.SuggestionsParametersUpdate = {
    apiVersion: API_VERSION,
    top: MAX_SUGGESTIONS,
    suggesterName: 'suggester',
    select:
    // eslint-disable-next-line max-len
      'AssignmentId, AssignmentStatus, ClientName, InternalCompanyCode, StartDate, ProfileType, EndDate, WorkOrderVersionId, WorkOrderId, WOStatus, WorkerLegalName, WorkerPreferredName, WorkOrderNumber, ProfileType, WorkerDisplayName',
    highlightPreTag: HIGHLIGHT_PRE_TAG,
    highlightPostTag: HIGHLIGHT_POST_TAG
  };

  const GsWOFacetsConfig: IFacetsConfig = [
    {
      SearchFieldName: 'ClientName',
      UiFieldName: 'Client',
      UiType: FacetConfigUiType.Dropdown
    },
    {
      SearchFieldName: 'WorkerDisplayName',
      UiFieldName: 'Worker',
      UiType: FacetConfigUiType.Dropdown
    },
    {
      SearchFieldName: 'WOVersionStatus',
      UiFieldName: 'Workflow Status',
      UiType: FacetConfigUiType.Checkbox
    },
    {
      SearchFieldName: 'WOStatus',
      UiFieldName: 'Work Order Status',
      UiType: FacetConfigUiType.Checkbox
    },
    {
      SearchFieldName: 'AssignmentStatus',
      UiFieldName: 'Assignment Status',
      UiType: FacetConfigUiType.Checkbox
    },
    {
      SearchFieldName: 'StartDate',
      UiFieldName: 'Start Date',
      UiType: FacetConfigUiType.Range,
      Min: MIN_FACET_DATE,
      Max: MAX_FACET_DATE
    },
    {
      SearchFieldName: 'EndDate',
      UiFieldName: 'End Date',
      UiType: FacetConfigUiType.Range,
      Min: MIN_FACET_DATE,
      Max: MAX_FACET_DATE
    },
    {
      SearchFieldName: 'ProfileType',
      UiFieldName: 'Profile Type',
      UiType: FacetConfigUiType.Checkbox
    },
    {
      SearchFieldName: 'Country',
      UiFieldName: 'Country',
      UiType: FacetConfigUiType.Dropdown
    }
  ];

  const GsWorkOrderListViewConfig: ListViewConfig = [
    { ColumnHeader: 'Number', FieldName: 'AssignmentId', DisplayOverride: obj => obj.WorkOrderNumber, Sortable: true },
    { ColumnHeader: 'Worker Name', FieldName: 'WorkerDisplayName', Sortable: true },
    { ColumnHeader: 'Start Date', FieldName: 'StartDate', DisplayOverride: obj => formatDate(obj.StartDate, 'YYYY-MM-dd', PhxLocalizationService.locale), Sortable: true },
    { ColumnHeader: 'End Date', FieldName: 'EndDate', DisplayOverride: obj => formatDate(obj.EndDate, 'YYYY-MM-dd', PhxLocalizationService.locale), Sortable: true },
    { ColumnHeader: 'Bill Rate', FieldName: 'BillRate', DisplayOverride: obj => obj.BillRate + '/' + obj.BillRateUnitType, Sortable: true },
    { ColumnHeader: 'Pay Rate', FieldName: 'Payrate', DisplayOverride: obj => obj.Payrate + '/' + obj.PayRateUnitType, Sortable: true },
    { ColumnHeader: 'Workflow Status', FieldName: 'WOVersionStatus', Sortable: true },
    { ColumnHeader: 'Organization', FieldName: 'WorkerOrgName', Sortable: true },
    { ColumnHeader: 'Worker Email', FieldName: 'WorkerEmail', Sortable: true },
    { ColumnHeader: 'Client Manager', FieldName: 'ClientManagerName', Sortable: true },
    { ColumnHeader: 'Client Manager Email', FieldName: 'ClientManagerEmail', Sortable: true },
    { ColumnHeader: 'Internal Company', FieldName: 'InternalCompany', Sortable: true },
    { ColumnHeader: 'Internal Company Code', FieldName: 'InternalCompanyCode', Sortable: true },
    { ColumnHeader: 'Work Order Status', FieldName: 'WOStatus', Sortable: true },
    { ColumnHeader: 'Assignment Status', FieldName: 'AssignmentStatus', Sortable: true },
    { ColumnHeader: 'Client Name', FieldName: 'ClientName', Sortable: true },
    { ColumnHeader: 'Client Code', FieldName: 'ClientCode', Sortable: true },
    { ColumnHeader: 'Profile Type', FieldName: 'ProfileType', Sortable: true },
    { ColumnHeader: 'Sub Vendor Contact', FieldName: 'SubVendorName', Sortable: true },
    { ColumnHeader: 'Sub Vendor Agency', FieldName: 'SubVendorOrg', Sortable: true },
    { ColumnHeader: 'Sub Vendor Contact Email', FieldName: 'SubVendorEmail', Sortable: true },
    { ColumnHeader: 'Country', FieldName: 'Country', Sortable: true }
  ];

  export const GsWorkOrderTypeDeclarations = {
    ClientName: GlobalSearchFieldType.String,
    WorkerPreferredName: GlobalSearchFieldType.String,
    WorkerDisplayName: GlobalSearchFieldType.String,
    WOVersionStatus: GlobalSearchFieldType.String,
    WOStatus: GlobalSearchFieldType.String,
    AssignmentStatus: GlobalSearchFieldType.String,
    StartDate: GlobalSearchFieldType.Date,
    EndDate: GlobalSearchFieldType.Date,
    ProfileType: GlobalSearchFieldType.String,
    Country: GlobalSearchFieldType.String
  };

  export const GsWorkOrderConfiguration: IGsConfiguration = {
    config: GsWorkOrderConfig,
    searchParameters: GsSearchParameters,
    suggestionParameters: GsWorkOrderSuggestionsParameters,
    facetsConfig: GsWOFacetsConfig,
    listViewConfig: GsWorkOrderListViewConfig
  };

  /////////////////////////////
  // TRANSACTION SEARCH VARS //
  /////////////////////////////
  const GsTransactionConfig: AzSearchStore.Config = {
    service: '',
    queryKey: '',
    index: ''
  };

  const GsTransactionSuggestionsParameters: AzSearchStore.SuggestionsParametersUpdate = {
    apiVersion: API_VERSION,
    top: MAX_SUGGESTIONS,
    suggesterName: 'suggester',
    select:
    // eslint-disable-next-line max-len
      'id, BillingCurrencyId,PaymentCurrencyId,TransactionId,TransactionTypeId, TransactionNumber, TransactionType, FromDate, ToDate,WorkerDisplayName, WorkerName, SubvendorAgency, BillingTotal, PaymentTotal, OrganizationClient, TransactionHeaderStatus',
    highlightPreTag: HIGHLIGHT_PRE_TAG,
    highlightPostTag: HIGHLIGHT_POST_TAG
  };

  const GsTransactionFacetsConfig: IFacetsConfig = [
    {
      SearchFieldName: 'OrganizationClient',
      UiFieldName: 'Client',
      UiType: FacetConfigUiType.Dropdown
    },
    {
      SearchFieldName: 'WorkerName',
      UiFieldName: 'Worker',
      UiType: FacetConfigUiType.Dropdown
    },
    {
      SearchFieldName: 'SubvendorAgency',
      UiFieldName: 'Subvendor',
      UiType: FacetConfigUiType.Dropdown
    },
    {
      SearchFieldName: 'PayeeName',
      UiFieldName: 'Payee',
      UiType: FacetConfigUiType.Dropdown
    },
    {
      SearchFieldName: 'FromDate',
      UiFieldName: 'From Date',
      UiType: FacetConfigUiType.Range,
      Min: MIN_FACET_DATE,
      Max: MAX_FACET_DATE
    },
    {
      SearchFieldName: 'ToDate',
      UiFieldName: 'To Date',
      UiType: FacetConfigUiType.Range,
      Min: MIN_FACET_DATE,
      Max: MAX_FACET_DATE
    },
    {
      SearchFieldName: 'TransactionType',
      UiFieldName: 'Transaction Type',
      UiType: FacetConfigUiType.Checkbox
    },
    {
      SearchFieldName: 'LineOfBusiness',
      UiFieldName: 'Line Of Business',
      UiType: FacetConfigUiType.Dropdown
    },
    {
      SearchFieldName: 'TransactionHeaderStatus',
      UiFieldName: 'Transaction Status',
      UiType: FacetConfigUiType.Checkbox
    },
    {
      SearchFieldName: 'OrganizationInternal',
      UiFieldName: 'Internal Organization',
      UiType: FacetConfigUiType.Dropdown
    }
  ];

  const GsTransactionListViewConfig: ListViewConfig = [
    { ColumnHeader: 'Id', FieldName: 'TransactionId', Sortable: true },
    { ColumnHeader: 'Transaction #', FieldName: 'TransactionNumber', Sortable: true },
    { ColumnHeader: 'Work Order', FieldName: 'AssignmentId', DisplayOverride: obj => obj.WorkOrderFullNumber, Sortable: true },
    { ColumnHeader: 'Type', FieldName: 'TransactionType', Sortable: true },
    { ColumnHeader: 'Worker Name', FieldName: 'WorkerName', Sortable: true },
    { ColumnHeader: 'Payee Name', FieldName: 'PayeeName', Sortable: true },
    { ColumnHeader: 'From', FieldName: 'FromDate', DisplayOverride: obj => formatDate(obj.FromDate, 'YYYY-MM-dd', PhxLocalizationService.locale), Sortable: true },
    { ColumnHeader: 'To', FieldName: 'ToDate', DisplayOverride: obj => formatDate(obj.ToDate, 'YYYY-MM-dd', PhxLocalizationService.locale), Sortable: true },
    { ColumnHeader: 'Bill Total', FieldName: 'BillingTotal', Sortable: true },
    { ColumnHeader: 'Pay Total', FieldName: 'PaymentTotal', Sortable: true },
    { ColumnHeader: 'Pay Subtotal', FieldName: 'PaymentSubtotal', Sortable: true },
    { ColumnHeader: 'Pay Tax', FieldName: 'PaymentTax', Sortable: true },
    { ColumnHeader: 'Deductions', FieldName: 'PaymentDeductions', Sortable: true },
    { ColumnHeader: 'Category', FieldName: 'TransactionCategory', Sortable: true },
    { ColumnHeader: 'Internal Company', FieldName: 'OrganizationInternalCode', Sortable: true },
    { ColumnHeader: 'Line Of Business', FieldName: 'LineOfBusiness', Sortable: true },
    { ColumnHeader: 'Client', FieldName: 'OrganizationClient', Sortable: true },
    { ColumnHeader: 'Invoice Note 1', FieldName: 'InvoiceNote1', Sortable: true },
    { ColumnHeader: 'Alternate Billing Client', FieldName: 'AlternateBillingClient', Sortable: true },
    { ColumnHeader: 'PONumber', FieldName: 'PONumber', Sortable: true },
    { ColumnHeader: 'Status', FieldName: 'TransactionHeaderStatus', Sortable: true },
    { ColumnHeader: 'Work Actual Payment Release Date', FieldName: 'PayTranReleaseDates',
      DisplayOverride: obj => formatDate(obj.PayTranReleaseDates, 'YYYY-MM-dd', PhxLocalizationService.locale), Sortable: true },
    { ColumnHeader: 'Work Payment Method', FieldName: 'PayTranMethods', Sortable: true },
    {
      ColumnHeader: 'Implementation',
      FieldName: 'IsTest',
      DisplayOverride: obj => {
        if (!obj.IsTest) {
          return 'Live';
        } else {
          return 'Test';
        }
      },
      Sortable: true
    }
  ];

  export const GsTransactionTypeDeclarations = {
    AssignmentId: GlobalSearchFieldType.String,
    TransactionId: GlobalSearchFieldType.String,
    TransactionType: GlobalSearchFieldType.String,
    LineOfBusiness: GlobalSearchFieldType.String,
    TransactionHeaderStatus: GlobalSearchFieldType.String,
    PayeeName: GlobalSearchFieldType.String,
    SubvendorAgency: GlobalSearchFieldType.String,
    WorkerName: GlobalSearchFieldType.String,
    OrganizationClient: GlobalSearchFieldType.String,
    OrganizationInternal: GlobalSearchFieldType.String,
    ToDate: GlobalSearchFieldType.Date,
    FromDate: GlobalSearchFieldType.Date
    // DOES NOT EXIST
    // PaymentMethod: GlobalSearchFieldType.String
  };

  export const GsTransactionConfiguration: IGsConfiguration = {
    config: GsTransactionConfig,
    searchParameters: GsSearchParameters,
    suggestionParameters: GsTransactionSuggestionsParameters,
    facetsConfig: GsTransactionFacetsConfig,
    listViewConfig: GsTransactionListViewConfig
  };

  /////////////////////////////
  // PAYMENT SEARCH VARS //
  /////////////////////////////
  const GsPaymentConfig: AzSearchStore.Config = {
    service: '',
    queryKey: '',
    index: ''
  };

  const GsPaymentSuggestionsParameters: AzSearchStore.SuggestionsParametersUpdate = {
    apiVersion: API_VERSION,
    top: MAX_SUGGESTIONS,
    suggesterName: 'suggester',
    select:
    // eslint-disable-next-line max-len
      'id,PaymentId, PaymentNumber,PaymentMethodId, WorkerDisplayName,WorkerName, PaymentReleaseDate,WorkerName,SubvendorAgency,CurrencyId, ClientName, Status, PaymentTotal,PayeeTypeId,PaymentDate, PaymentNumber, PaymentTotal, StatusId',
    highlightPreTag: HIGHLIGHT_PRE_TAG,
    highlightPostTag: HIGHLIGHT_POST_TAG
  };

  const GsPaymentFacetsConfig: IFacetsConfig = [
    {
      SearchFieldName: 'ClientName',
      UiFieldName: 'Client',
      UiType: FacetConfigUiType.Dropdown
    },
    {
      SearchFieldName: 'WorkerName',
      UiFieldName: 'Worker',
      UiType: FacetConfigUiType.Dropdown
    },
    {
      SearchFieldName: 'PayeeType',
      UiFieldName: 'Payee Type',
      UiType: FacetConfigUiType.Checkbox
    },
    {
      SearchFieldName: 'PaymentDate',
      UiFieldName: 'Payment Date',
      UiType: FacetConfigUiType.Range,
      Min: MIN_FACET_DATE,
      Max: MAX_FACET_DATE
    },
    {
      SearchFieldName: 'InternalCompany',
      UiFieldName: 'Internal Company',
      UiType: FacetConfigUiType.Dropdown
    },
    {
      SearchFieldName: 'PayeeName',
      UiFieldName: 'Payee',
      UiType: FacetConfigUiType.Dropdown
    }
  ];

  const GsPaymentListViewConfig: ListViewConfig = [
    { ColumnHeader: 'Payment Id', FieldName: 'PaymentId', Sortable: true },
    { ColumnHeader: 'Payment Number', FieldName: 'PaymentNumber', Sortable: true },
    { ColumnHeader: 'Payee Name', FieldName: 'PayeeName', Sortable: true },
    { ColumnHeader: '# of Transactions', FieldName: 'NoOfTransactions', Sortable: true },
    { ColumnHeader: 'Worker Name', FieldName: 'WorkerName', DisplayOverride: obj => (obj ? gsUtil.firstPlusCount(obj.WorkerName) : ''), Sortable: false },
    { ColumnHeader: 'Payment Total', FieldName: 'PaymentTotal', Sortable: true },
    {
      ColumnHeader: 'Payment Method',
      FieldName: 'PaymentMethodId',
      DisplayOverride: obj => {
        let result = null;
        const codeValues = (window as any).PhoenixCodeValues['payment.CodePaymentMethodType'];
        if (codeValues) {
          // eslint-disable-next-line eqeqeq
          result = codeValues.find(c => c.id === +obj.PaymentMethodId);
        }
        return result?.description;
      },
      Sortable: true
    },
    { ColumnHeader: 'Payment Date', FieldName: 'PaymentDate', DisplayOverride: obj => formatDate(obj.PaymentDate, 'YYYY-MM-dd', 'en-US'), Sortable: true },
    { ColumnHeader: 'Payee Type', FieldName: 'PayeeType', Sortable: true }
  ];

  export const GsPaymentTypeDeclarations = {
    PayeeType: GlobalSearchFieldType.String,
    PayeeName: GlobalSearchFieldType.String,
    WorkerName: GlobalSearchFieldType.Collection,
    PaymentDate: GlobalSearchFieldType.Date,
    ClientName: GlobalSearchFieldType.String,
    InternalCompany: GlobalSearchFieldType.String
  };

  export const GsPaymentConfiguration: IGsConfiguration = {
    config: GsPaymentConfig,
    searchParameters: GsSearchParameters,
    suggestionParameters: GsPaymentSuggestionsParameters,
    facetsConfig: GsPaymentFacetsConfig,
    listViewConfig: GsPaymentListViewConfig
  };

  ////////////////////////////
  // GLOBAL SEARCH BAR VARS //
  ////////////////////////////

  export const GsSearchBarSearchTypes: Array<ISearchBarType> = [
    {
      display: 'Work Order',
      searchType: AzSearchStoreExtensionType.WorkOrder,
      disabled: false
    },
    {
      display: 'Transaction',
      searchType: AzSearchStoreExtensionType.Transaction,
      disabled: false
    },
    {
      display: 'Payment',
      searchType: AzSearchStoreExtensionType.Payment,
      disabled: true
    },
    {
      display: 'Invoice',
      searchType: null,
      disabled: true
    },
    {
      display: 'Organization',
      searchType: null,
      disabled: true
    },
    {
      display: 'People',
      searchType: null,
      disabled: true
    },
    {
      display: 'Timesheet',
      searchType: null,
      disabled: true
    },
    {
      display: 'Expense',
      searchType: null,
      disabled: true
    }
  ].filter(({ disabled }) => !disabled);
}
