import { Component, Input, OnInit } from '@angular/core';
import { IRecordOfEmploymentTransactionInfo, IRecordOfEmploymentField } from 'src/app/contact/models/roe-report.interface';
import { CommonService } from '../../services/common.service';
import { GoogleAnalyticsService } from '../../services/google-analytics/google-analytics.service';
import { DialogRef } from '@angular/cdk/dialog';
import { AnalyticsFeatures } from '../../services/google-analytics/models/analytics-features.model';
import { AnalyticsFeatureTypes } from '../../services/google-analytics/models/analytics-feature-types.model';

@Component({
  selector: 'app-roe-modal',
  templateUrl: './roe-modal.component.html',
  styleUrls: ['./roe-modal.component.less']
})
export class RoeModalComponent implements OnInit {
  @Input() modalTitle: string;
  @Input() roeFields: IRecordOfEmploymentField[];
  @Input() detailedRows: IRecordOfEmploymentTransactionInfo[];
  @Input() dialogRef: DialogRef<void>;
  
  feedbackValue: boolean;
  copiedFields: { [key: string]: boolean } = {};

  constructor(
    private commonService: CommonService, 
    private googleAnalyticsService: GoogleAnalyticsService
  ) {}

  ngOnInit(): void {
    this.googleAnalyticsService.sendClickData({
      type: AnalyticsFeatureTypes.Profile,
      feature: AnalyticsFeatures.RecordOfEmploymentReport,
      action: 'Open'
    });
    
    this.dialogRef.closed.subscribe(async () => {
      this.googleAnalyticsService.sendClickData({
        type: AnalyticsFeatureTypes.Profile,
        feature: AnalyticsFeatures.RecordOfEmploymentReport,
        action: 'Close'
      });
    });
  }

  onFeedbackClick(isPositive: boolean): void {
    this.feedbackValue = isPositive;

    try {
      this.googleAnalyticsService.sendClickData({
        type: AnalyticsFeatureTypes.Profile,
        feature: AnalyticsFeatures.RecordOfEmploymentReport,
        action: 'Feedback',
        label: isPositive ? 'Positive' : 'Negative',
      });

      this.commonService.logSuccess('Thank you for your feedback');
    } catch (error) {
      this.commonService.logError('Failed to send feedback');
    }

    
  }

  async copyToClipboard(value: string, index: string, copiedFieldLabel: string): Promise<void> {
    try {
      navigator.clipboard.writeText(value);
      this.copiedFields[index] = true;
      this.commonService.logSuccess('Copied to clipboard');

      this.googleAnalyticsService.sendClickData({
        type: AnalyticsFeatureTypes.Profile,
        feature: AnalyticsFeatures.RecordOfEmploymentReport,
        action: 'Copy',
        label: copiedFieldLabel,
      });
    } catch (error) {
      this.commonService.logError('Failed to copy text to clipboard');
    }
  }
} 
