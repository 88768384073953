import DataGrid from 'devextreme/ui/data_grid';

export function enableColumnChooserSort() {
  (DataGrid as any).registerModule('columnChooserSorting', {
    extenders: {
      controllers: {
        columns: {
          getChooserColumns(loadAllColumns) {
            const result = this.callBase(loadAllColumns);
            return result.sort((column1, column2) => {
              return column1.caption.localeCompare(column2.caption);
            });
          }
        }
      }
    }
  });
}
