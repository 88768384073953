import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class DownloadCenterButtonService {
  notifyDownloadsChangedSubject$ = new Subject();
  constructor() { }

  public notifyDownloadsChanged() {
    this.notifyDownloadsChangedSubject$.next(true);
  }
}
