
import { distinctUntilChanged, debounceTime, takeUntil } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { IAccountResetPassword, AccountService } from '../shared';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { PhxFormControlLayoutType } from '../../common/model';
import { ValidationExtensions } from '../../common';
import { AccountModuleResourceKeys } from '../account-module-resource-keys';
import { BaseComponentOnDestroy } from '../../common/epics/base-component-on-destroy';

@Component({
  selector: 'app-account-reset-password',
  templateUrl: './app-account-reset-password.component.html',
  styleUrls: ['./app-account-reset-password.component.less']
})
export class AccountResetPasswordComponent extends BaseComponentOnDestroy implements OnInit, OnDestroy {
  accountModuleResourceKeys: typeof AccountModuleResourceKeys;
  model: IAccountResetPassword = {
    code: null,
    email: null,
    password: null,
    confirmPassword: null
  };

  validationMessages: any;
  validationDataParams: any;

  form: UntypedFormGroup;
  newPasswordGroup: UntypedFormGroup;

  isPasswordReset = false;

  formControlLayoutType: PhxFormControlLayoutType = PhxFormControlLayoutType.Stacked;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private accountService: AccountService,
    private formBuilder: UntypedFormBuilder) {
    super();
  }

  ngOnInit() {
    this.buildForm();
    this.accountModuleResourceKeys = AccountModuleResourceKeys;
    this.activatedRoute.queryParams.pipe(
      takeUntil(this.isDestroyed$)
    ).subscribe(params => {
      this.model.code = params.et;
      this.model.email = params.email;
      if (!this.model.code || !this.model.email) {
        this.validationDataParams = { ValidationMessages: [{ PropertyName: '', Message: 'Invalid Token!' }] };
      } else {
        this.validationDataParams = null;
      }
      this.form.patchValue({
        email: this.model.email
      });
    });
    this.form.valueChanges.pipe(
      takeUntil(this.isDestroyed$),
      debounceTime(300),
      distinctUntilChanged())
      .subscribe(changes => {
        changes = { ...{ email: changes.email }, ...changes.newPasswordGroup };
        this.model = { ...this.model, ...changes };
        this.validationMessages = null;
      });
  }

  buildForm() {
    const initial = this.model;
    this.newPasswordGroup = this.formBuilder.group(
      {
        password: [
          initial.password,
          [
            Validators.required,
            // 1. check whether the entered password has a min lenght
            Validators.minLength(8),
            // 2. check whether the entered password has a number
            ValidationExtensions.pattern(/\d/, 'Must have at least 1 number'),
            // 3. check whether the entered password has upper case letter
            ValidationExtensions.pattern(/[A-Z]/, 'Must contain at least 1 capital case letter'),
            // 4. check whether the entered password has a lower-case letter
            ValidationExtensions.pattern(/[a-z]/, 'Must contain at least 1 lower case letter'),
            // 5. check whether the entered password has a special character
            ValidationExtensions.pattern(/[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, 'Must contain at least 1 special character')
          ]
        ],
        confirmPassword: [initial.confirmPassword, [Validators.required, Validators.minLength(8)]]
      },
      { validator: ValidationExtensions.passwords() }
    );

    this.form = this.formBuilder.group({
      email: [initial.email, [Validators.required, Validators.email]],
      newPasswordGroup: this.newPasswordGroup
    });
  }

  reset() {
    this.accountService
      .resetPassword(this.model)
      .then(() => {
        setTimeout(() => {
          this.router.navigate(['login']);
        }, 3000);
      })
      .catch(error => {
        this.validationMessages = error;
      });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
