import { Injectable } from '@angular/core';
import { ActivationStart, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

import { AuthService } from '../auth.service';
import { AnalyticsInfo } from './models/analytics-info.model';
import { AnalyticsEvent } from './models/analytics-event.model';
import { ClickAnalyticsData } from './models/click-analytics-data.model';
import { PageViewAnalyticsData } from './models/page-view-analytics-data.model';

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {
  private pageMetaData = {
    pageUri: '',
    pageUrl: ''
  };

  private dataLayerRef?: AnalyticsInfo<PageViewAnalyticsData | ClickAnalyticsData>[];
  constructor(private authService: AuthService, private router: Router) { }

  /** This method should be called only once (currently called from AppComponent) to provide dataLayerRef to push events to */
  init(dataLayerRef?: any): void {
    if (!this.dataLayerRef) {
      this.dataLayerRef = dataLayerRef;
      if (dataLayerRef) {
        /** Tracking events require keeping track of URL and URI */
        this.subscribeToNavigationChange();
      }
    } else {
      // eslint-disable-next-line no-console
      console.error('The analytics service has already been initialized!');
    }
  }

  sendPageViewData<T extends PageViewAnalyticsData>(eventData: T): Promise<void> {
    return this.sendDataToGoogle(eventData, 'on_page_view');
  }

  sendClickData<T extends ClickAnalyticsData>(eventData: T): Promise<void> {
    return this.sendDataToGoogle(eventData, 'on_element_click');
  }

  private async sendDataToGoogle<T extends PageViewAnalyticsData | ClickAnalyticsData>(payload: T, event: AnalyticsEvent): Promise<void> {
    if (this.dataLayerRef) {
      const data = await this.addMetaDataToPayload(payload, event);
      this.dataLayerRef.push(data);
    }
  }

  private async addMetaDataToPayload<T>(payload: T, event: AnalyticsEvent): Promise<AnalyticsInfo<T>> {
    const userContext = await this.authService.getUserContext();
    const userProfile = await this.authService.fetchCurrentProfile();

    return {
      ...payload,
      ...this.pageMetaData,
      event,
      userId: userContext.User.Id,
      tenantName: userContext.User.CurrentTenantName,
      pageDom: window.location.hostname,
      userProfileId: userProfile.Id
    };
  }

  private subscribeToNavigationChange(): void {
    let routeSegments: string[] = [];
    this.router.events.pipe(filter(e => e instanceof NavigationEnd || e instanceof ActivationStart || e instanceof NavigationStart)).subscribe(e => {
      if (e instanceof NavigationStart) {
        routeSegments = [];
      }
      if (e instanceof ActivationStart && e.snapshot.routeConfig.path) {
        let parentSegment = e.snapshot.parent;

        do {
          const parentSegmentPathHasNotBeenCollectedYet = routeSegments[routeSegments.length - 1] !== parentSegment?.routeConfig?.path;

          if (parentSegment?.routeConfig?.path && parentSegmentPathHasNotBeenCollectedYet) {
            routeSegments.unshift(parentSegment.routeConfig.path);
          } else {
            /** Else we have reached the top route segment or a parent segment, which has already been collected */
            break;
          }
          parentSegment = parentSegment.parent;
        } while (parentSegment?.routeConfig?.path);

        routeSegments.push(e.snapshot.routeConfig.path);
      }
      if (e instanceof NavigationEnd) {
        const pageUri = '/' + routeSegments.join('/').replace(/\/$/, '');
        routeSegments = [];
        this.pageMetaData = {
          pageUri,
          pageUrl: `${window.location.hostname}${e.urlAfterRedirects}`
        };
      }
    });
  }
}
