<app-headstone
  subPath="Mail Campaigns History"
  i18n-subPath="@@mailCampaign.history"
>
</app-headstone>

<button
  (click)="createNewMailCampaign()"
  class="btn btn-default icon-button"
  [disabled]="isCreatingMailCampaign"
>
  <mat-icon>add</mat-icon>
  <span i18n="@@common.button.back">New</span>
</button>
<app-phx-data-table
  (contextMenuOpenTab)="contextMenuOpenTab($event)"
  (rowClick)="rowClick($event)"
  [columns]="columns"
  [configuration]="dataTableConfiguration"
  [dataSource]="campaigns$ | async"
></app-phx-data-table>
