<div
  class="sidebar menu-min"
  id="sidebar"
  style="z-index: 101"
  [class.display]="mobileExpanded"
  [class.collapsed]="isNavigationBarCollapsed"
>
  <div
    class="sidebar-shortcuts"
    id="sidebar-shortcuts"
    style="z-index: 1"
  >
    <div
      class="sidebar-shortcuts-large"
      id="sidebar-shortcuts-large"
      style="z-index: 1"
    >
      <button
        class="btn btn-cloud"
        title="{{ localization.menuLabel | phxTranslate }}"
        (click)="selectedSideView = MENU"
      >
        <i class="material-icons md-24">view_list</i>
      </button>
      <button
        class="btn btn-cloud"
        title="To Do List"
        (click)="selectedSideView = TODO"
        *ngIf="isTodoListEnabled"
      >
        <i class="material-icons md-24">done_outline</i>
      </button>
      <button
        class="btn btn-cloud"
        title="{{ localization.addToFavoritesLabel | phxTranslate }}"
        (click)="selectedSideView === ADD ? (selectedSideView = ADDA) : (selectedSideView = ADD)"
      >
        <i class="material-icons md-24 shortcut-add-bookmark">star_border</i>
      </button>
    </div>
  </div>

  <!-- ngClass binding so the menu position is saved when going between bookmarks and menu -->
  <div
    class="new-div"
    [ngClass]="{ 'sidenav-hidden': selectedSideView === MENU || selectedSideView === TODO }"
  >
    <app-bookmark-side-menu
      *ngIf="!isNavigationBarCollapsed"
      [eventName]="selectedSideView"
    ></app-bookmark-side-menu>
  </div>

  <app-phx-accordion
    *ngIf="navigation"
    [closeOthers]="true"
    [ngClass]="{ 'sidenav-hidden': selectedSideView !== MENU }"
  >
    <div *ngFor="let n of navigation.NavigationItems">
      <!--no child-->
      <a
        *ngIf="!n.HasChildren"
        (click)="setIframeUri(n);onSideNavLinkClick(n.Id)"
        [routerLink]="n.State"
      >
        <app-phx-accordion-group
          class="parent-panel"
          [routerLink]="n.State"
          routerLinkActive="active-link"
          [showToggle]="false"
          [isAnimated]="false"
        >
          <span accordion-heading>
            <i class="material-icons accordion-heading">{{ n.Icon }}</i>
            {{ n.Value }}
          </span>
        </app-phx-accordion-group>
      </a>

      <!--yes child-->
      <app-phx-accordion-group
        *ngIf="n.HasChildren"
        (isOpenChange)="accordionGroupOpenChange($event, n)"
        [isOpen]="n.IsOpen ?? false"
        [routerLink]="n.State"
        routerLinkActive="active-link"
        [showToggle]="false"
        [isAnimated]="false"
      >
        <span accordion-heading>
          <i class="material-icons accordion-heading">{{ n.Icon }}</i>
          {{ n.Value }}
        </span>

        <div
          *ngFor="let s of n.Children"
          class="child-panel"
        >
          <!--no child-->
          <a
            *ngIf="!s.HasChildren"
            class="navigation-button"
            (click)="setIframeUri(s);onSideNavLinkClick(s.Id);"
            [routerLink]="s.State"
            routerLinkActive="active-link"
          >
            <i
              *ngIf="s.Icon"
              class="material-icons"
              >{{ s.Icon }}</i
            >
            {{ s.Value }}
          </a>

          <!--yes child-->
          <ng-container *ngIf="s.HasChildren">
            <div
              [routerLink]="s.State"
              routerLinkActive="active-nested-container"
              [class.expanded]="s.IsOpen"
            >
              <a
                (click)="toggleCollapsed(s)"
                class="navigation-button"
              >
                {{ s.Value }}
                <i
                  class="arrow material-icons md-18"
                  [ngClass]="{ arrowExpanded: s.IsOpen }"
                ></i>
              </a>
              <div [class.hide]="!s.IsOpen">
                <div *ngFor="let p of s.Children">
                  <a
                    class="nested-navigation-button"
                    (click)="setIframeUri(p);onSideNavLinkClick(p.Id)"
                    [routerLink]="p.State"
                    routerLinkActive="active-link"
                  >
                    {{ p.Value }}
                  </a>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </app-phx-accordion-group>
    </div>
  </app-phx-accordion>
  <app-todo-list [ngClass]="{ 'sidenav-hidden': selectedSideView !== TODO }"></app-todo-list>
</div>

<footer [class.collapsed]="isNavigationBarCollapsed">
  <div
    class="build-number"
    [class.collapsed]="isNavigationBarCollapsed"
  >
    {{ buildNumber }}
  </div>

  <button
    #toggleNavigation
    class="sidebar-toggle"
    [class.collapsed]="isNavigationBarCollapsed"
    (click)="toggleNavigationBar()"
    [appCollectClickDataAnalytics]="{
      action: 'Collapse navigation',
      feature: 'Side Navigation',
      type: 'Toggle Navigation'
    }"
  >
    <mat-icon> keyboard_double_arrow_left </mat-icon>
  </button>
</footer>

<app-mini-navigation
  *ngIf="isNavigationBarCollapsed && navigation?.NavigationItems"
  [navigationItems]="navigation.NavigationItems"
  (sidebarToggle)="toggleNavigationBar()"
  class="hidden-sm hidden-xs"
></app-mini-navigation>
