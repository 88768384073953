import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { IReportViewState } from '../model/ireport-view-state';
import { environment } from '../../../environments/environment';
import { IReportSecurityViewApiResponse } from '../../report-azsearch/model/report-security-view';

@Injectable()
export class ReportViewStateService {
  private reportEndpoint = environment.reportServiceApiEndpoint;

  constructor(private apiService: ApiService) { }

  saveReportViewState(componentName: string, reportViewState: IReportViewState, showSpinner = false) {
    return this.apiService.httpPostRequest<number>(`Report/reportViewState/${componentName}`, reportViewState, this.reportEndpoint, showSpinner);
  }

  getReportViewState(componentName: string, showSpinner = false) {
    return this.apiService.httpGetRequest<IReportViewState>(`Report/reportViewState/${componentName}`, this.reportEndpoint, showSpinner);
  }

  getReportSecurityViewFilterData() {
    return this.apiService.httpGetRequest<IReportSecurityViewApiResponse>(`reportSecurityView`, this.reportEndpoint, false);
  }
}
