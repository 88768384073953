import { AzSearchStore } from 'azsearchstore';
import { IExecutedSearchInfo, ISearchStateExtension, SearchDirection, SearchType } from './model/az-search-store-model-extensions';
import { Subject } from 'rxjs';
import { FacetTracker } from './helpers/facet-tracker';
import { SearchTimeTracker } from './helpers/search-time-tracker';
import { map, cloneDeep } from 'lodash';
import * as Store from 'azsearchstore/dist/store';

export class AzSearchStoreExtension extends AzSearchStore {
  private resultSearchTimeTracker = new SearchTimeTracker();
  private suggestionsSearchTimeTracker = new SearchTimeTracker();
  private globalFilterKeys = new Set<string>();
  private facetTracker = new FacetTracker();
  private searchInput = '';
  private suggestionInput = '';
  private canSearchWithNoFacetSelection = true;
  /**
   * Notifies anytime a search is executed.
   * ___
   *
   * **_Use this rather than subscribing on_ `store` _or using_ `subscribe()`_._**
   *
   * _`executedSearchInfo$` only notifies observers when a new search has been executed
   * (rather than anytime any property, such as input, changes, which is what `store`
   * and `subscribe()` do)._
   */
  executedSearchInfo$ = new Subject<IExecutedSearchInfo>();
  lastSearchType: SearchType;

  set allowSearchWithNoFacetSelection(setting: boolean) {
    this.canSearchWithNoFacetSelection = setting;
  }

  /**
   * Set input to be used for a **results** search.
   */
  setInput(input: string) {
    this.searchInput = input;

    if (!this.includesQuotes(input)) {
      input = input.split(' ').map(word => {
        word = word.trim();
        if (word.endsWith('*')) {
          return word;
        }
        if (word.includes('@') || word.includes('-')) {
          return `"${word}"`;
        }
        // Empty word is a legit search use to retrieve everything
        return !word ? word : `${word}*`;
      }).join(' ');
    }
    super.setInput(input);
  }

  /**
   * Set input to be used for a **suggestions** search.
   */
  setSuggestionInput(suggestionInput: string) {
    this.suggestionInput = suggestionInput;
    super.setInput(suggestionInput);
  }

  /**
   * Searches and clears all facets. Does not remove global filters. Additionally, goes to the first page.
   */
  search(top = 25): Promise<void> {
    // Override the existing method to always clear the facets before searching.
    this.setInput(this.searchInput);
    this.resultSearchTimeTracker.markSearchStart();

    this.clearFacetsSelections();
    this.updateSearchParameters({
      top,
      orderby: '', // Remove sorting
      skip: 0 // Go back to the first page
    });
    return super.search().then(() => {
      this.lastSearchType = SearchType.SearchAndGetNewFacets;
      this.executedSearchInfo$.next({ searchType: this.lastSearchType });
    });
  }

  /**
   * Searches and clears all facets. Does not remove global filters. Additionally, goes to the first page.
   * ___
   * Same as `search()`. This method name provides greater clarity.
   */
  searchAndResetFacets(top = 25): Promise<void> {
    return this.search(top);
  }

  searchFromFacetAction(): Promise<void> {
    this.setInput(this.searchInput);
    const state = super.getState();
    const top = state.parameters.searchParameters.top;
    const shouldFetchNoResults = !this.canSearchWithNoFacetSelection && this.checkHasAnyFacetsSelected(state);
    /** There could be a case, when we need to fetch all the facets, but not the results, i.e. the mail campaign.
     * Mail Campaign does recurring requests, until the total is reached, i.e. with limit of 1000 and total of 3000,
     * it would perform 3 requests to grab all results. To prevent it from bombarding the backend with requests before
     * the user had a chance to select search parameters, this check was introduced.
     * The top is set to 0 to fetch only the facets with counts, with the original top parameter restored, once
     * the request has been completed. */
    if (shouldFetchNoResults){
      this.updateSearchParameters({
        top: 0
      });
    }

    this.setPage(1);
    this.resultSearchTimeTracker.markSearchStart();
    return super.searchFromFacetAction().then(() => {
      this.lastSearchType = SearchType.SearchAndPreserveFacets;
      this.executedSearchInfo$.next({ searchType: this.lastSearchType });
      /** If top was set to 0 prior to the request, we need to restore the original value to it. */
      if (shouldFetchNoResults){
        this.updateSearchParameters({
          top
        });
      }
    });
  }

  searchAndPreserveFacets(): Promise<void> {
    return this.searchFromFacetAction();
  }

  searchFromSortOrPageChangeAction(): Promise<void> {
    this.setInput(this.searchInput);
    this.resultSearchTimeTracker.markSearchStart();
    return super.searchFromFacetAction().then(() => {
      this.lastSearchType = SearchType.SortOrPageChange;
      this.executedSearchInfo$.next({ searchType: this.lastSearchType });
    });
  }

  suggest(): Promise<void> {
    this.setSuggestionInput(this.suggestionInput);
    this.suggestionsSearchTimeTracker.markSearchStart();
    return super.suggest();
  }

  getState(): ISearchStateExtension {
    const searchState = cloneDeep(super.getState()) as ISearchStateExtension;
    searchState.parameters.input = this.searchInput;
    searchState.parameters.suggestionInput = this.suggestionInput;
    searchState.facets.numActiveFacets = this.facetTracker.getNumActiveFacets();
    searchState.results.searchTime = this.resultSearchTimeTracker.getSearchTime(searchState.results);
    searchState.suggestions.searchTime = this.suggestionsSearchTimeTracker.getSearchTime(searchState.suggestions);
    return searchState;
  }

  addRangeFacet(fieldName: string, dataType: Store.RangeDataType, min: number | Date, max: number | Date): void {
    super.addRangeFacet(fieldName, dataType, min, max);
    this.facetTracker.addRangeFacet(fieldName, min, max);
  }

  addCheckboxFacet(fieldName: string, dataType: Store.CheckboxDataType, count?: number): void {
    super.addCheckboxFacet(fieldName, dataType, count);
    this.facetTracker.addCheckboxFacet(fieldName);
  }

  toggleCheckboxFacet(fieldName: string, value: string | number): void {
    super.toggleCheckboxFacet(fieldName, value);
    this.facetTracker.toggleCheckboxFacet(fieldName, value);
  }

  setFacetRange(fieldName: string, lowerBound: number | Date, upperBound: number | Date): void {
    super.setFacetRange(fieldName, lowerBound, upperBound);
    this.facetTracker.setFacetRange(fieldName, lowerBound, upperBound);
  }

  setFacetRangeAndSearch(fieldName: string, lowerBound: number | Date, upperBound: number | Date): Promise<void> {
    this.setFacetRange(fieldName, lowerBound, upperBound);
    return this.searchAndPreserveFacets();
  }

  toggleCheckboxFacetAndSearch(fieldName: string, value: string | number): Promise<void> {
    this.toggleCheckboxFacet(fieldName, value);
    return this.searchAndPreserveFacets();
  }

  clearFacetsSelections(): void {
    super.clearFacetsSelections();
    this.facetTracker.clearFacetsSelections();
  }

  setPageAndSearch(page: number): Promise<void> {
    this.setPage(page);
    return this.searchFromSortOrPageChangeAction();
  }

  sort(fieldName: string, direction: SearchDirection): Promise<void> {
    let searchField = fieldName;
    switch (direction) {
      case SearchDirection.Asc:
        searchField += ' asc';
        break;
      case SearchDirection.Desc:
        searchField += ' desc';
        break;
    }

    this.updateSearchParameters({ orderby: searchField });
    return this.searchFromSortOrPageChangeAction();
  }

  setGlobalFilter(key: string, val: string) {
    // TODO Remove when the IsDeleted filer moves to proxy.
    if (key === 'IsDeleted') {
      return;
    }
    if (val.trim() !== '') {
      this.globalFilterKeys.add(key);
    } else {
      this.globalFilterKeys.delete(key);
    }
    super.setGlobalFilter(key, val);
  }

  setEqualsGlobalFilter(key: string, val: string | number | boolean | Date) {
    // TODO Remove when the IsDeleted filer moves to proxy.
    if (key === 'IsDeleted') {
      return;
    }
    const stringVal = this.getValForFilter(val);
    if (!stringVal) {
      return;
    }
    this.setGlobalFilter(key, `${key} eq ${stringVal}`);
  }

  setIncludedInFilter(collectionKey: string, valIncluded: string | number | boolean | Date) {
    // TODO Remove when the IsDeleted filer moves to proxy.
    if (collectionKey === 'IsDeleted') {
      return;
    }
    const stringVal = this.getValForFilter(valIncluded);
    if (!stringVal) {
      return;
    }
    this.setGlobalFilter(collectionKey, `${collectionKey}/any(v: v eq ${stringVal})`);
  }

  removeGlobalFilter(key) {
    // TODO Remove when the IsDeleted filer moves to proxy.
    if (key === 'IsDeleted') {
      return;
    }
    this.setGlobalFilter(key, '');
  }

  clearAllGlobalFilters() {
    map(Array.from(this.globalFilterKeys.values()), filterKey => this.removeGlobalFilter(filterKey));
  }

  private getValForFilter(val: string | number | boolean | Date): string {
    if (typeof val === 'string') {
      return `'${val}'`; // Need to add quotes on both ends to denote as string.
    } else {
      if (val !== null && val !== undefined) {
        return val.toString();
      }
    }
  }

  private includesQuotes(input: string) {
    return input.includes('\'') || input.includes('`') || input.includes('"');
  }

  private checkHasAnyFacetsSelected(state: Store.SearchState): boolean {
    return Object.values(state.facets.facets)
      .every(
        facet =>
        // A checkbox facet or a dropdown without values selected
          (facet.type === 'CheckboxFacet' && Object.values(facet.values).every(c => !c.selected))
        ||
        // A range facet, without a value entered into it
          facet.type === 'RangeFacet' 
          && facet.min.valueOf() === facet.filterLowerBound.valueOf()
          && facet.max.valueOf() === facet.filterUpperBound.valueOf()
      );
  }
}
