import { Component, Input } from '@angular/core';
import { PhxConstants } from '../../index';

@Component({
  selector: 'app-time-sheet-rate-label',
  templateUrl: './time-sheet-rate-label.component.html',
  styleUrls: ['./time-sheet-rate-label.component.less']
})
export class TimeSheetRateLabelComponent {
  @Input() labelText: string;
  @Input() rateTypeId?: number;

  readonly rateIdIdToClassMap = new Map<PhxConstants.RateType, string>([
    [PhxConstants.RateType.Primary, 'primary'],
    [PhxConstants.RateType.Overtime, 'overtime'],
    [PhxConstants.RateType.DoubleTime, 'overtime']
  ]);
}
