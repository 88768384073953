import { Injectable, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { merge, Observable, Subject, Subscription } from 'rxjs';

export enum PhxEvents {
  ProfileChanged = 'ProfileChanged', // Used on firing the user profile change
  UserBehavior = 'UserBehavior'
}

@Injectable()
export class EventService implements OnDestroy {
  private subjects: { [eventName: string]: Subject<any>; };
  private defaultTakeUntil: Subject<void> = new Subject();

  constructor() {
    this.subjects = {};
  }

  ngOnDestroy(): void {
    this.defaultTakeUntil.next();
    this.defaultTakeUntil.complete();
    this.subjects = null;
  }

  subscribe(eventName: string, handler: (payload?: any) => void, takeUntilOverride?: Observable<any>): Subscription {
    const fnName = this.createName(eventName);
    if (!this.subjects[fnName]) {
      this.subjects[fnName] = new Subject();
    }
    return this.subjects[fnName]
      .asObservable()
      .pipe(takeUntil(takeUntilOverride ? merge(takeUntilOverride, this.defaultTakeUntil) : this.defaultTakeUntil))
      .subscribe(handler);
  }

  trigger(eventName: string, payload?: any): void {
    const fnName = this.createName(eventName);
    if (!this.subjects[fnName]) {
      this.subjects[fnName] = new Subject();
    }
    this.subjects[fnName].next(payload);
  }

  private createName(name: string): string {
    return `$ ${name}`;
  }
}
