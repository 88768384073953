<app-headstone
  subPath="New Mail Campaign"
  i18n-subPath="@@mailCampaign.newMailCampaign"
  [entityId]="mailCampaignId"
  [doShowActions]="!hasSentLaunchedTheMailCampaign"
  (saveMailCampaign)="saveMailCampaign()"
>
</app-headstone>

<mat-stepper
  #stepper
  [linear]="true"
  (selectionChange)="stepChange($event)"
>
  <mat-step [completed]="steps.one.isComplete">
    <app-stepper-header
      [selectedIndex]="selectedIndex"
      [wizardSteps]="steps"
    >
      <button
        left
        [routerLink]="mailCampaignPaths.history.getRouterLink()"
        class="btn btn-default icon-button"
      >
        <mat-icon>history</mat-icon>
        <span i18n="@@common.button.history">History</span>
      </button>
      <button
        right
        matStepperNext
        [disabled]="!steps.one.isComplete"
        class="btn btn-primary icon-button"
      >
        <mat-icon>mail</mat-icon>
        <span i18n="@@common.button.next">Next</span>
      </button>
    </app-stepper-header>
    <app-select-recipients
      (recipientsSelected)="selectRecipients($event)"
      [initialRecipients]="initialRecipients"
    ></app-select-recipients>
  </mat-step>
  <mat-step [completed]="steps.two.isComplete">
    <app-stepper-header
      [selectedIndex]="selectedIndex"
      [wizardSteps]="steps"
    >
      <button
        left
        matStepperPrevious
        class="btn btn-default icon-button"
      >
        <mat-icon>people</mat-icon>
        <span i18n="@@common.button.previous">Previous</span>
      </button>

      <button
        right
        matStepperNext
        [disabled]="!steps.two.isComplete"
        class="btn btn-primary icon-button"
      >
        <mat-icon>schedule_send</mat-icon>
        <span i18n="@@common.button.next">Next</span>
      </button>
    </app-stepper-header>
    <app-email
      #emailStep
      (emailTemplateChanged)="onEmailChange($event)"
      [formValue]="initialEmailFormValues"
      [mailCampaignId]="mailCampaignId"
      class="margin-top-30"
    ></app-email>
  </mat-step>
  <mat-step [completed]="steps.three.isComplete">
    <app-stepper-header
      [selectedIndex]="selectedIndex"
      [wizardSteps]="steps"
    >
      <button
        left
        matStepperPrevious
        class="btn btn-default icon-button"
      >
        <mat-icon>mail</mat-icon>
        <span i18n="@@common.button.previous">Previous</span>
      </button>

      <button
        right
        (click)="sendEmail()"
        class="btn btn-primary icon-button"
      >
        <mat-icon>send</mat-icon>
        <span i18n="@@common.button.send">Send</span>
      </button>
    </app-stepper-header>

    <div class="row">
      <div class="col-md-8"></div>
      <div class="form-horizontal col-md-4">
        <phx-form-control
          [control]="scheduleTimeControl"
          [layoutType]="phxFormControlLayoutType.Responsive"
          i18n-labelText="@@mailCampaign.schedule"
          labelText="Schedule?"
          name="schedule"
        >
          <app-phx-date-box
            [displayFormat]="dateFormat.MMM_dd_yyyy_HH_mm"
            [formControl]="scheduleTimeControl"
            [min]="minDate"
            [showClearButton]="true"
            type="datetime"
          ></app-phx-date-box>
        </phx-form-control>
      </div>
    </div>


    <app-email-preview
      *ngIf="mailCampaignId && steps.two.isComplete"
      [formValue]="emailStep.emailTemplateForm.getRawValue()"
      [recipients]="recipients"
      [mailCampaignId]="mailCampaignId"
    ></app-email-preview>
  </mat-step>

  <mat-step>
    <app-mail-sent></app-mail-sent>
  </mat-step>
</mat-stepper>
