import { ISearchResultsExtension, ISearchSuggestionsExtension } from '../model/az-search-store-model-extensions';

export class SearchTimeTracker {
    private startTime: number;
    private lastSearchTime: number;

    markSearchStart() {
        this.startTime = Date.now();
    }

    getSearchTime(stateResults: ISearchResultsExtension | ISearchSuggestionsExtension): number {
        if (!stateResults.isFetching) {
            this.lastSearchTime = stateResults.lastUpdated - this.startTime;
        }
        return this.lastSearchTime;
    }
}
