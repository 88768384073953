import { Injectable } from '@angular/core';
import { lastValueFrom, Observable, Subject } from 'rxjs';
import { ApiService } from '../common';
import { AuthService } from '../common/services/auth.service';
import { Navigation, UserProfileNavigationHistory } from './model';

@Injectable()
export class SidenavService {
  constructor(private apiService: ApiService, private authService: AuthService) {
    this.authService.subscribeProfileChanges(() => this.updateNavigationData());
  }

  private navigation$: Subject<Navigation> = new Subject();

  getNavigationData(): Observable<Navigation> {
    this.updateNavigationData();
    return this.navigation$.asObservable();
  }

  async updateNavigationData() {
    try {
      const httpResponse = await lastValueFrom(this.apiService.query<Navigation>('Navigation'));
      this.navigation$.next(httpResponse);
    } catch (error) {
    }
  }

  public saveUserProfileNavigationHistory(navigationId: number, userProfileId: number) {
    // don't care if this results in error, just need a useless catch for promise rejection
    this.apiService.command('UserProfileNavigationHistorySave', {
      UserProfileId: userProfileId,
      NavigationId: navigationId
    }, false).catch(() => {});
  }

  public async getUserProfileNavigationHistoryLastNavigationId(userProfileId: number): Promise<UserProfileNavigationHistory> {
    return lastValueFrom(this.apiService.query<UserProfileNavigationHistory>('UserProfile/getUserProfileNavigationHistoryLastItemByUserProfileId/' + userProfileId, false));
  }
}
