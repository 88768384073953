import { takeUntil } from 'rxjs/operators';
import { Component } from '@angular/core';
import { NavigationService } from '../common';
import { PageTitleDetail } from '../common/model';
import { BaseComponentOnDestroy } from '../common/epics/base-component-on-destroy';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.less']
})
export class BreadcrumbsComponent extends BaseComponentOnDestroy {
  pageTitleDecorators: PageTitleDetail[] = [];

  constructor(private navigationService: NavigationService) {
    super();
    this.initNavigationPageTitleListener();
  }

  initNavigationPageTitleListener() {
    this.navigationService.pageTitleDecoratorsChanged.pipe(
      takeUntil(this.isDestroyed$))
      .subscribe((pageTitleDecorators) => {
        this.pageTitleDecorators = pageTitleDecorators;
      });
  }
}
