import { Store as AzSearchStore } from 'azsearchstore';
import { IFacetsConfig, ListViewConfig } from './global-search-facet';
import { AzSearchStoreExtension } from '../az-search-store-extension/az-search-store-extension';

export interface IGsConfiguration {
    config: AzSearchStore.Config;
    searchParameters: AzSearchStore.SearchParametersUpdate;
    suggestionParameters: AzSearchStore.SuggestionsParametersUpdate;
    facetsConfig: IFacetsConfig;
    listViewConfig: ListViewConfig;
}

export enum AzSearchStoreExtensionType {
    WorkOrder,
    Transaction,
    Payment
}

export interface IActiveGsAzSearchStoreExtensionInfo {
    AzSearchStoreExtension: AzSearchStoreExtension;
    Type: AzSearchStoreExtensionType;
}

export enum GlobalSearchFieldType {
    // eslint-disable-next-line id-blacklist
    Number,
    // eslint-disable-next-line id-blacklist
    String,
    Date,
    Collection
}
