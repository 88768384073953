<ng-container *ngIf="hasStateActions()">
    
    <div class="btn-toolbar link-bar" *ngIf="showDropdown()">
        <div class="btn-group">
            <div class="btn-wrapper">
                <button class="btn btn-clouds dropdown-toggle" type="button" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="true">
                    {{'common.generic.actions' | phxTranslate}}...
                    <span class="caret"></span>
                </button>
                <ul class="dropdown-menu bordered">
                    <ng-container *ngFor="let action of filteredStateActions">
                        <li *ngIf="!isActionHidden(action)" (click)="onClickAction(action)"
                            class="action-link" [class.disabled]="executing || isActionDisabled(action) || (action.disabled$ && (action.disabled$ | async) === true)"
                            [attr.data-action-name]="action.actionName">
                            <a> {{action.displayText}} </a>
                        </li>
                    </ng-container>
                </ul>
            </div>
        </div>
    </div>

    <div class="btn-toolbar button-bar" *ngIf="showButton() || showLargeButton()">
        <div class="btn-group">
            <ng-container *ngFor="let action of filteredStateActions">
                <button *ngIf="!isActionHidden(action)" type="button" class="btn btn-default action-button"
                    (click)="onClickAction(action)"
                    [disabled]="executing || isActionDisabled(action) || (action.disabled$ && (action.disabled$ | async) === true)"
                    [class.btn-primary]="action.primaryAction" [class.btn-danger]="action.dangerAction"
                    [class.btn-secondary]="action.secondaryAction" [class.btn-warning]="action.warningAction"
                    [class.btn-lg]="showLargeButton()" [attr.data-action-name]="action.actionName">
                    {{action.displayText}}
                </button>
            </ng-container>
        </div>
    </div>

    <div class="btn-group-xs" *ngIf="showSmallButton()">
        <ng-container *ngFor="let action of filteredStateActions">
            <button *ngIf="!isActionHidden(action)" type="button" class="btn btn-xs" (click)="onClickAction(action)"
                [disabled]="executing || isActionDisabled(action) || (action.disabled$ && (action.disabled$ | async) === true)"
                [class.btn-primary]="action.primaryAction" [class.btn-danger]="action.dangerAction"
                [class.btn-secondary]="action.secondaryAction" [class.btn-warning]="action.warningAction"
                [attr.data-action-name]="action.actionName">
                {{action.displayText}}
            </button>
        </ng-container>
    </div>

    <div class="btn-toolbar button-bar" *ngIf="showButtonWithElipsis()">
        <div class="btn-group">
            <ng-container *ngFor="let action of filteredStateActions">
                <button *ngIf="action.style && !isActionHidden(action)" type="button"
                    class="btn btn-default action-button" (click)="onClickAction(action)"
                    [disabled]="executing || isActionDisabled(action) || (action.disabled$ && (action.disabled$ | async) === true)"
                    [class.btn-primary]="action.primaryAction" [class.btn-danger]="action.dangerAction"
                    [class.btn-secondary]="action.secondaryAction" [class.btn-warning]="action.warningAction"
                    [attr.data-action-name]="action.actionName">
                    {{action.displayText}}
                </button>
            </ng-container>
            <div class="btn-group elipsis">
                <button class="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="true">
                    <i class="material-icons md-24">more_horiz</i>
                </button>
                <ul
                  class="dropdown-menu bordered"
                  [class.float-buttons-list-to-right]="floatButtonsListToRight"
                >
                    <ng-container *ngFor="let action of filteredStateActions">
                        <li *ngIf="!action.style && !isActionHidden(action)" (click)="onClickAction(action)"
                            class="action-link" [class.disabled]="executing || isActionDisabled(action) || (action.disabled$ && (action.disabled$ | async) === true)"
                            [attr.data-action-name]="action.actionName">
                            <a> {{action.displayText}} </a>
                        </li>
                    </ng-container>
                </ul>
            </div>
        </div>
    </div>
    
</ng-container>
