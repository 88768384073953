import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PhxSideBarService } from '../../services/phx-side-bar.service';
import { BaseComponentOnDestroy } from '../../epics/base-component-on-destroy';
import { filter, take, takeUntil } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { NoteService } from '../../services/note.service';
import { PhxNote } from '../../model/phx-note';
import { PhxConstants } from '../../PhoenixCommon.module';

@Component({
  selector: 'app-phx-panel-notes',
  templateUrl: './phx-panel-notes.component.html',
  styleUrls: ['./phx-panel-notes.component.less']
})
export class PhxPanelNotesComponent extends BaseComponentOnDestroy implements OnInit {

  entityTypeId: number;
  entityId: number;

  notes: Array<PhxNote>;
  noteCount: number;
  readonly dateColumnFormat = PhxConstants.DateFormat.mediumDate;

  constructor(
    private phxSidebarService: PhxSideBarService,
    private noteService: NoteService,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.initNoteServiceListener();
    this.initSidebarConfigListener();
  }

  toggleIsRead(note: PhxNote, isNoteRead: boolean) {
    const markReadCommand = {
      NoteId: note.Id,
      LastModifiedProfileId: note.LastModifiedByProfileId,
      IsRead: isNoteRead
    };
    this.noteService.markRead(markReadCommand)
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(() => this.noteService.loadNotes(this.entityTypeId, this.entityId, this.isDestroyed$, true));
  }

  private initNoteServiceListener() {
    this.noteService.notes$.pipe(
      filter(noteStore => !!noteStore),
      filter(() => !!this.entityId && !!this.entityTypeId),
      takeUntil(this.isDestroyed$)
    ).subscribe(noteStore => {
      noteStore = noteStore || [];
      const note = noteStore.find(n => +n.entityId === this.entityId && +n.entityTypeId === this.entityTypeId);
      this.notes = note ? note.notes : [];
      this.noteCount = note ? note.noteCount : 0;

      this.cdr.detectChanges();
    });
  }

  private initSidebarConfigListener() {
    forkJoin([
      this.phxSidebarService.entityIdChange$().pipe(take(1)),
      this.phxSidebarService.entityTypeIdChange$().pipe(take(1))
    ]).pipe(
      filter(([entityId, entityTypeId]) => !!entityId && !!entityTypeId),
      filter(([entityId, entityTypeId]) => entityId !== this.entityId || entityTypeId !== this.entityTypeId),
      takeUntil(this.isDestroyed$)
    ).subscribe(([entityId, entityTypeId]) => {
      this.entityId = +entityId;
      this.entityTypeId = entityTypeId;
      this.noteService.loadNotes(this.entityTypeId, this.entityId, this.isDestroyed$, true);
    });
  }
}
