import { TeamStatus } from '../../../team/model/team.enums';
import {PhxConstants, PhxDataTableState} from '../../../common/model';

export interface IApiUserResponse {
    userProfileId: number;
    firstName: string;
    lastName: string;
    fullName: string;
    preferredFirstName: string;
    preferredLastName: string;
    functionalRolesId: number[];
    primaryEmail: string;
}

export interface IApiTeamSummaryResponse {
    id: string;
    createdByProfileId: number;
    name: string;
    statusId: TeamStatus;
    adminCount: number;
    memberCount: number;
    expiryDate: string;
}

interface IApiReportShareBaseResponse {
    userProfileSearchStateId: number;
    userProfileIdOwner: number;
    isPublic: boolean;
    stateName: string;
    componentName: string;
    teamGuids: string[];
    userProfileIds: number[];
}

export interface IApiReportShareListResponse extends IApiReportShareBaseResponse {
    fullName: string;
}

export interface IApiReportShareDetailResponse extends IApiReportShareBaseResponse {
    state: string;
    stateDescription: string;
}

export interface IApiPostReportShareLean {
    userProfileSearchStateId: number;
    componentName: string;
    isPublic: boolean;
    teamGuids: string[];
    userProfileIds: number[];
}

export interface IApiPostReportShareDetail extends IApiPostReportShareLean {
    stateName: string;
    stateDescription: string;
    state: string;
}

export interface IReportShareBase {
    StateId: number;
    ComponentName: string;
    IsPublic: boolean;
    ShareeTeamIds: string[];
    ShareeUserIds: number[];
}

export type IReportShareSimpleSave = IReportShareBase;

export interface IReportShareDetailSave extends IReportShareSimpleSave {
    StateName: string;
    StateDescription: string;
    State: PhxDataTableState;
}

export interface IReportShare extends IReportShareBase {
    OwnerProfileId: number;
    OwnerFullName: string;
    StateId: number;
    IsPublic: boolean;
    StateName: string;
    ReportTypeId: PhxConstants.ReportType;
    ComponentName: string;
    ShareeTeamIds: string[];
    ShareeUserIds: number[];
    IsShared: boolean;
}

export interface IReportShareDetail extends IReportShare {
    State: PhxDataTableState;
    StateDescription: string;
}

export interface PhxDataTableUserProfile {
    Id: number;
    LastModifiedDatetime?: Date |string;
    ComponentName?: string;
    StateName?: string;
    StateDescription?: string;
    State?: PhxDataTableState;
    UserProfileId?: number;
    UserProfileFullName?: string;
    IsPublic?: boolean;
    ShareeUserIds?: number[];
    ShareeTeamIds?: string[];
}

export enum ReportShareeType {
    User,
    Team
}

export type IReportSharee = IReportUserSharee | IReportTeamSharee;

// export type IBaseReportShareeId = number | string;

interface IReportBaseSharee {
    ShareeType: ReportShareeType;
    Id: number | string;
    Name: string;
}

export interface IReportUserSharee extends IReportBaseSharee {
    ShareeType: ReportShareeType.User;
    Id: number;
    FunctionalRolesId: number[];
}

export interface IReportTeamSharee extends IReportBaseSharee {
    ShareeType: ReportShareeType.Team;
    Id: string;
    NumTeamMembers: number;
}

export interface IReportShareeData {
    List: IReportSharee[];
    Map: Map<number | string, IReportSharee>;
}
