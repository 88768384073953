<ng-template #phxDialogTemplate>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="header-title">{{ config?.HeaderTitle }}</h4>
        <button type="button" class="close" aria-label="Close" (click)="close()"><i class="material-icons">close</i></button>
      </div>
      <div class="modal-body">
        <div *ngIf="config?.ErrorMessage && config?.ErrorMessage?.showMessage()" class="alert alert-warning">
          <div class="row2">
            <div class="col-md-12 ">
              <span class="banner-box">
                <i class="alert-icon material-icons">warning</i>
              </span>
              <div class="banner-box">
                <strong class="status-title">Caution: </strong>
                <span> {{ config?.ErrorMessage?.Message }} </span>
              </div>
            </div>
          </div>
        </div>
        <p *ngIf="config?.BodyMessage">{{ config?.BodyMessage }}</p>
        <ng-content select="[custom-body]" *ngIf="config?.ShowCustomBody"></ng-content>
        <div class="form form-horizontal">
          <div class="row form-group" *ngIf="config?.ObjectDate">
            <label class="col-lg-3 control-label label-to-right" title="">
              {{ config?.ObjectDate?.Label }}
              <span *ngIf="config.ObjectDate.IsRequired" class="asterisk" [class.error]="!config.ObjectDate.Value">*</span>
            </label>
            <div class="col-lg-5">
              <app-phx-date-box
                class="col-lg-8"
                [(ngModel)]="config.ObjectDate.Value"
                type="date"
                [required]="config.ObjectDate.IsRequired"
                [max]="config.ObjectDate.Max"
                [min]="config.ObjectDate.Min"
                (isValidChange)="isValidChangeHandler($event)"
              ></app-phx-date-box>
            </div>
            <div class="col-lg-4"></div>
          </div>
          <div class="form-group " *ngIf="config?.ObjectDropdown">
            <label class="col-lg-3 control-label label-to-right" title="">
              {{ config?.ObjectDropdown?.Label }}
              <span *ngIf="config.ObjectDropdown.IsRequired" class="asterisk" [class.error]="!config.ObjectDropdown.Value">*</span>
            </label>
            <div class="col-lg-5">
              <app-phx-select-box
                [required]="config.ObjectDropdown.IsRequired"
                [items]="config.ObjectDropdown.DropDownList"
                valueField="id"
                [(ngModel)]="config.ObjectDropdown.Value"
                textField="text"
                searchable="true"
                placeholder="{{ config?.ObjectComment?.PlaceHolder ? config?.ObjectDropdown?.PlaceHolder : '-- Select One --' }}"
                class="col-xs-12"
              >
              </app-phx-select-box>
            </div>
          </div>
          <div class="row form-group" *ngIf="config?.ObjectComment">
            <label class="col-lg-3 control-label label-to-right" title="">
              {{ config?.ObjectComment?.Label }}
              <span
                *ngIf="config.ObjectComment.IsRequired"
                class="asterisk"
                [class.error]="config.ObjectComment.IsRequired && (!config.ObjectComment.Value || config.ObjectComment.Value.length < config.ObjectComment.LengthMin || config.ObjectComment.Value.length > config.ObjectComment.LengthMax)"
                >*</span
              >
            </label>
            <div [ngClass]="config?.ObjectComment?.Label ? 'col-lg-9' : 'col-lg-12'">
              <div class="col-lg-12">
                <textarea
                  style="width:100%;height:100px;"
                  name="ObjectComment"
                  maxlength="{{ config?.ObjectComment?.LengthMax }}"
                  minlength="{{ config?.ObjectComment?.LengthMin }}"
                  required="config.ObjectComment.isRequired"
                  [(ngModel)]="config.ObjectComment.Value"
                  placeholder="{{ config?.ObjectComment?.PlaceHolder }}"
                ></textarea>
                <span class="help-block">{{ config?.ObjectComment?.HelpBlock }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn"
          *ngFor="let btn of config?.Buttons | orderBy: 'SortOrder'"
          [ngClass]="btn.Class"
          (click)="onButtonClick(btn)"
          [disabled]="
            btn?.CheckValidation &&
            ((config?.ObjectDate && (!isValidDate || (config?.ObjectDate?.IsRequired && !config?.ObjectDate.Value))) ||
              (config?.ObjectDropdown && config?.ObjectDropdown?.IsRequired && !config?.ObjectDropdown.Value) ||
              (!config?.ObjectComment && config?.ObjectComment?.IsRequired && (config?.ObjectComment?.Value?.length < config.ObjectComment.LengthMin || config?.ObjectComment?.Value?.length > config.ObjectComment.LengthMax)))
          "
        >
          {{ btn.Name }}
        </button>
      </div>
    </div>
  </div>
</ng-template>
