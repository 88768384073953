import { Directive, ElementRef, Input, OnInit, Renderer2, RendererStyleFlags2 } from '@angular/core';
import { animate, AnimationBuilder, AnimationPlayer, style } from '@angular/animations';

@Directive({
  selector: '[appCollapse]'
})
export class CollapseDirective implements OnInit {
  @Input('appCollapse')
  public set collapse(isCollapsed: boolean) {
    this.toggleCollapsible(!isCollapsed);
  }

  @Input() isAnimated = false;

  private player: AnimationPlayer;
  private removeOverflowTimeout: ReturnType<typeof setTimeout>;

  constructor(private el: ElementRef, private renderer: Renderer2, private builder: AnimationBuilder) {}

  ngOnInit(): void {
    this.renderer.setStyle(this.el.nativeElement, 'overflow', 'hidden');
  }

  private toggleCollapsible(isOpen: boolean) {
    if (isOpen) {
      if (this.isAnimated) {
        this.renderer.setStyle(this.el.nativeElement, 'height', 'auto');
      }
    } else {
      this.renderer.setStyle(this.el.nativeElement, 'overflow', 'hidden');
      // The height is set only for non-animated element, because Angular animations cannot override this value.
      if (!this.isAnimated) {
        this.renderer.setStyle(this.el.nativeElement, 'height', '0');
      }
    }

    if (this.isAnimated) {
      this.toggleWithAnimation(isOpen);
    } else {
      this.toggleWithoutAnimation(isOpen);
    }
  }

  private toggleWithoutAnimation(isOpen: boolean): void {
    if (isOpen) {
      this.renderer.removeStyle(this.el.nativeElement, 'overflow');
      this.renderer.setStyle(this.el.nativeElement, 'height', 'auto');
    } else {
      this.renderer.setStyle(this.el.nativeElement, 'height', '0');
    }
  }

  private toggleWithAnimation(isOpen: boolean): void {
    const animationDuration = 300;
    const animationFactory = this.builder.build([style({ height: isOpen ? '0' : '*' }), animate(`${animationDuration}ms ease-in-out`, style({ height: isOpen ? '*' : '0' }))]);

    if (this.player) {
      this.player.destroy();
    }

    this.player = animationFactory.create(this.el.nativeElement);
    this.player.play();

    /** Wait for the animation to finish and hide overflow, hiding it too early will make content visible overflowing */
    if (isOpen) {
      this.removeOverflowTimeout = setTimeout(() => {
        this.renderer.removeStyle(this.el.nativeElement, 'overflow');
        /** Setting height to auto 'important' is essential for cases when toggled content can change its height,
         * i.e. when nested elements are not of fixed height, nested accordions for example.
         * If set without the important flag, the set height of the opened element will be limited to the exact value
         * set at the end of the opening animation, despite the element having height auto set inline. */
        this.renderer.setStyle(this.el.nativeElement, 'height', 'auto', RendererStyleFlags2.Important);
      }, animationDuration);
    } else {
      this.renderer.removeStyle(this.el.nativeElement, 'height');
      clearTimeout(this.removeOverflowTimeout);
    }
  }
}
