import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HeaderVersionHeightService {
  private height$ = new Subject<number>();

  public currentHeight$ = this.height$.asObservable().pipe(distinctUntilChanged());

  public set height(height: number) {
    this.height$.next(height);
  }
}
