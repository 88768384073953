export const AnalyticsFeatures = {
  Bookmarks: 'Bookmarks',
  ComplianceDocuments: 'Compliance Documents',
  CompliancyChecklist: 'Compliancy checklist',
  IntelligentValidation: 'Intelligent Validation',
  RealTimeUserBehavior: 'Real time user behavior',
  Report: 'Report',
  SideNavigation: 'Side Navigation',
  Sidebar: 'Sidebar',
  Timesheets: 'Timesheets',
  Todo: 'Todo',
  VersionComparison: 'Version Comparison',
  WorkOrder: 'Work Order',
  RecordOfEmploymentReport: 'Record of Employment Report'
} as const;
