import { environment } from '../../environments/environment';

export const appInitializer = () => {
  return () => {
    loadGoogleMaps();
  };
};

const loadGoogleMaps = (): void => {
  createScriptTag().src = `https://maps.googleapis.com/maps/api/js?key=${environment.googleMapsKey}&libraries=places&language=en`;
};

const createScriptTag = (): HTMLScriptElement => {
  const el = document.createElement('script');
  document.head.appendChild(el);
  return el;
};
