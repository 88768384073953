export const PhoenixCommonModuleResourceKeys = {
  generic: {
    /**
     * System
     */
    system: 'common.generic.system',
    /**
     * Search
     */
    search: 'common.generic.search',
    /**
     * Select
     */
    select: 'common.generic.select',
    /**
     * An error occurred.
     */
    genericErrorMessage: 'common.generic.genericErrorMessage',
    /**
     * N/A
     */
    notApplicable: 'common.generic.notApplicable',
    /**
     * Decline
     */
    decline: 'common.generic.decline',
    /**
     * Details
     */
    details: 'common.generic.details',
    /**
     * Highlight
     */
    highlight: 'common.generic.highlight',
    /**
     * Discard
     */
    discard: 'common.generic.discard',
    /**
     * Something unexpected happened here. Please copy the error below and pass it along to your representative so that we can have a look.
     */
    handlerExecuteExceptionMessage: 'common.generic.handlerExecuteExceptionMessage',
    /**
     * Loading History...
     */
    loadingHistory: 'common.generic.loadingHistory',
    /**
     * Submit
     */
    submit: 'common.generic.submit',
    /**
     * Leave
     */
    leave: 'common.generic.leave',
    /**
     * Hr
     */
    hourShort: 'common.generic.hourShort',
    /**
     * Confirm
     */
    confirm: 'common.generic.confirm',
    /**
     * Continue
     */
    continu: 'common.generic.continu',
    /**
     * Your submission has {0} Validation Message
     */
    oneValidationErrorMessage: 'common.generic.oneValidationErrorMessage',
    /**
     * Min
     */
    minuteShort: 'common.generic.minuteShort',
    /**
     * Your submission has {0} Validation Messages
     */
    multipleValidationErrorMessage: 'common.generic.multipleValidationErrorMessage',
    /**
     * Inactive
     */
    inactive: 'common.generic.inactive',
    /**
     * Oops!
     */
    handlerExecuteExceptionTitle: 'common.generic.handlerExecuteExceptionTitle',
    /**
     * ID
     */
    id: 'common.generic.id',
    /**
     * No
     */
    no: 'common.generic.no',
    /**
     * Error
     */
    error: 'common.generic.error',
    /**
     * Clear
     */
    clear: 'common.generic.clear',
    /**
     * Clear All
     */
    clearAll: 'common.generic.clearAll',
    /**
     * Confirm Clear
     */
    confirmClear: 'common.generic.confirmClear',
    /**
     * Total
     */
    total: 'common.generic.total',
    /**
     * Print
     */
    print: 'common.generic.print',
    /**
     * Day
     */
    day: 'common.generic.day',
    /**
     * New
     */
    new: 'common.generic.new',
    /**
     * Yes
     */
    yes: 'common.generic.yes',
    /**
     * Reset
     */
    reset: 'common.generic.reset',
    /**
     * Responsibility
     */
    responsibility: 'common.generic.responsibility',
    /**
     * Document
     */
    document: 'common.generic.document',
    /**
     * Quick Fill
     */
    quickFill: 'common.generic.quickFill',
    /**
     * Actions
     */
    actions: 'common.generic.actions',
    /**
     * Information
     */
    information: 'common.generic.information',
    /**
     * Heads Up
     */
    headsUp: 'common.generic.headsUp',
    /**
     * History
     */
    history: 'common.generic.history',
    /**
     * Files
     */
    files: 'common.generic.files',
    /**
     * Save and New
     */
    saveAndNew: 'common.generic.saveAndNew',
    /**
     * Approve
     */
    approve: 'common.generic.approve',
    /**
     * Day
     */
    dayShort: 'common.generic.dayShort',
    /**
     * Workflow is Running
     */
    workflowRunningMessage: 'common.generic.workflowRunningMessage',
    /**
     * Active
     */
    active: 'common.generic.active',
    /**
     * Days
     */
    daysShort: 'common.generic.daysShort',
    /**
     * Replace
     */
    replace: 'common.generic.replace',
    /**
     * Minute
     */
    minute: 'common.generic.minute',
    /**
     * Cancel
     */
    cancel: 'common.generic.cancel',
    /**
     * Create
     */
    create: 'common.generic.create',
    /**
     * Quick Fill
     */
    quickFillBadge: 'common.generic.quickFillBadge',
    /**
     * Mins
     */
    minutesShort: 'common.generic.minutesShort',
    /**
     * Warning
     */
    warning: 'common.generic.warning',
    /**
     * Pre-fill
     */
    prefill: 'common.generic.prefill',
    /**
     * People
     */
    people: 'common.generic.people',
    /**
     * Summary
     */
    summary: 'common.generic.summary',
    /**
     * Edit
     */
    edit: 'common.generic.edit',
    /**
     * Less
     */
    less: 'common.generic.less',
    /**
     * More
     */
    more: 'common.generic.more',
    /**
     * Hour
     */
    hour: 'common.generic.hour',
    /**
     * Okay
     */
    okay: 'common.generic.okay',
    /**
     * View
     */
    view: 'common.generic.view',
    /**
     * Save
     */
    save: 'common.generic.save',
    /**
     * Please wait ...
     */
    loadingText: 'common.generic.loadingText',
    /**
     * Remove
     */
    remove: 'common.generic.remove',
    /**
     * Status
     */
    status: 'common.generic.status',
    /**
     * Hrs
     */
    hoursShort: 'common.generic.hoursShort',
    /**
     * Recall
     */
    recall: 'common.generic.recall',
    /**
     * Exit Highlight
     */
    exitHighlight: 'common.generic.exitHighlight',
    /**
     * For
     */
    for: 'common.generic.for',
    undo: 'common.generic.undo',
    goBack: 'common.generic.goBack',
    suggested: 'common.generic.suggested',
    add: 'common.generic.add',
    caution: 'common.generic.caution',
    /**
     * Week {0}
     */
    weekWithPlaceholder: 'common.generic.weekWithPlaceholder',
    words: 'common.generic.words',
    time: 'common.generic.time',
    weekend: 'common.generic.weekend',
    refresh: 'common.generic.refresh',
    downloads: 'common.generic.downloads'
  },
  phxDialogComment: {
    title: 'common.phxDialogComment.title',
    helpblock: 'common.phxDialogComment.helpblock',
    saveBtn: 'common.phxDialogComment.saveBtn',
    cancelBtn: 'common.phxDialogComment.cancelBtn'
  },
  phxWorkflowEventHistory: {
    actionColumnHeader: 'common.phxWorkflowEventHistory.actionColumnHeader',
    startedColumnHeader: 'common.phxWorkflowEventHistory.startedColumnHeader',
    completedColumnHeader: 'common.phxWorkflowEventHistory.completedColumnHeader',
    approverColumnHeader: 'common.phxWorkflowEventHistory.approverColumnHeader',
    taskColumnHeader: 'common.phxWorkflowEventHistory.taskColumnHeader',
    assignedOnColumnHeader: 'common.phxWorkflowEventHistory.assignedOnColumnHeader',
    assignedToColumnHeader: 'common.phxWorkflowEventHistory.assignedToColumnHeader',
    completedOnColumnHeader: 'common.phxWorkflowEventHistory.completedOnColumnHeader',
    completedByColumnHeader: 'common.phxWorkflowEventHistory.completedByColumnHeader',
    notesColumnHeader: 'common.phxWorkflowEventHistory.notesColumnHeader'
  },
  customField: {
    requiredValidationMessage: 'common.customField.requiredValidationMessage',
    invalidValidationMessage: 'common.customField.invalidValidationMessage',
    uniqueValidationMessage: 'common.customField.uniqueValidationMessage',
    rangeValidationMessage: 'common.customField.rangeValidationMessage',
    lengthValidationMessage: 'common.customField.lengthValidationMessage',
    cannotExceedValidationMessage: 'common.customField.cannotExceedValidationMessage',
    atLeastValidationMessage: 'common.customField.atLeastValidationMessage'
  },
  changeHistory: {
    fieldNameHeader: 'common.changeHistory.fieldNameHeader',
    newValueHeader: 'common.changeHistory.newValueHeader',
    numberOfChangesHeader: 'common.changeHistory.numberOfChangesHeader',
    oldValueHeader: 'common.changeHistory.oldValueHeader',
    updateDateHeader: 'common.changeHistory.updateDateHeader',
    updatedByHeader: 'common.changeHistory.updatedByHeader'
  },
  phxWorkflowButtons: {
    actions: 'common.phxWorkflowButtons.actions',
    cancel: 'common.phxWorkflowButtons.cancel',
    declineHelpblock: 'common.phxWorkflowButtons.declineHelpblock',
    declineReasonLabel: 'common.phxWorkflowButtons.declineReasonLabel'
  },
  batch: {
    success: 'common.batch.success'
  },
  phxDataTable: {
    saveViewBtn: 'common.phxDataTable.saveViewBtn',
    resetFilterBtn: 'common.phxDataTable.resetFilterBtn',
    share: 'common.phxDataTable.share',
  }
};
