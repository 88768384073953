<div
  appResizable
  [doResize]="isResizable"
  class="phx-slider"
  [class.out]="!showSlider"
  [class.in]="showSlider"
  [class.full-width]="fullScreen"
>
  <ng-content></ng-content>
</div>
