import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DownloadCenterButtonService } from '../../services/download-center-button.service';
import { BaseComponentOnDestroy } from '../../../../common/epics/base-component-on-destroy';
import { takeUntil } from 'rxjs/operators';
import { PhoenixCommonModuleResourceKeys } from '../../../../common/PhoenixCommonModule.resource-keys';

@Component({
  selector: 'app-download-center-button',
  templateUrl: './download-center-button.component.html',
  styleUrls: ['./download-center-button.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DownloadCenterButtonComponent extends BaseComponentOnDestroy implements OnInit {
  currentUrl = '';
  showRedDot = false;
  phoenixCommonModuleResourceKeys = PhoenixCommonModuleResourceKeys;

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private cdr: ChangeDetectorRef,
              private downloadCenterButtonService: DownloadCenterButtonService) {
    super();
  }

  ngOnInit() {
    this.activatedRoute.url
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(() => {
        this.currentUrl = this.router.url;
        if (this.currentUrl === '/next/download-center') {
          this.showRedDot = false;
        }
        this.cdr.detectChanges();
      });

    this.downloadCenterButtonService.notifyDownloadsChangedSubject$
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(() => this.notifyDownloadsChanged());
  }

  gotToDownloadCenter() {
    this.router.navigate(['next/download-center']);
  }

  notifyDownloadsChanged() {
    if (this.currentUrl !== '/next/download-center') {
      this.showRedDot = true;
      this.cdr.detectChanges();
    }
  }

}
