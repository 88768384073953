import { Routes } from '@angular/router';

import { CampaignViewComponent } from '../components/campaign-view/campaign-view.component';
import { HistoryComponent } from '../components/history/history.component';
import { MODULE_ROUTES } from '../../common/constants/module-routes.const';
import { MailStepperComponent } from '../components/mail-stepper/mail-stepper.component';
import { addFlexBackofficeSuffix } from '../../common/utility/add-flexbackoffice-suffix.util';
import { canEditCampaignGuard } from '../guards/can-edit-campaign/can-edit-campaign.guard';

export const MAIL_CAMPAIGN_PATHS = {
  modulePath: 'mail-campaign',
  getDefaultRoute: (): string => MAIL_CAMPAIGN_PATHS.history.path,
  getFullPathToModule: (): string => `/${MODULE_ROUTES.MAIN}/${MAIL_CAMPAIGN_PATHS.modulePath}`,
  getRoutes: (): Routes => [
    MAIL_CAMPAIGN_PATHS.history,
    MAIL_CAMPAIGN_PATHS.viewCampaign,
    MAIL_CAMPAIGN_PATHS.newMailCampaign,
    {
      path: '**',
      redirectTo: MAIL_CAMPAIGN_PATHS.getDefaultRoute()
    }
  ],

  history: {
    path: '',
    component: HistoryComponent,
    title: addFlexBackofficeSuffix($localize`:@@mailCampaign.history:Mail Campaigns History`),
    getRouterLink: (): string => `${MAIL_CAMPAIGN_PATHS.getFullPathToModule()}/${MAIL_CAMPAIGN_PATHS.history.path}`
  } as const,

  viewCampaign: {
    campaignParamName: 'id',
    component: CampaignViewComponent,
    path: 'campaign/:id',
    title: addFlexBackofficeSuffix($localize`:@@mailCampaign.viewMailCampaign:View Mail Campaign`),
    getRouterLink: (id: number): string =>
      `${MAIL_CAMPAIGN_PATHS.getFullPathToModule()}/` + MAIL_CAMPAIGN_PATHS.viewCampaign.path.replace(`:${MAIL_CAMPAIGN_PATHS.viewCampaign.campaignParamName}`, id.toString())
  } as const,

  newMailCampaign: {
    campaignParamName: 'id',
    path: 'new-mail-campaign/:id',
    component: MailStepperComponent,
    title: addFlexBackofficeSuffix($localize`:@@mailCampaign.newMailCampaign:New Mail Campaign`),
    getRouterLink: (id: number): string =>
      `${MAIL_CAMPAIGN_PATHS.getFullPathToModule()}/` + MAIL_CAMPAIGN_PATHS.newMailCampaign.path.replace(`:${MAIL_CAMPAIGN_PATHS.newMailCampaign.campaignParamName}`, id.toString()),
    canMatch: [canEditCampaignGuard]
  }
};
