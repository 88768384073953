import { AfterViewInit, Directive, ElementRef, HostListener, Input, OnDestroy, Renderer2 } from '@angular/core';

import { GoogleAnalyticsService } from '../../services/google-analytics/google-analytics.service';
import { ClickAnalyticsData } from '../../services/google-analytics/models/click-analytics-data.model';

@Directive({
  selector: '[appResizable]'
})
export class ResizableDirective implements AfterViewInit, OnDestroy {
  @Input() resizeFrom: 'right' | 'left' = 'left';
  // Directive cannot be added conditionally, so this flag is needed
  @Input() doResize = true;
  @Input() analyticsData?: ClickAnalyticsData & Record<string, any>;

  private isResizing = false;
  private startX = 0;
  private originalWidth = 0;
  private screenOverlay: HTMLElement | null = null;
  private resizeButton: HTMLButtonElement | null = null;
  private readonly resizeButtonWidth = 20;

  constructor(private el: ElementRef, private renderer: Renderer2, private googleAnalyticsService: GoogleAnalyticsService) {}

  ngAfterViewInit(): void {
    if (this.doResize) {
      this.addResizeButton();
      this.renderer.listen(this.resizeButton, 'mousedown', this.startResizing);
      this.renderer.listen(this.resizeButton, 'touchstart', this.startResizing);
    }
  }

  ngOnDestroy(): void {
    this.stopResizing();
  }

  private get maxWidth(): number {
    return window.innerWidth - this.resizeButtonWidth;
  }

  @HostListener('window:resize')
  private setMaxWidth() {
    // Correction for cases when window is made smaller than the width of the element
    this.renderer.setStyle(this.el.nativeElement, 'max-width', `${(this.maxWidth / window.innerWidth) * 100}vw`);
  }

  private startResizing = (event: MouseEvent | TouchEvent): void => {
    if (this.analyticsData) {
      void this.googleAnalyticsService.sendClickData(this.analyticsData);
    }
    this.isResizing = true;
    this.startX = this.getXCoordinateForElementFromEvent(event);
    this.originalWidth = this.el.nativeElement.offsetWidth;
    this.setResizingMode(true);
  };

  private resize = (event: MouseEvent | TouchEvent): void => {
    if (!this.isResizing) {
      return;
    }
    const deltaX = this.getXCoordinateForElementFromEvent(event) - this.startX;
    const newWidth = this.originalWidth + (this.resizeFrom === 'right' ? deltaX : -deltaX);

    const minWidth = this.resizeButtonWidth;
    const finalWidth = Math.min(Math.max(newWidth, minWidth), this.maxWidth);

    this.renderer.setStyle(this.el.nativeElement, 'width', `${finalWidth}px`);
  };

  private stopResizing = (): void => {
    this.isResizing = false;
    this.setResizingMode(false);
  };

  /** Add button to allow grabbing and resizing the host element */
  private addResizeButton(): void {
    // Create and append a button for resizing
    this.resizeButton = this.renderer.createElement('button');
    this.resizeButton.classList.add('hidden-sm', 'hidden-xs');
    this.renderer.appendChild(this.el.nativeElement, this.resizeButton);
    this.renderer.setStyle(this.el.nativeElement, 'box-sizing', 'border-box');
    this.renderer.setStyle(this.el.nativeElement, `padding-${this.resizeFrom}`, `${this.resizeButtonWidth}px`);

    // Add styling to the button
    this.renderer.setStyle(this.resizeButton, this.resizeFrom, '0');
    this.renderer.setStyle(this.resizeButton, 'position', 'absolute');
    this.renderer.setStyle(this.resizeButton, 'top', '50%');
    this.renderer.setStyle(this.resizeButton, 'transform', 'translateY(-50%)');
    this.renderer.setStyle(this.resizeButton, 'width', `${this.resizeButtonWidth}px`);
    this.renderer.setStyle(this.resizeButton, 'height', '100%');
    this.renderer.setStyle(this.resizeButton, 'cursor', 'ew-resize');
    this.renderer.setStyle(this.resizeButton, 'background', 'transparent');
    this.renderer.setStyle(this.resizeButton, 'border', 'none');
    this.renderer.setStyle(this.resizeButton, 'display', 'flex');
    this.renderer.setStyle(this.resizeButton, 'align-items', 'center');
    this.renderer.setStyle(this.resizeButton, 'justify-content', 'center');

    // Add Font Awesome icon
    const icon = this.renderer.createElement('i');
    this.renderer.addClass(icon, 'fa');
    this.renderer.addClass(icon, 'fa-solid');
    this.renderer.addClass(icon, 'fa-arrows-h');
    this.renderer.appendChild(this.resizeButton, icon);
  }

  private getXCoordinateForElementFromEvent(event: MouseEvent | TouchEvent): number {
    return event instanceof MouseEvent ? event.clientX : event.touches[0].clientX;
  }

  // Generate a full screen overlay, so we don't lose track of cursor movements over iframes
  private generateScreenOverlay(): void {
    this.screenOverlay = this.renderer.createElement('div');
    this.renderer.setStyle(this.screenOverlay, 'position', 'fixed');
    this.renderer.setStyle(this.screenOverlay, 'top', '0');
    this.renderer.setStyle(this.screenOverlay, 'right', '0');
    this.renderer.setStyle(this.screenOverlay, 'bottom', '0');
    this.renderer.setStyle(this.screenOverlay, 'left', '0');
    this.renderer.setStyle(this.screenOverlay, 'cursor', 'ew-resize');
    this.renderer.setStyle(this.screenOverlay, 'z-index', '9999');
    this.renderer.setStyle(this.screenOverlay, 'opacity', '0');
    this.renderer.appendChild(document.body, this.screenOverlay);
  }

  private setResizingMode(isOn: boolean): void {
    if (isOn) {
      this.generateScreenOverlay();
      document.addEventListener('mousemove', this.resize);
      document.addEventListener('mouseup', this.stopResizing);
      document.addEventListener('touchmove', this.resize, { passive: false });
      document.addEventListener('touchend', this.stopResizing);
      this.renderer.setStyle(document.body, 'cursor', 'ew-resize');
    } else {
      if (this.screenOverlay) {
        this.renderer.removeChild(document.body, this.screenOverlay);
        this.screenOverlay = null;
      }
      document.removeEventListener('mousemove', this.resize);
      document.removeEventListener('mouseup', this.stopResizing);
      document.removeEventListener('touchmove', this.resize);
      document.removeEventListener('touchend', this.stopResizing);
      this.renderer.removeStyle(document.body, 'cursor');
    }
  }
}
