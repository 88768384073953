<div class="breadcrumbs " id="breadcrumbs">
  <div class="page-title">
      <span *ngFor="let decorator of pageTitleDecorators">
          <i *ngIf="decorator.ShowIcon === true" 
            class="material-icons page-icon breadcrumb-icon" 
            [ngClass]="{'iconMarginRight': decorator.ShowText === false}" >
            {{decorator.Icon}}
          </i>
          <span *ngIf="decorator.ShowText === true" class="page-text">
            {{decorator.Text}}
          </span>
          <i *ngIf="decorator.ShowText === true" 
            class="material-icons breadcrumb-spacer">
            keyboard_arrow_right
          </i>
      </span>
  </div>
</div>
