<div class="form-horizontal form-group">
  <phx-form-control
    [control]="profileIdControl"
    [editable]="hasLoadedOptions"
    [labelText]="labelText"
    [canDisplayValue]="hasLoadedOptions"
    name="profileIdControl"
  >
    <app-phx-select-box-w-tooltip
      [items]="listUserProfileWorker"
      [formControl]="profileIdControl"
      textField="DisplayValue"
      valueField="Id"
    >
      <ng-template let-item>
        <table>
          <ng-container *ngFor="let field of tooltipFields">
            <tr>
              <td class="text-left prm f12c">{{ field.label }}:</td>
              <td class="text-left f12c">{{ item[field.value] || '-' }}</td>
            </tr>
          </ng-container>
        </table>
      </ng-template>
    </app-phx-select-box-w-tooltip>
    <div
      *ngIf="profileIdControl.value && selectWorkerProfile"
      class="selected-profile-worker-box"
    >
      <div
        *ngIf="selectedProfileWorkerUrlLink"
        class="row"
      >
        <div class="col-lg-12">
          <a
            routerLink="{{ selectedProfileWorkerUrlLink }}"
            target="_blank"
          >
            {{ currentlySelectedWorker.DisplayValue }}
          </a>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 bold-thin">Email:</div>
        <div class="col-lg-9">{{ currentlySelectedWorker.PrimaryEmail }}</div>
      </div>
      <div class="row">
        <div class="col-lg-3 bold-thin">Organization:</div>
        <div class="col-lg-9">{{ currentlySelectedWorker.OrganizationName || '-' }}</div>
      </div>
      <div class="row">
        <div class="col-lg-3 bold-thin">Update Date:</div>
        <div class="col-lg-9">{{ currentlySelectedWorker.LastModifiedDatetime }}</div>
      </div>
    </div>
  </phx-form-control>
</div>
