<dx-tag-box #tagBox
            [items]="items"
            [dataSource]="phxDataSource"
            [displayExpr]="displayExpr"
            [valueExpr]="valueExpr"
            [disabled]="disabled"
            [value]="value"
            [placeholder]="placeholder"
            [showSelectionControls]="showSelectionControls"
            [applyValueMode]="applyValueMode"
            [searchEnabled]="searchEnabled"
            [maxDisplayedTags]="maxDisplayedTags"
            (onValueChanged)="onValueChanged($event)"
            (onOpened)="addPopoverClasses()"
            (onClosed)="removePopoverClasses()">
</dx-tag-box>
