import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControlExtensions } from './formcontrol.extensions';
import { AbstractControl } from '../../ngx-strongly-typed-forms';

@Component({
  selector: 'phx-form-control-label',
  templateUrl: './phx-form-control-label.component.html',
  styleUrls: ['./phx-form-control-label.component.less']
})
export class PhxFormControlLabelComponent implements OnInit {
  @Input() name: string;
  @Input() control: AbstractControl<any>;
  @Input() enableAsterisk = true;
  @Input() enableForceAsterisk = false;
  @Input() showLabelAsHyperlink = false;

  @Output() click: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit(): void {
    this.name = this.name || FormControlExtensions.findControlName(this.control);
  }

  hasRequiredField() {
    return FormControlExtensions.hasRequiredField(this.control);
  }

  onClick() {
    this.click.emit();
  }
}

