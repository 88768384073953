<ng-template #formControlTemplate>
  <ng-content></ng-content>
</ng-template>
<ng-template #formLabelTemplate>
  <ng-content select="phx-form-control-label" *ngIf="!labelText"></ng-content>
  <div *ngIf="labelText">
    <phx-form-control-label [control]="control" [name]="name" [enableForceAsterisk]="enableLabelForceAsterisk"
      [enableAsterisk]="enableLabelAsterisk && editable" (click)="onLabelClick()"
      [showLabelAsHyperlink]="showLabelAsHyperlink">{{labelText}}</phx-form-control-label>
  </div>
</ng-template>

<ng-template #formValidationTemplate>
  <phx-form-control-validation *ngIf="(control && !control.pristine && showValidationMessages) || forceValidation"
    [control]="control"></phx-form-control-validation>
  <p *ngIf="warningMessage" class="alert alert-warning form-control-static">{{warningMessage}}</p>
</ng-template>

<ng-template #formViewTextTemplate>
    <ng-container *ngIf="canDisplayValue; else skeleton">
        <ng-container *ngIf="!piiMetaData; else formViewPIITextTemplate">
            <ng-container  *ngIf="viewModeText?.length; else viewModeTemplate">
                <div
                  [ngClass]="removeStyleClasses ? [] : ['fieldViewMode', 'form-control-static']"
                >
                    <ng-container *ngIf="link; else justText">
                        <a
                          [appCollectClickDataAnalytics]="clickAnalyticsData"
                          [routerLink]="link"
                          [relativeTo]="relativeToRoute"
                          target="_blank"
                        >
                            {{ viewModeText }}
                        </a>
                    </ng-container>

                    <ng-template #justText>
                        {{ viewModeText }}
                    </ng-template>
                </div>
            </ng-container>
            <ng-template #viewModeTemplate>
                <ng-content select="[view-mode]"></ng-content>
            </ng-template>
        </ng-container>
    </ng-container>
</ng-template>

<ng-template #formViewPIITextTemplate>
  <app-phx-pii-field [piiMetaData]="piiMetaData" [removeStyleClasses]="removeStyleClasses"
    (revealedPiiMetaData)="getPiiMetaData($event)"></app-phx-pii-field>
</ng-template>

<!-- responsive/inline -->
<div [ngClass]="{'changed-content-container': hasChanged$ | async, 'form-group': !removeStyleClasses}"
  *ngIf="layoutType === PhxFormControlLayoutType.Responsive || layoutType === PhxFormControlLayoutType.Inline">
  <div>
    <div [class.col-lg-4]="layoutType === PhxFormControlLayoutType.Responsive"
      [class.col-xs-4]="layoutType === PhxFormControlLayoutType.Inline" *ngIf="!hideLabelText">
      <div [ngClass]="{'text-right' : labelAlign === 'right', 'text-left': labelAlign === 'left'}"
        class="hidden-md hidden-sm hidden-xs ">
        <ng-container *ngTemplateOutlet="formLabelTemplate"></ng-container>
      </div>
      <div class="hidden-lg hidden-xl hidden-xxl hidden-xxxl">
        <ng-container *ngTemplateOutlet="formLabelTemplate"></ng-container>
      </div>
    </div>
    <div [class.col-lg-8]="layoutType === PhxFormControlLayoutType.Responsive && !removeStyleClasses"
      [class.col-xs-8]="layoutType === PhxFormControlLayoutType.Inline && !removeStyleClasses">
      <ng-container *ngIf="!editable">
        <ng-container *ngTemplateOutlet="formViewTextTemplate"></ng-container>
        <ng-container *ngIf="piiMetaData">
          <ng-container *ngTemplateOutlet="formValidationTemplate"></ng-container>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="editable">
        <ng-container *ngTemplateOutlet="formControlTemplate"></ng-container>
        <ng-container *ngTemplateOutlet="formValidationTemplate"></ng-container>
      </ng-container>
    </div>
  </div>
  <div *ngIf="editable && helperText">
    <div [class.col-lg-4]="layoutType === PhxFormControlLayoutType.Responsive"
      [class.col-xs-4]="layoutType === PhxFormControlLayoutType.Inline"></div>
    <p class="helperText" [class.col-lg-8]="layoutType === PhxFormControlLayoutType.Responsive"
      [class.col-xs-8]="layoutType === PhxFormControlLayoutType.Inline">
      {{helperText}}
    </p>
  </div>
</div>

<!-- stacked-->
<div class="form-group row" *ngIf="layoutType === PhxFormControlLayoutType.Stacked"
  [ngClass]="{'changed-content-container': hasChanged$ | async}">
  <div class="col-xs-12">
    <div>
      <ng-container *ngTemplateOutlet="formLabelTemplate"></ng-container>
    </div>
    <div *ngIf="!editable">
      <ng-container *ngTemplateOutlet="formViewTextTemplate"></ng-container>
    </div>
    <div *ngIf="editable">
      <ng-container *ngTemplateOutlet="formControlTemplate"></ng-container>
      <ng-container *ngTemplateOutlet="formValidationTemplate"></ng-container>
      <p class="helperText" *ngIf="helperText">{{helperText}}</p>
    </div>
  </div>
</div>

<!-- control only-->
<span *ngIf="layoutType === PhxFormControlLayoutType.InputOnly">
  <div *ngIf="!editable" [ngClass]="{'changed-content-container': hasChanged$ | async}">
    <ng-container *ngTemplateOutlet="formViewTextTemplate"></ng-container>
  </div>
  <div *ngIf="editable" [ngClass]="{'changed-content-container': hasChanged$ | async}">
    <ng-container *ngTemplateOutlet="formControlTemplate"></ng-container>
    <ng-container *ngTemplateOutlet="formValidationTemplate"></ng-container>
    <p class="helperText" *ngIf="helperText">{{helperText}}</p>
  </div>
</span>

<!-- mobile modal field -->
<div class="row" *ngIf="layoutType === PhxFormControlLayoutType.Mobile"
  [ngClass]="{'changed-content-container': hasChanged$ | async}">
  <div class="flex-row row-spacer">
    <div class="left-icon">
      <ng-container *ngIf="iconTemplate !== null">
        <ng-container *ngTemplateOutlet="iconTemplate"></ng-container>
      </ng-container>
      <i *ngIf="!iconTemplate" class="material-icons">
        subdirectory_arrow_right
      </i>
    </div>
    <div class="right-element">
      <div class="float-form-box">
        <ng-container *ngIf="editable">
          <ng-container *ngTemplateOutlet="formValidationTemplate"></ng-container>
          <ng-container *ngIf="!listDataSource">
            <ng-container *ngTemplateOutlet="formControlTemplate"></ng-container>
          </ng-container>
          <ng-container *ngIf="listDataSource">
            <span [class.text-muted]="viewModeText === null || viewModeText === ''" (click)="displayModal(true)"
              class="read-only-text pointer">{{ viewModeText ||
              (PhoenixCommonModuleResourceKeys.generic.select | phxTranslate) }}</span>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="!editable">
          <span class="read-only-text">{{ viewModeText }}</span>
        </ng-container>
        <label [ngClass]="{'error' : control.invalid && control.dirty}" for="comment">{{ labelText }}</label>
      </div>
    </div>
  </div>
  <app-phx-modal #selectListModal [showChevronButton]="true" [title]="labelText" [enableSearchFeature]="true"
    (filter)="onFilterList($event)">
    <div class="item-row" *ngFor="let item of filteredDataSource; trackBy: trackByFn">
      <div class="col item-name-r" (click)="onSelectItem(item)">
        {{ item[displayField] }}
      </div>
      <div class="col item-arrow pull-right">
        <i class="material-icons chevron-right">chevron_right</i>
      </div>
    </div>
  </app-phx-modal>
</div>

<ng-template #skeleton>
  <div
    [ngClass]="{'fieldViewMode': !removeStyleClasses, 'form-control-static': !removeStyleClasses, 'position-relative': !removeStyleClasses}">
    <ngx-skeleton-loader count="1" appearance="line"
      [theme]="{marginBottom: '0', height: '18px'}"></ngx-skeleton-loader>
  </div>
</ng-template>
