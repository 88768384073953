export const DownloadCentreModuleResourceKeys = {
  report: {
    common: {
      /**
       * Preparing for export
       */
      preparingForExport: 'report.common.preparingForExport',
      /**
       * Export limit of 100,000 reached. If you need larger exports, please open a Hotfix ticket
       */
      exportLimitReached: 'report.common.exportLimitReached',
      /**
       * Export limit of 100,000 reached.
       */
      exportLimitReachedExternalUser: 'report.common.exportLimitReachedExternalUser',
      /**
       * Exported
       */
      exported: 'report.common.exported',
      /**
       * Requested
       */
      requested: 'report.common.requested',
      /**
       * Download file
       */
      downloadFile: 'report.common.downloadFile',
      /**
       * Files you exported will appear here
       */
      exportedFilesAppear: 'report.common.exportedFilesAppear',
      /**
       * Are you sure you want to clear all downloads?
       */
      clearDownloadsConfirm: 'report.common.clearDownloadsConfirm',
      /**
       * Downloads that are 7 days or older will be automatically cleared
       */
      oldDownloadsClearMessage: 'report.common.oldDownloadsClearMessage',
      /**
       * We're preparing your report for export!
       */
      preparingForExportMessage: 'report.common.preparingForExportMessage',
      /**
       * Couldn't send report for preparation. Please try again.
       */
      unableToPrepareReport: 'report.common.unableToPrepareReport',
      /**
       * Your report contains 0 results!
       */
      noResultsExport: 'report.common.noResultsExport',
      /**
       * Your report is ready to download!
       */
      reportReadyToDownload: 'report.common.reportReadyToDownload',
      /**
       * Could not export your report!
       */
      unableToExportReport: 'report.common.unableToExportReport',
      /**
       * Report was archived before it was ready
       */
      exportErrorArchived: 'report.common.exportErrorArchived',
      /**
       * Failed to clear files. Please try again
       */
      clearFilesError: 'report.common.clearFilesError',
      /**
       * Failed to clear file. Please try again
       */
      clearFileError: 'report.common.clearFileError',
      /**
       * Failed to download file. Please try again
       */
      downloadFailedError: 'report.common.downloadFailedError',
      /**
       * Payment is still pending
       */
      paymentStillPending: 'report.common.paymentStillPending',
      /**
       * All Company Reports
       */
      allCompanyReports: 'report.common.allCompanyReports',
      /**
       * My Reports
       */
      myReports: 'report.common.myReports',
      /**
       * Share With Me
       */
      sharedWithMe: 'report.common.sharedWithMe',
      /**
       * All Company Data
       */
      allCompanyData: 'report.common.allCompanyData',
      /**
       * My Data
       */
      myData: 'report.common.myData',
      /**
       * All My Teams
       */
      allMyTeams: 'report.common.allMyTeams',
      /**
       * All Reportees
       */
      allReportees: 'report.common.allReportees',
      /**
       * No reports available
       */
      noReportAvailable: 'report.common.noReportAvailable',
      /**
       * Organizational Contact
       */
      organizationalContact: 'report.common.organizationalContact',
      /**
       * Worker Contact
       */
      workerContact: 'report.common.workerContact',
      /**
       * Internal Contact
       */
      internalContact: 'report.common.internalContact'
    }
  }
};
