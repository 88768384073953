import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { BaseComponentOnDestroy } from 'src/app/common/epics/base-component-on-destroy';
import { DialogResultType } from 'src/app/common/model';
import { TodoItem } from 'src/app/common/model/to-do-item';
import { DialogService } from 'src/app/common/services/dialog.service';
import { TodoService } from 'src/app/common/services/todo.service';

@Component({
  selector: 'app-todo-list',
  templateUrl: './todo-list.component.html',
  styleUrls: ['./todo-list.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TodoListComponent extends BaseComponentOnDestroy implements OnInit {
  @Output()
  draggingInProgress = new EventEmitter<boolean>();

  todoList$ = this.todoService.userTodo$;
  /** NOTE: track which todo lists are expanded by group type */
  todoListsShowing: { [key: string]: boolean; } = {};
  /** NOTE: need list of todo list names (WO, org, people, etc,) */
  todoListNames: { [key: number]: string; } = {};

  constructor(
    private todoService: TodoService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private dialogService: DialogService
  ) {
    super();
  }

  ngOnInit(): void {
    this.todoService.initTodoList();
    this.mapTodoListNames();
    this.initToDoListChange();
  }

  /** NOTE: if the user todo list is updated then we expand the todo list */
  initToDoListChange() {
    this.todoService.todoListChanged$.pipe(
      takeUntil(this.isDestroyed$)
    ).subscribe(reportTypeId => {
      if (!this.todoListsShowing[reportTypeId]) {
        this.todoListsShowing = {};
        this.toggleGroup(reportTypeId);
        this.cdr.detectChanges();
      }
    });
  }

  toggleGroup(reportTypeId: number) {
    this.todoListsShowing[reportTypeId] = !this.todoListsShowing[reportTypeId];
  }

  toggleToDo(todoItem: TodoItem, reportTypeId: number) {
    this.todoService.toggleTodo(todoItem, reportTypeId);
  }

  removeTodoItem(todoItem: TodoItem, reportTypeId: number) {
    this.todoService.removeTodo(todoItem, reportTypeId);
  }

  navigateToItem(todoItem: TodoItem, reportTypeId: number) {
    this.todoService.addTodoItemClickGAEvent(reportTypeId);
    this.router.navigate([this.todoService.getTodoUrl(todoItem, reportTypeId)]);
  }

  onItemReordered(e, reportTypeId: number) {
    this.todoService.reorderTodoList(e, reportTypeId);
  }

  clearList(reportTypeId: number) {
    this.dialogService.confirm('Remove To-Do Items', 'Are you sure you want to remove these items from your To-Do List?').then(button => {
      if (button === DialogResultType.Yes) {
        this.todoService.clearToDoList(reportTypeId);
      }
    }).catch(() => {
    });
  }

  dragStart = () => {
    this.draggingInProgress.emit(true);
  };

  dragEnd = () => {
    this.draggingInProgress.emit(false);
  };

  private mapTodoListNames() {
    this.todoService.activeTodoReportTypes.forEach(reportType => this.todoListNames[reportType.reportTypeId] = reportType.description);
  }
}
