import { MsalGuardConfiguration, MsalInterceptorConfiguration } from '@azure/msal-angular';
import { InteractionType, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { environment } from '../../environments/environment';

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

export abstract class AzureScopes {
  static readonly USER_READ = 'user.read';
  static readonly WEB_API = 'api://0b6dd18e-5f4a-4c5b-81bb-6cd87de2fcf1/FBO.Auth';
}

export abstract class AzureADErrors {
  static readonly LOGIN_REQUIRED = 'login_required';
  static readonly CONSENT_REQUIRED = 'consent_required';
  static readonly INTERACTION_REQUIRED = 'interaction_required';
  static readonly USER_CANCELLED = 'user_cancelled';
}

export const MS_GRAPH_URL = 'https://graph.microsoft.com/v1.0/';
export const MS_GRAPH_USER_PROFILE_URL = MS_GRAPH_URL + 'me';

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: '0b6dd18e-5f4a-4c5b-81bb-6cd87de2fcf1',
      authority: 'https://login.microsoftonline.com/8d195b37-4c12-4f37-b356-c0d6a39b46a1',
      redirectUri: environment.activeDirectoryRedirectUri,
      postLogoutRedirectUri: environment.activeDirectoryPostLogoutRedirectUri
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: isIE
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(MS_GRAPH_USER_PROFILE_URL, [AzureScopes.USER_READ]);
  protectedResourceMap.set(environment.apiUrl, [AzureScopes.WEB_API]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [AzureScopes.USER_READ, AzureScopes.WEB_API]
    },
  };
}
