import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DownloadCenterButtonComponent } from './components/download-center-button/download-center-button.component';
import { DownloadCenterButtonService } from './services/download-center-button.service';
import { PhoenixCommonModule } from '../../common/PhoenixCommon.module';



@NgModule({
  declarations: [DownloadCenterButtonComponent],
  exports: [
    DownloadCenterButtonComponent
  ],
  imports: [
    CommonModule,
    PhoenixCommonModule
  ],
  providers: [
    DownloadCenterButtonService
  ]
})
export class DownloadCenterButtonModule { }
