import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { AuthService } from '../services/auth.service';
import { MODULE_ROUTES } from '../constants/module-routes.const';

export const authGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree => {
  const authService = inject(AuthService);
  const router = inject(Router);

  const isLoggedIn = authService.isLoggedIn();
  if (!isLoggedIn) {
    authService.setRedirectAfterLogin(state.url);
  }

  return isLoggedIn || router.createUrlTree([MODULE_ROUTES.LOGIN]);
};
