import { Component, Inject } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';

import { DialogComment } from '../../model';
import { PhxLocalizationService } from '../../services/phx-localization.service';
import { PhoenixCommonModuleResourceKeys } from '../../PhoenixCommonModule.resource-keys';
import { DialogStateWithReasons } from '../../model/dialog-state';
import { DialogComponent } from '../../services/dialog/models/dialog-component.model';

@Component({
  selector: 'app-phx-dialog-comment-template',
  templateUrl: './phx-dialog-comment-template.component.html',
  styleUrls: ['./phx-dialog-comment-template.component.less']
})
export class PhxDialogCommentTemplateComponent implements DialogComponent<DialogStateWithReasons, void> {
  title: string = this.dialogData.title || this.localizationService.translate(PhoenixCommonModuleResourceKeys.phxDialogComment.title);
  helpBlock: string = this.dialogData.helpBlock || this.localizationService.translate(PhoenixCommonModuleResourceKeys.phxDialogComment.helpblock);
  maxLength = this.dialogData.maxLength || 4000;
  saveButtonText: string = this.dialogData.saveButtonText || this.localizationService.translate(PhoenixCommonModuleResourceKeys.phxDialogComment.saveBtn);
  selectLabel = this.dialogData.selectLabel ?? 'Select the decline reason(s)';
  selectMode: 'tagBox' | 'select' = this.dialogData.selectMode ?? 'tagBox';
  isCommentRequired = this.dialogData?.isCommentRequired ?? true;

  yesFlag = false;
  comment: string;
  dialogResult: DialogComment = {} as DialogComment;

  constructor(private localizationService: PhxLocalizationService, public dialogRef: DialogRef<void>, @Inject(DIALOG_DATA) public dialogData: DialogStateWithReasons) {}

  updateComment(comment: string) {
    this.comment = comment;
    this.dialogResult.Comment = comment;
  }

  onDeclineReasonsChanged(data: { value: string[] | string }) {
    this.dialogResult.DeclineReasons = data.value;
  }

  isValid(): boolean {
    if (this.dialogData.showDeclineReasonList) {
      const isCommentValid = !this.isCommentRequired || this.dialogResult.Comment?.trim();
      return Boolean(isCommentValid && this.dialogResult.DeclineReasons?.length);
    } else {
      return Boolean(!this.isCommentRequired || this.comment?.trim());
    }
  }

  save(): void {
    this.yesFlag = true;
    this.close();
    this.dialogData.saveCallbackFn?.(this.comment);

    if (this.dialogData.showDeclineReasonList) {
      this.dialogData.saveReasonIDsCallbackFn?.(this.dialogResult);
    }
  }

  close(): void {
    this.dialogRef.close();
    if (this.yesFlag === false) {
      this.dialogData.cancelCallbackFn?.();
    }
  }

  placeholderProvided(): boolean {
    // null or undefined denotes no placeholder was provided. '' is considered a valid placeholder.
    return typeof this.dialogData.commentPlaceholder === 'string';
  }
}
