import { Component, Inject, OnInit } from '@angular/core';
import { BaseComponentOnDestroy } from 'src/app/common/epics/base-component-on-destroy';
import { takeUntil } from 'rxjs';
import { AppStorageService, NavigationService } from 'src/app/common';
import { Router } from '@angular/router';
import { StorageService } from 'ngx-webstorage-service';

@Component({
  selector: 'app-demo-iframe',
  templateUrl: './demo-iframe.component.html',
  styleUrls: ['./demo-iframe.component.less']
})
export class DemoIframeComponent extends BaseComponentOnDestroy implements OnInit {
  iframeSource: string | null;

  constructor(
    private navigationService: NavigationService,
    private router: Router,
    @Inject(AppStorageService) private storageService: StorageService
  ) {
    super();
  }

  ngOnDestroy(): void {
    this.navigationService.updateRemovePaddingMarginState(false);
    super.ngOnDestroy();
  }

  ngOnInit(): void {
    this.navigationService.updateBreadCrumbState(false);
    this.navigationService.updateRemovePaddingMarginState(true);
    this.navigationService.demoIframeUri$.pipe(
      takeUntil(this.isDestroyed$),
    ).subscribe(uri => {
      if (!uri) {
        this.router.navigate(['/next/account/home']);
      } else {
        const token = this.storageService.get('BearerToken');
        this.iframeSource = `${uri}${uri.includes('?') ? '&' : '?'}token=${token}`;
      }
    });
  }


}
