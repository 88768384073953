/**
 * The map between PhxConstants enums and their respective translation text.
 * Update the map if any new type comes in, that required to be shown as translated to local corresponding text
 *
 * Format:
 *
 *      <EnumType>: {
 *          <EnumCode>: <'EnumType.EnumKey'>
 *      }
 *
 */

export const TypedResourceKeys = {
    StateAction: {
        901: 'activityCentre2.actionBar.approve', // TimesheetApprove
        903: 'activityCentre2.actionBar.decline', // TimesheetDecline
        9610: 'activityCentre2.actionBar.approve', // ExpenseCaimApprove
        9609: 'activityCentre2.actionBar.decline' // ExpenseClaimDecline

    },
    RateUnit: {
        1: 'timesheet.rateUnit.Hour', // hr
        2: 'timesheet.rateUnit.Day', // d
        3: 'timesheet.rateUnit.Fixed', // fx
        4: 'timesheet.rateUnit.Words', // wo
        5: 'timesheet.rateUnit.Monthly', // mo
        6: 'timesheet.rateUnit.Shift' // s
    }
};
