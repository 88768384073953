
import {distinctUntilChanged, debounceTime} from 'rxjs/operators';
import { Directive, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[ngModel][phxDebounce]',
})
export class DebounceDirective implements OnInit {
    @Output() public debounce = new EventEmitter<any>();

    @Input() public phxDebounce = 500;

    private isFirstChange = true;

    constructor(public model: NgControl) {
    }

    ngOnInit() {
        this.model.valueChanges.pipe(
            debounceTime(this.phxDebounce),
            distinctUntilChanged(),)
            .subscribe(modelValue => {
                if (this.isFirstChange) {
                    this.isFirstChange = false;
                } else {
                    this.debounce.emit(modelValue);
                }
            });
    }
}
