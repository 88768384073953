import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BaseComponentOnDestroy } from '../../epics/base-component-on-destroy';
import { ReportShareService } from '../../services/report-share.service';
import { AuthService } from '../../services/auth.service';
import { PhxModalComponent } from '../phx-modal/phx-modal.component';
import { IReportShareDetails } from '../../model/data-table/i-report-share-details';
import { FormGroup } from '../../ngx-strongly-typed-forms';
import { IReportSharee, IReportUserSharee, ReportShareeType } from '../../../helpers/classes/report-sharing-api/i-report-sharing-api';
import { PhxButton, PhxFormControlLayoutType, User, UserProfile } from '../../model';
import { filter, take } from 'rxjs/operators';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-report-share-dialog',
  templateUrl: './report-share-dialog.component.html',
  styleUrls: ['./report-share-dialog.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportShareDialogComponent extends BaseComponentOnDestroy implements OnInit {
  constructor(private reportShareService: ReportShareService,
              private authService: AuthService,
              private cdr: ChangeDetectorRef) {
    super();
  }
  @ViewChild('shareDialog', {static: true}) shareDialog: PhxModalComponent;
  @Input() inputFormGroup: FormGroup<IReportShareDetails>;
  @Output() shareReport = new EventEmitter<IReportShareDetails>();
  shareeList: IReportSharee[] = [];
  currentUserId: number;
  functionalRoles: Map<number, string> = new Map<number, string>();
  ReportShareeType = ReportShareeType;
  owner: IReportUserSharee;
  PhxFormControlLayoutType = PhxFormControlLayoutType;
  modalButtons: PhxButton[] = [{
    icon: null,
    tooltip: 'Done',
    btnType: 'primary',
    action: () => {
      this.saveReportShare();
      this.shareDialog.hide();
    }
  }, {
    icon: null,
    tooltip: 'Cancel',
    btnType: 'default',
    action: () => {
      this.shareDialog.hide();
    }
  }];
  ngOnInit() {
    forkJoin([
      this.reportShareService.shareeList$.pipe(filter(e => !!e), take(1)),
      this.authService.getUserContext(),
      this.authService.getCurrentProfile()])
        .pipe(take(1))
        .subscribe(([shareeList, userContext, userProfile]) => {
          this.currentUserId = userContext.User.Id;
          this.shareeList = shareeList;
          this.owner = this.getOwnerShareeObject(userContext.User, userProfile);
          this.cdr.detectChanges();
        });
  }

  getFilteredShareeList(shareeList: IReportSharee[], currentUserId: number): IReportSharee[] {
    const disallowedUserIds = [currentUserId].concat(this.inputFormGroup.controls.ShareeUserIds.value);
    const disallowedTeamIds = this.inputFormGroup.controls.ShareeTeamIds.value;

    const result = shareeList.filter(sharee => {
      if (sharee.ShareeType === ReportShareeType.User) {
        return !disallowedUserIds.includes(sharee.Id);
      } else {
        return !disallowedTeamIds.includes(sharee.Id);
      }
    });

    return result;
  }

  getOwnerShareeObject(user: User, userProfile: UserProfile): IReportUserSharee {
    return {
      Name: [user.FirstName, user.LastName].filter(n => !!n).join(' '),
      Id: userProfile.Id,
      FunctionalRolesId: userProfile.FunctionalRoles.map(fr => fr.FunctionalRoleId),
      ShareeType: ReportShareeType.User
    };
  }

  getFunctionRoleString(functionalRoleIds: number[]): string {
    return this.reportShareService.getFunctionRoleString(functionalRoleIds);
  }

  addReportSharee($event: any) {
    if (!$event.value) { return; }

    const [shareeId, shareeType] = [$event.value.Id, $event.value.ShareeType];
    if (shareeType === ReportShareeType.User) {
      const newShareeIds = this.inputFormGroup.controls.ShareeUserIds.value.concat(shareeId);
      this.inputFormGroup.controls.ShareeUserIds.patchValue(newShareeIds);
    } else {
      const newShareeIds = this.inputFormGroup.controls.ShareeTeamIds.value.concat(shareeId);
      this.inputFormGroup.controls.ShareeTeamIds.patchValue(newShareeIds);
    }

    this.cdr.detectChanges();
  }

  show() {
    this.shareDialog.show();
  }

  removeSharee($event: { shareeId: number | string; shareeType: ReportShareeType }) {
    const [shareeId, shareeType] = [$event.shareeId, $event.shareeType];
    if (shareeType === ReportShareeType.User) {
      const newShareeIds = this.inputFormGroup.controls.ShareeUserIds.value.filter(userId => userId !== shareeId);
      this.inputFormGroup.controls.ShareeUserIds.patchValue(newShareeIds);
    } else {
      const newShareeIds = this.inputFormGroup.controls.ShareeTeamIds.value.filter(userId => userId !== shareeId);
      this.inputFormGroup.controls.ShareeTeamIds.patchValue(newShareeIds);
    }
  }

  saveReportShare() {
    this.shareReport.emit(this.inputFormGroup.value);
  }
}
