import { Component, OnInit, OnDestroy, Input, OnChanges, ChangeDetectorRef } from '@angular/core';
import { CommonService, LoadingSpinnerService } from '../../..';
import { Router } from '@angular/router';
import { debounceTime } from 'rxjs/operators';
import { ApiService } from 'src/app/common/services/api.service';

@Component({
  selector: 'app-loading-spinner-overlay',
  templateUrl: './loading-spinner-overlay.component.html',
  styleUrls: ['./loading-spinner-overlay.component.less']
})
export class LoadingSpinnerOverlayComponent implements OnDestroy, OnChanges, OnInit {
  /**
   * Text to display, or Translation Code for display text.
   * If translate is false, value will be displayed as-is.
   * If translate is true, value will be fed through translation service to find matching translation.
   */
  @Input() text: string;
  @Input() translate: boolean = true;
  progressText: string;

  loadingStatusSubscription: any;
  progressTextSubscription: any;
  isVisible = false;
  visibleTimeStamp: Date = null;

  private spinnerTimerTimeout: ReturnType<typeof setTimeout>;
  private spinnerTimerThresholdInMinutes = 10;


  constructor(
    private loadingSpinnerService: LoadingSpinnerService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private apiService: ApiService,
    private commonService: CommonService
  ) {
  }
  ngOnInit() {
    this.loadingStatusSubscription = this.loadingSpinnerService.loadingChanged
      .pipe(debounceTime(10))
      .subscribe((value) => {
        if (value) {
          this.showSpinner();
        } else {
          this.hideSpinner();
        }
      });
    this.progressTextSubscription = this.loadingSpinnerService.progressTextChanged.subscribe((value) => {
      this.progressText = value;
      this.cdr.detectChanges();
    });
  }

  ngOnDestroy() {
    if (this.loadingStatusSubscription) {
      this.loadingStatusSubscription.unsubscribe();
    }
  }

  ngOnChanges() {

  }

  showSpinner() {
    if (!this.isVisible) {
      this.isVisible = true;
      this.apiService.pageLoaderStart(this.router.url);
      this.startSpinnerTimer();
    }
  }

  hideSpinner() {
    this.isVisible = false;
    this.apiService.pageLoaderEnd(this.router.url);

    if (this.spinnerTimerTimeout) {
      clearTimeout(this.spinnerTimerTimeout);
    }

    this.cdr.detectChanges();
  }

  /** NOTE: start timer as fail safe for scenario's when an issue keeps the spinner visible */
  private startSpinnerTimer() {
    if (this.spinnerTimerTimeout) {
      clearTimeout(this.spinnerTimerTimeout);
    }

    /** NOTE: this will trigger change detection */
    this.spinnerTimerTimeout = setTimeout(() => {
      this.hideSpinner();
    }, this.spinnerTimerThresholdInMinutes * 60000);
  }
}
