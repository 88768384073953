import { Injectable } from '@angular/core';
import { CodeValue } from '../model';

@Injectable({
    providedIn: 'root'
})
export class PhxWorkflowDisplayNameService {

    public getStatusDisplayName(code: string, entityType: string): string {
        const codeValueArray = (window as any).PhoenixCodeValues['Code' + entityType + 'Status'];
        if (!codeValueArray){
            return code;
        }
        
        return this.getDisplayNameFromCodeValues(codeValueArray, code);
    }

    public getActionDisplayName(code: string, entityType: string): string {
        const codeValueArray = (window as any).PhoenixCodeValues['Code' + entityType + 'Action'];
        if (!codeValueArray){
            return code;
        }
        
        return this.getDisplayNameFromCodeValues(codeValueArray, code);
    }

    public getCodeValues(groupName: string, required: boolean): Array<CodeValue> {
        if (!groupName) {
            console.error('PhxWorkflowDisplayNameService.getCodeValues groupName is empty');
        }

        const result = (window as any).PhoenixCodeValues[groupName];
        if (!result){
            return new Array<CodeValue>();
        }

        if (required === false) {
            // add first empty item
            result.unshift({ id: null, text: '   ', value: null });
        }

        return result;
    }

    private getDisplayNameFromCodeValues(codeValues: Array<CodeValue>, code: string): string {
        const codeValue = codeValues.find(c => c.code === code);
        if (!codeValue){
            return code;
        }
            
        const displayName = codeValue.text;
        return displayName ?? code;
    }
}
