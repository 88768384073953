import { inject, Injectable } from '@angular/core';
import { AppStorageService, PhxLocalizationService } from './common';
import { AuthService } from './common/services/auth.service';
import { ConfigurationService } from './configuration/service/configuration.service';
import { Params } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AppInitService {
  public loaded = false;

  private readonly authService = inject(AuthService);
  private readonly localizationService = inject(PhxLocalizationService);
  private readonly configurationService = inject(ConfigurationService);
  private readonly storageService = inject(AppStorageService);

  async initApp(externalParams?: Params): Promise<void> {
    if (externalParams) {
      await this.initializeExternalApp(externalParams);
    } else {
      await this.initializeApp();
    }

    this.loaded = true;
  }

  private async initializeExternalApp(externalParams: Params): Promise<void> {
    const { locale, token } = externalParams;
    this.storageService.set('BearerToken', token);
    this.localizationService.setLocale(locale);

    await this.authService.fetchCurrentUser();
    await this.authService.initApp(locale);
    await this.configurationService.fetchFeatureConfigurationData();
  }

  private async initializeApp(): Promise<void> {
    await this.authService.fetchCurrentUser();

    const userContext = await this.authService.loadContext();
    const userLocale = this.localizationService.getLangFromCultureId(userContext.User.CultureId);

    await this.configurationService.fetchFeatureConfigurationData();
    await this.authService.initApp(userLocale);
  }
}
