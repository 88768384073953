<ng-template #registrationRequiredTemplate>
    <div class="login-fields">
        <div class="field text-center">
            {{ accountResourceKeys.accessCode.registrationRequiredMessage | phxTranslate }}
        </div>
    </div>
    <div class="login-buttons text-center">
        <button class="btn btn-primary" routerLink="/register" [queryParams]="{et: tokenObj?.token}" style="min-width: 221px;">
            {{ accountResourceKeys.accessCode.startRegistrationBtn | phxTranslate }}
        </button>
    </div>
</ng-template>

<ng-template #accessCodeTemplate>
    <form [formGroup]="form">
        <div class="login-fields">
            <ng-container *ngIf="tokenObj">
                <div class="field">
                    <label name="AccessCode" for="accessCode" class="control-label">{{ accountResourceKeys.accessCode.accessCodeLabel | phxTranslate }}</label>
                    <input type="text" class="form-control input-lg input-access-code" formControlName="accessCode" id="accessCode" />
                </div>
            </ng-container>
            <div *ngIf="loginHideMessage" class="text-center alert alert-danger">
                {{ loginHideMessage }}
            </div>
        </div>
        <div class="login-buttons">
            <a class="btn btn-link pull-left" routerLink="/login">{{ accountResourceKeys.accessCode.pwdLoginMessage | phxTranslate }}</a>
            <button *ngIf="tokenObj" [disabled]="form.invalid" class="login-action btn btn-primary" (click)="submit()">
                {{ accountResourceKeys.accessCode.logInLabel | phxTranslate }}
            </button>
        </div>
        <div *ngIf="loginFailedMessage"
            class="login-error text-center alert alert-danger">
            {{ loginFailedMessage }}
        </div>
    </form>
</ng-template>

<div class="account-container portal-container stacked">
    <div class="content">
        <div class="logo text-center">
            <img src="/assets/logo-flex-backoffice.png" alt="logo" />
        </div>
        <div *ngIf="loading" class="text-center">
            {{ accountResourceKeys.accessCode.loadingMessage | phxTranslate }}
        </div>
        <ng-container *ngIf="!loading" [ngTemplateOutlet]="tokenObj && !tokenObj.IsExistingUser ? registrationRequiredTemplate : accessCodeTemplate"></ng-container>
    </div>
</div>