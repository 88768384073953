import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { NavigationMenuItem } from '../../model';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-mini-navigation',
  templateUrl: './mini-navigation.component.html',
  styleUrls: ['./mini-navigation.component.less']
})
export class MiniNavigationComponent implements AfterViewInit {
  @Input() navigationItems: NavigationMenuItem[] = [];

  @Output() sidebarToggle = new EventEmitter<void>();

  @ViewChild('toggleNavigation') toggleNavigation: ElementRef<HTMLButtonElement>;

  public readonly buildNumber = environment.buildNumber;

  // The only reason this property exist is to force-collapse navigation during navigation event
  public isViewingNavigation = true;
  public isViewingBookmarks = false;

  public isDraggingTodos = false;
  public isBusyWithTodos = false;

  ngAfterViewInit(): void {
    this.toggleNavigation.nativeElement.focus();
  }

  public clickNavItem(navItem: NavigationMenuItem): void {
    /** Force collapsing when navigation is about to happen,
     * Router events are not use here, due to the time between NavigationStart and NavigationEnd
     * being sometimes too short for the element to get lose the hover effect and disappear.
     * */
    if (navItem.State) {
      this.isViewingNavigation = false;
      setTimeout(() => {
        /** Enable it back to get icons highlight for the activated route */
        this.isViewingNavigation = true;
      });
    }
  }

  /** Dragging of todos causes mouseleave event to fire before the mouse actually leaves the menu item.
   * This is caused by the devextreme's screen overlay when dragging is at work, we can only endure it.
   *
   * This is the reason why there is a check for dragging being in progress.
   * The sequence of events is: drag starts => mouseleave fires => drag ends => mouse enter => mouse leave when user
   * moves the mouse from the actual menu item manually, this is when we want it to collapse.
   * All this means that if no dragging happens, this method is invoked once, when user moves the mouse from the menu,
   * but for the cases when dragging occurs, this method fires once per each drag event and one more time when the user
   * moves the mouse out.
   * */
  public handleMouseLeave(): void {
    /** Dragging in progress, meaning this event was triggered by forced mouse out, we skip it. */
    if (this.isDraggingTodos) {
      return;
    }
    /** No dragging in progress, can collapse */
    this.isBusyWithTodos = false;
  }
}
