import { EdmLiteral } from 'devextreme/data/odata/utils';

import { PhxLocalizationService } from '../../services/phx-localization.service';
import { PhxDataColumnLookup } from './phx-data-column-lookup';
import { PhxDataTableEditorOptions } from './../index';
import { PhxDataTableColumnFormat } from './phx-data-table-column-format.model';

/** De-facto the class implements DevExpress.ui.dxDataGrid.Column, however we cannot explicitly state it due to the format property,
 * which supports Intl.DateTimeFormatOptions, and is recommended for use with inline dxi-columns, but has not been added to their
 * internal deprecated base column interface. format can be typed as {}, but not as Intl.DateTimeFormatOptions, which is odd.
 * Once devextreme fix their interfaces, we can re-add 'implements DevExpress.ui.dxDataGrid.Column' to the class. */
export class PhxDataTableColumn {
  static isTest = {
    lookupDataSource: (localizationService: PhxLocalizationService) => {
      return [
        {
          id: 1,
          code: 'Test',
          text: localizationService.translate('common.phxDataTable.implementationCellTest'),
          value: true
        },
        {
          id: 2,
          code: 'Live',
          text: localizationService.translate('common.phxDataTable.implementationCellLive'),
          value: false
        }
      ];
    }
  };

  allowEditing?: boolean;

  dataField: string;
  caption: string;
  columns?: PhxDataTableColumn[];
  dataType?: any = 'string'; // 'string' | 'number' | 'date' | 'boolean' | 'object' | 'datetime' | 'decimal' | 'money';
  name?: string;
  width?: any;
  minWidth?: number;
  visible?: boolean;
  showInColumnChooser?: boolean;  // default true
  visibleIndex?: number;
  hidingPriority?: number;
  format?: PhxDataTableColumnFormat;
  filterValue?: any;
  filterValues?: any[];
  editorOptions?: PhxDataTableEditorOptions;
  fixed?: boolean;
  alignment?: 'center' | 'left' | 'right' | undefined = 'left';
  allowFixing?: boolean;
  allowResizing?: boolean;
  allowHiding?: boolean;
  allowReordering?: boolean;
  allowFiltering?: boolean;
  allowSearch?: boolean;
  allowGrouping?: boolean;
  allowExporting?: boolean;
  allowHeaderFiltering?: boolean;
  allowSorting?: boolean;
  sortOrder?: 'asc' | 'desc' | undefined;
  sortIndex?: number;
  lookup?: PhxDataColumnLookup;
  precision?: number;
  filterOperations?: Array<'=' | '<>' | '<' | '<=' | '>' | '>=' | 'notcontains' | 'contains' | 'startswith' | 'endswith' | 'between'>;
  selectedFilterOperation?: '<' | '<=' | '<>' | '=' | '>' | '>=' | 'between' | 'contains' | 'endswith' | 'notcontains' | 'startswith';
  cssClass?: string;
  fixedPosition?: 'left' | 'right';
  cellTemplate?: any;
  headerCellTemplate?: any;
  isArray?: boolean;
  calculateDisplayValue?: any;
  calculateFilterExpression?: any;
  calculateSortValue?: any;
  calculateGroupValue?: any;
  calculateCellValue?: any;
  customizeText?: any;
  encodeHtml?: boolean;
  isFromOdata?: boolean;
  groupIndex?: number;

  constructor(params: PhxDataTableColumn) {
    params.fixed = params.hasOwnProperty('fixed') ? params.fixed : false;
    params.allowFixing = params.hasOwnProperty('allowFixing') ? params.allowFixing : true;
    params.allowResizing = params.hasOwnProperty('allowResizing') ? params.allowResizing : true;
    params.allowHiding = params.hasOwnProperty('allowHiding') ? params.allowHiding : true;
    params.allowReordering = params.hasOwnProperty('allowReordering') ? params.allowReordering : true;
    params.allowFiltering = params.hasOwnProperty('allowFiltering') ? params.allowFiltering : true;
    params.allowSearch = params.hasOwnProperty('allowSearch') ? params.allowSearch : true;
    params.allowGrouping = params.hasOwnProperty('allowGrouping') ? params.allowGrouping : true;
    params.allowExporting = params.hasOwnProperty('allowExporting') ? params.allowExporting : true;
    params.allowHeaderFiltering = params.hasOwnProperty('allowHeaderFiltering') ? params.allowHeaderFiltering : true;
    params.allowSorting = params.hasOwnProperty('allowSorting') ? params.allowSorting : true;
    params.isArray = params.hasOwnProperty('isArray') ? params.isArray : false;
    params.isFromOdata = params.hasOwnProperty('isFromOdata') ? params.isFromOdata : true;

    params = Object.assign(
      {
        isFromOdata: true,
        showInColumnChooser: true
      },
      params
    );

    const moneyHeaderCellTemplate = (cellElement: any, cellInfo: any) => {
      cellElement.parentElement.setAttribute('style', 'text-align: left;');
      cellElement.innerText = cellInfo.column.caption;
    };  

    if (params.dataType === 'multiValue'){
      params.calculateDisplayValue = (colData => {
        const combinedValues = colData[params.dataField].join(', ');
        if (combinedValues.length > 50){
          return combinedValues.substring(0, 50) + '...';
        }
        return combinedValues;
      });
    }

    if (params.dataType === 'money') {
      params.format = { type: 'fixedPoint', precision: 2 };
      params.alignment = 'right';
      params.headerCellTemplate = moneyHeaderCellTemplate.bind(this);
    }
    if (params.dataType === 'decimal' || params.dataType === 'money') {
      params.dataType = 'number';
      if (params.isFromOdata) {
        const calculateFilterExpression = (filterValue, selectedFilterOperation) => {
          let value = filterValue;
          if (Number.isFinite(filterValue)) {
            value = new EdmLiteral(filterValue + 'm');
          } else if (selectedFilterOperation === 'between') {
            if (Number.isFinite(filterValue[0]) && Number.isFinite(filterValue[1])) {
              return [[this.dataField, '>=', new EdmLiteral(filterValue[0] + 'm')], 'and', [this.dataField, '<=', new EdmLiteral(filterValue[1] + 'm')]];
            }
            return undefined;
          }
          return [this.dataField, selectedFilterOperation || '=', value];
        };

        params.calculateFilterExpression = params.calculateFilterExpression || calculateFilterExpression;
      }
    }
    if (params.dataType === 'date' && (params.cellTemplate == null || params.cellTemplate === '')) {
      params.cellTemplate = 'defaultDateCellTemplate';
    }

    if (params.lookup) {

      const calculateFilterExpression = (filterValue, selectedFilterOperation) => {
        if (filterValue?.length) {
          const filterExpression = [];
          for (let i = 0; i < filterValue.length; i++) {
            const filterExpr = [this.dataField, selectedFilterOperation || '=', filterValue[i]];
            if (i > 0) {
              if (selectedFilterOperation === 'notcontains') {
                filterExpression.push('and');
              } else {
                filterExpression.push('or');
              }
            }
            filterExpression.push(filterExpr);
          }
          return filterExpression;
        }
      };

      params.calculateFilterExpression = params.calculateFilterExpression || calculateFilterExpression;
    }

    Object.assign(this, params);
  }
}
