export interface IDropdown {
  id: number;
  name: string;
  bold?: boolean;
  disabled?: boolean;
  teamId?: string;
  userProfileId?: string;
  optionType?: DropdownFilterType;
}

export enum DropdownFilterType {
  AllCompanyData = 1,
  MyData = 2,
  AllMyTeamsData = 3,
  AllPeopleReportingToMeData = 4
}
