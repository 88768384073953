<h3
  class="title-version"
  (click)="isOpen = !isOpen"
>
  <span class="title-text">
    {{ customEntityName ? customEntityName : entityType + ' ' + (currentVersion || '') }}
    <span
      *ngIf="sortedVersions?.length && selectedIndex > -1"
      class="title-chevron material-icons-outlined"
      id="entity-version-select"
    >
      expand_more
    </span>
  </span>
</h3>

<dx-popover
  target="#entity-version-select"
  [visible]="isOpen"
  (onHidden)="isOpen = false"
  [hideOnOutsideClick]="true"
  width="auto"
  [wrapperAttr]="{ class: 'entity-version-select' }"
>
  <ng-container *dxTemplate="let data of 'content'">
    <div
      class="v-switcher"
      *ngIf="sortedVersions[selectedIndex] as selectedVersion"
    >
      <h5 i18n="@@phx.switchVersion.versionSelection">Version Selection</h5>
      <div class="v-list">
        <div
          class="v-item v-end"
          (click)="selectVersion(0)"
          [class.disabled]="selectedIndex <= 0"
        >
          <span class="material-icons-outlined"> keyboard_double_arrow_left </span>
        </div>
        <div
          class="v-item v-np"
          (click)="selectVersion(selectedIndex - 1)"
          [class.disabled]="selectedIndex <= 0"
        >
          <span class="material-icons-outlined"> arrow_back_ios </span>
        </div>
        <div class="v-item v-detail">
          <p i18n="@@common.versionInterpolation">Version {{ selectedVersion.VersionNumber }}</p>
          <p i18n="@@common.effectiveDateInterpolation">Effective {{ selectedVersion.EffectiveDate | date : phxConstants.DateFormat.mediumDate }}</p>
          <p *ngIf="statusGroup">
            <span i18n="@@common.status">Status</span>
            <ng-container *ngIf="selectedVersion.StatusId">
              {{ selectedVersion.StatusId | CodeValue : statusGroup : 'text' }}
            </ng-container>
            <ng-container *ngIf="selectedVersion.StatusCode && !selectedVersion.StatusId">
              {{ selectedVersion.StatusCode | CodeValueByCode : statusGroup : 'text' }}
            </ng-container>
          </p>
        </div>
        <div
          class="v-item v-np"
          (click)="selectVersion(selectedIndex + 1)"
          [class.disabled]="selectedIndex === sortedVersions.length - 1"
        >
          <span class="material-icons-outlined"> arrow_forward_ios </span>
        </div>
        <div
          class="v-item v-end"
          (click)="selectVersion(sortedVersions.length - 1)"
          [class.disabled]="selectedIndex === sortedVersions.length - 1"
        >
          <span class="material-icons-outlined"> keyboard_double_arrow_right </span>
        </div>
      </div>
      <div
        *ngIf="isShowAllVersionsButtonVisible"
        class="text-center mt10"
      >
        <span
          ><a
            role="button"
            (click)="showAll()"
            i18n="@@phx.switchVersion.showAllVersions"
            >Show All Versions</a
          ></span
        >
      </div>
    </div>
  </ng-container>
</dx-popover>
