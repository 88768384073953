<div class="home-container">
  <div class="max-width-2150 padded">
    <header class="main-header">
      <div class="home-logo">
        <a
          href="https://gorillaworks.io"
          target="_blank"
          aria-label="GorillaWorks"
        >
          <img
            src="../../assets/homepage/logo-home-header.svg"
            alt="GorillaWorksLogo"
            class="header-logo"
          />
        </a>
      </div>

      <nav
        class="home-nav"
        aria-labelledby="navigation"
      >
        <ng-container *ngTemplateOutlet="navigationLinks"></ng-container>
      </nav>
      <button
        type="button"
        mat-icon-button
        class="mobile-menu-button"
        (click)="isShowingMobileMenu = !isShowingMobileMenu"
      >
        <mat-icon>menu</mat-icon>
      </button>
    </header>
  </div>

  <div class="home-content">
    <div class="gray-wrapper">
      <section
        id="top-section"
        class="welcome-section max-width-2150 padded"
      >
        <div class="e2e-solutioning">
          <img
            appApplyClassesWhenScrolledIntoView="slide-in-from-left"
            src="../../assets/homepage/logo.svg"
            alt="FlexBackOfficeLogo"
          />
          <h2>End to End Solutioning</h2>

          <div class="e2e-text-block">
            <p>Contract Management and Finance Solutions for Staffing Companies</p>
          </div>

          <a
            href="#contact-section"
            class="call-to-action-button"
          >
            Let's Talk
          </a>
        </div>
        <div
          appApplyClassesWhenScrolledIntoView="slide-in-from-right"
          class="e2e-solutioning-image"
        >
          <img
            src="../../assets/homepage/e2e-solutioning.svg"
            alt="End to end solutioning"
          />
        </div>
      </section>
    </div>
    <section class="advantages-section max-width-2150 padded">
      <h3>At a Glance</h3>

      <header>
        <p>Staffing can be tough, but back office should be easy.</p>
        <p>Easy for you, easy for your clients and easy for your contractors on assignment. <strong>FLEXBackOffice</strong> can help.</p>
      </header>

      <p class="question">Ready for easy?</p>

      <div class="cards-row max-width-1500">
        <ng-container *ngFor="let i of fboAdvantages">
          <div
            appApplyClassesWhenScrolledIntoView="slide-in-from-bottom"
            class="card"
            [ngStyle]="{ 'background-color': i.bgColor }"
          >
            <mat-icon [ngStyle]="{ color: i.iconColor }">{{ i.icon }}</mat-icon>
            <h4>{{ i.header }}</h4>
            <p>{{ i.text }}</p>
          </div>
        </ng-container>
      </div>
    </section>

    <div class="gray-wrapper">
      <section class="contact-section max-width-2150 padded">
        <div class="row no-padding no-margin max-width-1500 flex-lg">
          <div
            appApplyClassesWhenScrolledIntoView="slide-in-from-left"
            class="col-lg-6 questions-text-container"
          >
            <img
              src="../../assets/homepage/flexback-icon.svg"
              alt="FlexBackOffice"
              class="flexbackoffice-icon"
            />
            <h3>Do you have questions about how FLEXBackOffice can help your business?</h3>

            <p>We’re here for you. Just let us know how we can help and our team will get back to you right away.</p>
          </div>

          <div
            appApplyClassesWhenScrolledIntoView="slide-in-from-right"
            class="col-lg-5 col-lg-offset-1 form-container"
          >
            <article
              class="form"
              id="contact-section"
            >
              <img
                src="../../assets/homepage/flexback.svg"
                alt="FlexBackOfficeLogo"
                class="form-logo"
              />

              <form
                *ngIf="!hasSubmittedTheForm"
                [formGroup]="contactForm"
                (ngSubmit)="submit()"
                class="form-horizontal"
              >
                <phx-form-control
                  [control]="contactForm.controls.fullName"
                  labelText="Full Name"
                  [layoutType]="layoutType"
                  [viewModeText]="contactForm.controls.fullName.value ?? ' '"
                >
                  <input
                    type="text"
                    class="form-control"
                    [formControl]="contactForm.controls.fullName"
                  />
                </phx-form-control>
                <phx-form-control
                  [control]="contactForm.controls.company"
                  labelText="Company"
                  [layoutType]="layoutType"
                  [viewModeText]="contactForm.controls.company.value ?? ' '"
                >
                  <input
                    type="text"
                    class="form-control"
                    [formControl]="contactForm.controls.company"
                  />
                </phx-form-control>
                <phx-form-control
                  [control]="contactForm.controls.phone"
                  labelText="Phone"
                  [layoutType]="layoutType"
                  [viewModeText]="contactForm.controls.phone.value ?? ' '"
                >
                  <input
                    type="tel"
                    class="form-control"
                    [formControl]="contactForm.controls.phone"
                  />
                </phx-form-control>
                <phx-form-control
                  [control]="contactForm.controls.email"
                  labelText="Email"
                  [layoutType]="layoutType"
                  [viewModeText]="contactForm.controls.email.value ?? ' '"
                >
                  <input
                    type="email"
                    class="form-control"
                    [formControl]="contactForm.controls.email"
                  />
                </phx-form-control>

                <phx-form-control
                  [control]="contactForm.controls.message"
                  labelText="Message"
                  [layoutType]="layoutType"
                  [viewModeText]="contactForm.controls.message.value ?? ' '"
                >
                  <textarea
                    class="form-control"
                    rows="6"
                    [formControl]="contactForm.controls.message"
                  ></textarea>
                </phx-form-control>

                <button
                  type="submit"
                  class="call-to-action-button"
                  [disabled]="contactForm.invalid || isSubmittingForm"
                >
                  Let's Talk
                </button>
              </form>

              <div
                *ngIf="hasSubmittedTheForm"
                [@fadeIn]
                class="submitted-message"
              >
                <h3>Thanks for contacting us!</h3>
                <p>We will be in touch with you shortly.</p>
              </div>
            </article>
          </div>
        </div>
      </section>
    </div>

    <div class="dark-wrapper">
      <footer class="footer-section max-width-2150 padded">
        <img
          src="../../assets/homepage/footer-logo.svg"
          alt="GorillaWorks"
          class="footer-logo"
        />
        <hr />
        <a
          href="https://gorillaworks.io"
          target="_blank"
          >gorillaworks.io</a
        >
        <p>{{ currentYear }} © FLEXBackOffice, All Rights Reserved</p>
      </footer>
    </div>
  </div>
</div>

<aside
  *ngIf="isShowingMobileMenu"
  [@slideInFromLeft]
  class="mobile-nav"
>
  <div class="nav-container">
    <button
      type="button"
      mat-icon-button
      (click)="isShowingMobileMenu = false"
      class="collapse-mobile-nav"
    >
      <mat-icon>close</mat-icon>
    </button>

    <nav aria-labelledby="navigation">
      <ng-container *ngTemplateOutlet="navigationLinks"></ng-container>
    </nav>
  </div>
  <div
    (click)="isShowingMobileMenu = false"
    class="overlay"
    onKeyDown=""
  ></div>
</aside>

<ng-template #navigationLinks>
  <a
    href="#top-section"
    (click)="scrollTo('#top-section')"
    class="link active"
    >Home</a
  >
  <a
    href="#contact-section"
    (click)="scrollTo('#contact-section')"
    class="link"
    >Contact</a
  >
  <a
    class="link"
    routerLink="/login"
    target="_self"
    >Login</a
  >
</ng-template>
