import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../common';
import { AuthService } from '../common/services/auth.service';
import { BaseComponentOnDestroy } from '../common/epics/base-component-on-destroy';
import { takeUntil } from 'rxjs/operators';
import { ThemeService } from '../common/services/theme/theme.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class HeaderComponent extends BaseComponentOnDestroy implements OnInit {
  isSideNavOpen = false;
  showGlobalSearch = false;
  logo$ = this.themeService.logo$;

  constructor(private navigationService: NavigationService,
    private authService: AuthService,
    private themeService: ThemeService) {
    super();
  }

  ngOnInit(): void {
    this.checkCurrentUser();
    this.authService.currentProfileChange$.pipe(
      takeUntil(this.isDestroyed$)
    ).subscribe(() => this.checkCurrentUser());
  }

  onSideMenuOpenChanged() {
    this.isSideNavOpen = !this.isSideNavOpen;
    this.navigationService.updateSideNavState(this.isSideNavOpen);
  }

  private async checkCurrentUser() {
    try {
      const user = await this.authService.fetchCurrentProfile();
      this.showGlobalSearch = user?.IsGlobalSearchAllowed;
    } catch (e) {
    }
  }
}
