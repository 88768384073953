<div
  @fading
  [ngClass]="style"
  class="alert alert-dismissible"
  role="alert"
>
  <div class="toast-aligner">
    <span
      class="toast-box"
      [ngSwitch]="style"
    >
      <i
        *ngSwitchCase="phxToastTypes.info"
        class="alert-icon material-icons"
        >info</i
      >
      <i
        *ngSwitchCase="phxToastTypes.warning"
        class="alert-icon material-icons"
        >warning</i
      >
      <i
        *ngSwitchCase="phxToastTypes.success"
        class="alert-icon material-icons"
        >info</i
      >
      <i
        *ngSwitchCase="phxToastTypes.danger"
        class="alert-icon material-icons"
        >error</i
      >
    </span>

    <div class="toast-message">
      <strong
        *ngIf="head?.length"
        class="status-title"
      >
        <span [innerHTML]="head"> </span>
      </strong>
      <span [innerHTML]="messageBody"> </span>
    </div>

    <ng-container *ngIf="optionButtons">
      <ng-container *ngFor="let button of optionButtons; let i = index">
        <button
          [ngClass]="{ 'first-option-button': i === 0 }"
          (click)="onOptionButtonClick(button)"
          type="button"
          class="option"
          [id]="'phx-toast-tooltip-button-' + i"
        >
          {{ button.displayText }}
        </button>
        <app-phx-tool-tip
          [targetId]="'#phx-toast-tooltip-button-' + i"
          [showEvent]="{ delay: 300, name: 'dxhoverstart' }"
        >
          {{ button.toolTip }}
        </app-phx-tool-tip>
      </ng-container>
    </ng-container>

    <button
      type="button"
      class="close close-toast"
      (click)="closeToast()"
    >
      <i class="large material-icons">close</i>
    </button>
  </div>
</div>
