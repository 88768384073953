import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '../../services/api.service';
import { MailCampaignDto } from './models/mail-campaign.dto';
import { EntityList } from '../../model';

@Injectable({
  providedIn: 'root'
})
export class FboMonolithMailCampaignService {
  constructor(private apiService: ApiService) {}

  public getMailCampaignsList(): Observable<EntityList<MailCampaignDto>> {
    return this.apiService.httpGetRequest<EntityList<MailCampaignDto>>('MailCampaign/getListMailCampaign');
  }

  public getCampaignById(id: number): Observable<MailCampaignDto> {
    return this.apiService.httpGetRequest<MailCampaignDto>(`MailCampaign/${id}`);
  }
}
