export interface IWindowMessage {
    messageType: string;
    payload: any;
}

// Can define different window message classes here

/**
 * Use this for entity action events. 
 * E.g. 
 * { event: 'TimesheetSubmit' }
 * { event: 'NewExpenseClaim', entityId: 1 }
 */
export class WindowEntityEventMessage implements IWindowMessage {
    messageType = 'EntityEvent';
    payload: {
        event: string;
        entityId?: number;
    }
}
