export enum TeamStatus {
  Draft = 1,
  PendingReview = 2,
  Active = 3,
  Declined = 4,
  RecalledToDraft = 5,
  Replaced = 6,
  Deleted = 7,
  Inactive = 8
}
