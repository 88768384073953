import { CheckboxFacet, RangeFacet } from 'azsearchstore/dist/store';

import { FacetConfigUiType, IFacetsConfig } from '../../../../global-search/models/global-search-facet';
import { SavedFacetConfig } from '../../../../common/model/saved-facet-config.model';

const getDateAsString = (d: number | Date): string => {
  return new Date(d).toISOString().split('T')?.[0].replace(/-/g, '/');
};

export const convertFacetsConfigToFacetsStringPayload = (config: IFacetsConfig | null): string => {
  const configToSave = (structuredClone(config) as IFacetsConfig).reduce((facetsToStore, facetConfig) => {
    let savedFacetConfig: SavedFacetConfig;
    switch (facetConfig.UiType) {
      case FacetConfigUiType.Dropdown:
      case FacetConfigUiType.Checkbox: {
        const facet = facetConfig.Facet as CheckboxFacet;
        const selectedValues = Object.values(facet.values).filter(value => value.selected);
        if (selectedValues.length) {
          savedFacetConfig = {
            UiType: facetConfig.UiType,
            Facet: {
              values: selectedValues.map(({ value }) => value)
            },
            SearchFieldName: facetConfig.SearchFieldName
          };
        }
        break;
      }
      case FacetConfigUiType.Range: {
        const facet = facetConfig.Facet as RangeFacet;
        if (!facet.filterLowerBound && !facet.filterUpperBound) {
          return;
        }
        savedFacetConfig = {
          UiType: facetConfig.UiType,
          Facet: {
            filterUpperBound: facet.filterUpperBound && facet.filterUpperBound.toString() !== facetConfig.Max.toString() ? getDateAsString(facet.filterUpperBound) : undefined,
            filterLowerBound: facet.filterLowerBound && facet.filterLowerBound.toString() !== facetConfig.Min.toString() ? getDateAsString(facet.filterLowerBound) : undefined
          },
          SearchFieldName: facetConfig.SearchFieldName
        };
        break;
      }
    }

    return savedFacetConfig ? facetsToStore.concat(savedFacetConfig) : facetsToStore;
  }, [] as SavedFacetConfig[]);
  return JSON.stringify(configToSave);
};
