import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { IFacetsConfig } from '../../../global-search/models/global-search-facet';
import { SavedFacetConfig } from '../../model/saved-facet-config.model';

@Injectable({
  providedIn: 'root'
})
export class GlobalSearchFacetsStateService {
  private facetsConfig$ = new BehaviorSubject<IFacetsConfig | null>(null);
  private facetsConfigForUpdate$ = new Subject<SavedFacetConfig[]>();
  private facetsBeingUpdates = false;

  public get areFacetsBeingUpdated(): boolean {
    return this.facetsBeingUpdates;
  }

  public set updatingFacets(state: boolean) {
    this.facetsBeingUpdates = state;
  }

  public set facetsConfig(config: IFacetsConfig) {
    this.facetsConfig$.next(config);
  }

  public set facetsConfigForUpdate(config: SavedFacetConfig[]) {
    this.facetsConfigForUpdate$.next(config);
  }

  public get currentGlobalFacetsConfig(): IFacetsConfig {
    return this.facetsConfig$.getValue();
  }

  public get facetsConfigUpdate$(): Observable<SavedFacetConfig[]> {
    return this.facetsConfigForUpdate$.asObservable();
  }
}
