import { Pipe, PipeTransform } from '@angular/core';
import { PhxLocalizationService } from '../services/phx-localization.service';

const PADDING = '000000';

@Pipe({ name: 'pxCurrency' })
export class PxCurrencyPipe implements PipeTransform {

  private decimalSeparator: string;
  private thousandSeparator: string;

  private isFrench = false;

  constructor(private localizationService: PhxLocalizationService) {
    // TODO comes from configuration settings
    this.decimalSeparator = '.';
    this.thousandSeparator = ',';

    /** TODO: should check for all 'french' language code variations */
    this.isFrench = localizationService.currentLang === 'fr-CA';
  }

  transform(value: number | string, fractionSize: number = 2, symbol: string = null): string {

    if (value === null) { return ''; }
    // if not numeric - leave what the user entered as is
    value = (value + '').replace(/[\s+,]/g, '');
    if (!value || !(new RegExp('^[\\d,\\.?]*$')).test(value)) { return value + ''; }

    let [integer, fraction = ''] = (value || '').toString()
      .split(this.decimalSeparator);

    fraction = fractionSize > 0
      ? this.decimalSeparator + (fraction + PADDING).substring(0, fractionSize)
      : '';

    integer = Number(integer).toString().replace(/\B(?=(\d{3})+(?!\d))/g, this.thousandSeparator);

    if (symbol) {
      return this.isFrench ? `${integer}${fraction} ${symbol}` : symbol + integer + fraction;
    } else {
      return integer + fraction;
    }
  }

  parse(value: string, fractionSize: number = 2, symbol: string = null): string {
    let [integer, fraction = ''] = (value || '').split(this.decimalSeparator);

    integer = integer.replace(new RegExp(this.thousandSeparator, 'g'), '');

    fraction = parseInt(fraction, 10) > 0 && fractionSize > 0
      ? this.decimalSeparator + (fraction + PADDING).substring(0, fractionSize)
      : '';

    if (symbol) {
      return this.isFrench ? `${integer}${fraction} ${symbol}` : symbol + integer + fraction;
    } else {
      return integer + fraction;
    }
  }
}
