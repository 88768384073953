import { ChangeDetectorRef, Component, OnInit, HostListener } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { PhxSideBarService } from 'src/app/common/services/phx-side-bar.service';
import { IframeService, NavigationService } from '../../common';
import { BaseComponentOnDestroy } from '../../common/epics/base-component-on-destroy';
import { AuthService } from 'src/app/common/services/auth.service';
import { UserBehaviorService } from 'src/app/common/services/user-behavior.service';

@Component({
  selector: 'app-layout-component',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.less']
})
export class LayoutComponent extends BaseComponentOnDestroy implements OnInit {
  topMenuOpen = false;
  showBreadcrumb = true;
  isSidebarEnabled = false;
  isSidebarOpen = false;
  //if the app is being loaded in an iFrame then hide the SideNav & top header
  showOnlyMainContent = window.self !== window.top;
  removePaddingMargin = false;
  userIsImpersonating = false;
  isNavigationBarCollapsed = false;

  constructor(
    private navigationService: NavigationService,
    private cdr: ChangeDetectorRef,
    private iframeService: IframeService,
    private phxSidebarService: PhxSideBarService,
    private authService: AuthService,
    private userBehaviorService: UserBehaviorService
  ) {
    super();
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadHandler(event: Event) {
    this.userBehaviorService.clearUserSessionBehaviors();
  }

  ngOnInit() {
    this.navigationService.topNavChange$().pipe(takeUntil(this.isDestroyed$)).subscribe(topNavState => this.topMenuOpen = topNavState);

    this.navigationService.breadCrumbChange$().pipe(takeUntil(this.isDestroyed$)).subscribe(showBreadcrumb => {
      // If is inside Iframe dont want to show
      this.showBreadcrumb = showBreadcrumb && !this.iframeService.isIframe;
      this.cdr.detectChanges();
    });

    /** NOTE: is the sidebar enabled - is the phx-panel-bar component on the page */
    this.phxSidebarService.getSideBarIsEnabled().pipe(
      takeUntil(this.isDestroyed$)
    ).subscribe(isEnabled => {
      this.isSidebarEnabled = isEnabled;
      this.cdr.detectChanges();
    });


    this.navigationService.removePaddingMarginChange$().pipe(takeUntil(this.isDestroyed$)).subscribe(removePaddingMargin => {
      this.removePaddingMargin = removePaddingMargin;
      this.cdr.detectChanges();
    });

    this.phxSidebarService.showSideBar$().pipe(takeUntil(this.isDestroyed$)).subscribe(value => {
      this.isSidebarOpen = value;
      this.cdr.detectChanges();
    });

    this.authService.isImpersonating$.pipe(
      takeUntil(this.isDestroyed$)
    ).subscribe(isImpersonating => this.userIsImpersonating = isImpersonating);

  }

  onToggle() {
    this.phxSidebarService.toggleSideBar();
  }

  handleNavigationBarCollapse(isCollapsed: boolean) {
    this.isNavigationBarCollapsed = isCollapsed;
  }
}
