<section class="phx-mat-dropdown-container">
  <mat-form-field class="w-100">
    <mat-label>{{displayLabel}}</mat-label>
    <input matTooltip="{{selectedItemsDisplayText}}" (focusin)="openDropdown()" [value]="selectedItemsDisplayText" readonly matInput placeholder="{{displayLabel}}">
    <mat-icon matSuffix>arrow_drop_down</mat-icon>
    <mat-error *ngIf="selectFormControl.hasError('required')">
      {{validationMessage}}
    </mat-error>
  </mat-form-field>

  <ng-container *ngIf="isDropdownOpen">
    <section class="dropdown-open-overlay" (click)="closeDropdown()"></section>
    <section class="dropdown-options-container mat-elevation-z3">
      <mat-form-field class="w-100">
        <input #searchFilterMatInput [formControl]="searchFilterFormControl" matInput placeholder="{{placeholderLabel}}">
      </mat-form-field>
      <mat-selection-list *ngIf="multiple; else singleSelectionList" [formControl]="selectFormControl">
        <cdk-virtual-scroll-viewport class="vs-viewport" itemSize="10">
          <ng-container *cdkVirtualFor="let item of filteredDropdownData  | async; trackBy: trackByFn">
            <mat-divider *ngIf="item.bold"></mat-divider>
            <mat-list-option checkboxPosition="before" [value]="item.id" [disabled]="item.disabled">
              <span title="{{item.name}}" class="text-ellipsis" [class.bold]="item.bold">{{item.name}}</span>
            </mat-list-option>
          </ng-container>
        </cdk-virtual-scroll-viewport>
      </mat-selection-list>
      <ng-template #singleSelectionList>
        <mat-list>
          <cdk-virtual-scroll-viewport class="vs-viewport" itemSize="10">
            <ng-container *cdkVirtualFor="let item of filteredDropdownData  | async; trackBy: trackByFn">
              <mat-divider *ngIf="item.bold"></mat-divider>
              <mat-list-item (click)="onSingleSelectionItemClick(item)">
                <span title="{{item.name}}" class="text-ellipsis" [class.bold]="item.bold">{{item.name}}</span>
              </mat-list-item>
            </ng-container>
          </cdk-virtual-scroll-viewport>
        </mat-list>
      </ng-template>
      <section *ngIf="showActionButtons" class="action-buttons-container">
        <button class="btn btn-outline" (click)="reset()">{{phoenixCommonModuleResourceKeys.generic.reset | phxTranslate}}</button>
        <button class="btn btn-primary" (click)="closeDropdown()">{{phoenixCommonModuleResourceKeys.generic.okay | phxTranslate}}</button>
      </section>
    </section>
  </ng-container>
</section>
