import { DialogRef } from '@angular/cdk/dialog';
import { Component, Input, EventEmitter, Output, ViewChild, TemplateRef } from '@angular/core';

import { PhxLocalizationService } from '../../services/phx-localization.service';
import { PhoenixCommonModuleResourceKeys } from '../../PhoenixCommonModule.resource-keys';
import { DialogComment, DialogResultType } from '../../model';
import { DialogService } from '../../services/dialog.service';

@Component({
  selector: 'app-phx-dialog-comment',
  templateUrl: './phx-dialog-comment.component.html',
  styleUrls: ['./phx-dialog-comment.component.less']
})
export class PhxDialogCommentComponent {
  @Input() title: string;
  @Input() inputname: string = '';
  @Input() helpblock: string;
  @Input() saveButtonText: string;
  @Input() cancelButtonText: string;
  @Input() saveButtonClass: string = 'default';
  @Input() maxLength: number = 4000;

  @Output() onclose: EventEmitter<any> = new EventEmitter();
  @ViewChild('dialogCommentTemplate', { static: true }) dialogCommentTemplate: TemplateRef<void>;

  comment: string;

  private dialogRef?: DialogRef<void>;

  constructor(private localizationService: PhxLocalizationService, private dialogService: DialogService) {
    // Defaults
    this.title = this.localizationService.translate(PhoenixCommonModuleResourceKeys.phxDialogComment.title);
    this.helpblock = this.localizationService.translate(PhoenixCommonModuleResourceKeys.phxDialogComment.helpblock);
    this.saveButtonText = this.localizationService.translate(PhoenixCommonModuleResourceKeys.phxDialogComment.saveBtn);
    this.cancelButtonText = this.localizationService.translate(PhoenixCommonModuleResourceKeys.phxDialogComment.cancelBtn);
  }

  public open(): void {
    this.dialogRef = this.dialogService.showInPopup(this.dialogCommentTemplate);
  }

  public updateComment(comment: string): void {
    this.comment = comment;
  }

  public save(): void {
    if (this.comment?.trim().length) {
      // TODO: validation
      this.close();
    }
  }

  public cancel(): void {
    this.close(true);
  }

  private close(isCancel: boolean = false) {
    this.dialogRef?.close();

    const result: DialogComment = {
      ResultType: !isCancel ? DialogResultType.OK : DialogResultType.Close,
      Comment: !isCancel ? this.comment : null
    };
    this.onclose.emit(result);

    this.comment = null;
  }
}
