import { Component, Inject, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { StorageService } from 'ngx-webstorage-service';
import { takeUntil } from 'rxjs/operators';
import { AppStorageService, IframeService, PhxConstants } from 'src/app/common';
import { BaseComponentOnDestroy } from 'src/app/common/epics/base-component-on-destroy';
import { AvailableWorkOrder } from 'src/app/expense/model/available-work-order';
import { ReportAZSearchService } from 'src/app/report-azsearch/service/report-azsearch.service';

@Component({
  selector: 'app-redirect-mappeduser',
  templateUrl: './redirect-mappeduser.component.html',
  styleUrls: ['./redirect-mappeduser.component.less']
})
export class RedirectMappeduserComponent extends BaseComponentOnDestroy implements OnInit {
  availableWorkOrdersForExpense: Array<AvailableWorkOrder> = [];

  constructor(
    private reportService: ReportAZSearchService,
    private router: Router,
    private route: ActivatedRoute,
    private iframeService: IframeService,
    @Inject(AppStorageService) private storageService: StorageService) {
    super();
  }

  ngOnInit() {
    const isIframe = this.iframeService.isIframe;
    const navigationExtras = this.iframeService.navigationExtras;

    this.route.params.pipe(takeUntil(this.isDestroyed$)).subscribe(params => {
      const { redirectType, entityId } = params;

      if (redirectType === PhxConstants.RedirectTypeForMappedUser.TimeSheet.toString()) {
        this.router.navigate([`/next/timesheet/${entityId}`], navigationExtras);
      } else if (redirectType === PhxConstants.RedirectTypeForMappedUser.Expense.toString()) {
        this.router.navigate([`/next/expense/${entityId}`], navigationExtras);
      } else if (redirectType === PhxConstants.RedirectTypeForMappedUser.AgencyTimesheet.toString()) {
        this.router.navigate([`/next/timesheet/agencytimesheet`], navigationExtras);
      } else if (redirectType === PhxConstants.RedirectTypeForMappedUser.NewExpense.toString()) {
        this.checkExpenseCreateNewAccess().then(() => {
          if (this.availableWorkOrdersForExpense.some(wo => wo.Id === +entityId)) {
            this.reportService.createNewExpenseClaim(+entityId)
            .then(expenseClaimId => {
              if (isIframe) {
                this.iframeService.postEventMessage('NewExpenseClaim', expenseClaimId);
              }
              this.router.navigate(['/next', 'expense', `${expenseClaimId}`], navigationExtras);
            });
          }
        });
      }
    });
  }

  private async checkExpenseCreateNewAccess() {
    this.availableWorkOrdersForExpense = await this.reportService.getAvailableWorkOrders();
    return this.availableWorkOrdersForExpense?.length > 0;
  }
}
