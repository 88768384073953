export const AnalyticsFeatureTypes = {
  Organization: 'Organization',
  People: 'People',
  Profile: 'Profile',
  RedirectionLink: 'Redirection link',
  ThirdPartyTimesheets: 'Third Party Timesheets',
  ToggleNavigation: 'Toggle Navigation',
  Transaction: 'Transaction',
  Workorder: 'Workorder'
} as const;
