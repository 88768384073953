import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { forEach, values } from 'lodash';
import { takeUntil } from 'rxjs/operators';

import { AuthService } from '../../common/services/auth.service';
import { PhxFormControlLayoutType } from '../../common/model';
import { DialogService, LoadingSpinnerService, ValidationExtensions } from '../../common';
import { AccountModuleResourceKeys } from '../account-module-resource-keys';
import { BaseComponentOnDestroy } from '../../common/epics/base-component-on-destroy';
import { AppInitService } from 'src/app/app-init.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.less']
})
export class RegisterComponent extends BaseComponentOnDestroy implements OnInit {
  form: UntypedFormGroup;
  passwordGroup: UntypedFormGroup;
  cultureList: Array<{ text; id }> = [];
  isLoading = true;
  tokenObj: any;
  validationMessages: Array<any> = [];
  formControlLayoutType: PhxFormControlLayoutType = PhxFormControlLayoutType.Stacked;

  @ViewChild('invalidTokenTemplate') templateInvalidToken: TemplateRef<any>;
  @ViewChild('existingUserTemplate') templateExistingUser: TemplateRef<any>;
  @ViewChild('registerTemplate') templateRegisterUser: TemplateRef<any>;

  accountResourceKeys = AccountModuleResourceKeys;

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private loadingSpinner: LoadingSpinnerService,
    private dialogService: DialogService,
    private appInitService: AppInitService
  ) {
    super();
  }

  static MatchPassword(group: UntypedFormGroup) {
    const pass = group.controls.password.value;
    const confirmPass = group.controls.confirmPassword.value;

    return pass === confirmPass ? null : { passwords: true };
  }

  initializeForm() {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.minLength(6)]],
      newPasswordGroup: this.passwordGroup = this.fb.group(
        {
          password: [
            '',
            [
              Validators.required,
              // 1. check whether the entered password has a min lenght
              Validators.minLength(8),
              // 2. check whether the entered password has a number
              ValidationExtensions.pattern(/\d/, 'Must have at least 1 number'),
              // 3. check whether the entered password has upper case letter
              ValidationExtensions.pattern(/[A-Z]/, 'Must contain at least 1 capital case letter'),
              // 4. check whether the entered password has a lower-case letter
              ValidationExtensions.pattern(/[a-z]/, 'Must contain at least 1 lower case letter'),
              // 5. check whether the entered password has a special character
              ValidationExtensions.pattern(/[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, 'Must contain at least 1 special character')
            ]
          ],
          confirmPassword: ['', [Validators.required, Validators.minLength(8)]]
        },
        { validator: ValidationExtensions.passwords() }
      ),
      cultureId: [null, [Validators.required]]
    });
  }

  getCultureList(translations): any {
    const cultureObj = translations.codeValue?.culture;
    if (cultureObj) {
      return Object.keys(cultureObj).map(key => ({ id: +key, text: cultureObj[key] }));
    } else {
      return null;
    }
  }

  ngOnInit() {
    this.initializeForm();
    const et = this.activatedRoute.snapshot.queryParams.et;
    this.authService.validateRegistrationToken(et)
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe({
        next: (tokenObj: any) => {
          this.tokenObj = tokenObj;
          this.tokenObj.token = et;
          if (tokenObj) {
            this.form.patchValue(
              {
                email: tokenObj.UserName,
                cultureId: tokenObj.CultureId
              },
              { emitEvent: true }
            );

            // TODO: Call PhxLocalizationService.setLocale(tokenObj.CultureId) here? Remove PhoenixCultureId request header?

            if ((window as any).PhxTranslations) {
              // has translation data
              this.cultureList = this.getCultureList((window as any).PhxTranslations);
              this.isLoading = false;
            } else {
              this.authService.loadTranslationDataByBrowserCulture()
                .pipe(takeUntil(this.isDestroyed$))
                .subscribe(res => {
                  (window as any).PhxTranslations = res;
                  this.cultureList = this.getCultureList(res);
                  this.isLoading = false;
                });
            }
          }
        },
        error: () => {
          this.isLoading = false;
        }
      });
  }

  register() {
    this.loadingSpinner.show();
    const username = this.form.controls.email.value;
    const password = this.passwordGroup.controls.password.value;
    const cPassword = this.passwordGroup.controls.confirmPassword.value;
    const cultureId = this.form.controls.cultureId.value;
    this.authService.register(username, password, cPassword, +cultureId, this.tokenObj.token)
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe({
        next: () => {
          // FIXME: get rid of the nested subscription, this is an anti-pattern
            this.authService.login(username, password)
              .pipe(takeUntil(this.isDestroyed$))
              .subscribe({
                next: () => {
                  this.appInitService.initApp().then(() => {
                    this.router.navigateByUrl('next/activity-centre');
                    this.loadingSpinner.hide();
                  });
                },
                error: loginErr => {
                  this.dialogService.notify(loginErr);
                  this.loadingSpinner.hide();
                }
              });
          },
        error: err => {
          this.validationMessages = [];
          Object.keys(err.error).forEach(item => {
            if (item === 'ModelState' && Object.keys(err.error[item]).length) {
              const val = values(err.error[item]);
              forEach(val, value => {
                for (const msg of value) {
                  this.validationMessages.push(msg);
                }
              });
            } else if (item === 'Message' && Object.keys(err.error[item]).length) {
              this.validationMessages.push(err.error[item]);
            }
          });

          this.loadingSpinner.hide();
        }
      });
  }

  getTemplate() {
    let retVal: TemplateRef<any>;
    if (this.tokenObj) {
      if (this.tokenObj.IsExistingUser) {
        retVal = this.templateExistingUser;
      } else {
        retVal = this.templateRegisterUser;
      }
    } else {
      retVal = this.templateInvalidToken;
    }
    return retVal;
  }

  redirectHomePage() {
    this.router.navigate(['/']);
  }

  redirectLoginPage() {
    this.router.navigate(['/login']);
  }
}
