<div class="account-container stacked" *ngIf="true">
  <div class="content clearfix margin-10-v-t">
    <div class="col-xs-12" *ngIf="isPasswordReset">
      {{accountModuleResourceKeys.reset.pwdResetRedirectionMessage | phxTranslate}}
    </div>
    <form [formGroup]="form" *ngIf="form &&  !isPasswordReset">
      <h1 class="col-xs-12">{{accountModuleResourceKeys.reset.resetPwdLabel | phxTranslate}}</h1>
      <div class="login-fields">
        <p class="col-xs-12">{{accountModuleResourceKeys.reset.pwdResetMessage | phxTranslate}}</p>
        <div class="field">
          <phx-form-control [control]="form.controls['email']" [labelText]="'Email'" [editable]="true"
            [showValidationMessages]="true" [layoutType]="formControlLayoutType">
            <input type="password" class="form-control" type="email" placeholder="Email" formControlName="email" id="email" aria-required="true" />
          </phx-form-control>
        </div>
        <div formGroupName="newPasswordGroup">
          <div class="field">
            <phx-form-control [control]="newPasswordGroup.controls['password']"
              [labelText]="accountModuleResourceKeys.reset.pwdLabel| phxTranslate" [editable]="true"
              [showValidationMessages]="true" [layoutType]="formControlLayoutType">
              <input type="password" class="form-control" placeholder="Password" formControlName="password" id="password" aria-required="true" />
            </phx-form-control>
          </div>
          <div class="field">
            <phx-form-control [control]="newPasswordGroup.controls['confirmPassword']"
              [labelText]="accountModuleResourceKeys.reset.confirmPwdLabel | phxTranslate" [editable]="true"
              [showValidationMessages]="true" [layoutType]="formControlLayoutType">
              <input type="password" class="form-control" placeholder="Confirm Password"
                formControlName="confirmPassword" id="confirmPassword" aria-required="true" />
            </phx-form-control>
          </div>
        </div>
      </div>

      <div class="col-xs-12">
        <phx-form-control-validation [control]="newPasswordGroup" *ngIf="!newPasswordGroup.controls['confirmPassword'].pristine">
        </phx-form-control-validation>
      </div>

      <div class="col-xs-12">
        <app-phx-validation-messages [messages]="validationMessages"></app-phx-validation-messages>
        <app-phx-validation-messages [messages]="validationDataParams" *ngIf = "validationDataParams"></app-phx-validation-messages>
      </div>
      <div class="login-actions col-xs-12">
        <button class="login-action btn btn-primary" (click)="reset()"
          [disabled]="!form.valid || !form.dirty">{{accountModuleResourceKeys.reset.resetPwdLabel | phxTranslate}}</button>
      </div>
    </form>
  </div>
</div>