import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { first, map, withLatestFrom } from 'rxjs/operators';

import { EntityVersion, PhxConstants } from '../../model';
import { PhxSideBarService } from '../../services/phx-side-bar.service';
import { HeaderVersionHeightService } from '../../services/header-version-height.service';

@Component({
  selector: 'app-phx-header-version',
  templateUrl: './phx-header-version.component.html',
  styleUrls: ['./phx-header-version.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PhxHeaderVersionComponent implements OnChanges, AfterViewInit, OnDestroy {
  @Input() initials: string;

  @Input() entityName: string;
  @Input() entityPath: string;
  @Input() entityStatus: string;

  @Input() customEntityName: string;
  @Input() complianceStatus: number;

  @Input() statusGroup: string;

  @Input() multiple: boolean;
  @Input() currentVersion: number;
  @Input() entityVersions: EntityVersion[];
  @Input() doEmitComputedHeight = true;

  @Output() selected = new EventEmitter<EntityVersion>();

  @ViewChild('header') header: ElementRef<HTMLDivElement>;

  isPositionFixed = false;
  entityStatusIcon = null;

  hasVersionsPanel$ = this.phxSideBarService.panelListChange$().pipe(map(panels => panels?.some(({ type }) => type === PhxConstants.PanelBarType.VERSIONS)));
  paddingTop = 'initial';

  private observer?: MutationObserver;

  constructor(private phxSideBarService: PhxSideBarService, private headerVersionHeightService: HeaderVersionHeightService, private el: ElementRef) {}

  ngAfterViewInit(): void {
    if (this.doEmitComputedHeight) {
      this.initObservingHeaderHeight();
    }
  }

  ngOnDestroy(): void {
    this.observer?.disconnect();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.entityStatus) {
      this.entityStatusIcon = null;
      const newStatus = changes.entityStatus.currentValue;

      if (newStatus === PhxConstants.StatusType.Critical) {
        this.entityStatusIcon = 'close';
      } else if (newStatus === PhxConstants.StatusType.Warn) {
        this.entityStatusIcon = 'warning';
      } else if (newStatus === PhxConstants.StatusType.Success) {
        this.entityStatusIcon = 'check_circle';
      }
    }
  }

  @HostListener('window:scroll', [])
  fixHeadstoneOnTop() {
    if (this.isPositionFixed !== window.scrollY > 70) {
      this.isPositionFixed = window.scrollY > 70;

      /**
       * Once the element has fixed position, its height is not taken into the account when determining if the parent
       * component has a scrollbar, this could cause issue of the scrollbar disappearing once the element becomes fixed,
       * if the height of its parent no longer is enough to maintain the scrollbar, which automatically sets it back to
       * non-fixed, as the scrollY becomes 0.
       * To address this problem, we add padding equal to the original height of the now fixed element, which allows
       * to maintain the original height of the element, even though a part of it has become fixed and is outside the
       * normal flow. */
      this.paddingTop = this.isPositionFixed ? this.getComputedElementHeight() - this.el.nativeElement.offsetTop + 'px' : 'initial';
    }
  }

  selectVersion(version: EntityVersion) {
    if (version.VersionNumber !== this.currentVersion) {
      this.selected.emit(version);
    }
  }

  showVersionsPanel(): void {
    this.phxSideBarService
      .getSideBarIsEnabled()
      .pipe(withLatestFrom(this.phxSideBarService.showSideBar$(), this.hasVersionsPanel$), first())
      .subscribe(([isEnabled, isShown, hasVersionsPanel]) => {
        if (!isEnabled || !hasVersionsPanel) {
          return;
        }

        this.phxSideBarService.setActivePanel(PhxConstants.PanelBarType.VERSIONS);

        if (!isShown) {
          this.phxSideBarService.openSidebar();
        }
      });
  }

  private observeContentChanges(): void {
    this.observer = new MutationObserver(() => {
      this.emitComputedHeight();
    });

    const config: MutationObserverInit = { childList: true, subtree: true, attributes: true };
    this.observer.observe(this.header.nativeElement, config);
  }

  private emitComputedHeight(): void {
    this.headerVersionHeightService.height = this.getComputedElementHeight();
  }

  private getComputedElementHeight(): number {
    const el = this.header.nativeElement;
    const computedStyle = window.getComputedStyle(el);
    const paddingTop = parseFloat(computedStyle.paddingTop);
    const paddingBottom = parseFloat(computedStyle.paddingBottom);
    const marginTop = parseFloat(computedStyle.marginTop);
    const marginBottom = parseFloat(computedStyle.marginBottom);
    return el.offsetTop + el.offsetHeight + paddingBottom + paddingTop + marginBottom + marginTop;
  }

  /* Observing fixed header height is necessary for correct
     calculation of offset for sticky elements,
     so they don't end up covered by it
  */
  private initObservingHeaderHeight(): void {
    this.emitComputedHeight();
    this.observeContentChanges();
  }
}
