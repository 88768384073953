import { Injectable } from '@angular/core';
import { ApiService } from '../common';
import { CustomField } from './model/custom-field';

@Injectable()
export class ClientSpecificFieldsService {

  constructor(private apiSvc: ApiService) {
  }

  async getCustomFields(clientId, entityId, entityTypeId): Promise<CustomField[]> {
    try {
      const res: any = await this.apiSvc.queryWithPromise(`ClientBasedEntityCustomField/GetCustomFields?organizationId=${clientId}&entityType=${entityTypeId}&entityId=${entityId}`);
      return res ? res.CustomFields : [];
    } catch (e) {
      return e;
    }
  }
}
