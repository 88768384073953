<div class="side-bar adjust-for-top-nav">
  <div
    class="p-bar fixed"
    [ngClass]="{ closed: !isSidebarOpen, 'in-iframe': inIframe }"
  >
    <button
      *ngIf="hasExpandButton"
      class="btn expand"
      (click)="toggleVisibility()"
    >
      <span class="material-icons-outlined">
        {{ isSidebarOpen ? 'arrow_forward_ios' : 'arrow_back_ios' }}
      </span>
    </button>
    <ng-container *ngFor="let panel of panelList">
      <app-phx-tool-tip
        position="left"
        targetId="#pbar-{{ panel.type }}"
        container=".pbar"
      >
        <div class="p-tooltip">
          {{ panel.name }}
        </div>
      </app-phx-tool-tip>
      <button
        id="pbar-{{ panel.type }}"
        class="btn"
        [ngClass]="{ opened: isSidebarOpen && selectedPanel?.type === panel.type }"
        (click)="clickPanel(panel)"
      >
        <span class="material-icons-outlined">
          {{ panel.type }}
        </span>
      </button>
    </ng-container>
  </div>
  <div
    [hidden]="!isSidebarOpen"
    [@slideRightToggle]
    class="p-body fixed"
    [ngClass]="{'in-iframe': inIframe }"
  >
    <div>
      <ng-container *ngFor="let panel of panelList">
        <div
          *ngIf="panel.type === phxConstants.PanelBarType.CHECKLIST"
          [hidden]="selectedPanel?.type !== phxConstants.PanelBarType.CHECKLIST"
        >
          <app-phx-panel-checklist> </app-phx-panel-checklist>
        </div>
        <div
          *ngIf="panel.type === phxConstants.PanelBarType.VERSIONS"
          [hidden]="selectedPanel?.type !== phxConstants.PanelBarType.VERSIONS"
        >
          <app-phx-panel-version> </app-phx-panel-version>
        </div>
        <div *ngIf="panel.type === phxConstants.PanelBarType.NOTES"
          [hidden]="selectedPanel?.type !== phxConstants.PanelBarType.NOTES">
          <app-phx-panel-notes> </app-phx-panel-notes>
        </div>
        <div *ngIf="panel.isCustom" [hidden]="selectedPanel?.type !== panel.type">
          <app-phx-panel-custom [componentType]="panel.customComponentType" [componentInputs]="panel.customInputs"
            [name]="panel.name" [icon]="panel.type">
          </app-phx-panel-custom>
        </div>
      </ng-container>
    </div>
  </div>
</div>
