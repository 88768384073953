import { DialogRef } from '@angular/cdk/dialog';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';

import { PhxButton } from '../../model';
import { BaseComponentOnDestroy } from '../../epics/base-component-on-destroy';
import { IPhxModalMessage } from './phx-modal.interface';
import { DialogService } from '../../services/dialog.service';

@Component({
  selector: 'app-phx-modal',
  templateUrl: './phx-modal.component.html',
  styleUrls: ['./phx-modal.component.less']
})
export class PhxModalComponent extends BaseComponentOnDestroy implements OnInit, OnChanges {
  @ViewChild('itemTemplate', { static: true }) itemTemplate: TemplateRef<void>;
  @Input() buttons: PhxButton[] = [];
  @Input() title: string;
  @Input() showCloseButton: boolean = true;
  @Input() cssClass: string;
  @Input() fullScreen: boolean = false;
  @Input() showChevronButton: boolean = false;
  @Input() noBodyPadding: boolean = false;
  @Input() noMessagePadding: boolean = false;
  @Input() showHeader: boolean = false;
  @Input() messages: Array<IPhxModalMessage> = [];
  @Input() disableCloseWithBackdropClick = false;

  /**
   * Specifies whether the search functionality should be enabled on mobile view of this modal
   */
  @Input() enableSearchFeature: boolean = false;

  @Output() closeModal: EventEmitter<any> = new EventEmitter();
  @Output() filter: EventEmitter<string> = new EventEmitter();

  modalRef: DialogRef<void>;
  isVisible: boolean;
  isSearchVisible: boolean = false;

  private modalConfig = {
    class: 'responsive-modal modal-md '
  };

  constructor(private router: Router, private dialogService: DialogService) {
    super();
  }

  ngOnInit(): void {
    // To close the modals when user moves out from the page using browser navigation buttons
    this.router.events.pipe(takeUntil(this.isDestroyed$)).subscribe(() => {
      this.modalRef?.close();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.cssClass) {
      if (changes.cssClass.currentValue && changes.cssClass.currentValue !== '') {
        this.addClassToConfig(changes.cssClass.currentValue);
      } else if (changes.cssClass.previousValue && changes.cssClass.previousValue !== '') {
        this.removeClassFromConfig(changes.cssClass.previousValue);
      }
    }
  }

  addClassToConfig(cssClass: string) {
    if (!this.modalConfig.class.includes(cssClass)) {
      this.modalConfig.class += ` ${cssClass}`;
    }
  }

  removeClassFromConfig(cssClass: string) {
    if (this.modalConfig.class.includes(cssClass)) {
      this.modalConfig.class.replace(cssClass, '');
    }
  }

  setModalClasses() {
    if (this.fullScreen) {
      this.addClassToConfig('phx-full-screen-modal');
      this.removeClassFromConfig('phx-modal');
    } else {
      this.removeClassFromConfig('phx-full-screen-modal');
      this.addClassToConfig('phx-modal');
    }
  }

  show() {
    this.setModalClasses();
    this.modalRef = this.dialogService.showInPopup(this.itemTemplate, {
      panelClass: this.modalConfig.class.split(' '),
      disableClose: this.disableCloseWithBackdropClick
    });
    this.isSearchVisible = false;
    this.isVisible = true;
  }

  hide(raiseEvent: boolean = true) {
    this.modalRef?.close();
    if (this.isVisible && raiseEvent) {
      this.closeModal.emit();
    }
    this.isVisible = false;
    this.modalRef = null;
  }

  /**
   * Emitting the filter event
   * @param filterText The filter text
   */
  filterList(filterText: string) {
    this.filter.emit(filterText);
  }
}
