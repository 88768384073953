import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { DatePipe, formatDate } from '@angular/common';
import { PhxConstants } from '../../model';
import { IPIILastViewedByUserInfo, IPIIMetaData } from './phx-pii-field.interface';
import { ApiService, CommonService, PhxLocalizationService, PhxToastService } from '../..';
import { PhxToastType } from '../phx-toast/phx-toast-types';
import { environment } from '../../../../environments/environment';
import { PiiRevealService } from './pii-reveal.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-phx-pii-field',
  templateUrl: './phx-pii-field.component.html',
  styleUrls: ['./phx-pii-field.component.less'],
  providers: [DatePipe]
})
export class PhxPiiFieldComponent implements OnInit {
  @Input() piiMetaData: IPIIMetaData;
  @Input() removeStyleClasses = false;
  @Output() revealedPiiMetaData: EventEmitter<IPIIMetaData> = new EventEmitter<IPIIMetaData>();
  
  showPopup = false;
  lastViewedUserData = {} as Omit<IPIILastViewedByUserInfo, 'LastViewedDate'> & {
    LastViewedDate: string;
  };
  phxConstants = PhxConstants;
  loadingLastViewedUserInfo = false;

  private readonly datePiiConfigurationIds = [PhxConstants.PIIConfiguration.UserProfileDateOfBirth, PhxConstants.PIIConfiguration.OnboardingSourceDateOfBirth];

  constructor(
    private apiService: ApiService,
    private commonService: CommonService,
    private toastService: PhxToastService,
    private cd: ChangeDetectorRef,
    private datePipe: DatePipe,
    @Optional() @Inject(PiiRevealService) private piiRevealService: PiiRevealService
    ) {
  }

  ngOnInit() {
    if (this.piiMetaData) {
      if (!this.piiMetaData.IsMasked && this.piiMetaData.FieldValue && this.datePiiConfigurationIds.some(x => x === this.piiMetaData.PIIConfigurationId)) {
        this.piiMetaData.FieldValue = this.datePipe.transform(this.piiMetaData.FieldValue.toString(), PhxConstants.DateFormat.mediumDate);
      }
    }
  }

  onIconClicked() {
    if (this.piiMetaData) {
      this.showPopup = this.piiMetaData.PIIRestrictionLevelId === PhxConstants.PIIRestrictionLevel.Level1;
      if (this.showPopup) {
        this.loadingLastViewedUserInfo = true;
        // noinspection JSIgnoredPromiseFromCall
        this.getLastViewedUserInfo();
      }

      if (this.piiMetaData.PIIRestrictionLevelId === this.phxConstants.PIIRestrictionLevel.Level2) {
        this.reveal();
      }
    }
  }

  closePopup() {
    this.showPopup = false;
  }

  reveal() {
    let promise: Promise<any>;

    if (this.piiRevealService){
      promise = lastValueFrom(this.piiRevealService.revealPiiData(this.piiMetaData));
    } else if (this.piiMetaData.EntityType === PhxConstants.EntityType.Organization) {
      const endpoint = `Organization/${this.piiMetaData.EntityId}/${this.piiMetaData.EntityVersion}/pii-field/${this.piiMetaData.PIIConfigurationId}/${this.piiMetaData.FieldPath}`;
      promise = lastValueFrom(this.apiService.httpGetRequest(endpoint, environment.organizationServiceApiEndpoint));
    } else if (this.piiMetaData.EntityType === PhxConstants.EntityType.UserProfile || this.piiMetaData.IsOCRData) {
      const endpoint = `People/${this.piiMetaData.EntityId}/${this.piiMetaData.EntityVersion}/pii-field/${this.piiMetaData.PIIConfigurationId}/${this.piiMetaData.FieldPath}`;
      promise = lastValueFrom(this.apiService.httpGetRequest<string>(endpoint, environment.peopleServiceApiEndpoint));
    } else if (this.piiMetaData.EntityType === PhxConstants.EntityType.OnboardingSource) {
      const endpoint = `OnboardingSource/${this.piiMetaData.EntityId}/pii-field/${this.piiMetaData.PIIConfigurationId}/${this.piiMetaData.FieldPath}`;
      promise = lastValueFrom(this.apiService.httpGetRequest<string>(endpoint, environment.fboOnboardingServiceApiEndpoint));
    } else {
      promise = lastValueFrom(this.apiService.query<string>(`PII/GetFieldValueAsString/configuration/${this.piiMetaData.PIIConfigurationId}/Entity/${this.piiMetaData.EntityId}`, false));
    }

    promise.then(response => {
      if (this.datePiiConfigurationIds.some(x => x === this.piiMetaData.PIIConfigurationId)) {
        this.piiMetaData.FieldValue = formatDate(response.toString(), PhxConstants.DateFormat.mediumDate, PhxLocalizationService.locale);
      } else {
        this.piiMetaData.FieldValue = response;
      }

      this.piiMetaData.IsMasked = false;
      this.revealedPiiMetaData.emit(this.piiMetaData);
    })
    .catch(err => {
      if (err.status === PhxConstants.HTTPResponseStatus.Forbidden && err.error) {
        const remainingTime = err.error.remainingTime ?? err.error.RemainingTime; // TODO: Remove capitalized RemaningTime when all PII is migrated to MARS
        this.toastService.showToast(null,
          `You've reached an access limit. Try again in: ${remainingTime}`, PhxToastType.info, null);
      }
    })
    .finally(() => {
      this.showPopup = false;
    });
  }

  private async getLastViewedUserInfo() {
    const lastViewedUserData = await lastValueFrom(this.apiService.query<IPIILastViewedByUserInfo>(`userProfile/getUserInfoForPIIFieldView/${this.piiMetaData.PIIConfigurationId}`, false));
    
    if (lastViewedUserData) {
      lastViewedUserData.LastViewedDate = this.commonService.convertUTCToLocal(lastViewedUserData.LastViewedDate);
      this.lastViewedUserData = lastViewedUserData as PhxPiiFieldComponent['lastViewedUserData'];
    }
    
    this.loadingLastViewedUserInfo = false;
    this.cd.detectChanges();
  }
}
