import { Injectable } from '@angular/core';

@Injectable()
export class UtilityService {

    constructor() { }

    createUuid (): any {
        const s = [];
        const hexDigits = '0123456789abcdef';
        for (let i = 0; i < 36; i++) {
            s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
        }
        s[14] = '4'; // bits 12-15 of the time_hi_and_version field to 0010
        s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
        s[8] = s[13] = s[18] = s[23] = '-';

        const uuid = s.join('');
        return uuid;
    }

    isEffectiveDateInfinite (entity: any): any {
        if (!entity) {return false};

        const date = entity.ExpiryDate;
        if (!date) {return true};

        return ((date.valueOf() === (new Date(1900, 1 - 1, 1).valueOf())) ||
            (date.valueOf() === -62135578800000) ||
            (date.valueOf() === (new Date(9999, 12 - 1, 31)).valueOf()));
    }

    isValid (entity: any): any {
        let result = true;
        for (const property in entity.BrokenRules) {
            if (entity.BrokenRules[property].length > 0) {
                result = false;
            }
        }
        return result;
    }

}
