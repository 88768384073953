export class PhxDocumentFileUploadConfiguration {
  entityTypeId: number;
  entityId: number;
  documentTypeId: number;
  limitMultiFileUploads?: number;
  UploadTitle?: string;
  SupportedFileExtensions?: string;
  customId1?: number;
  customId2?: number;
  customMethodata?: number;
  customDateTime?: string;
  customComment?: string;
  description?: string;
  isFinalDocument?: boolean;
  isAsync?: boolean;
  additionalInformation?: string;
  isPreviewWithCompliance?: boolean;
  hasRulesetDefined?: boolean = false;
  documentSubEntityId?: number;
  existingDocumentName?: string;
  documentTypeName?: string;
  documentSubTypes?: {
    name: string;
    id: number;
    existingDocumentName?: string
  }[];
  customUiConfig?: {
    objectDate: {
      value: Date | string;
    } & CustomUiConfigBase;
    objectComment: {
      value: string;
      minlength: number;
      maxlength: number;
    } & CustomUiConfigBase;
    objectDocumentType?: {
      value?: number;
    } & CustomUiConfigBase;
    tertiaryAction?: {
      text: string;
      disabled: () => boolean;
      action: () => void;
    };
    complianceDocumentSubTypes?: ({
      value?: number;
      fileName?: string;
    } & CustomUiConfigBase)[];
  };

  constructor(params: PhxDocumentFileUploadConfiguration) {
    Object.assign(this, params);
  }
}

export interface CustomUiConfigBase {
  isRequired: boolean;
  label: string;
  helpBlock: string;
}
