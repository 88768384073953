import { Component } from '@angular/core';
import { PhxLayoutBannerService } from '../../services/phx-layout-banner.service';
import { BaseComponentOnDestroy } from '../../epics/base-component-on-destroy';
import { NavigationStart, Router } from '@angular/router';
import { filter, takeUntil, withLatestFrom } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-phx-layout-banner',
  templateUrl: './phx-layout-banner.component.html',
  styleUrls: ['./phx-layout-banner.component.less']
})
export class PhxLayoutBannerComponent extends BaseComponentOnDestroy {

  bannerConfig$ = this.layoutBannerService.bannerConfig$;
  showDevDetails = false;
  devDetailsShowing = false;

  constructor(
    private layoutBannerService: PhxLayoutBannerService,
    private router: Router
  ) {
    super();
    this.initStartNavigationListener();
    /** NOTE: to hide error details set this to false  */
    this.showDevDetails = !environment.production;
  }

  closeBanner() {
    this.layoutBannerService.closeBanner();
  }

  private initStartNavigationListener() {
    this.router.events.pipe(
      withLatestFrom(this.bannerConfig$),
      filter(([routerEvent, bannerConfig]) => !bannerConfig?.canClose && routerEvent instanceof NavigationStart),
      takeUntil(this.isDestroyed$)
    ).subscribe(() => this.closeBanner());
  }

}
