import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { AzSearchStoreExtensionType } from '../../models/global-search';
import { ISearchBarType } from '../../interfaces/global-search';
import { GlobalSearchConstants } from '../../models/global-search-constants';

@Injectable()
export class GlobalSearchBarService {

  private restartSearch$: Subject<boolean> = new Subject<boolean>();

  constructor() {
  }

  get searchBoxRefresh$() {
    return this.restartSearch$.asObservable();
  }

  triggerSearchBoxRefresh() {
    this.restartSearch$.next(true);
  }

  getAvailableOptionOfType(searchType: AzSearchStoreExtensionType): ISearchBarType {
    return GlobalSearchConstants.GsSearchBarSearchTypes.find(opt => opt.searchType === searchType);
  }
}
