import { Component, Inject, OnInit } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';

import { ConfirmComponentProperties } from './models/confirm-component-properties.model';
import { DialogComponent } from '../../services/dialog/models/dialog-component.model';

@Component({
  selector: 'app-phx-dialog-confirm-template',
  templateUrl: './phx-dialog-confirm-template.component.html',
  styleUrls: ['./phx-dialog-confirm-template.component.less']
})
export class PhxDialogConfirmTemplateComponent implements OnInit, DialogComponent<ConfirmComponentProperties, void> {
  yesFlag = false;

  constructor(public dialogRef: DialogRef<void>, @Inject(DIALOG_DATA) public dialogData: ConfirmComponentProperties) {}

  ngOnInit() {
    this.dialogData.yesButtonText ??= 'common.generic.yes';
    this.dialogData.noButtonText ??= 'common.generic.no';
  }

  yes(): void {
    this.yesFlag = true;
    this.close();
    this.dialogData.yesCallbackFn?.();
  }

  close(): void {
    this.dialogRef.close();
    if (this.yesFlag === false) {
      this.dialogData.noCallbackFn?.();
    }
  }
}
