import { Component, OnInit } from '@angular/core';
import { SignJWT } from 'jose';
import { PhxConstants } from 'src/app/common';
import { AuthService } from 'src/app/common/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-portal-dialog',
  templateUrl: './portal-dialog.component.html',
  styleUrls: ['./portal-dialog.component.less']
})
export class PortalDialogComponent implements OnInit {

  iFrameUrl = null;

  constructor(
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.setIframeAddress();
  }

  private async setIframeAddress() {
    const portalUrl = await this.getPortalUrlWithToken();
    this.iFrameUrl = portalUrl;
  }

  private async getPortalUrlWithToken() {
    const userContext = await this.authService.getUserContext();
    const jwtBody = {
      email: userContext.User.UserProfiles?.[0]?.PrimaryEmail,
      id: `${userContext.User.Id}`,
      name: `${userContext.User.PreferredFirstName} ${userContext.User.PreferredLastName}`,
      company_name: userContext.User.CurrentTenantName,
      company_domain: userContext.User.UserProfiles?.[0]?.PrimaryEmail.split('@')?.[1]
    };

    if (userContext.User.UserProfiles?.[0]?.ProfileTypeId === PhxConstants.UserProfileType.Internal) {
      const token = await this.createToken(jwtBody, environment.productboardInternalPortalSecret);
      return `https://portal.productboard.com/${environment.productboardInternalPortalKey}?hide_header=1&token=${token}`;
    } else {
      const token = await this.createToken(jwtBody, environment.productboardPortalSecret);
      return `https://portal.productboard.com/${environment.productboardPortalKey}?hide_header=1&token=${token}`;
    }
  }

  private async createToken(body: any, secret: string) {
    const portalSecret = new TextEncoder().encode(secret);
    const alg = 'HS256';

    const token = await new SignJWT(body)
      .setProtectedHeader({ alg })
      .setIssuedAt()
      .sign(portalSecret);

    return token;
  }

}
