import { Inject, Injectable } from '@angular/core';
import { TodoItem, UserTodo, TodoList, UserTodoList } from '../model/to-do-item';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ConfigurationService } from 'src/app/configuration/service/configuration.service';
import { AuthService } from './auth.service';
import { AppStorageService } from './app-storage.service';
import { StorageService } from 'ngx-webstorage-service';
import { filter, map, take, tap } from 'rxjs/operators';
import { MODULE_ROUTES } from '../constants/module-routes.const';
import { CodeValueService } from './code-value.service';
import { CodeValueGroups, PhxConstants } from '../model';
import { GoogleAnalyticsService } from './google-analytics/google-analytics.service';
import { IOrganization } from 'src/app/organization/models';
import { IPeople } from 'src/app/contact/models/people.interface';

interface ActiveReportType {
  reportTypeId: number;
  description: string;
}

interface DataGridSelectionEvent {
  currentDeselectedRowKeys: any[];
  currentSelectedRowKeys: any[];
  selectedRowKeys: any[];
  selectedRowsData: any[];
}

@Injectable({
  providedIn: 'root'
})
export class TodoService {

  activeTodoReportTypes: ActiveReportType[] = [];
  currentReportTypeId: number = null;

  private todoStorageLabel = 'TodoList';
  private deleteTodoAction = 'deleteTodo';
  private toggleTodoAction = 'toggleTodo';
  private currentVersion = 1;

  private isTodoFeatureEnabled = false;
  private localTodoList: UserTodo;
  private localListOfTodos: UserTodoList;

  private userTodoSubject = new BehaviorSubject<UserTodo>(null);
  public userTodo$ = this.userTodoSubject.asObservable().pipe(filter(userTodo => !!userTodo));

  private todoListChangedSubject = new Subject<number>();
  public todoListChanged$ = this.todoListChangedSubject.asObservable();

  private gridIsLoading = false;

  constructor(
    private configurationService: ConfigurationService,
    private authService: AuthService,
    private codeValueService: CodeValueService,
    private googleAnalyticsService: GoogleAnalyticsService,
    @Inject(AppStorageService) private storageService: StorageService
  ) {
    this.initActiveReportTypeList();
  }

  /** NOTE: we want to load any existing todo list without firing an event that flips the side nav to the todo list */
  getStartingUserTodoList() {
    return this.localTodoList;
  }

  /** NOTE: user is making a change to their todo list from a data grid page  */
  updateTodoList(reportTypeId: number, selections: DataGridSelectionEvent) {
    if (this.isTodoFeatureEnabled && !!reportTypeId) {
      this.currentReportTypeId = reportTypeId;

      /** NOTE: when the grid is loading it deselects any previously selected rows - we dont care about that
       * so we reset selected rows by report type to the newly loaded report
       */
      if (this.gridIsLoading) {
        this.updateUserTodo(this.localTodoList.items.find(f => f.reportTypeId === reportTypeId));
      }
      else {
        const newTodo = this.getUpdatedTodoList(reportTypeId, selections);
        this.addTodoGAEvent(newTodo.todos, reportTypeId);
        this.updateUserTodo(newTodo);
      }
    }
  }

  /** NOTE: user can reorder todo list by dragging and dropping - save new order */
  reorderTodoList(reorderDragEvent: { fromIndex: number, toIndex: number; }, reportTypeId: number) {
    const todoList = this.localTodoList.items.find(f => f.reportTypeId === reportTypeId);
    const reorderedTodos = [...todoList.todos];

    let start = reorderDragEvent.fromIndex;
    let end = reorderDragEvent.toIndex;

    while (start < 0) {
      start += todoList.todos.length;
    }
    while (end < 0) {
      end += reorderedTodos.length;
    }

    reorderedTodos.splice(end, 0, reorderedTodos.splice(start, 1)[0]);

    this.updateUserTodo({
      ...todoList,
      todos: reorderedTodos.map((todo, idx) => ({ ...todo, order: idx }))
    });

    this.addSortTodoGAEvent(reportTypeId);
  }

  setGridIsLoading(isLoading: boolean) {
    this.gridIsLoading = isLoading;
  }

  removeTodo(todoToRemove: TodoItem, reportTypeId: number) {
    this.updateUserTodoList(todoToRemove, reportTypeId, this.deleteTodoAction);
    this.googleAnalyticsService.sendClickData({
      feature: 'Todo',
      type: todoToRemove?.description || `Report type: ${reportTypeId}`,
      action: 'Delete',
    });
  }

  toggleTodo(todoToToggle: TodoItem, reportTypeId: number) {
    this.updateUserTodoList(todoToToggle, reportTypeId, this.toggleTodoAction);
  }

  /** NOTE: data grids need the current list of selected items(todo checkbox) on render - user could have 
   * removed todo item while on another page - so give them the current list */
  getSelectedRowsKeysByReportType(reportTypeId: number) {
    return this.localTodoList.items?.find(f => f.reportTypeId === reportTypeId)?.todos.map(todo => todo.entityId) || [];
  }

  getTodoListIsEnabledForReport(reportTypeId: number): Observable<boolean> {
    /** NOTE: is the todo feature flag on and is the current report type in the list of report types that have the todo list */
    return this.configurationService.isFeatureActive$([PhxConstants.FeatureFlags.ShowToDoList]).pipe(
      take(1),
      tap(featureFlagState => this.isTodoFeatureEnabled = featureFlagState[PhxConstants.FeatureFlags.ShowToDoList]),
      map(featureFlagState => {
        return featureFlagState[PhxConstants.FeatureFlags.ShowToDoList] && this.activeTodoReportTypes.some(f => f.reportTypeId === reportTypeId);
      })
    );
  }

  getTodoUrl(todo: TodoItem, reportTypeId: number) {
    let returnUrl = '/next/account/home';

    switch (reportTypeId) {
      case PhxConstants.ReportType.WorkOrderReport:
        returnUrl = `/next/${MODULE_ROUTES.WORK_ORDER_LEGACY}/${todo.urlValues.assignmentId}/${todo.urlValues.woId}/${todo.urlValues.wovId}/core`;
        break;
      case PhxConstants.ReportType.OrgAllReport:
        returnUrl = `/next/${MODULE_ROUTES.ORGANIZATION}/${todo.urlValues.orgId}/details`;
        break;
      case PhxConstants.ReportType.PeopleAllReport:
        returnUrl = `/next/${MODULE_ROUTES.CONTACT}/userprofile/${todo.urlValues.profileId}`;
        break;
    }

    return returnUrl;
  }

  clearToDoList(reportTypeId: number) {
    this.updateUserTodo({ reportTypeId, rowKeys: [], todos: [] });
    this.googleAnalyticsService.sendClickData({
      feature: 'Todo',
      type: `Report type: ${reportTypeId}`,
      action: 'Clear list',
    });

  }

  /** NOTE: an organization added to the todo list could have changes that need to be reflected in the todo list */
  updateOrganization(organization: IOrganization) {
    const orgTodos = this.localTodoList.items.find(f => f.reportTypeId === PhxConstants.ReportType.OrgAllReport);
    if (orgTodos?.todos?.some(f => f.entityId === organization.OrganizationId)) {
      const updatedTodos = orgTodos.todos.map(org => {
        if (org.entityId === organization.OrganizationId) {
          const roles = [];
          if (organization.OrganizationLimitedLiabilityCompanyRoles?.length) {
            roles.push('US Independent Contractor');
          }
          if (organization.OrganizationClientRoles?.length) {
            roles.push('Client');
          }
          if (organization.OrganizationInternalRoles?.length) {
            roles.push('Internal');
          }
          if (organization.OrganizationSubVendorRoles?.length) {
            roles.push('Sub vendor');
          }
          if (organization.OrganizationIndependentContractorRoles?.length) {
            roles.push('Independent Contractor');
          }

          return {
            ...org,
            description: `${organization.LegalName || '-'} (${roles.join(', ')})`
          };
        }

        return org;
      });
      this.updateUserTodo({
        ...orgTodos,
        todos: updatedTodos
      });
    }
  }

  /** NOTE: a contact added to the todo list could have changes that need to be reflected in the todo list */
  updateContact(contact: IPeople) {
    const orgTodos = this.localTodoList.items.find(f => f.reportTypeId === PhxConstants.ReportType.PeopleAllReport);
    if (orgTodos?.todos?.some(f => f.entityId === contact?.UserProfile?.UserProfileId)) {
      const updatedTodos = orgTodos.todos.map(org => {
        if (org.entityId === contact?.UserProfile?.UserProfileId) {
          return {
            ...org,
            // eslint-disable-next-line max-len
            description: `${contact?.Contact?.FirstName || '-'} ${contact?.Contact?.LastName || '-'} (${this.codeValueService.getCodeValueTextByCode(contact.UserProfile.ProfileTypeCode, CodeValueGroups.ProfileType)})`
          };
        }

        return org;
      });
      this.updateUserTodo({
        ...orgTodos,
        todos: updatedTodos
      });
    }

  }

  /** NOTE: get user todos from local storage if the exist or initiate new todo */
  initTodoList() {
    const existingStorage = this.getTodoListFromStorage();
    const currentProfile = this.authService.currentProfile;

    this.localListOfTodos = existingStorage;
    this.localTodoList = existingStorage.listOfTodos.find(f => f.userProfileId === currentProfile.Id);

    /** NOTE: if the current user doesnt have a saved todo list - create a new one and add it to the list */
    if (!this.localTodoList) {
      this.localTodoList = this.getEmptyUserTodo(currentProfile.Id);
      this.localListOfTodos.listOfTodos.push(this.localTodoList);
    }
    this.saveCurrentUserTodo();
  }

  addTodoItemClickGAEvent(reportTypeId: number) {
    this.googleAnalyticsService.sendClickData({
      feature: 'Todo',
      type: `Report type: ${reportTypeId}`,
      action: 'Todo Click',
    });
  }

  private getUpdatedTodoList(reportTypeId: number, selections: DataGridSelectionEvent) {
    const newTodo: TodoList = {
      reportTypeId,
      rowKeys: [],
      todos: []
    };

    newTodo.todos = this.localTodoList.items.find(f => f.reportTypeId === reportTypeId)?.todos || [];
    newTodo.rowKeys = this.localTodoList.items.find(f => f.reportTypeId === reportTypeId)?.rowKeys || [];

    const currentSelectionIds = newTodo.todos.map(m => m.entityId) || [];
    if (!!selections.currentDeselectedRowKeys.length) {
      /** NOTE: find the selections that we have selected in our todo list and remove them */
      const newDeselectionIds = selections.currentDeselectedRowKeys.map(m => Object.values(m)[0]);
      if (newDeselectionIds.length) {
        newTodo.todos = newTodo.todos.filter(f => !newDeselectionIds.includes(f.entityId));
        newTodo.rowKeys = newTodo.rowKeys.filter(f => !newDeselectionIds.includes(Object.values(f)[0] as number));
      }
    } else if (!!selections.currentSelectedRowKeys.length) {
      /** NOTE: map selections so we can easily get the id */
      const mappedSelections = this.mapTodoItem(selections.selectedRowsData, reportTypeId);

      /** NOTE: find any selections that we do not already have in our todo list and add them */
      const newSelections = mappedSelections.filter(f => !currentSelectionIds.includes(f.entityId));
      if (newSelections.length) {
        newTodo.todos = [...newTodo.todos, ...newSelections];
        newTodo.rowKeys = [...newTodo.rowKeys, ...selections.currentSelectedRowKeys.filter(f => !currentSelectionIds.includes(Object.values(f)[0] as number))];
      }
    }

    /** NOTE: update observable with report type id - side nav will show todo list nav and expand report type list item */
    if (currentSelectionIds.length !== newTodo.todos.length && newTodo.todos.length > 0) {
      this.todoListChangedSubject.next(reportTypeId);
    }

    return newTodo;
  }

  /** NOTE: we are tracking usage - number added and number removed */
  private addTodoGAEvent(selectedRows: any[], reportTypeId: number) {
    const todoListItemsCount = this.localTodoList.items.find(f => f.reportTypeId === reportTypeId)?.todos?.length || 0;
    const currentTodoType = this.activeTodoReportTypes.find(f => f.reportTypeId === reportTypeId);

    this.googleAnalyticsService.sendClickData({
      feature: 'Todo',
      type: currentTodoType?.description || `Report type: ${reportTypeId}`,
      action: selectedRows.length > todoListItemsCount ? 'Add' : 'Remove',
    });
  }

  /** NOTE: track if users sorts list - is report type and sorting related? */
  private addSortTodoGAEvent(reportTypeId: number) {
    const currentTodoType = this.activeTodoReportTypes.find(f => f.reportTypeId === reportTypeId);
    this.googleAnalyticsService.sendClickData({
      feature: 'Todo',
      type: currentTodoType?.description || `Report type: ${reportTypeId}`,
      action: 'Sort',
    });
  }

  private getEmptyUserTodo(userProfileId: number = null) {
    return { userProfileId: userProfileId ?? this.authService.currentProfile.Id, items: [], version: this.currentVersion };
  }

  private updateUserTodoList(todoBeingUpdated: TodoItem, reportTypeId: number, action: string) {
    let updatedTodoList: TodoList = null;

    this.localTodoList.items.forEach(todoList => {
      if (todoList.reportTypeId === reportTypeId) {
        /** NOTE: if deleteing todo - remove it from the existing report types list */
        if (action === this.deleteTodoAction) {
          updatedTodoList = {
            ...todoList,
            rowKeys: todoList.rowKeys.filter(f => Object.values(f)[0] !== todoBeingUpdated.entityId),
            todos: todoList.todos.filter(f => f.entityId !== todoBeingUpdated.entityId)
          };
        } else if (action === this.toggleTodoAction) {
          /** NOTE: when toggling a todo - find todo and flip flag  */
          updatedTodoList = {
            ...todoList,
            todos: todoList.todos.map(todo => ({ ...todo, isDone: todo.entityId === todoBeingUpdated.entityId ? !todo.isDone : todo.isDone }))
          };
        }
      }
    });

    this.updateUserTodo(updatedTodoList);
  }

  private updateUserTodo(updatedTodoList: TodoList) {
    const existingIndex = this.localTodoList.items.findIndex(f => f.reportTypeId === updatedTodoList.reportTypeId);

    if (existingIndex === -1 && !!updatedTodoList.todos.length) {
      this.localTodoList.items.push(updatedTodoList);
    } else if (!!updatedTodoList.todos.length) {
      this.localTodoList.items[existingIndex] = updatedTodoList;
    } else {
      this.localTodoList = {
        ...this.localTodoList,
        items: this.localTodoList.items.filter(f => f.reportTypeId !== updatedTodoList.reportTypeId)
      };
    }

    this.saveCurrentUserTodo();
  }

  /** NOTE: initialize a list of report types that can use the todo list feature */
  private initActiveReportTypeList() {
    /** NOTE: adding a new report type requires a new mapping (this.mapTodoItem) and a new url (this.getTodoUrl)  */
    this.activeTodoReportTypes.push({ reportTypeId: PhxConstants.ReportType.WorkOrderReport, description: 'Work Orders' });
    this.activeTodoReportTypes.push({ reportTypeId: PhxConstants.ReportType.OrgAllReport, description: 'Organizations' });
    this.activeTodoReportTypes.push({ reportTypeId: PhxConstants.ReportType.PeopleAllReport, description: 'People' });
  }

  private async saveCurrentUserTodo() {
    /** NOTE: sort by report type so todo lists are always in the same order */
    this.localTodoList.items.sort((a, b) => b.reportTypeId - a.reportTypeId);
    /** NOTE: update observable */
    this.userTodoSubject.next(this.localTodoList);
    /** NOTE: update current user's list in list of todo lists - then save to localstorage */
    this.localListOfTodos.listOfTodos = this.localListOfTodos.listOfTodos.map(todo => {
      if (todo.userProfileId === this.localTodoList.userProfileId) {
        return this.localTodoList;
      }
      return todo;
    });

    this.storageService.set(this.todoStorageLabel, JSON.stringify(this.localListOfTodos));
  }

  private getTodoListFromStorage(): UserTodoList {
    const todoFromStorage = this.storageService.get(this.todoStorageLabel);

    const storedTodo = JSON.parse(todoFromStorage);
    const returnVal: UserTodoList = { listOfTodos: [] };

    /** NOTE: we changed the 'TodoList' local storage data model after feature was released*/
    if (!!storedTodo) {
      /** NOTE: if listOfTodos is undefined then storedTodo is stored the old way - as UserTodo*/
      if (storedTodo.listOfTodos === undefined) {
        /** NOTE: map to UserTodoList */
        returnVal.listOfTodos = [storedTodo];
      } else {
        returnVal.listOfTodos = storedTodo.listOfTodos;
      }
    }

    return returnVal;
  }

  /** NOTE: map the selected data grid row to a 'todo' item */
  private mapTodoItem(selectedRowsData: any[], reportTypeId: number): TodoItem[] {
    let todoItems: TodoItem[] = [];

    switch (reportTypeId) {
      case PhxConstants.ReportType.WorkOrderReport:
        todoItems = selectedRowsData.map((wo, idx) => ({
          /** NOTE: get api to always return contact first name and contact last name */
          description: `${wo.ContactLastName || '-'}, ${wo.ContactFirstName || '-'} (${wo.ProfileType})`,
          urlValues: { assignmentId: wo.AssignmentId, woId: wo.WorkOrderId, wovId: wo.WorkOrderVersionId },
          isDone: false,
          order: idx,
          entityId: wo.WorkOrderId
        }));
        break;
      case PhxConstants.ReportType.OrgAllReport:
        todoItems = selectedRowsData.map((org, idx) => ({
          /** TODO: get api to always return legal name and OrganizationRoleIdsDisplayValue */
          description: `${org.LegalName || '-'} (${org.OrganizationRoleIdsDisplayValue})`,
          urlValues: { orgId: org.EntityId },
          isDone: false,
          order: idx,
          entityId: org.EntityId
        }));
        break;
      case PhxConstants.ReportType.PeopleAllReport:
        todoItems = selectedRowsData.map((profile, idx) => ({
          /** TODO: get api to always return first name and last name */
          description: `${profile.FirstName || '-'} ${profile.LastName || '-'} (${this.codeValueService.getCodeValueTextByCode(profile.ProfileType, CodeValueGroups.ProfileType)})`,
          urlValues: { contactId: profile.ContactId, profileType: profile.ProfileType, profileId: profile.EntityId },
          isDone: false,
          order: idx,
          entityId: profile.EntityId
        }));
        break;
    }

    return todoItems;
  }
}
