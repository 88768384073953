import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DocumentService } from '../../services/document.service';
import { PhxConstants } from './../../model/phx-constants';

@Component({
  selector: 'app-phx-profile-picture',
  templateUrl: './phx-profile-picture.component.html',
  styleUrls: ['./phx-profile-picture.component.less']
})
export class PhxProfilePictureComponent implements OnChanges {

  @Input() profileId: number = null;
  @Input() statusType: string = PhxConstants.StatusType.None;
  @Input() public showInitials = false;
  @Input() public initials: string;
  public profilePictureUrl: string;

  constructor(private documentService: DocumentService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.profileId != null) {
      this.setUserProfilePicture();
    } else if (this.profileId == null) {
      this.profilePictureUrl = null;
    }
  }

  setUserProfilePicture() {
    this.documentService.getProfilePicture(this.profileId).then(imageUrl => {
      this.profilePictureUrl = imageUrl;
    });
  }

  handleMissingPicture(event: any) {
    (event.target as HTMLImageElement).style.display = 'none';
    this.profilePictureUrl = null;
    this.showInitials = false;
  }
}
