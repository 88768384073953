import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { Theme } from './models/theme.model';
import { PROCOM_THEME } from './constants/procom-theme.const';
import { HCM_THEME } from './constants/hcm-theme.const';
import { SYMBIOTIC_THEME } from './constants/symbiotic-theme.const';
import { SOFTCHOICE_THEME } from './constants/softchoice-theme.const';
import { DEMO_SANDBOX_THEME } from './constants/demo-sandbox-theme.const';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private readonly themeLogo$ = new BehaviorSubject<string>('');
  public readonly logo$: Observable<string> = this.themeLogo$.asObservable();

  private readonly themePrefix = `fbo-theme`;
  private readonly themes = [PROCOM_THEME, HCM_THEME, SYMBIOTIC_THEME, SOFTCHOICE_THEME, DEMO_SANDBOX_THEME];

  public setTenantTheme(tenantCode: string) {
    const theme = this.themes.find(i => i.tenantCode === tenantCode) ?? PROCOM_THEME;
    this.applyTheme(theme);
  }

  private applyTheme(theme: Theme): void {
    this.themeLogo$.next(theme.tenantLogo);
    const docRoot = document.querySelector(':root') as HTMLElement;
    theme.attributes.forEach(attribute => {
      docRoot.style.setProperty(`--${this.themePrefix}-${attribute.variableName}`, attribute.value);
    });
  }
}
