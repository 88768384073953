import { Component, Input } from '@angular/core';
import { CommonService, PhxConstants } from '../../../../common';
import { GlobalSearchService } from '../../../services/global-search-service';

@Component({
  selector: 'app-global-search-suggestion-assignment',
  templateUrl: './global-search-suggestion-assignment.component.html',
  styleUrls: ['./global-search-suggestion-assignment.component.less']
})
export class GlobalSearchSuggestionAssignmentComponent {
  @Input() assignmentData;
  readonly dateFormat = PhxConstants.DateFormat.mediumDate;

  constructor(private commonService: CommonService, private globalSearchService: GlobalSearchService) {}

  getProfileTypeForDisplay(profileType) {
    return this.commonService.getUserProfileTypeFromProfileTypeForCardDisplay(profileType);
  }

  onWorkOrderSuggestionClick(item) {
    this.globalSearchService.onWorkOrderSuggestionClick(item);
  }
}
