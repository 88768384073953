import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { MsalService } from '@azure/msal-angular';

import { IframeService, LoadingSpinnerService } from './common';
import { LoadAnalyticsScripts } from './analyticsLoader';
import { BaseComponentOnDestroy } from './common/epics/base-component-on-destroy';
import { AuthService } from './common/services/auth.service';
import { Userpilot } from 'userpilot';
import { GoogleAnalyticsService } from './common/services/google-analytics/google-analytics.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent extends BaseComponentOnDestroy implements OnInit {
  isIframe = false;

  constructor(
    private router: Router,
    private loadingSpinnerService: LoadingSpinnerService,
    private iframeService: IframeService,
    private msalService: MsalService,
    private authService: AuthService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {
    super();
    //  This is to avoid reload during acquireTokenSilent() because of hidden iframe
    this.isIframe = this.iframeService.isIframe;
  }

  ngOnInit() {
    this.loadAnalytics();

    this.router.events.pipe(
      takeUntil(this.isDestroyed$))
      .subscribe(e => {
        if (e instanceof NavigationStart) {
          this.loadingSpinnerService.show();
        }
        if (e instanceof NavigationCancel) {
          this.loadingSpinnerService.hide();
        }
        if (e instanceof NavigationEnd) {
          this.loadingSpinnerService.hide();

          if (this.authService.isLoggedIn()) {

            this.authService.getUserContext().then(() => {
              // reload user pilot only when user had been authenticated.
              Userpilot.reload();

              const pageViewEventData = {
                pageDom: window.location.hostname,
                pageTitle: document.title
              };

              this.googleAnalyticsService.sendPageViewData(pageViewEventData);
            });
          }
        }
        if (e instanceof NavigationError) {
          this.loadingSpinnerService.hideAll();
        }
      });
  }

  private loadAnalytics(): void {
    if (!this.isIframe) {
      const googleAnalyticsDataLayer = LoadAnalyticsScripts();
      this.googleAnalyticsService.init(googleAnalyticsDataLayer);
    }
  }
}
