import { FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn } from '../ngx-strongly-typed-forms';
import { Subject } from 'rxjs/internal/Subject';
import { ValidationExtensions } from '../components/phx-form-control/validation.extensions';


export type Validators<T> = ValidatorFn<T> | ValidatorFn<T>[] | null;
export type ValidatorsConfig<T> = { [P in keyof T]?: Validators<T> }

export type DisabledConfig<T> = { [P in keyof T]?: boolean }

export type DeepPartial<T> = T extends object ? {
  [P in keyof T]?: DeepPartial<T[P]>;
} : T;

export interface IFormService<T = any> {
  setupFormListeners(rootFormGroup: FormGroup<T>): void;

  createForm(data: T, isRootComponentDestroyed$: Subject<boolean>): FormGroup<T> | FormArray<T> | FormControl<T>;

  updateForm(data: T): void;

  destroyForm(): void;
}

// Implement this instead of IFormService in document Rule
export interface IFormService2<TFormModel> extends IFormService {
  formGroup: FormGroup<TFormModel>;
}
// Don't implement this now, just an idea
export interface IFormService3<TFormModel, TDataModel> {
  formGroup: FormGroup<TFormModel>;
  isRootComponentDestroyed$: Subject<boolean>;

  setupFormListeners(rootFormGroup: FormGroup<TFormModel>): void;

  createForm(data: TDataModel, isRootComponentDestroyed$: Subject<boolean>): FormGroup<TFormModel> | FormArray<TFormModel> | FormControl<TFormModel>;

  updateForm(data: TDataModel): void;

  destroyForm(): void;
}

// TODO: Make this a service, no need for static functions?
export class FormServiceHelper {

  // Temporary, TBD static
  static setValidators<T>(formGroup: FormGroup<T> | FormArray<T>, config: ValidatorsConfig<T>) {
    for(const prop in formGroup.controls) {
      const validators = config[prop] ?? null;

      // TODO: emitEvent? onlySelf?
      const formControl = this.getControlByName(prop, formGroup);
      if (formControl) {
        formControl.setValidators(validators);
      } else {
        console.warn(`Missing form control ${prop} in setValidators`);
      }
    }

    ValidationExtensions.updateTreeValidity(formGroup as any, false);
  }

  // TODO: Move to service? Not static?
  static setDisabled<T>(formGroup: FormGroup<T> | FormArray<T>, config: DisabledConfig<T>) {

    // Don't set child controls disabled/enabled if the parent is disabled
    // If the parent is already disabled, all child controls should be disabled
    // Enabling child controls will re-enable the parent!
    if (!formGroup.disabled && !formGroup.parent?.disabled) {
      for(const prop in formGroup.controls) {
        const disable = config[prop] ?? false;
        const formControl = this.getControlByName(prop, formGroup);
  
        // TODO: emitEvent? onlySelf?
        if (formControl) {
          if (disable != formControl.disabled) {
            if (disable) {
              formControl.disable();
            } else {
              formControl.enable();
            }
          }
        } else {
          console.warn(`Missing form control ${prop} in setDisabled`);
        }
      }
    }

    ValidationExtensions.updateTreeValidity(formGroup as any, false);
  }

  // TBD where this will go
  static updateFormArrayControls<T>(formArray: FormArray<T>, values: T[], fb: FormBuilder, overwriteExistingValues = true) {

    // Add/Remove form array controls
    if (formArray.length !== values?.length ?? 0) {
      // Create missing controls for value at index
      values.forEach((value, index) => {
        const formGroup = formArray.at(index);
        if (!formGroup) {
          formArray.push(fb.group(value));
        } else if (overwriteExistingValues) {
          formGroup.setValue(value, { emitEvent: false }); // TODO: emitEvent? onlySelf?
        }
      });
      
      // Remove extra controls
      while ((values?.length ?? 0) < formArray.length) {
        formArray.removeAt(formArray.length - 1);
      }
    }
  }

  static addRemoveFormArrayControls<T>(formArray: FormArray<T>, fb: FormBuilder, createFormGroup: (value: T) => FormGroup<T> = null) {

    const values = formArray.value;

    // Add/Remove form array controls
    if (formArray.length !== values?.length ?? 0) {
      // Create missing controls for value at index
      values.forEach((value, index) => {
        const formGroup = formArray.at(index);
        if (!formGroup) {
          if (createFormGroup != null) {
            formArray.push(createFormGroup(value));
          } else {
            formArray.push(fb.group(value));
          }
        } else {
          // Nothing
        }
      });
      
      // Remove extra controls
      while ((values?.length ?? 0) < formArray.length) {
        formArray.removeAt(formArray.length - 1);
      }
    }
  }

  // Temporary, TBD static
  static getControlByName<T>(name: string, formGroup: FormGroup<T> | FormArray<T>) {
    if (formGroup instanceof FormGroup) {
      return formGroup.get(name as any);
    }
    else if (formGroup instanceof FormArray) {
      return formGroup.at(+name);
    }
  } 
}

