import {
  AfterContentInit,
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Inject,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TransferState } from '@angular/platform-browser';

import { DxComponent, DxSelectBoxComponent, DxTemplateHost, WatcherHelper } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';

@Component({
  selector: 'app-phx-select-box',
  templateUrl: './phx-select-box.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => PhxSelectBoxComponent)
    }
  ]
})
export class PhxSelectBoxComponent extends DxComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor, AfterContentInit, AfterViewInit, AfterViewChecked {
  @Input() items: any;
  @Input() grouped = false;
  @Input() groupField: string;
  @Input() placeholder = $localize`:@@phx.selectBox.selectOne:-- Select One --`;
  @Input() showClearButton = true;
  @Input() readOnly = false;
  @Input() disabled = false;
  @Input() searchable = true;
  @Input() acceptCustomValue = false;
  @Input() maxLength: number = null;
  @Input() textField: string;
  @Input() valueField: string;
  @Input() width: any; // "55px", "80%", "auto", "inherit" (default = undefined )
  @Input() itemTemplate = 'item-template';
  @Input() itemTemplateRef: TemplateRef<any>;
  @Input() fieldTemplate = 'field-template';
  @Input() dropDownButtonTemplate = 'drop-down-button-template';
  @Input() dropdownWidth: any; // "55px", "80%", "auto", "inherit" (default = undefined )
  @Output() valueChanged: EventEmitter<any> = new EventEmitter();
  @Output() customItemCreating: EventEmitter<any> = new EventEmitter();
  @ViewChild('selectBox', { static: true }) selectBox: DxSelectBoxComponent;
  dataSource: any;
  @Input() value: any = null;
  @Input() searchField?: string;
  @Input() useItemTextAsTitle = false;

  isDestroyed = false;

  constructor(
    eRef: ElementRef,
    ngZone: NgZone,
    templateHost: DxTemplateHost,
    _watcherHelper: WatcherHelper,
    transferState: TransferState,
    @Inject(PLATFORM_ID) platformId: any,
    private cdr: ChangeDetectorRef
  ) {
    super(eRef, ngZone, templateHost, _watcherHelper, transferState, platformId);
  }

  onTouched = () => {};

  // This is an override, we cannot remove the unused variable
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange = _ => {};

  // This overrides DxComponent's OnInit and causes an error if removed
  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit() {}

  // Implementation of abstract method of DxComponent, have to live with it
  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
  _createInstance(element: any, options: any) {
    return this.selectBox.instance;
  }

  ngOnDestroy() {
    this.isDestroyed = true;
  }

  ngAfterViewInit() {
    if (this.instance) {
      super.ngAfterViewInit();
    }
  }

  ngAfterViewChecked() {
    if (this.instance) {
      super.ngAfterViewChecked();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.items) {
      this.dataSource = new DataSource({
        store: this.items || [],
        paginate: true,
        group: this.grouped ? this.groupField : null
      });
      this.dataSource.reload();
    }
  }

  ngAfterContentInit(): void {
    if (this.templates) {
      const itemTemplates = this.templates.filter(t => t.name === this.itemTemplate);
      if (itemTemplates?.length) {
        this.selectBox.itemTemplate = itemTemplates[0];
      }

      const fieldTemplates = this.templates.filter(t => t.name === this.fieldTemplate);
      if (fieldTemplates?.length) {
        this.selectBox.fieldTemplate = fieldTemplates[0];
      }

      const dropDownButtonTemplates = this.templates.filter(t => t.name === this.dropDownButtonTemplate);
      if (dropDownButtonTemplates?.length) {
        this.selectBox.dropDownButtonTemplate = dropDownButtonTemplates[0];
      }
    }
  }

  writeValue(value: any) {
    this.value = value;
    if (!this.isDestroyed) {
      this.cdr.detectChanges();
    }
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  onValueChanged(event: any) {
    this.value = event.value;
    const nativeSelectElement = this.selectBox.instance.element();
    const isSelectFocused = nativeSelectElement && document.activeElement ? nativeSelectElement.contains(document.activeElement) : false;

    if (event.event || isSelectFocused) {
      this.onChange(this.value);
    }
    this.onTouched();
    this.valueChanged.emit(event);
    if (!this.isDestroyed) {
      this.cdr.detectChanges();
    }
  }

  onCustomItemCreating(event: any) {
    this.customItemCreating.emit(event);
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    if (!this.isDestroyed) {
      this.cdr.detectChanges();
    }
  }

  clear() {
    this.value = null;
    if (!this.isDestroyed) {
      this.cdr.detectChanges();
    }
  }

  onOpened(event: any) {
    this.setCustomDropdownWidth(event);
  }

  private setCustomDropdownWidth(event: any) {
    setTimeout(() => {
      // eslint-disable-next-line no-underscore-dangle
      event.component._popup.option('width', this.dropdownWidth);
    });
  }
}
