<div class="custom-panel-container">
    <div class="header-container">
        <div class="title-container">
            <h3>
                <div class="material-icons-outlined col-xs-1">
                    {{icon}}
                </div>
                <div class="col-xs-4">{{name}}</div>
            </h3>
        </div>
    </div>
    <ng-container #customPanel></ng-container>
</div>