<app-phx-modal #shareDialog title="Share Report" [buttons]="modalButtons">
    <ng-container *ngIf="inputFormGroup">
        <div class="dialog-content-container">
            <div class="row button-row">
                <div class="col-xs-6 button-col">
                    Make report publicly accessible
                </div>
                <div class="col-xs-6 align-right button-col">
                    <mat-button-toggle-group class="tabs" [formControl]="inputFormGroup.get('IsPublic')">
                        <mat-button-toggle [value]="false" aria-label="No">
                            No
                        </mat-button-toggle>
                        <mat-button-toggle [value]="true" aria-label="Yes">
                            Yes
                        </mat-button-toggle>
                    </mat-button-toggle-group>
                </div>
            </div>
            <ng-container *ngIf="!inputFormGroup.get('IsPublic').value">
                <div class="row">
                    <div class="col-xs-12">
                        Share with
                    </div>
                </div>
                <div class="row">
                    <div class="col-xs-12">
                        <phx-form-control name="members" labelAlign="left"
                                          [layoutType]="PhxFormControlLayoutType.InputOnly">
                            <app-phx-select-box placeholder="-- Invite people or email --" textField="Name"
                                                [items]="getFilteredShareeList(shareeList, currentUserId)"
                                                [itemTemplateRef]="shareeTemplate" itemTemplate="item"
                                                (valueChanged)="addReportSharee($event)"
                            ></app-phx-select-box>
                        </phx-form-control>
                    </div>
                </div>
            </ng-container>
            <div class="row">
                <div class="col-xs-12">
                    <app-report-sharee *ngIf="owner" [shareeId]="owner.Id" [shareeType]="owner.ShareeType"
                                       isOwner="true" disabled="true"></app-report-sharee>
                    <app-report-sharee *ngIf="inputFormGroup.get('IsPublic').value" dummyPublicState="true" disabled="true"></app-report-sharee>
                    <ng-container *ngIf="!inputFormGroup.get('IsPublic').value">
                        <app-report-sharee *ngFor="let userId of inputFormGroup.get('ShareeUserIds').value"
                                           [shareeId]="userId" [shareeType]="ReportShareeType.User" (removeSharee)="removeSharee($event)"></app-report-sharee>
                        <app-report-sharee *ngFor="let teamId of inputFormGroup.get('ShareeTeamIds').value"
                                           [shareeId]="teamId" [shareeType]="ReportShareeType.Team" (removeSharee)="removeSharee($event)"></app-report-sharee>
                    </ng-container>
                </div>
            </div>
        </div>
    </ng-container>
</app-phx-modal>

<ng-template #shareeTemplate let-itemData="itemData">
    <ng-container *ngIf="itemData.ShareeType == ReportShareeType.User">
        <span>{{ itemData.Name }}</span>
        <span class="text-info"><i>{{ getFunctionRoleString(itemData.FunctionalRolesId) }}</i></span>
    </ng-container>
    <ng-container *ngIf="itemData.ShareeType == ReportShareeType.Team">
        <span>{{ itemData.Name }}</span>
        <span class="text-info"><i>{{ itemData.NumTeamMembers }} {{ itemData.NumTeamMembers > 1 ? 'members' : 'member' }}</i></span>
    </ng-container>
</ng-template>
