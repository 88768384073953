import { Pipe, PipeTransform } from '@angular/core';
import { PhxWorkflowDisplayNameService } from '..';

@Pipe({
  name: 'phxWorkflowStatusName'
})
export class PhxWorkflowStatusNamePipe implements PipeTransform {

  constructor(private wfsDisplayNameService: PhxWorkflowDisplayNameService) { }

  transform(value: string, arg1: string): string {
    let result = '';
    if (value) {
        result = this.wfsDisplayNameService.getStatusDisplayName(value, arg1);
    }
    return result;
  }

}
