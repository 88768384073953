import { Component, OnInit, ElementRef } from '@angular/core';
import { ApiService, CommonService } from '../common';

@Component({
  selector: 'app-view-email-in-browser',
  templateUrl: './view-email-in-browser.component.html',
  styleUrls: ['./view-email-in-browser.component.less']
})
export class ViewEmailInBrowserComponent implements OnInit {
  constructor(private apiService: ApiService, private commonService: CommonService, private elRef: ElementRef) {}

  ngOnInit() {
    const code = this.commonService.getUrlParams('code');
    this.apiService.queryWithPromise<string>('viewemail/' + code, true, 'text/html').then(res => {
      this.elRef.nativeElement.ownerDocument.documentElement.innerHTML = res;
    });
  }
}
