export interface IRuleResult {
  ruleCategory: string;
  ruleName: string;
  ruleText: string;
  strictness: RuleStrictness;
  sortOrder: number;
  isValid: boolean;
  /** NOTE: this property is a JSON string in the response - once parse it can be anything  */
  additionalInformation: string | any;
  actionName: string;
  reasons?: string;
  ruleSubCategory?: string;
  ruleSubCategoryTranslations: { [cultureCode: string]: string; };
  ruleSubCategoryId?: number;
  info?: { [cultureCode: string]: string; };
  providedValue?: string[];
  extractedValue?: string;
  operation?: string;
  type?: string;
  isOptional?: boolean;
  documentRuleSetId?: string;
}

export enum RuleStrictness {
  NotApplicable = 10,
  Warning = 20,
  Regular = 30,
  Mandatory = 40
}

export enum ComplianceStatus {
  Compliant = 1,
  Warning = 2,
  NonCompliant = 3
}

export interface IRuleCategory {
  id: string;
  entityType: string;
  ruleCategoryName: string;
  ruleCategoryText: string;
  sortOrder: number;
}

export interface IRuleGroup {
  category: string;
  length: number;
  rules: Array<IRuleResult> | Array<IRuleResult & IRuleGroup>;
  groupCompliance: ComplianceStatus,
  compliantCount: number;
  nonCompliantCount: number;
  warningCount: number;
  categorySortOrder: number;
  isOpen: boolean;
  documentRuleSetId?: string;
}

export interface IChecklistRefreshedEvent {
  nonCompliantCount: number;
}
