<dx-popup
  #columnChooser
  [title]="selectColumnsTitle"
  [(visible)]="visible"
  [container]="container"
  width="700"
  height="600"
  [resizeEnabled]="true"
  [shading]="true"
  (onHidden)="onChooserClose()"
>
  <div class="column-list-container">
    <div class="list-container hidden-columns">
      <dx-list
        #hiddenColumnList
        [dataSource]="hiddenColumns"
        [selectedItems]="selectedHiddenColumns"
        [searchEnabled]="true"
        [showSelectionControls]="true"
        [searchEditorOptions]="{ placeholder: searchHiddenColumns }"
        showScrollbar="always"
        displayExpr="value"
        searchExpr="value"
        keyExpr="id"
        selectionMode="multiple"
        [noDataText]="noHiddenColumns"
        pageLoadMode="scrollBottom"
        (onSelectionChanged)="onClickShowColumn($event)"
      >
      </dx-list>
    </div>
    <div class="list-container">
      <dx-list
        #visibleColumnList
        [(dataSource)]="visibleColumns"
        [selectedItems]="visibleColumns"
        [searchEnabled]="true"
        [showSelectionControls]="true"
        [searchEditorOptions]="{ placeholder: searchVisibleColumns }"
        showScrollbar="always"
        displayExpr="value"
        searchExpr="value"
        keyExpr="id"
        selectionMode="multiple"
        [noDataText]="noVisibleColumns"
        pageLoadMode="scrollBottom"
        (onSelectionChanged)="onClickHideColumn($event)"
      >
        <dxo-item-dragging
          group="visibleColumns"
          [data]="visibleColumns"
          [allowReordering]="true"
          [onDragStart]="onDragStart"
          [onDragEnd]="onDragEnd"
        >
        </dxo-item-dragging>
      </dx-list>
    </div>
  </div>
  <div class="footer-container">
    <button
      class="btn btn-default"
      (click)="onChooserClose()"
      i18n="@@common.button.cancel"
    >Cancel</button>
    <button
      class="btn btn-primary"
      (click)="onClickUpdateColumns()"
      i18n="@@common.button.apply"
    >
      Apply
    </button>
  </div>
</dx-popup>
