<ul class="overtime-breakdown">
  <li
    *ngFor="let details of localOvertimeSheetDetails"
    class="item"
  >
    <app-time-sheet-rate-label
      class="unit-label"
      [rateTypeId]="details.RateTypeId"
      [labelText]="details.displayText"
    ></app-time-sheet-rate-label>

    <span class="unit-amount">{{ details.UnitAmount | number : '1.2-2' }}&nbsp;{{ hourUnit | phxTypedTranslate : 'RateUnit' }}</span>
  </li>
</ul>
