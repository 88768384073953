<app-headstone
  subPath="View Mail Campaign"
  i18n-subPath="@@mailCampaign.viewMailCampaign"
  [entityId]="mailCampaignId"
>
</app-headstone>

<button
  [routerLink]="mailCampaignPaths.history.getRouterLink()"
  class="btn btn-default icon-button"
>
  <mat-icon>history</mat-icon>
  <span i18n="@@common.button.back">Back</span>
</button>

<form class="row form-horizontal margin-top-30">
  <div class="col-sm-6 col-md-4 col-lg-3">
    <phx-form-control
      [control]="campaignDataForm.controls.dateTime"
      [editable]="false"
      [layoutType]="phxFormControlLayoutType.Stacked"
      [viewModeText]="campaignDataForm.controls.dateTime.value"
      i18n-labelText="@@mailCampaign.mailCampaignTime"
      labelText="Mail Campaign Time"
    >
    </phx-form-control>
  </div>
  <div class="col-sm-6 col-md-4 col-lg-3">
    <phx-form-control
      [control]="campaignDataForm.controls.requestedBy"
      [editable]="false"
      [layoutType]="phxFormControlLayoutType.Stacked"
      [viewModeText]="campaignDataForm.controls.requestedBy.value"
      i18n-labelText="@@mailCampaign.requestedBy"
      labelText="Requested By"
    >
    </phx-form-control>
  </div>
</form>

<app-email-preview
  *ngIf="mailCampaignId && message$ | async as message"
  [formValue]="message"
  [recipients]="recipients$ | async"
  [mailCampaignId]="mailCampaignId"
></app-email-preview>
