<div>
  <!-- inside Edit and add, view bookmarks, edit and add?  -->
  <div *ngIf="component.items.length > 0" class="bookmark-top-menu backgroundColorDarkerBlue">
      <button class="bookmarkButtonStyle bookmarkIcons headerBookmarkIcons" 
        [ngClass]="showAdd === true ? 'bookmarkButtonSelected' : 'backgroundColorDarkerBlue bookmarkButtonWhiteColor'" 
        (click)="showAdd = !showAdd; bookmarkTitle = showAddPreparation(); showEditAndDelete = false; showOrderOptions = false;">
          <i *ngIf="!bookmarked" class="material-icons md-24">star_border</i>
          <i *ngIf="bookmarked" class="material-icons md-24">star</i>
      </button>
      <button class="bookmarkButtonStyle bookmarkIcons bookmarkButtonWhiteColor headerBookmarkIcons" 
        [ngClass]="showEditAndDelete === true ? 'bookmarkButtonSelected' : 'backgroundColorDarkerBlue bookmarkButtonWhiteColor'" 
        (click)="showEditAndDelete = !showEditAndDelete; firstActiveNoLonger = true; showAdd = false;">
          <i class="material-icons md-24">edit</i>
      </button>
      <button class="bookmarkButtonStyle bookmarkIcons bookmarkButtonWhiteColor headerBookmarkIcons" 
        [ngClass]="showOrderOptions === true ? 'bookmarkButtonSelected' : 'backgroundColorDarkerBlue bookmarkButtonWhiteColor'" 
        (click)="showOrderOptions = !showOrderOptions; showAdd = false;">
          <i class="material-icons md-24">list</i>
      </button>
  </div>

  <!-- sort order options -->
  <div *ngIf="showOrderOptions">
      <div class="bookmark-order-menu backgroundColorDarkBlue">
          <button class="bookmarkButtonStyle bookmarkButtonWhiteColor backgroundColorDarkBlue bookmarkFilterButtons"
            [ngClass]="orderByRepeatProperty === TITLE ? 'backgroundColorDarkerBlue' : 'backgroundColorDarkBlue'" 
            (click)="sort(TITLE)">
              {{ localization.titleLabel }}
              <i *ngIf="orderByRepeatProperty === TITLE && orderByRepeatReverse" class="material-icons md-18 pull-right bookmarkChevronStyling">keyboard_arrow_down</i>
              <i *ngIf="orderByRepeatProperty === TITLE && !orderByRepeatReverse" class="material-icons md-18 pull-right bookmarkChevronStyling">keyboard_arrow_up</i>
          </button>
          <button class="bookmarkButtonStyle bookmarkButtonWhiteColor bookmarkFilterButtons"
            [ngClass]="orderByRepeatProperty === DATE ? 'backgroundColorDarkerBlue' : 'backgroundColorDarkBlue'" 
            (click)="sort(DATE)">
              {{ localization.dateLabel }}
              <i *ngIf="orderByRepeatProperty === DATE && orderByRepeatReverse" class="material-icons md-18 pull-right bookmarkChevronStyling">keyboard_arrow_down</i>
              <i *ngIf="orderByRepeatProperty === DATE && !orderByRepeatReverse" class="material-icons md-18 pull-right bookmarkChevronStyling">keyboard_arrow_up</i>
          </button>
          <button class="bookmarkButtonStyle bookmarkButtonWhiteColor backgroundColorDarkBlue bookmarkFilterButtons"
            [ngClass]="orderByRepeatProperty === ICON ? 'backgroundColorDarkerBlue' : 'backgroundColorDarkBlue'" 
            (click)="sort(ICON)">
              {{ localization.typeLabel }}
              <i *ngIf="orderByRepeatProperty === ICON && orderByRepeatReverse" class="material-icons md-18 pull-right bookmarkChevronStyling">keyboard_arrow_down</i>
              <i *ngIf="orderByRepeatProperty === ICON && !orderByRepeatReverse" class="material-icons md-18 pull-right bookmarkChevronStyling">keyboard_arrow_up</i>
          </button>
      </div>
  </div>

  <!-- plus button and input when looking at the star section -->
  <div *ngIf="showAdd && !bookmarked">
      <div class="bookmark-input-menu">
          <div class="bookmark-input-menu-inner-div">
              <input class="pull-left bookmark-input-menu-title bookmarkInput bookmark-input-title" 
                type="text" 
                [(ngModel)]="bookmarkTitle" 
                placeholder="Enter title for bookmark." 
                maxlength="128" />
              <button class="pull-right add-bookmark-icon bookmarkButtonStyle bookmarkButtonWhiteColor backgroundColorDarkBlue bookmarkIconAdd" 
                (click)="bookmarkAdd()">
                  <i class="material-icons md-24">add</i>
              </button>
          </div>
          
      </div>
  </div>
  <div class="bookmark-input-menu-inner-div">
    <h2 *ngIf="component.items.length === 0" class="bookmark-input-menu-none">{{ localization.noBookmarksMessage }}</h2>
  </div>
  <ul class="nav nav-list bookmark-ul-menu backgroundColorDarkBlue no-max-height">
      <li class="bookmarkDisplayInlineBoxImportant bookmark-ul-menu-li-wrapper col-sm-12" 
        *ngFor="let nitem of component.items; first as isFirst" 
        (mouseenter)="nitem.showEditDeleteSaveMenu = true; firstActiveNoLonger = false;" 
        (mouseleave)="nitem.showEditDeleteSaveMenu = false;">
          <div class="bookmark-ul-menu-link-wrapper">
              <a class="dropdown-toggle bookmark-ul-menu-link" [title]="nitem.title"
                *ngIf="!isFoundPathNameInUpdateList(nitem) || !showEditAndDelete" 
                (click)="redirection(nitem.pathName)">
                  <i *ngIf="nitem.icon && orderByRepeatProperty === ICON" class="pull-left bookmarkDisplayInlineBox material-icons md-24 bookmark-icon">{{nitem.icon}}</i>
                  <span class="menu-text">{{nitem.title}}</span>
              </a>
              <input class="bookmark-input-title bookmarkInput editBookmarkTitleInput" 
                *ngIf="isFoundPathNameInUpdateList(nitem) && showEditAndDelete" 
                type="text" 
                [(ngModel)]="nitem.tempValue" 
                placeholder="Enter title for bookmark." 
                maxlength="128"/>
              <div class="bookmarkDisplayInlineBox pull-right bookmark-ul-menu-buttons-wrapper"
                *ngIf="showEditAndDelete && (nitem.showEditDeleteSaveMenu || isFoundPathNameInUpdateList(nitem) || (isFirst && firstActiveNoLonger))">
                  <button class="bookmark-details-menu bookmarkButtonStyleNoBorder bookmarkButtonWhiteColor bookmarkInsideIcons" 
                    *ngIf="!isFoundPathNameInUpdateList(nitem) && (nitem.showEditDeleteSaveMenu || (isFirst && firstActiveNoLonger))"
                    [ngClass]="nitem.showEditDeleteSaveMenu === true ? 'backgroundColorDarkerBlue' : 'backgroundColorDarkBlue'" 
                    title="{{ localization.editBookmarkTitleLabel }}" 
                    (click)="updateBookmarkPrecursor(nitem);">
                      <i class="material-icons md-24">edit</i>
                  </button>
                  <button class="bookmark-details-menu bookmarkButtonStyleNoBorder bookmarkButtonWhiteColor bookmarkInsideIcons" 
                    *ngIf="isFoundPathNameInUpdateList(nitem)" 
                    [ngClass]="nitem.showEditDeleteSaveMenu === true ? 'backgroundColorDarkerBlue' : 'backgroundColorDarkBlue'"
                    title="{{ localization.updateBookmarkTitleLabel }}" 
                    (click)="bookmarkUpdate(nitem);">
                      <i class="material-icons md-24">save</i>
                  </button>
                  <button class="delete-icon bookmark-details-menu bookmarkButtonStyleNoBorder bookmarkButtonWhiteColor bookmarkInsideIcons"
                    *ngIf="isFoundPathNameInUpdateList(nitem) || (nitem.showEditDeleteSaveMenu || (isFirst && firstActiveNoLonger))" 
                    [ngClass]="nitem.showEditDeleteSaveMenu === true ? 'backgroundColorDarkerBlue' : 'backgroundColorDarkBlue'" 
                    title="{{ localization.deleteBookmarkLabel }}" 
                    (click)="bookmarkDelete(nitem)">
                      <i class="material-icons md-24">delete</i>
                  </button>
              </div>
          </div>
      </li>
  </ul>

</div>
