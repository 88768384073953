import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { EntityVersion, IEntityActionResult, PhxConstants, StateAction } from '../../common/model';
import { ApiService } from '../../common/services/api.service';
import { IContact, IContactCreateForm, IContactProfileSummary, IPeople, IProfileSummary, IReassign, IUserProfile } from '../models/people.interface';
import { JsonPatchDocument } from '../../common/model/json-patch-document';
import { tap } from 'rxjs/operators';

@Injectable()
export class PeopleApiService {
    private readonly apiEndPoint: string;
    private readonly apiDefaultPath = 'People';
    private readonly generalServiceApiEndpoint: string;

    private contactProfilesUpdated = new BehaviorSubject<IProfileSummary[]>([]);
    private contactProfileCache: Map<number, Map<number, IUserProfile[]>>;

    updatedContactProfiles$ = this.contactProfilesUpdated.asObservable();

    constructor(private apiService: ApiService) {
        this.apiEndPoint = environment.peopleServiceApiEndpoint;
        this.generalServiceApiEndpoint = environment.generalServiceApiEndpoint;
    }

    set updatedContactProfiles(profiles: IProfileSummary[]) {
        this.contactProfilesUpdated.next(profiles);
    }

    getPeopleObject(id: number, contactVersion?: number): Observable<IPeople> {
        const requestUrl = contactVersion ? `${this.apiDefaultPath}/${id}/${contactVersion}` : `${this.apiDefaultPath}/${id}`;
        return this.apiService.httpGetRequest<IPeople>(requestUrl, this.apiEndPoint);
    }

    createPeopleObject(initialDetails: IContactCreateForm): Observable<IPeople> {
        const contact = {
            ContactId: initialDetails.ContactId
        } as IContact;

        const userProfile = {
            ProfileTypeCode: initialDetails.ProfileTypeCode,
            PrimaryEmail: initialDetails.PrimaryEmail,
            OrganizationId: initialDetails.OrganizationId
        } as IUserProfile;

        this.contactProfileCache = new Map();
        return this.apiService.httpPostRequest<IPeople>(
            `${this.apiDefaultPath}`,
            {
                Contact: contact,
                UserProfile: userProfile
            },
            this.apiEndPoint,
            true
        );
    }

    getAvailableActions(peopleDto: IPeople): Observable<IEntityActionResult> {
        return this.apiService.httpGetRequest<IEntityActionResult>(
            `${this.apiDefaultPath}/${peopleDto.UserProfile.UserProfileId}/available-actions`,
            this.apiEndPoint
        );
    }

    executeAction(action: StateAction, peopleDto: IPeople, comment?: string, declineReasons?: string): Observable<IPeople> {
        return this.apiService.httpPostRequest<IPeople>(
            `${this.apiDefaultPath}/${peopleDto.UserProfile.UserProfileId}/actions/${action.actionName}`,
            {
                PeopleDto: peopleDto,
                Comment: comment,
                DeclineReasons: declineReasons
            },
            this.apiEndPoint,
            true
        );
    }

    patchAndExecuteAction(action: StateAction, userProfileId: number, patchDocument: JsonPatchDocument, comment?: string, declineReasons?: string): Observable<IPeople> {
        return this.apiService.httpPatchRequest<IPeople>(
            `${this.apiDefaultPath}/${userProfileId}/actions/${action.actionName}`,
            {
                PatchDocument: patchDocument.patchOperations,
                Comment: comment,
                DeclineReasons: declineReasons
            },
            this.apiEndPoint,
            true
        );
    }

    getEntityWorkflowProgress(workflowInstanceId: string): Observable<boolean> {
        return this.apiService.httpGetRequest<boolean>(`WorkflowProgress/${PhxConstants.EntityType.UserProfile}/${workflowInstanceId}/IsInProgress`, this.generalServiceApiEndpoint);
    }

    hasReadAllNotes(id: number): Observable<boolean> {
        return this.apiService.httpGetRequest<boolean>(`${this.apiDefaultPath}/${id}/notes-read-check`, this.apiEndPoint);
    }

    getComplianceCheckResult(peopleDto: IPeople): Observable<boolean> {
        return this.apiService.httpPostRequest<boolean>(`${this.apiDefaultPath}/${peopleDto.UserProfile.UserProfileId}/compliance-check`, peopleDto, this.apiEndPoint, false);
    }

    getContactProfiles(contactId: number, contactVersion: number): Observable<IUserProfile[]> {
        const currentContactCache = this.contactProfileCache?.get(contactId);
        if (currentContactCache?.has(contactVersion)) {
            return of(currentContactCache?.get(contactVersion));
        } else {
            return this.apiService.httpGetRequest<Array<IUserProfile>>(`${this.apiDefaultPath}/${contactId}/${contactVersion}/contact-profiles`, this.apiEndPoint).pipe(
                tap(profiles => {
                    if (!this.contactProfileCache || !currentContactCache) {
                        this.contactProfileCache = new Map();
                    }
                    this.contactProfileCache.set(contactId, new Map());
                    this.contactProfileCache.get(contactId).set(contactVersion, profiles);
                })
            );
        }
    }

    getPeopleVersion(profileId: number, profileVersionId: number, contactVersionId: number): Observable<IPeople> {
        return this.apiService.httpGetRequest<IPeople>(`${this.apiDefaultPath}/${profileId}/${profileVersionId}/contact-version/${contactVersionId}`, this.apiEndPoint);
    }

    getVersions(contactId: number): Observable<EntityVersion[]> {
        return this.apiService.httpGetRequest<EntityVersion[]>(`${this.apiDefaultPath}/${contactId}/versions`, this.apiEndPoint);
    }

  getExistingContactProfiles(primaryEmail?: string): Observable<IContactProfileSummary[]> {
    let requestUrl = `${this.apiDefaultPath}/existing-contact-profiles`;
    if (primaryEmail) {
      requestUrl += `/${primaryEmail}`;
    }

    return this.apiService.httpGetRequest<IContactProfileSummary[]>(requestUrl, this.apiEndPoint);
  }

  getActiveInternalProfiles(): Observable<IContactProfileSummary[]> {
    return this.apiService.httpGetRequest<IContactProfileSummary[]>(`${this.apiDefaultPath}/active-internal-profiles`, this.apiEndPoint);
  }

  getContactsAssociatedWithUserProfile(userProfileId: number): Observable<IContact[]> {
    return this.apiService.httpGetRequest<IContact[]>(`${this.apiDefaultPath}/associated-contacts/${userProfileId}`, this.apiEndPoint);
  }

  reassign(reassignment: IReassign) {
    return this.apiService.httpPostRequest(`${this.apiDefaultPath}/reassign`, reassignment, this.apiEndPoint, false);
  }

  makePrimary(contactId: number, userProfileId: number) {
    const requestUrl = `${this.apiDefaultPath}/${contactId}/make-primary/${userProfileId}`;
    return this.apiService.httpPostRequest(
        requestUrl,
        null,
        this.apiEndPoint,
        true
    );
  }

  mergeContacts(contactId1: number, contactId2: number) {
    const requestUrl = `${this.apiDefaultPath}/contact-merge?contactId1=${contactId1}&contactId2=${contactId2}`;
    return this.apiService.httpPostRequest(
      requestUrl,
      null,
      this.apiEndPoint
    );
  }
}
