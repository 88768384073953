import { OnDestroy, Directive } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class BaseComponentOnDestroy implements OnDestroy {
  isDestroyed$ = new Subject<boolean>();

  ngOnDestroy() {
    this.isDestroyed$.next(true);
    this.isDestroyed$.complete();
  }

  takeUntilDestroyed = <T>(o$: Observable<T>): Observable<T> => {
    return o$.pipe(takeUntil(this.isDestroyed$));
  }
}
