import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Optional, Output, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl, Validator, FormControl } from '@angular/forms';

import { DxDateBoxComponent } from 'devextreme-angular';
import DevExpress from 'devextreme/bundles/dx.all';

@Component({
  selector: 'app-phx-date-box',
  templateUrl: './phx-date-box.component.html',
  styleUrls: ['./phx-date-box.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PhxDateBoxComponent implements ControlValueAccessor, Validator, AfterViewInit, OnDestroy {
  @Input() type: DevExpress.ui.dxDateBox.DateType = 'date';
  @Input() min: Date | number | string;
  @Input() max: Date | number | string;
  @Input() disabled = false;
  @Input() readOnly = false;
  @Input() showClearButton = false;
  @Input() width: string | number = 'auto'; // "55px", "80%", "auto", "inherit"
  @Input() displayFormat: Intl.DateTimeFormatOptions;
  @Input() maxZoomLevel: DevExpress.ui.dxCalendar.CalendarZoomLevel;
  @Input() minZoomLevel: DevExpress.ui.dxCalendar.CalendarZoomLevel;
  @Input() interval: number;
  @Input() pickerType?: DevExpress.ui.dxDateBox.DatePickerType;
  @Input() placeholder: string;
  @Input() allowUserInput = true;
  @Input() openOnFieldClick = true;
  @Input() resetInvalidValueOnOpen = true;
  @Input() value: any = null;

  @Output() valueChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() isValidChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() closed: EventEmitter<any> = new EventEmitter<any>();
  @Output() opened: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(DxDateBoxComponent, { static: false }) dxDateBox: DxDateBoxComponent;

  private isOpen = false;
  private isDestroyed = false;

  constructor(@Self() @Optional() public ngControl: NgControl, private cdr: ChangeDetectorRef) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  /* The getter can be removed as soon as devextreme incorporates Intl.DateTimeFormatOptions in their interfaces. They do support it. */
  get getExtremeFormat() {
    return this.displayFormat as any;
  }

  ngOnDestroy() {
    this.isDestroyed = true;
  }

  ngAfterViewInit(): void {
    const isValid = !this.dxDateBox || this.dxDateBox.isValid;
    this.isValidChange.emit(isValid);
  }

  writeValue(value: any) {
    this.value = value;
    if (!this.isDestroyed) {
      this.cdr.detectChanges();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange = _ => {};

  onTouched = () => {};

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
    if (!this.isDestroyed) {
      this.cdr.detectChanges();
    }
  }

  onKeyUp(event: { event: KeyboardEvent }) {
    // open the datepicker dropdown if the focus was given to this element by a tab key navigation
    if (!this.isOpen && event?.event?.code === 'Tab') {
      this.dxDateBox.instance.open();
    }
  }

  onValueChangedHandler(event: any) {
    this.value = event.value;
    if (event.event) {
      this.onChange(this.value);
    }
    this.onTouched();
    this.valueChanged.emit(event.value);
    if (!this.isDestroyed) {
      this.cdr.detectChanges();
    }
  }

  closedHandler(event: any) {
    this.closed.emit(event);
    this.isOpen = false;
  }

  openedHandler(event: any) {
    if (this.resetInvalidValueOnOpen && !this.dxDateBox.isValid) {
      this.dxDateBox.instance.reset();
    }
    this.opened.emit(event);
    this.isOpen = true;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  validate(c: FormControl) {
    return !this.dxDateBox || this.dxDateBox.isValid
      ? null
      : {
          phxDateBox: {
            valid: false
          }
        };
  }

  isValidChangeHandler(event: any) {
    this.isValidChange.emit(event);
    if (this.ngControl != null) {
      this.ngControl.control.updateValueAndValidity({ emitEvent: false });
    }
  }
}
