export const AccountModuleResourceKeys = {
  accessCode: {
    /**
    * Login with my password
    */
    pwdLoginMessage: 'account.accessCode.pwdLoginMessage',
    /**
    * Start Registration
    */
    startRegistrationBtn: 'account.accessCode.startRegistrationBtn',
    /**
    * Enter your access code to login to FlexBackOffice
    */
    accessCodeLabel: 'account.accessCode.accessCodeLabel',
    /**
    * Error -
    */
    errorLabel: 'account.accessCode.errorLabel',
    /**
    * Log In
    */
    logInLabel: 'account.accessCode.logInLabel',
    /**
    * Loading . . .
    */
    loadingMessage: 'account.accessCode.loadingMessage',
    /**
    * You must be registered to login with an access code
    */
    registrationRequiredMessage: 'account.accessCode.registrationRequiredMessage',
    /**
    * Invalid token.
    */
    invalidToken: 'account.accessCode.invalidToken',
  },
  portal: {
    /**
    * Please continue to the new portal below to find information related to your work engagement.
    */
    portalMessageText: 'account.portal.portalMessageText',
    /**
    * We have moved!
    */
    portalMessageTitle: 'account.portal.portalMessageTitle',
    /**
    * Learn more about the move
    */
    learnMoreLink: 'account.portal.learnMoreLink',
    /**
    * Continue to Contractor Portal
    */
    goToPortalBtn: 'account.portal.goToPortalBtn',
  },
  login: {
    /**
    * Logging out…
    */
    loggingOutMessage: 'account.login.loggingOutMessage',
    /**
    * Login has failed.
    */
    loginFailedMessage: 'account.login.loginFailedMessage',
    /**
    * Remember me
    */
    rememberMeLabel: 'account.login.rememberMeLabel',
    /**
    * Password
    */
    pwdLabel: 'account.login.pwdLabel',
    /**
    * Sign In
    */
    signInLabel: 'account.login.signInLabel',
    /**
    * Error -
    */
    errorLabel: 'account.login.errorLabel',
    /**
    * Loading . . .
    */
    loadingMessage: 'account.login.loadingMessage',
    /**
    * Username or Email
    */
    usernameEmailLabel: 'account.login.usernameEmailLabel',
    /**
    * Recover your password
    */
    pwdRecoverMessage: 'account.login.pwdRecoverMessage',
  },
  forgot: {
    /**
    * An email has been sent with your password recovery instructions. Redirecting back to the login page ...
    */
    pwdForgotSentMessage: 'account.forgot.pwdForgotSentMessage',
    /**
    * Enter the email you use to login to FlexBackOffice
    */
    emailId: 'account.forgot.emailId',
    /**
    * The current email entered is incorrect.
    */
    emailIncorrectErrorMessage: 'account.forgot.emailIncorrectErrorMessage',
    /**
    * Recover
    */
    recoverButton: 'account.forgot.recoverButton',
    /**
    * There was an error resetting your password.
    */
    pwdResetErrorMessage: 'account.forgot.pwdResetErrorMessage',
  },
  reset: {
    /**
    * Your password has been reset. Redirecting …
    */
    pwdResetRedirectionMessage: 'account.reset.pwdResetRedirectionMessage',
    /**
    * Reset the password for your account.
    */
    pwdResetMessage: 'account.reset.pwdResetMessage',
    /**
    * Password
    */
    pwdLabel: 'account.reset.pwdLabel',

    /**
    * Confirm Password
    */
    confirmPwdLabel: 'account.reset.confirmPwdLabel',
    /**
    * Reset Password
    */
    resetPwdLabel: 'account.reset.resetPwdLabel',
    /**
    * The current password entered is incorrect.
    */
    pwdIncorrectErrorMessage: 'account.reset.pwdIncorrectErrorMessage',
    /**
    * Error resetting your password
    */
    pwdResetErrorMessage: 'account.reset.pwdResetErrorMessage',
  },
  register: {
    /**
    * An error occurred.
    */
    genericErrorMessage: 'account.register.genericErrorMessage',
    /**
    * Password
    */
    pwdLabel: 'account.register.pwdLabel',
    /**
    * It looks like you have already registered an account on FlexBackOffice! One moment while we redirect you to the login page....
    */
    existingUserMessage: 'account.register.existingUserMessage',
    /**
    * Type Password Again
    */
    confirmPwdPlaceholder: 'account.register.confirmPwdPlaceholder',
    /**
    * Language
    */
    languageLabel: 'account.register.languageLabel',
    /**
    * Type Password
    */
    pwdPlaceholder: 'account.register.pwdPlaceholder',
    /**
    * Username
    */
    usernameLabel: 'account.register.usernameLabel',
    /**
    * Register
    */
    registerButton: 'account.register.registerButton',
    /**
    * Confirm Password
    */
    confirmPwdLabel: 'account.register.confirmPwdLabel',
  },
};
