<ng-container *ngIf="history$ | async as history">
  <ng-container *ngIf="history.length; else noHistoryFound">
    <form
      *ngIf="searchForm"
      [formGroup]="searchForm"
      class="searchForm"
    >
      <div class="form-group">
        <phx-form-control
          [editable]="true"
          [control]="searchForm.controls.statusCode"
          [layoutType]="layoutType.InputOnly"
        >
          <app-phx-select-box
            [items]="searchStatusList"
            textField="value"
            valueField="key"
            [formControl]="searchForm.controls.statusCode"
            placeholder="Select Status"
          >
          </app-phx-select-box>
        </phx-form-control>
      </div>
      <div class="form-group">
        <phx-form-control
          [editable]="true"
          [control]="searchForm.controls.userId"
          [layoutType]="layoutType.InputOnly"
        >
          <app-phx-select-box
            [items]="searchUsersList"
            textField="value"
            valueField="key"
            [formControl]="searchForm.controls.userId"
            placeholder="Select User"
          >
          </app-phx-select-box>
        </phx-form-control>
      </div>
      <div
        *ngIf="entityActionList.length"
        class="form-group"
      >
        <phx-form-control
          [editable]="true"
          [control]="searchForm.controls.action"
          [layoutType]="layoutType.InputOnly"
        >
          <app-phx-select-box
            [items]="entityActionList"
            [formControl]="searchForm.controls.action"
            placeholder="Select Action"
          >
          </app-phx-select-box>
        </phx-form-control>
      </div>
      <div class="form-group">
        <phx-form-control
          [editable]="true"
          [layoutType]="layoutType.InputOnly"
          [control]="searchForm.controls.fieldName"
        >
          <input
            class="form-control"
            type="text"
            name="search"
            formControlName="fieldName"
            autocomplete="off"
            placeholder="Field Name"
          />
        </phx-form-control>
      </div>
      <ng-container *ngIf="isSearchEnabled">
        <p
          class="clearFilters"
          (click)="clearFilters()"
        >
          Clear Filters
        </p>
        <p class="resultsCount">
          <span class="bold">{{ searchFilterMetaData.count }}</span> result(s)
        </p>
      </ng-container>
    </form>

    <app-phx-accordion
      [closeOthers]="!canPerformSearch"
    >
      <app-phx-accordion-group
        *ngFor="let version of history | phxWorkflowHistorySearch : searchFilterMetaData; trackBy: trackByFn"
        [showToggle]="false"
        [(isOpen)]="version.isOpen"
        panelClass="workflow-history-panel"
      >
        <div
          accordion-heading
          class="wh-header gray"
        >
          <span class="wh-panel-icon">
            <i
              class="glyphicon"
              [ngClass]="{ 'glyphicon-menu-up': version.isOpen, 'glyphicon-menu-down': !version.isOpen }"
            ></i>
          </span>
          <div class="wh-header-content">
            <span class="width-adjust">
              <span class="d-block"> Version: {{ version.versionId }} </span>
              <span class="wh-text-blue d-block">
                {{ 'workflow.stateHistory.Status' | phxTranslate }}
                <ng-container *ngTemplateOutlet="statusText; context: { code: version.currentStatusCode || version.recentStatusCode }"></ng-container>
              </span>
            </span>
            <span>
              Workflow ID:
              <!-- <i class="fa fa-info-circle" data-toggle="tooltip" title="View the workflow on Workflow Server"></i> -->
              <span class="id-blue-text"> {{ version.workflowInstanceId }}</span>
              <span
                class="id-blue-text"
                (click)="copyWorkflowInstanceId(version.workflowInstanceId, $event)"
                ><strong>Copy</strong></span
              >
            </span>
            <span class="wh-text-blue align-right d-block auto-left">
              {{ 'workflow.stateHistory.Effective' | phxTranslate }}
              {{ version.recentStatusTime | date : phxConstant.DateFormat.mediumDate }}
            </span>
          </div>
        </div>
        <div class="content-group workflow-history-content">
          <div
            class="time-block"
            *ngFor="let status of version.statusList; last as isLastStatus"
            [ngClass]="{ last: !!isLastStatus, current: checkIsCurrentStatus(version, status), future: !!status.isFutureStep, noBlockBorder: isSearchEnabled }"
          >
            <div
              class="time-block-container"
              [ngClass]="{ isOpen: status.logsList.length && status.hasPropertyGroups && status.isOpen }"
            >
              <div class="content">
                <app-phx-accordion
                   *ngIf="status.logsList.length; else noStatusChangesHeaderTemplate"
                >
                  <app-phx-accordion-group
                    [(isOpen)]="status.isOpen"
                    panelClass="workflow-history-panel"
                    [showToggle]="false"
                  >
                    <div
                      accordion-heading
                      class="wh-header"
                    >
                      <span
                        class="wh-panel-icon"
                        *ngIf="status.hasPropertyGroups"
                      >
                        <i
                          class="glyphicon"
                          [ngClass]="{ 'glyphicon-menu-up': status.isOpen, 'glyphicon-menu-down': !status.isOpen }"
                        ></i>
                      </span>
                      <div class="wh-header-content">
                        <div [ngClass]="{ 'wh-text-blue': status.isFutureStep }">
                          {{ status.isNextStep ? 'Next: ' : '' }}
                          <ng-container *ngTemplateOutlet="statusText; context: { code: status.statusCode }"></ng-container>
                        </div>
                        <div class="wh-text-blue">
                          <ng-container *ngIf="status.entityAction">
                            <strong>
                              {{ status.entityAction }}
                            </strong>
                            by
                          </ng-container>

                          {{ status.logsUsers.length > 1 ? status.logsUsers[0].name + ' and ' + (status.logsUsers.length - 1) + ' other' : status.logsUsers[0].name }}
                          <span
                            class="link-text"
                            *ngIf="status.comment && !(entityType === phxConstant.CodeEntityType.UserProfile && status.entityAction === 'ContactMerge')"
                            (click)="showComment($event, status.comment, status.entityAction)"
                            ><strong>View reason</strong></span
                          >
                        </div>
                        <div class="wh-text-blue">
                          {{ status.recentLogTime | date : phxConstant.DateFormat.medium }}
                        </div>
                      </div>
                    </div>
                    <div
                      class="wh-container"
                      *ngIf="status.hasPropertyGroups"
                    >
                      <div
                        class="wh-item"
                        *ngFor="let log of status.logsList"
                      >
                        <p class="d-flex justify-content-between">
                          <span class="wh-user">
                            <i class="material-icons wh-user-icon">account_circle</i>
                            <span class="wh-user-name">{{ log.userName }}</span>
                          </span>
                          <span class="wh-text-blue">
                            {{ log.createdOn | date : phxConstant.DateFormat.medium }}
                          </span>
                        </p>
                        <div class="row margin-0">
                          <div class="col-xs-4 wh-text-blue">Field Name</div>
                          <div class="col-xs-4 wh-text-blue">Old</div>
                          <div class="col-xs-4 wh-text-blue">New</div>
                        </div>

                        <div
                          class="wh-user-differences"
                          *ngFor="let propertyGroup of log.propertyGroups"
                        >
                          <div class="row margin-0 padding-10-v-b">
                            <div
                              class="col-xs-12 bold"
                              *ngIf="propertyGroup.propertyPath !== 'default'"
                            >
                              {{ propertyGroup.propertyPath | workflowHistoryPropertyPath }}
                            </div>
                          </div>
                          <div
                            class="row margin-0 padding-10-v-b"
                            *ngFor="let difference of propertyGroup.differences"
                          >
                            <div class="col-xs-4">{{ difference.displayName || difference.fieldName }}</div>
                            <div class="col-xs-4">{{ difference.oldValue }}</div>
                            <div class="col-xs-4">{{ difference.newValue }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </app-phx-accordion-group>
                </app-phx-accordion>
                <ng-template #noStatusChangesHeaderTemplate>
                  <div class="wh-header">
                    <div class="wh-header-content">
                      <div [ngClass]="{ 'wh-text-blue': status.isFutureStep }">
                        {{ status.isNextStep ? 'Next: ' : '' }}
                        <ng-container *ngTemplateOutlet="statusText; context: { code: status.statusCode }"></ng-container>
                      </div>
                      <div class="wh-text-blue">
                        {{ status.logsUsers[0]?.name }}
                      </div>
                      <div class="wh-text-blue">
                        {{ status.recentLogTime | date : phxConstant.DateFormat.medium }}
                      </div>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </app-phx-accordion-group>
    </app-phx-accordion>
  </ng-container>

  <ng-template #noHistoryFound>
    <div *ngIf="showNoHistoryMessage">No History Found</div>
  </ng-template>
</ng-container>

<app-phx-modal
  title="{{actionName}} Reason"
  #actionReasonModal
>
  <p>{{ comment }}</p>
</app-phx-modal>

<ng-template
  #statusText
  let-code="code"
>
  <ng-container *ngIf="statusCodeGroupName; else otherwise">
    {{ code | CodeValueByCode : statusCodeGroupName }}
  </ng-container>

  <ng-template #otherwise>
    <ng-container>
      {{ code | phxWorkflowStatusName : entityType }}
    </ng-container>
  </ng-template>
</ng-template>
