import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';

import { IDropdown } from '../../model';
import { BaseComponentOnDestroy } from '../../epics/base-component-on-destroy';
import { PhoenixCommonModuleResourceKeys } from '../../PhoenixCommonModule.resource-keys';

@Component({
  selector: 'app-phx-mat-searchable-dropdown',
  templateUrl: './phx-mat-searchable-dropdown.component.html',
  styleUrls: ['./phx-mat-searchable-dropdown.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class PhxMatSearchableDropdownComponent extends BaseComponentOnDestroy implements OnInit, OnDestroy, OnChanges {
  @Input() selectFormControl: UntypedFormControl;
  @Input() dropdownData: Array<IDropdown>;
  @Input() placeholderLabel = 'Search...';
  @Input() displayLabel = '';
  @Input() noEntriesFoundLabel = 'No data found';
  @Input() validationMessage = 'This is a required field';
  @Input() showErrors = false;
  @Input() showActionButtons = true;
  @Input() multiple = true;
  @Output() openedChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() dataReset: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild(CdkVirtualScrollViewport) cdkVirtualScrollViewPort: CdkVirtualScrollViewport;
  @ViewChild('searchFilterMatInput') searchFilterMatInput: ElementRef<HTMLInputElement>;
  searchFilterFormControl: UntypedFormControl = new UntypedFormControl();
  filteredDropdownData: ReplaySubject<Array<IDropdown>> = new ReplaySubject<Array<IDropdown>>(1);
  isDropdownOpen = false;
  selectedItemsDisplayText = '';
  phoenixCommonModuleResourceKeys = PhoenixCommonModuleResourceKeys;

  constructor(private cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.searchFilterFormControl.valueChanges.pipe(
      debounceTime(100),
      takeUntil(this.isDestroyed$))
      .subscribe(() => this.filterDropdownData());

    if (this.selectFormControl) {
      this.setSelectedItemDisplayText(this.selectFormControl.value);
    }

    this.selectFormControl.valueChanges
      .pipe(takeUntil(this.isDestroyed$))
      .subscribe(value => this.setSelectedItemDisplayText(value));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.dropdownData?.currentValue) {
      this.setDropdownData(this.dropdownData);
      this.setSelectedItemDisplayText(this.selectFormControl.value);
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  trackByFn(index, item) {
    return item.id;
  }

  reset() {
    this.dataReset.next(true);
  }

  openDropdown() {
    this.isDropdownOpen = true;
    this.cdr.detectChanges();

    if (this.searchFilterMatInput?.nativeElement) {
      setTimeout(() => {
        this.searchFilterMatInput.nativeElement.focus();
      });
    }
    this.openedChange.emit(true);
    this.cdkVirtualScrollViewPort.setRenderedRange({
      start: 0,
      end: 10
    });
    this.cdkVirtualScrollViewPort.checkViewportSize();
  }

  closeDropdown() {
    this.setSelectedItemDisplayText(this.selectFormControl.value);
    this.isDropdownOpen = false;
    this.openedChange.emit(false);
  }

  onSingleSelectionItemClick(item: IDropdown) {
    this.selectFormControl.patchValue(item.id);
    this.closeDropdown();
  }

  private setSelectedItemDisplayText(value: Array<number>) {
    if (Array.isArray(value) && Array.isArray(this.dropdownData) && this.dropdownData.length > 0) {
      this.selectedItemsDisplayText = this.dropdownData.filter((item) => value.includes(item.id)).map((item) => item.name).join(', ');
      this.cdr.markForCheck();
    }
  }

  private filterDropdownData() {
    if (!this.dropdownData) {
      return;
    }

    let search = this.searchFilterFormControl.value;
    if (!search) {
      this.setDropdownData(this.dropdownData);
    } else {
      search = search.toLowerCase();
      const filteredRecords = this.dropdownData.filter(dataItem => dataItem.name.toLowerCase().indexOf(search) > -1);
      this.setDropdownData(filteredRecords);
    }
  }

  private setDropdownData(dropdownData: Array<IDropdown>) {
    this.filteredDropdownData.next(dropdownData);
    this.cdr.detectChanges();
  }
}
