import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { FilterTemplateSaveForm } from '../../models/filter-template-save-form.model';

@Injectable()
export class FilterTemplateSaveService {
  private saveSettings$ = new Subject<Partial<FilterTemplateSaveForm>>();
  private hasSaved$ = new Subject<void>();

  public get filterTemplateSaveSettings$(): Observable<Partial<FilterTemplateSaveForm>> {
    return this.saveSettings$.asObservable();
  }

  public get hasSavedFilterTemplate$(): Observable<void> {
    return this.hasSaved$.asObservable();
  }

  public saveFilterTemplate(settings: Partial<FilterTemplateSaveForm>): void {
    this.saveSettings$.next(settings);
  }

  public notifyHasFinishedSaving(): void {
    this.hasSaved$.next();
  }
}
