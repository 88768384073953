import { Component, OnInit, HostListener, Input, OnChanges, SimpleChanges } from '@angular/core';
import { PhxButton } from '../../model/phx-button';
import { PhxConstants } from '../../model/phx-constants';

@Component({
  selector: 'app-phx-responsive-buttons',
  templateUrl: './phx-responsive-buttons.component.html',
  styleUrls: ['./phx-responsive-buttons.component.less']
})
export class PhxResponsiveButtonsComponent implements OnInit, OnChanges {
  @Input() buttons: PhxButton[];
  @Input() placedOnModal: boolean = false;
  @Input() btnClasses: string[] = [];
  showButtonsLeft: boolean = false;

  isMobile = false;

  @HostListener('window:resize', ['$event']) onResize(event) {
    this.isWindowMobileSize(event.target);
  }

  ngOnInit() {
    this.isWindowMobileSize(window);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.buttons) {
      this.showButtonsLeft = this.buttons?.some(x => x.isTertiaryAction);
    }
  }

  isWindowMobileSize(window) {
    this.isMobile = window.innerWidth < PhxConstants.MOBILE_WIDTH;
  }

  onButtonClick(button: PhxButton) {
    button.action();
  }

}
