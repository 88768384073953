import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-bouncing-balls',
  templateUrl: './bouncing-balls.component.html',
  styleUrls: ['./bouncing-balls.component.less']
})
export class BouncingBallsComponent {
  @Input() color: string;
  @Input() isBouncing: boolean = true;
}
