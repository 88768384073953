import { IBusinessTaxEINNumberInUse } from '../../organization/models';
import { IRuleResult } from './rule-engine';
import { StateActionButtonStyle } from './state-action';


export interface IAvailableStateAction {
  ActionName: string;
  ButtonType: StateActionButtonStyle;
  DisplayName: string;
  SortOrder: number;
}

export interface IActionExecutionMessage {
  Success: string;
  Failure: string;
}

export interface IEntityActionResult {
  WorkflowActions: Array<IAvailableStateAction>,
  FunctionalActions: Array<IActionPermission>
}

export interface IActionPermission extends IAvailableStateAction {
  EntityType: string;
  IsAuditable: boolean;
  ActionConfig: IActionPermissionAction;
  DisplayType: string;
}

export interface IActionPermissionAction {
  ActionTypeCode: string;
  ActionName: string;
  Execution: Array<IActionExecution>;
}

export interface IActionExecution {
  ExecutionType: ActionPermisionExecutionType,
  IsCommand: boolean;
  ActionUrl: string;
  Data: any;
  FeedbackMessages: IActionExecutionMessage
}

export enum ActionPermisionExecutionType {
  HttpRequest = 'HttpRequest',
  RouterNavigate = 'RouterNavigate'
}


export interface IDuplicateCheckNumberInUseResponse  {
  Done: {
    Result: IBusinessTaxEINNumberInUse[];
  };
}


// New interfaces for ActionExecutor/Gateway?
export interface IErrorResponse {
  status: number;
  errorType: string;
  type: string;
  title: string;
  detail: string;
  instance: string;
}

export interface IValidationErrorResponse extends IErrorResponse {
  validationFailures: IRuleResult[];
  entityHasChanged: boolean;
}
