import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { PhxConstants } from '../../model';
import UserProfileType = PhxConstants.UserProfileType;
import { MODULE_ROUTES } from '../../constants/module-routes.const';

export const canActivateInternalProfileGuard: CanActivateFn = () => {
  const authService = inject(AuthService);
  const router = inject(Router);

  return new Promise(async (resolve, reject) => {
    try {
      const user = await authService.fetchCurrentProfile();
      const hasAccess = user?.ProfileTypeId === UserProfileType.Internal;

      if (!hasAccess) {
        router.navigate([`/${MODULE_ROUTES.MAIN}`, MODULE_ROUTES.NO_ACCESS], { replaceUrl: true });
      }

      return resolve(hasAccess);
    } catch (e) {
      reject(e);
    }
  });
};
