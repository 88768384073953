import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PhxLocalizationService } from '..';

@Injectable()
export class LocaleInterceptor implements HttpInterceptor {

  constructor(
    private localizationService: PhxLocalizationService
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    var locale = this.localizationService.currentLang;
    return next.handle(this.addDefaultHeaders(req, locale));
  }

  private addDefaultHeaders(request: HttpRequest<any>, locale: string): HttpRequest<any> {
    if (locale) {
      return request.clone({
        setHeaders: {
          "fbo-locale": locale
        }
      });
    }

    return request;
  }
}
