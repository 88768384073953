export interface IRecordOfEmploymentValidationResult {
  individualValidationResults: Array<IRecordOfEmploymentIndividualValidationResult>;
  groupValidationResults: IRecordOfEmploymentGroupValidationResult;
}

export interface IRecordOfEmploymentIndividualValidationResult {
  workOrderFullNumber: string;
  hasNoGovernmentRuling: boolean;
  hasRequestRoeInOffboardingChecklist: boolean;
  areAllWorkOrderTimesheetsApproved: boolean;
  areAllTransactionsInPaidOrSuppressedStatus: boolean;
  areAllVacationPaymentsComplete: boolean;
  isSuccess: boolean;
}

export interface IRecordOfEmploymentGroupValidationResult {
  areAllWorkOrdersSequential: boolean;
  areAllTimesheetCyclesConsistent: boolean;
  areAllPayCyclesConsistent: boolean;
}

export const RecordOfEmploymentValidationErrorMessages = {
  // individual validation messages
  hasNoGovernmentRuling: 'The profile is configured under the SP Government Ruling and does not require a Record of Employment (ROE).',
  hasRequestRoeInOffboardingChecklist: 'A Record of Employment (ROE) has not been requested for the selected work order. Please ensure this is completed before proceeding.',
  hasTimesheets: 'There are no timesheets for the selected work order. A Record of Employment (ROE) cannot be generated without approved timesheets.',
  areAllWorkOrderTimesheetsApproved: 'All timesheets for the selected work order are not yet approved. Please ensure they are approved before proceeding.',
  areAllTransactionsInPaidOrSuppressedStatus: 'Transactions for the selected work order are pending payment to the consultant. Please ensure the consultant is paid before proceeding.',
  areAllVacationPaymentsComplete: 'Vacation payment is still pending. Please complete the payment before proceeding.',
  // group validation messages
  areAllWorkOrdersSequential: 'The selected work orders are not consecutive. Please verify if a separate Record of Employment (ROE) is needed for each work order.',
  areAllTimesheetCyclesConsistent: 'Please review whether a separate Record of Employment (ROE) is needed for each selected work order, as the pay or time cycles differ.',
  areAllPayCyclesConsistent: 'The selected work orders have different pay cycles, so this Record of Employment (ROE) must be processed manually.'
};
