import { DialogRef } from '@angular/cdk/dialog';
import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';

import { PhxDialogComponentConfigModel, PhxDialogComponentEventEmitterInterface, PhxDialogComponentConfigModelButton } from './phx-dialog.component.model';
import { DialogService } from '../../services/dialog.service';

@Component({
  selector: 'app-phx-dialog',
  templateUrl: './phx-dialog.component.html',
  styleUrls: ['./phx-dialog.component.less']
})
export class PhxDialogComponent {
  @Input()
  config: PhxDialogComponentConfigModel;
  @Output()
  callback: EventEmitter<PhxDialogComponentEventEmitterInterface> = new EventEmitter();
  @ViewChild('phxDialogTemplate', { static: true })
  phxDialogTemplate: TemplateRef<void>;
  isValidDate = false;
  private dialogRef?: DialogRef<void>;

  constructor(private dialogService: DialogService) {}

  public open(): void {
    this.dialogRef = this.dialogService.showInPopup(this.phxDialogTemplate, {
      disableClose: false
    });
  }

  public close(): void {
    this.dialogRef?.close();
  }

  public onButtonClick(button: PhxDialogComponentConfigModelButton): void {
    this.close();
    const callBackObj: PhxDialogComponentEventEmitterInterface = {
      buttonId: button.Id,
      config: this.config
    };
    if (button.ClickEvent) {
      button.ClickEvent(callBackObj);
    } else {
      this.callback.emit(callBackObj);
    }
  }

  public isValidChangeHandler(isValidDate: boolean): void {
    this.isValidDate = isValidDate;
  }
}
